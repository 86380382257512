@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,900&family=Space+Grotesk:wght@300;500;700&display=swap");
.nav-link {
  font-size: 17px;
  font-weight: 400;
}
.nav-link.active {
  color: #B5BE00;
}

.btn-white {
  color: #FFF !important;
}
.btn-white:hover {
  color: #b5be00 !important;
}

.btn-warranty {
  background: #FFF !important;
}
.btn-warranty:hover {
  background-color: #E6ECF0 !important;
  border: 2px solid #6a8a9c !important;
}

#sidebarMenu a.nav-link {
  color: #FFF;
}
#sidebarMenu a.nav-link.active,
#sidebarMenu a:hover.nav-link {
  color: #b5be00;
}

#fileDocuments a.nav-link.active {
  color: #b5be00 !important;
  border: 2px solid #1e4d60 !important;
}

.z-1 {
  z-index: 1;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-4 {
  z-index: 4;
}

.z-5 {
  z-index: 5;
}

.fs-1 {
  font-size: 1px !important;
}

.fs-2 {
  font-size: 2px !important;
}

.fs-3 {
  font-size: 3px !important;
}

.fs-4 {
  font-size: 4px !important;
}

.fs-5 {
  font-size: 5px !important;
}

.fs-6 {
  font-size: 6px !important;
}

.fs-7 {
  font-size: 7px !important;
}

.fs-8 {
  font-size: 8px !important;
}

.fs-9 {
  font-size: 9px !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-11 {
  font-size: 11px !important;
}

.fs-12 {
  font-size: 12px !important;
}

.fs-13 {
  font-size: 13px !important;
}

.fs-14 {
  font-size: 14px !important;
}

.fs-15 {
  font-size: 15px !important;
}

.fs-16 {
  font-size: 16px !important;
}

.fs-17 {
  font-size: 17px !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-19 {
  font-size: 19px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.fs-21 {
  font-size: 21px !important;
}

.fs-22 {
  font-size: 22px !important;
}

.fs-23 {
  font-size: 23px !important;
}

.fs-24 {
  font-size: 24px !important;
}

.fs-25 {
  font-size: 25px !important;
}

.fs-26 {
  font-size: 26px !important;
}

.fs-27 {
  font-size: 27px !important;
}

.fs-28 {
  font-size: 28px !important;
}

.fs-29 {
  font-size: 29px !important;
}

.fs-30 {
  font-size: 30px !important;
}

.fs-31 {
  font-size: 31px !important;
}

.fs-32 {
  font-size: 32px !important;
}

.fs-33 {
  font-size: 33px !important;
}

.fs-34 {
  font-size: 34px !important;
}

.fs-35 {
  font-size: 35px !important;
}

.fs-36 {
  font-size: 36px !important;
}

.fs-37 {
  font-size: 37px !important;
}

.fs-38 {
  font-size: 38px !important;
}

.fs-39 {
  font-size: 39px !important;
}

.fs-40 {
  font-size: 40px !important;
}

.fs-41 {
  font-size: 41px !important;
}

.fs-42 {
  font-size: 42px !important;
}

.fs-43 {
  font-size: 43px !important;
}

.fs-44 {
  font-size: 44px !important;
}

.fs-45 {
  font-size: 45px !important;
}

.fs-46 {
  font-size: 46px !important;
}

.fs-47 {
  font-size: 47px !important;
}

.fs-48 {
  font-size: 48px !important;
}

.fs-49 {
  font-size: 49px !important;
}

.fs-50 {
  font-size: 50px !important;
}

.fs-51 {
  font-size: 51px !important;
}

.fs-52 {
  font-size: 52px !important;
}

.fs-53 {
  font-size: 53px !important;
}

.fs-54 {
  font-size: 54px !important;
}

.fs-55 {
  font-size: 55px !important;
}

.fs-56 {
  font-size: 56px !important;
}

.fs-57 {
  font-size: 57px !important;
}

.fs-58 {
  font-size: 58px !important;
}

.fs-59 {
  font-size: 59px !important;
}

.fs-60 {
  font-size: 60px !important;
}

.mt-n2 {
  margin-top: -2px;
}

.carousel-control-prev {
  left: 80px !important;
}

.carousel-control-next {
  right: 80px !important;
}

h1,
.h1 {
  line-height: 56px;
}

h2,
.h2 {
  line-height: 45px;
}

h3,
.h3 {
  line-height: 36px;
}

.font-weight-semi-bold {
  font-weight: 600;
}

.tooltip {
  top: -1px !important;
  z-index: 10;
  padding: 0;
}

.modal-lg {
  max-width: 560px !important;
}

.carousel-indicators {
  visibility: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.max-view-width {
  max-width: 1420px;
}

#notifications::after,
#profile::after {
  content: none;
}

#homeDetailCard {
  height: 706px;
}

#homeStatusCard,
#homeScheduleCard {
  height: 706px;
}

#homeScheduleCard {
  max-width: 455px;
}

#breadcrumbs {
  margin-top: -1px;
}

.form-control.is-valid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.was-validated .form-select:valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: none !important;
  padding-right: 2.25rem;
}

/*!
 * Bootstrap  v5.3.1 (https://getbootstrap.com/)
 * Copyright 2011-2023 The Bootstrap Authors
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root,
[data-bs-theme=light] {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-black: #000;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #1e4d60;
  --bs-secondary: #6a8a9c;
  --bs-tertiary: #D3DCE1;
  --bs-success: #b5be00;
  --bs-dark: #3a3a3a;
  --bs-light: #f6f6f6;
  --bs-white: #ffffff;
  --bs-danger: #080808;
  --bs-red: #DA0000;
  --bs-pink: #ec008c;
  --bs-orange: #f2c900;
  --bs-yellow: #f2c900;
  --bs-green: #00c608;
  --bs-blue: #0073b4;
  --bs-cyan: #02c2dc;
  --bs-grey: #BEB5B5;
  --bs-blue-alt: #3453c2;
  --bs-blue-90: rgba(30, 77, 96, 0.9647058824);
  --bs-light-blue: #E6ECF0;
  --bs-dark-blue: #183E4D;
  --bs-light-secondary: #e5e5e5;
  --bs-secondary-10: #D5DBDF;
  --bs-secondary-20: #FBFDFF;
  --bs-dark-grey: #8A8A8A;
  --bs-dark-pink: #BD0071;
  --bs-inactive: #D9D9D9;
  --bs-accent: #b5be00;
  --bs-primary-rgb: 30, 77, 96;
  --bs-secondary-rgb: 106, 138, 156;
  --bs-tertiary-rgb: 211, 220, 225;
  --bs-success-rgb: 181, 190, 0;
  --bs-dark-rgb: 58, 58, 58;
  --bs-light-rgb: 246, 246, 246;
  --bs-white-rgb: 255, 255, 255;
  --bs-danger-rgb: 8, 8, 8;
  --bs-red-rgb: 218, 0, 0;
  --bs-pink-rgb: 236, 0, 140;
  --bs-orange-rgb: 242, 201, 0;
  --bs-yellow-rgb: 242, 201, 0;
  --bs-green-rgb: 0, 198, 8;
  --bs-blue-rgb: 0, 115, 180;
  --bs-cyan-rgb: 2, 194, 220;
  --bs-grey-rgb: 190, 181, 181;
  --bs-blue-alt-rgb: 52, 83, 194;
  --bs-blue-90-rgb: 30, 77, 96;
  --bs-light-blue-rgb: 230, 236, 240;
  --bs-dark-blue-rgb: 24, 62, 77;
  --bs-light-secondary-rgb: 229, 229, 229;
  --bs-secondary-10-rgb: 213, 219, 223;
  --bs-secondary-20-rgb: 251, 253, 255;
  --bs-dark-grey-rgb: 138, 138, 138;
  --bs-dark-pink-rgb: 189, 0, 113;
  --bs-inactive-rgb: 217, 217, 217;
  --bs-accent-rgb: 181, 190, 0;
  --bs-primary-text-emphasis: #0c1f26;
  --bs-secondary-text-emphasis: #2a373e;
  --bs-success-text-emphasis: #0a3622;
  --bs-info-text-emphasis: #055160;
  --bs-warning-text-emphasis: #664d03;
  --bs-danger-text-emphasis: #58151c;
  --bs-light-text-emphasis: #495057;
  --bs-dark-text-emphasis: #495057;
  --bs-primary-bg-subtle: #d2dbdf;
  --bs-secondary-bg-subtle: #e1e8eb;
  --bs-success-bg-subtle: #d1e7dd;
  --bs-info-bg-subtle: #cff4fc;
  --bs-warning-bg-subtle: #fff3cd;
  --bs-danger-bg-subtle: #f8d7da;
  --bs-light-bg-subtle: #fcfcfd;
  --bs-dark-bg-subtle: #ced4da;
  --bs-primary-border-subtle: #a5b8bf;
  --bs-secondary-border-subtle: #c3d0d7;
  --bs-success-border-subtle: #a3cfbb;
  --bs-info-border-subtle: #9eeaf9;
  --bs-warning-border-subtle: #ffe69c;
  --bs-danger-border-subtle: #f1aeb5;
  --bs-light-border-subtle: #e9ecef;
  --bs-dark-border-subtle: #adb5bd;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-font-sans-serif: Lato, sans-serif;
  --bs-font-monospace: Lato, sans-serif;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Lato, sans-serif;
  --bs-body-font-size: 1.1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #3a3a3a;
  --bs-body-color-rgb: 58, 58, 58;
  --bs-body-bg: #ffffff;
  --bs-body-bg-rgb: 255, 255, 255;
  --bs-emphasis-color: #000;
  --bs-emphasis-color-rgb: 0, 0, 0;
  --bs-secondary-color: rgba(58, 58, 58, 0.75);
  --bs-secondary-color-rgb: 58, 58, 58;
  --bs-secondary-bg: #e9ecef;
  --bs-secondary-bg-rgb: 233, 236, 239;
  --bs-tertiary-color: rgba(58, 58, 58, 0.5);
  --bs-tertiary-color-rgb: 58, 58, 58;
  --bs-tertiary-bg: #f8f9fa;
  --bs-tertiary-bg-rgb: 248, 249, 250;
  --bs-heading-color: #1e4d60;
  --bs-link-color: #3a3a3a;
  --bs-link-color-rgb: 58, 58, 58;
  --bs-link-decoration: none;
  --bs-link-hover-color: #CAD63E;
  --bs-link-hover-color-rgb: 202, 214, 62;
  --bs-link-hover-decoration: none;
  --bs-code-color: #d63384;
  --bs-highlight-bg: #fff3cd;
  --bs-border-width: 1px;
  --bs-border-style: solid;
  --bs-border-color: #dee2e6;
  --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
  --bs-border-radius: 0.375rem;
  --bs-border-radius-sm: 0.25rem;
  --bs-border-radius-lg: 0.5rem;
  --bs-border-radius-xl: 1rem;
  --bs-border-radius-xxl: 2rem;
  --bs-border-radius-2xl: var(--bs-border-radius-xxl);
  --bs-border-radius-pill: 50rem;
  --bs-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);
  --bs-box-shadow-lg: 0 1rem 3rem rgba(0, 0, 0, 0.175);
  --bs-box-shadow-inset: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  --bs-focus-ring-width: 0.25rem;
  --bs-focus-ring-opacity: 0.25;
  --bs-focus-ring-color: rgba(30, 77, 96, 0.25);
  --bs-form-valid-color: #198754;
  --bs-form-valid-border-color: #198754;
  --bs-form-invalid-color: #dc3545;
  --bs-form-invalid-border-color: #dc3545;
}

[data-bs-theme=dark] {
  color-scheme: dark;
  --bs-body-color: #dee2e6;
  --bs-body-color-rgb: 222, 226, 230;
  --bs-body-bg: #212529;
  --bs-body-bg-rgb: 33, 37, 41;
  --bs-emphasis-color: #fff;
  --bs-emphasis-color-rgb: 255, 255, 255;
  --bs-secondary-color: rgba(222, 226, 230, 0.75);
  --bs-secondary-color-rgb: 222, 226, 230;
  --bs-secondary-bg: #343a40;
  --bs-secondary-bg-rgb: 52, 58, 64;
  --bs-tertiary-color: rgba(222, 226, 230, 0.5);
  --bs-tertiary-color-rgb: 222, 226, 230;
  --bs-tertiary-bg: #2b3035;
  --bs-tertiary-bg-rgb: 43, 48, 53;
  --bs-primary-text-emphasis: #7894a0;
  --bs-secondary-text-emphasis: #a6b9c4;
  --bs-success-text-emphasis: #75b798;
  --bs-info-text-emphasis: #6edff6;
  --bs-warning-text-emphasis: #ffda6a;
  --bs-danger-text-emphasis: #ea868f;
  --bs-light-text-emphasis: #f8f9fa;
  --bs-dark-text-emphasis: #dee2e6;
  --bs-primary-bg-subtle: #060f13;
  --bs-secondary-bg-subtle: #151c1f;
  --bs-success-bg-subtle: #051b11;
  --bs-info-bg-subtle: #032830;
  --bs-warning-bg-subtle: #332701;
  --bs-danger-bg-subtle: #2c0b0e;
  --bs-light-bg-subtle: #343a40;
  --bs-dark-bg-subtle: #1a1d20;
  --bs-primary-border-subtle: #122e3a;
  --bs-secondary-border-subtle: #40535e;
  --bs-success-border-subtle: #0f5132;
  --bs-info-border-subtle: #087990;
  --bs-warning-border-subtle: #997404;
  --bs-danger-border-subtle: #842029;
  --bs-light-border-subtle: #495057;
  --bs-dark-border-subtle: #343a40;
  --bs-heading-color: inherit;
  --bs-link-color: #7894a0;
  --bs-link-hover-color: #93a9b3;
  --bs-link-color-rgb: 120, 148, 160;
  --bs-link-hover-color-rgb: 147, 169, 179;
  --bs-code-color: #e685b5;
  --bs-border-color: #495057;
  --bs-border-color-translucent: rgba(255, 255, 255, 0.15);
  --bs-form-valid-color: #75b798;
  --bs-form-valid-border-color: #75b798;
  --bs-form-invalid-color: #ea868f;
  --bs-form-invalid-border-color: #ea868f;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: var(--bs-body-bg);
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  border: 0;
  border-top: var(--bs-border-width) solid;
  opacity: 0.25;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: bold;
  line-height: 1.2;
  color: var(--bs-heading-color);
}

h1, .h1 {
  font-size: calc(1.4375rem + 2.25vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 3.125rem;
  }
}

h2, .h2 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2.5rem;
  }
}

h3, .h3 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 2rem;
  }
}

h4, .h4 {
  font-size: calc(1.28125rem + 0.375vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5625rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: 900;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.1875em;
  background-color: var(--bs-highlight-bg);
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 1));
  text-decoration: none;
}
a:hover {
  --bs-link-color-rgb: var(--bs-link-hover-color-rgb);
  text-decoration: none;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: var(--bs-code-color);
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.1875rem 0.375rem;
  font-size: 0.875em;
  color: var(--bs-body-bg);
  background-color: var(--bs-body-color);
  border-radius: 0.25rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-secondary-color);
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]:not([type=date]):not([type=datetime-local]):not([type=month]):not([type=week]):not([type=time])::-webkit-calendar-picker-indicator {
  display: none !important;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: calc(1.2625rem + 0.15vw);
  font-weight: 300;
}
@media (min-width: 1200px) {
  .lead {
    font-size: 1.375rem;
  }
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .blockquote {
    font-size: 1.375rem;
  }
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: var(--bs-body-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: var(--bs-box-shadow-sm);
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.container,
.container-fluid,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 576px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 768px;
  }
}
@media (min-width: 1400px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 992px;
  }
}
.container-lg, .container-md, .container-sm, .container {
  max-width: 1440px;
}

:root {
  --bs-breakpoint-xs: 0;
  --bs-breakpoint-sm: 576px;
  --bs-breakpoint-md: 768px;
  --bs-breakpoint-lg: 1400px;
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 0.313rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 0.313rem;
}

.g-10,
.gx-10 {
  --bs-gutter-x: 0.625rem;
}

.g-10,
.gy-10 {
  --bs-gutter-y: 0.625rem;
}

.g-15,
.gx-15 {
  --bs-gutter-x: 0.938rem;
}

.g-15,
.gy-15 {
  --bs-gutter-y: 0.938rem;
}

.g-20,
.gx-20 {
  --bs-gutter-x: 1.25rem;
}

.g-20,
.gy-20 {
  --bs-gutter-y: 1.25rem;
}

.g-25,
.gx-25 {
  --bs-gutter-x: 1.563rem;
}

.g-25,
.gy-25 {
  --bs-gutter-y: 1.563rem;
}

.g-30,
.gx-30 {
  --bs-gutter-x: 1.875rem;
}

.g-30,
.gy-30 {
  --bs-gutter-y: 1.875rem;
}

.g-35,
.gx-35 {
  --bs-gutter-x: 2.188rem;
}

.g-35,
.gy-35 {
  --bs-gutter-y: 2.188rem;
}

.g-40,
.gx-40 {
  --bs-gutter-x: 2.5rem;
}

.g-40,
.gy-40 {
  --bs-gutter-y: 2.5rem;
}

.g-45,
.gx-45 {
  --bs-gutter-x: 2.813rem;
}

.g-45,
.gy-45 {
  --bs-gutter-y: 2.813rem;
}

.g-50,
.gx-50 {
  --bs-gutter-x: 3.125rem;
}

.g-50,
.gy-50 {
  --bs-gutter-y: 3.125rem;
}

.g-55,
.gx-55 {
  --bs-gutter-x: 3.438rem;
}

.g-55,
.gy-55 {
  --bs-gutter-y: 3.438rem;
}

.g-60,
.gx-60 {
  --bs-gutter-x: 3.75rem;
}

.g-60,
.gy-60 {
  --bs-gutter-y: 3.75rem;
}

.g-65,
.gx-65 {
  --bs-gutter-x: 4.063rem;
}

.g-65,
.gy-65 {
  --bs-gutter-y: 4.063rem;
}

.g-70,
.gx-70 {
  --bs-gutter-x: 4.375rem;
}

.g-70,
.gy-70 {
  --bs-gutter-y: 4.375rem;
}

.g-75,
.gx-75 {
  --bs-gutter-x: 4.688rem;
}

.g-75,
.gy-75 {
  --bs-gutter-y: 4.688rem;
}

.g-80,
.gx-80 {
  --bs-gutter-x: 5rem;
}

.g-80,
.gy-80 {
  --bs-gutter-y: 5rem;
}

.g-85,
.gx-85 {
  --bs-gutter-x: 5.313rem;
}

.g-85,
.gy-85 {
  --bs-gutter-y: 5.313rem;
}

.g-90,
.gx-90 {
  --bs-gutter-x: 5.625rem;
}

.g-90,
.gy-90 {
  --bs-gutter-y: 5.625rem;
}

.g-95,
.gx-95 {
  --bs-gutter-x: 5.938rem;
}

.g-95,
.gy-95 {
  --bs-gutter-y: 5.938rem;
}

.g-100,
.gx-100 {
  --bs-gutter-x: 6.25rem;
}

.g-100,
.gy-100 {
  --bs-gutter-y: 6.25rem;
}

.g-105,
.gx-105 {
  --bs-gutter-x: 6.5625rem;
}

.g-105,
.gy-105 {
  --bs-gutter-y: 6.5625rem;
}

.g-110,
.gx-110 {
  --bs-gutter-x: 6.875rem;
}

.g-110,
.gy-110 {
  --bs-gutter-y: 6.875rem;
}

.g-115,
.gx-115 {
  --bs-gutter-x: 7.1875rem;
}

.g-115,
.gy-115 {
  --bs-gutter-y: 7.1875rem;
}

.g-120,
.gx-120 {
  --bs-gutter-x: 7.5rem;
}

.g-120,
.gy-120 {
  --bs-gutter-y: 7.5rem;
}

.g-125,
.gx-125 {
  --bs-gutter-x: 7.8125rem;
}

.g-125,
.gy-125 {
  --bs-gutter-y: 7.8125rem;
}

.g-130,
.gx-130 {
  --bs-gutter-x: 8.125rem;
}

.g-130,
.gy-130 {
  --bs-gutter-y: 8.125rem;
}

.g-135,
.gx-135 {
  --bs-gutter-x: 8.4375rem;
}

.g-135,
.gy-135 {
  --bs-gutter-y: 8.4375rem;
}

.g-140,
.gx-140 {
  --bs-gutter-x: 8.75rem;
}

.g-140,
.gy-140 {
  --bs-gutter-y: 8.75rem;
}

.g-145,
.gx-145 {
  --bs-gutter-x: 9.0625rem;
}

.g-145,
.gy-145 {
  --bs-gutter-y: 9.0625rem;
}

.g-150,
.gx-150 {
  --bs-gutter-x: 9.375rem;
}

.g-150,
.gy-150 {
  --bs-gutter-y: 9.375rem;
}

.g-155,
.gx-155 {
  --bs-gutter-x: 9.6875rem;
}

.g-155,
.gy-155 {
  --bs-gutter-y: 9.6875rem;
}

.g-160,
.gx-160 {
  --bs-gutter-x: 10rem;
}

.g-160,
.gy-160 {
  --bs-gutter-y: 10rem;
}

.g-165,
.gx-165 {
  --bs-gutter-x: 10.3125rem;
}

.g-165,
.gy-165 {
  --bs-gutter-y: 10.3125rem;
}

.g-170,
.gx-170 {
  --bs-gutter-x: 10.625rem;
}

.g-170,
.gy-170 {
  --bs-gutter-y: 10.625rem;
}

.g-175,
.gx-175 {
  --bs-gutter-x: 10.9375rem;
}

.g-175,
.gy-175 {
  --bs-gutter-y: 10.9375rem;
}

.g-180,
.gx-180 {
  --bs-gutter-x: 11.25rem;
}

.g-180,
.gy-180 {
  --bs-gutter-y: 11.25rem;
}

.g-185,
.gx-185 {
  --bs-gutter-x: 11.5625rem;
}

.g-185,
.gy-185 {
  --bs-gutter-y: 11.5625rem;
}

.g-190,
.gx-190 {
  --bs-gutter-x: 11.875rem;
}

.g-190,
.gy-190 {
  --bs-gutter-y: 11.875rem;
}

.g-195,
.gx-195 {
  --bs-gutter-x: 12.1875rem;
}

.g-195,
.gy-195 {
  --bs-gutter-y: 12.1875rem;
}

.g-200,
.gx-200 {
  --bs-gutter-x: 12.5rem;
}

.g-200,
.gy-200 {
  --bs-gutter-y: 12.5rem;
}

.g-205,
.gx-205 {
  --bs-gutter-x: 12.8125rem;
}

.g-205,
.gy-205 {
  --bs-gutter-y: 12.8125rem;
}

.g-210,
.gx-210 {
  --bs-gutter-x: 13.125rem;
}

.g-210,
.gy-210 {
  --bs-gutter-y: 13.125rem;
}

.g-215,
.gx-215 {
  --bs-gutter-x: 13.4375rem;
}

.g-215,
.gy-215 {
  --bs-gutter-y: 13.4375rem;
}

.g-220,
.gx-220 {
  --bs-gutter-x: 13.75rem;
}

.g-220,
.gy-220 {
  --bs-gutter-y: 13.75rem;
}

.g-225,
.gx-225 {
  --bs-gutter-x: 14.0625rem;
}

.g-225,
.gy-225 {
  --bs-gutter-y: 14.0625rem;
}

.g-230,
.gx-230 {
  --bs-gutter-x: 14.375rem;
}

.g-230,
.gy-230 {
  --bs-gutter-y: 14.375rem;
}

.g-235,
.gx-235 {
  --bs-gutter-x: 14.6875rem;
}

.g-235,
.gy-235 {
  --bs-gutter-y: 14.6875rem;
}

.g-240,
.gx-240 {
  --bs-gutter-x: 15rem;
}

.g-240,
.gy-240 {
  --bs-gutter-y: 15rem;
}

.g-245,
.gx-245 {
  --bs-gutter-x: 15.3125rem;
}

.g-245,
.gy-245 {
  --bs-gutter-y: 15.3125rem;
}

.g-250,
.gx-250 {
  --bs-gutter-x: 15.625rem;
}

.g-250,
.gy-250 {
  --bs-gutter-y: 15.625rem;
}

.g-255,
.gx-255 {
  --bs-gutter-x: 15.9375rem;
}

.g-255,
.gy-255 {
  --bs-gutter-y: 15.9375rem;
}

.g-260,
.gx-260 {
  --bs-gutter-x: 16.25rem;
}

.g-260,
.gy-260 {
  --bs-gutter-y: 16.25rem;
}

.g-265,
.gx-265 {
  --bs-gutter-x: 16.5625rem;
}

.g-265,
.gy-265 {
  --bs-gutter-y: 16.5625rem;
}

.g-270,
.gx-270 {
  --bs-gutter-x: 16.875rem;
}

.g-270,
.gy-270 {
  --bs-gutter-y: 16.875rem;
}

.g-275,
.gx-275 {
  --bs-gutter-x: 17.1875rem;
}

.g-275,
.gy-275 {
  --bs-gutter-y: 17.1875rem;
}

.g-280,
.gx-280 {
  --bs-gutter-x: 17.5rem;
}

.g-280,
.gy-280 {
  --bs-gutter-y: 17.5rem;
}

.g-285,
.gx-285 {
  --bs-gutter-x: 17.8125rem;
}

.g-285,
.gy-285 {
  --bs-gutter-y: 17.8125rem;
}

.g-290,
.gx-290 {
  --bs-gutter-x: 18.125rem;
}

.g-290,
.gy-290 {
  --bs-gutter-y: 18.125rem;
}

.g-295,
.gx-295 {
  --bs-gutter-x: 18.4375rem;
}

.g-295,
.gy-295 {
  --bs-gutter-y: 18.4375rem;
}

.g-300,
.gx-300 {
  --bs-gutter-x: 18.75rem;
}

.g-300,
.gy-300 {
  --bs-gutter-y: 18.75rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }
  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 0.313rem;
  }
  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 0.313rem;
  }
  .g-sm-10,
  .gx-sm-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-sm-10,
  .gy-sm-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-sm-15,
  .gx-sm-15 {
    --bs-gutter-x: 0.938rem;
  }
  .g-sm-15,
  .gy-sm-15 {
    --bs-gutter-y: 0.938rem;
  }
  .g-sm-20,
  .gx-sm-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-sm-20,
  .gy-sm-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-sm-25,
  .gx-sm-25 {
    --bs-gutter-x: 1.563rem;
  }
  .g-sm-25,
  .gy-sm-25 {
    --bs-gutter-y: 1.563rem;
  }
  .g-sm-30,
  .gx-sm-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-sm-30,
  .gy-sm-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-sm-35,
  .gx-sm-35 {
    --bs-gutter-x: 2.188rem;
  }
  .g-sm-35,
  .gy-sm-35 {
    --bs-gutter-y: 2.188rem;
  }
  .g-sm-40,
  .gx-sm-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-sm-40,
  .gy-sm-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-sm-45,
  .gx-sm-45 {
    --bs-gutter-x: 2.813rem;
  }
  .g-sm-45,
  .gy-sm-45 {
    --bs-gutter-y: 2.813rem;
  }
  .g-sm-50,
  .gx-sm-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-sm-50,
  .gy-sm-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-sm-55,
  .gx-sm-55 {
    --bs-gutter-x: 3.438rem;
  }
  .g-sm-55,
  .gy-sm-55 {
    --bs-gutter-y: 3.438rem;
  }
  .g-sm-60,
  .gx-sm-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-sm-60,
  .gy-sm-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-sm-65,
  .gx-sm-65 {
    --bs-gutter-x: 4.063rem;
  }
  .g-sm-65,
  .gy-sm-65 {
    --bs-gutter-y: 4.063rem;
  }
  .g-sm-70,
  .gx-sm-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-sm-70,
  .gy-sm-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-sm-75,
  .gx-sm-75 {
    --bs-gutter-x: 4.688rem;
  }
  .g-sm-75,
  .gy-sm-75 {
    --bs-gutter-y: 4.688rem;
  }
  .g-sm-80,
  .gx-sm-80 {
    --bs-gutter-x: 5rem;
  }
  .g-sm-80,
  .gy-sm-80 {
    --bs-gutter-y: 5rem;
  }
  .g-sm-85,
  .gx-sm-85 {
    --bs-gutter-x: 5.313rem;
  }
  .g-sm-85,
  .gy-sm-85 {
    --bs-gutter-y: 5.313rem;
  }
  .g-sm-90,
  .gx-sm-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-sm-90,
  .gy-sm-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-sm-95,
  .gx-sm-95 {
    --bs-gutter-x: 5.938rem;
  }
  .g-sm-95,
  .gy-sm-95 {
    --bs-gutter-y: 5.938rem;
  }
  .g-sm-100,
  .gx-sm-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-sm-100,
  .gy-sm-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-sm-105,
  .gx-sm-105 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-sm-105,
  .gy-sm-105 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-sm-110,
  .gx-sm-110 {
    --bs-gutter-x: 6.875rem;
  }
  .g-sm-110,
  .gy-sm-110 {
    --bs-gutter-y: 6.875rem;
  }
  .g-sm-115,
  .gx-sm-115 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-sm-115,
  .gy-sm-115 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-sm-120,
  .gx-sm-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-sm-120,
  .gy-sm-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-sm-125,
  .gx-sm-125 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-sm-125,
  .gy-sm-125 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-sm-130,
  .gx-sm-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-sm-130,
  .gy-sm-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-sm-135,
  .gx-sm-135 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-sm-135,
  .gy-sm-135 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-sm-140,
  .gx-sm-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-sm-140,
  .gy-sm-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-sm-145,
  .gx-sm-145 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-sm-145,
  .gy-sm-145 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-sm-150,
  .gx-sm-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-sm-150,
  .gy-sm-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-sm-155,
  .gx-sm-155 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-sm-155,
  .gy-sm-155 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-sm-160,
  .gx-sm-160 {
    --bs-gutter-x: 10rem;
  }
  .g-sm-160,
  .gy-sm-160 {
    --bs-gutter-y: 10rem;
  }
  .g-sm-165,
  .gx-sm-165 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-sm-165,
  .gy-sm-165 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-sm-170,
  .gx-sm-170 {
    --bs-gutter-x: 10.625rem;
  }
  .g-sm-170,
  .gy-sm-170 {
    --bs-gutter-y: 10.625rem;
  }
  .g-sm-175,
  .gx-sm-175 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-sm-175,
  .gy-sm-175 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-sm-180,
  .gx-sm-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-sm-180,
  .gy-sm-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-sm-185,
  .gx-sm-185 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-sm-185,
  .gy-sm-185 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-sm-190,
  .gx-sm-190 {
    --bs-gutter-x: 11.875rem;
  }
  .g-sm-190,
  .gy-sm-190 {
    --bs-gutter-y: 11.875rem;
  }
  .g-sm-195,
  .gx-sm-195 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-sm-195,
  .gy-sm-195 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-sm-200,
  .gx-sm-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-sm-200,
  .gy-sm-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-sm-205,
  .gx-sm-205 {
    --bs-gutter-x: 12.8125rem;
  }
  .g-sm-205,
  .gy-sm-205 {
    --bs-gutter-y: 12.8125rem;
  }
  .g-sm-210,
  .gx-sm-210 {
    --bs-gutter-x: 13.125rem;
  }
  .g-sm-210,
  .gy-sm-210 {
    --bs-gutter-y: 13.125rem;
  }
  .g-sm-215,
  .gx-sm-215 {
    --bs-gutter-x: 13.4375rem;
  }
  .g-sm-215,
  .gy-sm-215 {
    --bs-gutter-y: 13.4375rem;
  }
  .g-sm-220,
  .gx-sm-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-sm-220,
  .gy-sm-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-sm-225,
  .gx-sm-225 {
    --bs-gutter-x: 14.0625rem;
  }
  .g-sm-225,
  .gy-sm-225 {
    --bs-gutter-y: 14.0625rem;
  }
  .g-sm-230,
  .gx-sm-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-sm-230,
  .gy-sm-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-sm-235,
  .gx-sm-235 {
    --bs-gutter-x: 14.6875rem;
  }
  .g-sm-235,
  .gy-sm-235 {
    --bs-gutter-y: 14.6875rem;
  }
  .g-sm-240,
  .gx-sm-240 {
    --bs-gutter-x: 15rem;
  }
  .g-sm-240,
  .gy-sm-240 {
    --bs-gutter-y: 15rem;
  }
  .g-sm-245,
  .gx-sm-245 {
    --bs-gutter-x: 15.3125rem;
  }
  .g-sm-245,
  .gy-sm-245 {
    --bs-gutter-y: 15.3125rem;
  }
  .g-sm-250,
  .gx-sm-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-sm-250,
  .gy-sm-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-sm-255,
  .gx-sm-255 {
    --bs-gutter-x: 15.9375rem;
  }
  .g-sm-255,
  .gy-sm-255 {
    --bs-gutter-y: 15.9375rem;
  }
  .g-sm-260,
  .gx-sm-260 {
    --bs-gutter-x: 16.25rem;
  }
  .g-sm-260,
  .gy-sm-260 {
    --bs-gutter-y: 16.25rem;
  }
  .g-sm-265,
  .gx-sm-265 {
    --bs-gutter-x: 16.5625rem;
  }
  .g-sm-265,
  .gy-sm-265 {
    --bs-gutter-y: 16.5625rem;
  }
  .g-sm-270,
  .gx-sm-270 {
    --bs-gutter-x: 16.875rem;
  }
  .g-sm-270,
  .gy-sm-270 {
    --bs-gutter-y: 16.875rem;
  }
  .g-sm-275,
  .gx-sm-275 {
    --bs-gutter-x: 17.1875rem;
  }
  .g-sm-275,
  .gy-sm-275 {
    --bs-gutter-y: 17.1875rem;
  }
  .g-sm-280,
  .gx-sm-280 {
    --bs-gutter-x: 17.5rem;
  }
  .g-sm-280,
  .gy-sm-280 {
    --bs-gutter-y: 17.5rem;
  }
  .g-sm-285,
  .gx-sm-285 {
    --bs-gutter-x: 17.8125rem;
  }
  .g-sm-285,
  .gy-sm-285 {
    --bs-gutter-y: 17.8125rem;
  }
  .g-sm-290,
  .gx-sm-290 {
    --bs-gutter-x: 18.125rem;
  }
  .g-sm-290,
  .gy-sm-290 {
    --bs-gutter-y: 18.125rem;
  }
  .g-sm-295,
  .gx-sm-295 {
    --bs-gutter-x: 18.4375rem;
  }
  .g-sm-295,
  .gy-sm-295 {
    --bs-gutter-y: 18.4375rem;
  }
  .g-sm-300,
  .gx-sm-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-sm-300,
  .gy-sm-300 {
    --bs-gutter-y: 18.75rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }
  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.33333333%;
  }
  .offset-md-2 {
    margin-left: 16.66666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.33333333%;
  }
  .offset-md-5 {
    margin-left: 41.66666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.33333333%;
  }
  .offset-md-8 {
    margin-left: 66.66666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.33333333%;
  }
  .offset-md-11 {
    margin-left: 91.66666667%;
  }
  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }
  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }
  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 0.313rem;
  }
  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 0.313rem;
  }
  .g-md-10,
  .gx-md-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-md-10,
  .gy-md-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-md-15,
  .gx-md-15 {
    --bs-gutter-x: 0.938rem;
  }
  .g-md-15,
  .gy-md-15 {
    --bs-gutter-y: 0.938rem;
  }
  .g-md-20,
  .gx-md-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-md-20,
  .gy-md-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-md-25,
  .gx-md-25 {
    --bs-gutter-x: 1.563rem;
  }
  .g-md-25,
  .gy-md-25 {
    --bs-gutter-y: 1.563rem;
  }
  .g-md-30,
  .gx-md-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-md-30,
  .gy-md-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-md-35,
  .gx-md-35 {
    --bs-gutter-x: 2.188rem;
  }
  .g-md-35,
  .gy-md-35 {
    --bs-gutter-y: 2.188rem;
  }
  .g-md-40,
  .gx-md-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-md-40,
  .gy-md-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-md-45,
  .gx-md-45 {
    --bs-gutter-x: 2.813rem;
  }
  .g-md-45,
  .gy-md-45 {
    --bs-gutter-y: 2.813rem;
  }
  .g-md-50,
  .gx-md-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-md-50,
  .gy-md-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-md-55,
  .gx-md-55 {
    --bs-gutter-x: 3.438rem;
  }
  .g-md-55,
  .gy-md-55 {
    --bs-gutter-y: 3.438rem;
  }
  .g-md-60,
  .gx-md-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-md-60,
  .gy-md-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-md-65,
  .gx-md-65 {
    --bs-gutter-x: 4.063rem;
  }
  .g-md-65,
  .gy-md-65 {
    --bs-gutter-y: 4.063rem;
  }
  .g-md-70,
  .gx-md-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-md-70,
  .gy-md-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-md-75,
  .gx-md-75 {
    --bs-gutter-x: 4.688rem;
  }
  .g-md-75,
  .gy-md-75 {
    --bs-gutter-y: 4.688rem;
  }
  .g-md-80,
  .gx-md-80 {
    --bs-gutter-x: 5rem;
  }
  .g-md-80,
  .gy-md-80 {
    --bs-gutter-y: 5rem;
  }
  .g-md-85,
  .gx-md-85 {
    --bs-gutter-x: 5.313rem;
  }
  .g-md-85,
  .gy-md-85 {
    --bs-gutter-y: 5.313rem;
  }
  .g-md-90,
  .gx-md-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-md-90,
  .gy-md-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-md-95,
  .gx-md-95 {
    --bs-gutter-x: 5.938rem;
  }
  .g-md-95,
  .gy-md-95 {
    --bs-gutter-y: 5.938rem;
  }
  .g-md-100,
  .gx-md-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-md-100,
  .gy-md-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-md-105,
  .gx-md-105 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-md-105,
  .gy-md-105 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-md-110,
  .gx-md-110 {
    --bs-gutter-x: 6.875rem;
  }
  .g-md-110,
  .gy-md-110 {
    --bs-gutter-y: 6.875rem;
  }
  .g-md-115,
  .gx-md-115 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-md-115,
  .gy-md-115 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-md-120,
  .gx-md-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-md-120,
  .gy-md-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-md-125,
  .gx-md-125 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-md-125,
  .gy-md-125 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-md-130,
  .gx-md-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-md-130,
  .gy-md-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-md-135,
  .gx-md-135 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-md-135,
  .gy-md-135 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-md-140,
  .gx-md-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-md-140,
  .gy-md-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-md-145,
  .gx-md-145 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-md-145,
  .gy-md-145 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-md-150,
  .gx-md-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-md-150,
  .gy-md-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-md-155,
  .gx-md-155 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-md-155,
  .gy-md-155 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-md-160,
  .gx-md-160 {
    --bs-gutter-x: 10rem;
  }
  .g-md-160,
  .gy-md-160 {
    --bs-gutter-y: 10rem;
  }
  .g-md-165,
  .gx-md-165 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-md-165,
  .gy-md-165 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-md-170,
  .gx-md-170 {
    --bs-gutter-x: 10.625rem;
  }
  .g-md-170,
  .gy-md-170 {
    --bs-gutter-y: 10.625rem;
  }
  .g-md-175,
  .gx-md-175 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-md-175,
  .gy-md-175 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-md-180,
  .gx-md-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-md-180,
  .gy-md-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-md-185,
  .gx-md-185 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-md-185,
  .gy-md-185 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-md-190,
  .gx-md-190 {
    --bs-gutter-x: 11.875rem;
  }
  .g-md-190,
  .gy-md-190 {
    --bs-gutter-y: 11.875rem;
  }
  .g-md-195,
  .gx-md-195 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-md-195,
  .gy-md-195 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-md-200,
  .gx-md-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-md-200,
  .gy-md-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-md-205,
  .gx-md-205 {
    --bs-gutter-x: 12.8125rem;
  }
  .g-md-205,
  .gy-md-205 {
    --bs-gutter-y: 12.8125rem;
  }
  .g-md-210,
  .gx-md-210 {
    --bs-gutter-x: 13.125rem;
  }
  .g-md-210,
  .gy-md-210 {
    --bs-gutter-y: 13.125rem;
  }
  .g-md-215,
  .gx-md-215 {
    --bs-gutter-x: 13.4375rem;
  }
  .g-md-215,
  .gy-md-215 {
    --bs-gutter-y: 13.4375rem;
  }
  .g-md-220,
  .gx-md-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-md-220,
  .gy-md-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-md-225,
  .gx-md-225 {
    --bs-gutter-x: 14.0625rem;
  }
  .g-md-225,
  .gy-md-225 {
    --bs-gutter-y: 14.0625rem;
  }
  .g-md-230,
  .gx-md-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-md-230,
  .gy-md-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-md-235,
  .gx-md-235 {
    --bs-gutter-x: 14.6875rem;
  }
  .g-md-235,
  .gy-md-235 {
    --bs-gutter-y: 14.6875rem;
  }
  .g-md-240,
  .gx-md-240 {
    --bs-gutter-x: 15rem;
  }
  .g-md-240,
  .gy-md-240 {
    --bs-gutter-y: 15rem;
  }
  .g-md-245,
  .gx-md-245 {
    --bs-gutter-x: 15.3125rem;
  }
  .g-md-245,
  .gy-md-245 {
    --bs-gutter-y: 15.3125rem;
  }
  .g-md-250,
  .gx-md-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-md-250,
  .gy-md-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-md-255,
  .gx-md-255 {
    --bs-gutter-x: 15.9375rem;
  }
  .g-md-255,
  .gy-md-255 {
    --bs-gutter-y: 15.9375rem;
  }
  .g-md-260,
  .gx-md-260 {
    --bs-gutter-x: 16.25rem;
  }
  .g-md-260,
  .gy-md-260 {
    --bs-gutter-y: 16.25rem;
  }
  .g-md-265,
  .gx-md-265 {
    --bs-gutter-x: 16.5625rem;
  }
  .g-md-265,
  .gy-md-265 {
    --bs-gutter-y: 16.5625rem;
  }
  .g-md-270,
  .gx-md-270 {
    --bs-gutter-x: 16.875rem;
  }
  .g-md-270,
  .gy-md-270 {
    --bs-gutter-y: 16.875rem;
  }
  .g-md-275,
  .gx-md-275 {
    --bs-gutter-x: 17.1875rem;
  }
  .g-md-275,
  .gy-md-275 {
    --bs-gutter-y: 17.1875rem;
  }
  .g-md-280,
  .gx-md-280 {
    --bs-gutter-x: 17.5rem;
  }
  .g-md-280,
  .gy-md-280 {
    --bs-gutter-y: 17.5rem;
  }
  .g-md-285,
  .gx-md-285 {
    --bs-gutter-x: 17.8125rem;
  }
  .g-md-285,
  .gy-md-285 {
    --bs-gutter-y: 17.8125rem;
  }
  .g-md-290,
  .gx-md-290 {
    --bs-gutter-x: 18.125rem;
  }
  .g-md-290,
  .gy-md-290 {
    --bs-gutter-y: 18.125rem;
  }
  .g-md-295,
  .gx-md-295 {
    --bs-gutter-x: 18.4375rem;
  }
  .g-md-295,
  .gy-md-295 {
    --bs-gutter-y: 18.4375rem;
  }
  .g-md-300,
  .gx-md-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-md-300,
  .gy-md-300 {
    --bs-gutter-y: 18.75rem;
  }
}
@media (min-width: 1400px) {
  .col-lg {
    flex: 1 0 0%;
  }
  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }
  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }
  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }
  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }
  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }
  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 0.313rem;
  }
  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 0.313rem;
  }
  .g-lg-10,
  .gx-lg-10 {
    --bs-gutter-x: 0.625rem;
  }
  .g-lg-10,
  .gy-lg-10 {
    --bs-gutter-y: 0.625rem;
  }
  .g-lg-15,
  .gx-lg-15 {
    --bs-gutter-x: 0.938rem;
  }
  .g-lg-15,
  .gy-lg-15 {
    --bs-gutter-y: 0.938rem;
  }
  .g-lg-20,
  .gx-lg-20 {
    --bs-gutter-x: 1.25rem;
  }
  .g-lg-20,
  .gy-lg-20 {
    --bs-gutter-y: 1.25rem;
  }
  .g-lg-25,
  .gx-lg-25 {
    --bs-gutter-x: 1.563rem;
  }
  .g-lg-25,
  .gy-lg-25 {
    --bs-gutter-y: 1.563rem;
  }
  .g-lg-30,
  .gx-lg-30 {
    --bs-gutter-x: 1.875rem;
  }
  .g-lg-30,
  .gy-lg-30 {
    --bs-gutter-y: 1.875rem;
  }
  .g-lg-35,
  .gx-lg-35 {
    --bs-gutter-x: 2.188rem;
  }
  .g-lg-35,
  .gy-lg-35 {
    --bs-gutter-y: 2.188rem;
  }
  .g-lg-40,
  .gx-lg-40 {
    --bs-gutter-x: 2.5rem;
  }
  .g-lg-40,
  .gy-lg-40 {
    --bs-gutter-y: 2.5rem;
  }
  .g-lg-45,
  .gx-lg-45 {
    --bs-gutter-x: 2.813rem;
  }
  .g-lg-45,
  .gy-lg-45 {
    --bs-gutter-y: 2.813rem;
  }
  .g-lg-50,
  .gx-lg-50 {
    --bs-gutter-x: 3.125rem;
  }
  .g-lg-50,
  .gy-lg-50 {
    --bs-gutter-y: 3.125rem;
  }
  .g-lg-55,
  .gx-lg-55 {
    --bs-gutter-x: 3.438rem;
  }
  .g-lg-55,
  .gy-lg-55 {
    --bs-gutter-y: 3.438rem;
  }
  .g-lg-60,
  .gx-lg-60 {
    --bs-gutter-x: 3.75rem;
  }
  .g-lg-60,
  .gy-lg-60 {
    --bs-gutter-y: 3.75rem;
  }
  .g-lg-65,
  .gx-lg-65 {
    --bs-gutter-x: 4.063rem;
  }
  .g-lg-65,
  .gy-lg-65 {
    --bs-gutter-y: 4.063rem;
  }
  .g-lg-70,
  .gx-lg-70 {
    --bs-gutter-x: 4.375rem;
  }
  .g-lg-70,
  .gy-lg-70 {
    --bs-gutter-y: 4.375rem;
  }
  .g-lg-75,
  .gx-lg-75 {
    --bs-gutter-x: 4.688rem;
  }
  .g-lg-75,
  .gy-lg-75 {
    --bs-gutter-y: 4.688rem;
  }
  .g-lg-80,
  .gx-lg-80 {
    --bs-gutter-x: 5rem;
  }
  .g-lg-80,
  .gy-lg-80 {
    --bs-gutter-y: 5rem;
  }
  .g-lg-85,
  .gx-lg-85 {
    --bs-gutter-x: 5.313rem;
  }
  .g-lg-85,
  .gy-lg-85 {
    --bs-gutter-y: 5.313rem;
  }
  .g-lg-90,
  .gx-lg-90 {
    --bs-gutter-x: 5.625rem;
  }
  .g-lg-90,
  .gy-lg-90 {
    --bs-gutter-y: 5.625rem;
  }
  .g-lg-95,
  .gx-lg-95 {
    --bs-gutter-x: 5.938rem;
  }
  .g-lg-95,
  .gy-lg-95 {
    --bs-gutter-y: 5.938rem;
  }
  .g-lg-100,
  .gx-lg-100 {
    --bs-gutter-x: 6.25rem;
  }
  .g-lg-100,
  .gy-lg-100 {
    --bs-gutter-y: 6.25rem;
  }
  .g-lg-105,
  .gx-lg-105 {
    --bs-gutter-x: 6.5625rem;
  }
  .g-lg-105,
  .gy-lg-105 {
    --bs-gutter-y: 6.5625rem;
  }
  .g-lg-110,
  .gx-lg-110 {
    --bs-gutter-x: 6.875rem;
  }
  .g-lg-110,
  .gy-lg-110 {
    --bs-gutter-y: 6.875rem;
  }
  .g-lg-115,
  .gx-lg-115 {
    --bs-gutter-x: 7.1875rem;
  }
  .g-lg-115,
  .gy-lg-115 {
    --bs-gutter-y: 7.1875rem;
  }
  .g-lg-120,
  .gx-lg-120 {
    --bs-gutter-x: 7.5rem;
  }
  .g-lg-120,
  .gy-lg-120 {
    --bs-gutter-y: 7.5rem;
  }
  .g-lg-125,
  .gx-lg-125 {
    --bs-gutter-x: 7.8125rem;
  }
  .g-lg-125,
  .gy-lg-125 {
    --bs-gutter-y: 7.8125rem;
  }
  .g-lg-130,
  .gx-lg-130 {
    --bs-gutter-x: 8.125rem;
  }
  .g-lg-130,
  .gy-lg-130 {
    --bs-gutter-y: 8.125rem;
  }
  .g-lg-135,
  .gx-lg-135 {
    --bs-gutter-x: 8.4375rem;
  }
  .g-lg-135,
  .gy-lg-135 {
    --bs-gutter-y: 8.4375rem;
  }
  .g-lg-140,
  .gx-lg-140 {
    --bs-gutter-x: 8.75rem;
  }
  .g-lg-140,
  .gy-lg-140 {
    --bs-gutter-y: 8.75rem;
  }
  .g-lg-145,
  .gx-lg-145 {
    --bs-gutter-x: 9.0625rem;
  }
  .g-lg-145,
  .gy-lg-145 {
    --bs-gutter-y: 9.0625rem;
  }
  .g-lg-150,
  .gx-lg-150 {
    --bs-gutter-x: 9.375rem;
  }
  .g-lg-150,
  .gy-lg-150 {
    --bs-gutter-y: 9.375rem;
  }
  .g-lg-155,
  .gx-lg-155 {
    --bs-gutter-x: 9.6875rem;
  }
  .g-lg-155,
  .gy-lg-155 {
    --bs-gutter-y: 9.6875rem;
  }
  .g-lg-160,
  .gx-lg-160 {
    --bs-gutter-x: 10rem;
  }
  .g-lg-160,
  .gy-lg-160 {
    --bs-gutter-y: 10rem;
  }
  .g-lg-165,
  .gx-lg-165 {
    --bs-gutter-x: 10.3125rem;
  }
  .g-lg-165,
  .gy-lg-165 {
    --bs-gutter-y: 10.3125rem;
  }
  .g-lg-170,
  .gx-lg-170 {
    --bs-gutter-x: 10.625rem;
  }
  .g-lg-170,
  .gy-lg-170 {
    --bs-gutter-y: 10.625rem;
  }
  .g-lg-175,
  .gx-lg-175 {
    --bs-gutter-x: 10.9375rem;
  }
  .g-lg-175,
  .gy-lg-175 {
    --bs-gutter-y: 10.9375rem;
  }
  .g-lg-180,
  .gx-lg-180 {
    --bs-gutter-x: 11.25rem;
  }
  .g-lg-180,
  .gy-lg-180 {
    --bs-gutter-y: 11.25rem;
  }
  .g-lg-185,
  .gx-lg-185 {
    --bs-gutter-x: 11.5625rem;
  }
  .g-lg-185,
  .gy-lg-185 {
    --bs-gutter-y: 11.5625rem;
  }
  .g-lg-190,
  .gx-lg-190 {
    --bs-gutter-x: 11.875rem;
  }
  .g-lg-190,
  .gy-lg-190 {
    --bs-gutter-y: 11.875rem;
  }
  .g-lg-195,
  .gx-lg-195 {
    --bs-gutter-x: 12.1875rem;
  }
  .g-lg-195,
  .gy-lg-195 {
    --bs-gutter-y: 12.1875rem;
  }
  .g-lg-200,
  .gx-lg-200 {
    --bs-gutter-x: 12.5rem;
  }
  .g-lg-200,
  .gy-lg-200 {
    --bs-gutter-y: 12.5rem;
  }
  .g-lg-205,
  .gx-lg-205 {
    --bs-gutter-x: 12.8125rem;
  }
  .g-lg-205,
  .gy-lg-205 {
    --bs-gutter-y: 12.8125rem;
  }
  .g-lg-210,
  .gx-lg-210 {
    --bs-gutter-x: 13.125rem;
  }
  .g-lg-210,
  .gy-lg-210 {
    --bs-gutter-y: 13.125rem;
  }
  .g-lg-215,
  .gx-lg-215 {
    --bs-gutter-x: 13.4375rem;
  }
  .g-lg-215,
  .gy-lg-215 {
    --bs-gutter-y: 13.4375rem;
  }
  .g-lg-220,
  .gx-lg-220 {
    --bs-gutter-x: 13.75rem;
  }
  .g-lg-220,
  .gy-lg-220 {
    --bs-gutter-y: 13.75rem;
  }
  .g-lg-225,
  .gx-lg-225 {
    --bs-gutter-x: 14.0625rem;
  }
  .g-lg-225,
  .gy-lg-225 {
    --bs-gutter-y: 14.0625rem;
  }
  .g-lg-230,
  .gx-lg-230 {
    --bs-gutter-x: 14.375rem;
  }
  .g-lg-230,
  .gy-lg-230 {
    --bs-gutter-y: 14.375rem;
  }
  .g-lg-235,
  .gx-lg-235 {
    --bs-gutter-x: 14.6875rem;
  }
  .g-lg-235,
  .gy-lg-235 {
    --bs-gutter-y: 14.6875rem;
  }
  .g-lg-240,
  .gx-lg-240 {
    --bs-gutter-x: 15rem;
  }
  .g-lg-240,
  .gy-lg-240 {
    --bs-gutter-y: 15rem;
  }
  .g-lg-245,
  .gx-lg-245 {
    --bs-gutter-x: 15.3125rem;
  }
  .g-lg-245,
  .gy-lg-245 {
    --bs-gutter-y: 15.3125rem;
  }
  .g-lg-250,
  .gx-lg-250 {
    --bs-gutter-x: 15.625rem;
  }
  .g-lg-250,
  .gy-lg-250 {
    --bs-gutter-y: 15.625rem;
  }
  .g-lg-255,
  .gx-lg-255 {
    --bs-gutter-x: 15.9375rem;
  }
  .g-lg-255,
  .gy-lg-255 {
    --bs-gutter-y: 15.9375rem;
  }
  .g-lg-260,
  .gx-lg-260 {
    --bs-gutter-x: 16.25rem;
  }
  .g-lg-260,
  .gy-lg-260 {
    --bs-gutter-y: 16.25rem;
  }
  .g-lg-265,
  .gx-lg-265 {
    --bs-gutter-x: 16.5625rem;
  }
  .g-lg-265,
  .gy-lg-265 {
    --bs-gutter-y: 16.5625rem;
  }
  .g-lg-270,
  .gx-lg-270 {
    --bs-gutter-x: 16.875rem;
  }
  .g-lg-270,
  .gy-lg-270 {
    --bs-gutter-y: 16.875rem;
  }
  .g-lg-275,
  .gx-lg-275 {
    --bs-gutter-x: 17.1875rem;
  }
  .g-lg-275,
  .gy-lg-275 {
    --bs-gutter-y: 17.1875rem;
  }
  .g-lg-280,
  .gx-lg-280 {
    --bs-gutter-x: 17.5rem;
  }
  .g-lg-280,
  .gy-lg-280 {
    --bs-gutter-y: 17.5rem;
  }
  .g-lg-285,
  .gx-lg-285 {
    --bs-gutter-x: 17.8125rem;
  }
  .g-lg-285,
  .gy-lg-285 {
    --bs-gutter-y: 17.8125rem;
  }
  .g-lg-290,
  .gx-lg-290 {
    --bs-gutter-x: 18.125rem;
  }
  .g-lg-290,
  .gy-lg-290 {
    --bs-gutter-y: 18.125rem;
  }
  .g-lg-295,
  .gx-lg-295 {
    --bs-gutter-x: 18.4375rem;
  }
  .g-lg-295,
  .gy-lg-295 {
    --bs-gutter-y: 18.4375rem;
  }
  .g-lg-300,
  .gx-lg-300 {
    --bs-gutter-x: 18.75rem;
  }
  .g-lg-300,
  .gy-lg-300 {
    --bs-gutter-y: 18.75rem;
  }
}
.table {
  --bs-table-color-type: initial;
  --bs-table-bg-type: initial;
  --bs-table-color-state: initial;
  --bs-table-bg-state: initial;
  --bs-table-color: var(--bs-body-color);
  --bs-table-bg: var(--bs-body-bg);
  --bs-table-border-color: var(--bs-border-color);
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: var(--bs-body-color);
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: var(--bs-body-color);
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: var(--bs-body-color);
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  vertical-align: top;
  border-color: var(--bs-table-border-color);
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  color: var(--bs-table-color-state, var(--bs-table-color-type, var(--bs-table-color)));
  background-color: var(--bs-table-bg);
  border-bottom-width: var(--bs-border-width);
  box-shadow: inset 0 0 0 9999px var(--bs-table-bg-state, var(--bs-table-bg-type, var(--bs-table-accent-bg)));
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}

.table-group-divider {
  border-top: calc(var(--bs-border-width) * 2) solid currentcolor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: var(--bs-border-width) 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 var(--bs-border-width);
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-striped-columns > :not(caption) > tr > :nth-child(even) {
  --bs-table-color-type: var(--bs-table-striped-color);
  --bs-table-bg-type: var(--bs-table-striped-bg);
}

.table-active {
  --bs-table-color-state: var(--bs-table-active-color);
  --bs-table-bg-state: var(--bs-table-active-bg);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-color-state: var(--bs-table-hover-color);
  --bs-table-bg-state: var(--bs-table-hover-bg);
}

.table-primary {
  --bs-table-color: #000;
  --bs-table-bg: #d2dbdf;
  --bs-table-border-color: #bdc5c9;
  --bs-table-striped-bg: #c8d0d4;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bdc5c9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c2cbce;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-secondary {
  --bs-table-color: #000;
  --bs-table-bg: #e1e8eb;
  --bs-table-border-color: #cbd1d4;
  --bs-table-striped-bg: #d6dcdf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbd1d4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d0d7d9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-success {
  --bs-table-color: #000;
  --bs-table-bg: #d1e7dd;
  --bs-table-border-color: #bcd0c7;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-info {
  --bs-table-color: #000;
  --bs-table-bg: #cff4fc;
  --bs-table-border-color: #badce3;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-warning {
  --bs-table-color: #000;
  --bs-table-bg: #fff3cd;
  --bs-table-border-color: #e6dbb9;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-danger {
  --bs-table-color: #000;
  --bs-table-bg: #f8d7da;
  --bs-table-border-color: #dfc2c4;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-light {
  --bs-table-color: #000;
  --bs-table-bg: #f8f9fa;
  --bs-table-border-color: #dfe0e1;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-dark {
  --bs-table-color: #fff;
  --bs-table-bg: #212529;
  --bs-table-border-color: #373b3e;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: var(--bs-table-color);
  border-color: var(--bs-table-border-color);
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + var(--bs-border-width));
  padding-bottom: calc(0.375rem + var(--bs-border-width));
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + var(--bs-border-width));
  padding-bottom: calc(0.5rem + var(--bs-border-width));
  font-size: calc(1.2625rem + 0.15vw);
}
@media (min-width: 1200px) {
  .col-form-label-lg {
    font-size: 1.375rem;
  }
}

.col-form-label-sm {
  padding-top: calc(0.25rem + var(--bs-border-width));
  padding-bottom: calc(0.25rem + var(--bs-border-width));
  font-size: 0.9625rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-secondary-color);
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: #8fa6b0;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.form-control::-webkit-date-and-time-value {
  min-width: 85px;
  height: 1.5em;
  margin: 0;
}
.form-control::-webkit-datetime-edit {
  display: block;
  padding: 0;
}
.form-control::placeholder {
  color: #c4c4c4;
  opacity: 1;
}
.form-control:disabled {
  color: #FFFFFF;
  background-color: var(--bs-secondary-bg);
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: var(--bs-body-color);
  background-color: var(--bs-tertiary-bg);
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: var(--bs-border-width);
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: var(--bs-secondary-bg);
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: var(--bs-body-color);
  background-color: transparent;
  border: solid transparent;
  border-width: var(--bs-border-width) 0;
}
.form-control-plaintext:focus {
  outline: 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  border-radius: var(--bs-border-radius-sm);
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
  padding: 0.5rem 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-control-lg {
    font-size: 1.375rem;
  }
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-control-color {
  width: 3rem;
  height: calc(1.5em + 0.75rem + calc(var(--bs-border-width) * 2));
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color::-webkit-color-swatch {
  border: 0 !important;
  border-radius: var(--bs-border-radius);
}
.form-control-color.form-control-sm {
  height: calc(1.5em + 0.5rem + calc(var(--bs-border-width) * 2));
}
.form-control-color.form-control-lg {
  height: calc(1.5em + 1rem + calc(var(--bs-border-width) * 2));
}

.form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-image: var(--bs-form-select-bg-img), var(--bs-form-select-bg-icon, none);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    transition: none;
  }
}
.form-select:focus {
  border-color: #8fa6b0;
  outline: 0;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075), 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  color: #FFFFFF;
  background-color: var(--bs-secondary-bg);
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 var(--bs-body-color);
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.9625rem;
  border-radius: var(--bs-border-radius-sm);
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .form-select-lg {
    font-size: 1.375rem;
  }
}

[data-bs-theme=dark] .form-select {
  --bs-form-select-bg-img: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23dee2e6' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
}

.form-check {
  display: block;
  min-height: 1.65rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-reverse {
  padding-right: 1.5em;
  padding-left: 0;
  text-align: right;
}
.form-check-reverse .form-check-input {
  float: right;
  margin-right: -1.5em;
  margin-left: 0;
}

.form-check-input {
  --bs-form-check-bg: var(--bs-body-bg);
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  appearance: none;
  background-color: var(--bs-form-check-bg);
  background-image: var(--bs-form-check-bg-image);
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: var(--bs-border-width) solid var(--bs-border-color);
  print-color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #8fa6b0;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.form-check-input:checked {
  background-color: #1e4d60;
  border-color: #1e4d60;
}
.form-check-input:checked[type=checkbox] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #1e4d60;
  border-color: #1e4d60;
  --bs-form-check-bg-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  cursor: default;
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  width: 2em;
  margin-left: -2.5em;
  background-image: var(--bs-form-switch-bg);
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%238fa6b0'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-switch.form-check-reverse {
  padding-right: 2.5em;
  padding-left: 0;
}
.form-switch.form-check-reverse .form-check-input {
  margin-right: -2.5em;
  margin-left: 0;
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

[data-bs-theme=dark] .form-switch .form-check-input:not(:checked):not(:focus) {
  --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%28255, 255, 255, 0.25%29'/%3e%3c/svg%3e");
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  appearance: none;
  background-color: transparent;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #ffffff, 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  appearance: none;
  background-color: #1e4d60;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #bccacf;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  appearance: none;
  background-color: #1e4d60;
  border: 0;
  border-radius: 1rem;
  box-shadow: 0 0.1rem 0.25rem rgba(0, 0, 0, 0.1);
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #bccacf;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: var(--bs-tertiary-bg);
  border-color: transparent;
  border-radius: 1rem;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.075);
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: var(--bs-secondary-color);
}
.form-range:disabled::-moz-range-thumb {
  background-color: var(--bs-secondary-color);
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext,
.form-floating > .form-select {
  height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  min-height: calc(3.5rem + calc(var(--bs-border-width) * 2));
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  height: 100%;
  padding: 1rem 0.75rem;
  overflow: hidden;
  text-align: start;
  text-overflow: ellipsis;
  white-space: nowrap;
  pointer-events: none;
  border: var(--bs-border-width) solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control,
.form-floating > .form-control-plaintext {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::placeholder,
.form-floating > .form-control-plaintext::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown),
.form-floating > .form-control-plaintext:focus,
.form-floating > .form-control-plaintext:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill,
.form-floating > .form-control-plaintext:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-control-plaintext ~ label,
.form-floating > .form-select ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label::after,
.form-floating > .form-control:not(:placeholder-shown) ~ label::after,
.form-floating > .form-control-plaintext ~ label::after,
.form-floating > .form-select ~ label::after {
  position: absolute;
  inset: 1rem 0.375rem;
  z-index: -1;
  height: 1.5em;
  content: "";
  background-color: var(--bs-body-bg);
  border-radius: var(--bs-border-radius);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  color: rgba(var(--bs-body-color-rgb), 0.65);
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control-plaintext ~ label {
  border-width: var(--bs-border-width) 0;
}
.form-floating > :disabled ~ label,
.form-floating > .form-control:disabled ~ label {
  color: #6c757d;
}
.form-floating > :disabled ~ label::after,
.form-floating > .form-control:disabled ~ label::after {
  background-color: var(--bs-secondary-bg);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select,
.input-group > .form-floating {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus,
.input-group > .form-floating:focus-within {
  z-index: 5;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 5;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-tertiary-bg);
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: calc(1.2625rem + 0.15vw);
  border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .input-group-lg > .form-control,
  .input-group-lg > .form-select,
  .input-group-lg > .input-group-text,
  .input-group-lg > .btn {
    font-size: 1.375rem;
  }
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.9625rem;
  border-radius: var(--bs-border-radius-sm);
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3),
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-control,
.input-group:not(.has-validation) > .form-floating:not(:last-child) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu):not(.form-floating),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4),
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-control,
.input-group.has-validation > .form-floating:nth-last-child(n+3) > .form-select {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: calc(var(--bs-border-width) * -1);
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.input-group > .form-floating:not(:first-child) > .form-control,
.input-group > .form-floating:not(:first-child) > .form-select {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-valid-color);
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0 0;
  margin-top: 0.1rem;
  font-size: 0.9625rem;
  color: #fff;
  background-color: var(--bs-success);
  border-radius: 10px;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: var(--bs-form-valid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: var(--bs-form-valid-border-color);
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: var(--bs-form-valid-color);
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-success-rgb), 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: var(--bs-form-valid-color);
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):valid, .input-group > .form-control:not(:focus).is-valid,
.was-validated .input-group > .form-select:not(:focus):valid,
.input-group > .form-select:not(:focus).is-valid,
.was-validated .input-group > .form-floating:not(:focus-within):valid,
.input-group > .form-floating:not(:focus-within).is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: var(--bs-form-invalid-color);
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0 0;
  margin-top: 0.1rem;
  font-size: 0.9625rem;
  color: #fff;
  background-color: var(--bs-danger);
  border-radius: 10px;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: var(--bs-form-invalid-border-color);
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: var(--bs-form-invalid-border-color);
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: var(--bs-form-invalid-color);
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(var(--bs-danger-rgb), 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: var(--bs-form-invalid-color);
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group > .form-control:not(:focus):invalid, .input-group > .form-control:not(:focus).is-invalid,
.was-validated .input-group > .form-select:not(:focus):invalid,
.input-group > .form-select:not(:focus).is-invalid,
.was-validated .input-group > .form-floating:not(:focus-within):invalid,
.input-group > .form-floating:not(:focus-within).is-invalid {
  z-index: 4;
}

.btn {
  --bs-btn-padding-x: 14px;
  --bs-btn-padding-y: 10px;
  --bs-btn-font-family: ;
  --bs-btn-font-size: 1.0625rem;
  --bs-btn-font-weight: 700;
  --bs-btn-line-height: 1.5;
  --bs-btn-color: var(--bs-body-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-width: 0;
  --bs-btn-border-color: transparent;
  --bs-btn-border-radius: 30px;
  --bs-btn-hover-border-color: transparent;
  --bs-btn-box-shadow: 0;
  --bs-btn-disabled-opacity: 0.65;
  --bs-btn-focus-box-shadow: 0 0 0 0 rgba(var(--bs-btn-focus-shadow-rgb), .5);
  display: inline-block;
  padding: var(--bs-btn-padding-y) var(--bs-btn-padding-x);
  font-family: var(--bs-btn-font-family);
  font-size: var(--bs-btn-font-size);
  font-weight: var(--bs-btn-font-weight);
  line-height: var(--bs-btn-line-height);
  color: var(--bs-btn-color);
  text-align: center;
  white-space: normal;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  border: var(--bs-btn-border-width) solid var(--bs-btn-border-color);
  border-radius: var(--bs-btn-border-radius);
  background-color: var(--bs-btn-bg);
  box-shadow: var(--bs-btn-box-shadow);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
}
.btn-check + .btn:hover {
  color: var(--bs-btn-color);
  background-color: var(--bs-btn-bg);
  border-color: var(--bs-btn-border-color);
}
.btn:focus-visible {
  color: var(--bs-btn-hover-color);
  background-color: var(--bs-btn-hover-bg);
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:focus-visible + .btn {
  border-color: var(--bs-btn-hover-border-color);
  outline: 0;
  box-shadow: var(--bs-btn-box-shadow), var(--bs-btn-focus-box-shadow);
}
.btn-check:checked + .btn, :not(.btn-check) + .btn:active, .btn:first-child:active, .btn.active, .btn.show {
  color: var(--bs-btn-active-color);
  background-color: var(--bs-btn-active-bg);
  border-color: var(--bs-btn-active-border-color);
  box-shadow: var(--bs-btn-active-shadow);
}
.btn-check:checked + .btn:focus-visible, :not(.btn-check) + .btn:active:focus-visible, .btn:first-child:active:focus-visible, .btn.active:focus-visible, .btn.show:focus-visible {
  box-shadow: var(--bs-btn-active-shadow), var(--bs-btn-focus-box-shadow);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  color: var(--bs-btn-disabled-color);
  pointer-events: none;
  background-color: var(--bs-btn-disabled-bg);
  border-color: var(--bs-btn-disabled-border-color);
  opacity: var(--bs-btn-disabled-opacity);
  box-shadow: none;
}

.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #1e4d60;
  --bs-btn-border-color: #1e4d60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1a4152;
  --bs-btn-hover-border-color: #183e4d;
  --bs-btn-focus-shadow-rgb: 64, 104, 120;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #183e4d;
  --bs-btn-active-border-color: #173a48;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #1e4d60;
  --bs-btn-disabled-border-color: #1e4d60;
}

.btn-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #6a8a9c;
  --bs-btn-border-color: #6a8a9c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #809cab;
  --bs-btn-hover-border-color: #7996a6;
  --bs-btn-focus-shadow-rgb: 90, 117, 133;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #88a1b0;
  --bs-btn-active-border-color: #7996a6;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #6a8a9c;
  --bs-btn-disabled-border-color: #6a8a9c;
}

.btn-tertiary {
  --bs-btn-color: #000;
  --bs-btn-bg: #D3DCE1;
  --bs-btn-border-color: #D3DCE1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dae1e6;
  --bs-btn-hover-border-color: #d7e0e4;
  --bs-btn-focus-shadow-rgb: 179, 187, 191;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dce3e7;
  --bs-btn-active-border-color: #d7e0e4;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D3DCE1;
  --bs-btn-disabled-border-color: #D3DCE1;
}

.btn-success {
  --bs-btn-color: #000;
  --bs-btn-bg: #b5be00;
  --bs-btn-border-color: #b5be00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c0c826;
  --bs-btn-hover-border-color: #bcc51a;
  --bs-btn-focus-shadow-rgb: 154, 162, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c4cb33;
  --bs-btn-active-border-color: #bcc51a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #b5be00;
  --bs-btn-disabled-border-color: #b5be00;
}

.btn-dark {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3a3a3a;
  --bs-btn-border-color: #3a3a3a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #585858;
  --bs-btn-hover-border-color: #4e4e4e;
  --bs-btn-focus-shadow-rgb: 88, 88, 88;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #616161;
  --bs-btn-active-border-color: #4e4e4e;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3a3a3a;
  --bs-btn-disabled-border-color: #3a3a3a;
}

.btn-light {
  --bs-btn-color: #000;
  --bs-btn-bg: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #d1d1d1;
  --bs-btn-hover-border-color: #c5c5c5;
  --bs-btn-focus-shadow-rgb: 209, 209, 209;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c5c5c5;
  --bs-btn-active-border-color: #b9b9b9;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f6f6f6;
  --bs-btn-disabled-border-color: #f6f6f6;
}

.btn-white {
  --bs-btn-color: #000;
  --bs-btn-bg: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: white;
  --bs-btn-hover-border-color: white;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: white;
  --bs-btn-active-border-color: white;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ffffff;
  --bs-btn-disabled-border-color: #ffffff;
}

.btn-danger {
  --bs-btn-color: #fff;
  --bs-btn-bg: #080808;
  --bs-btn-border-color: #080808;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #070707;
  --bs-btn-hover-border-color: #060606;
  --bs-btn-focus-shadow-rgb: 45, 45, 45;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #060606;
  --bs-btn-active-border-color: #060606;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #080808;
  --bs-btn-disabled-border-color: #080808;
}

.btn-red {
  --bs-btn-color: #fff;
  --bs-btn-bg: #DA0000;
  --bs-btn-border-color: #DA0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #b90000;
  --bs-btn-hover-border-color: #ae0000;
  --bs-btn-focus-shadow-rgb: 224, 38, 38;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #ae0000;
  --bs-btn-active-border-color: #a40000;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #DA0000;
  --bs-btn-disabled-border-color: #DA0000;
}

.btn-pink {
  --bs-btn-color: #000;
  --bs-btn-bg: #ec008c;
  --bs-btn-border-color: #ec008c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ef269d;
  --bs-btn-hover-border-color: #ee1a98;
  --bs-btn-focus-shadow-rgb: 201, 0, 119;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f033a3;
  --bs-btn-active-border-color: #ee1a98;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #ec008c;
  --bs-btn-disabled-border-color: #ec008c;
}

.btn-orange {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2c900;
  --bs-btn-border-color: #f2c900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4d126;
  --bs-btn-hover-border-color: #f3ce1a;
  --bs-btn-focus-shadow-rgb: 206, 171, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5d433;
  --bs-btn-active-border-color: #f3ce1a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2c900;
  --bs-btn-disabled-border-color: #f2c900;
}

.btn-yellow {
  --bs-btn-color: #000;
  --bs-btn-bg: #f2c900;
  --bs-btn-border-color: #f2c900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f4d126;
  --bs-btn-hover-border-color: #f3ce1a;
  --bs-btn-focus-shadow-rgb: 206, 171, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f5d433;
  --bs-btn-active-border-color: #f3ce1a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #f2c900;
  --bs-btn-disabled-border-color: #f2c900;
}

.btn-green {
  --bs-btn-color: #000;
  --bs-btn-bg: #00c608;
  --bs-btn-border-color: #00c608;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #26cf2d;
  --bs-btn-hover-border-color: #1acc21;
  --bs-btn-focus-shadow-rgb: 0, 168, 7;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #33d139;
  --bs-btn-active-border-color: #1acc21;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #00c608;
  --bs-btn-disabled-border-color: #00c608;
}

.btn-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #0073b4;
  --bs-btn-border-color: #0073b4;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #006299;
  --bs-btn-hover-border-color: #005c90;
  --bs-btn-focus-shadow-rgb: 38, 136, 191;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #005c90;
  --bs-btn-active-border-color: #005687;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #0073b4;
  --bs-btn-disabled-border-color: #0073b4;
}

.btn-cyan {
  --bs-btn-color: #000;
  --bs-btn-bg: #02c2dc;
  --bs-btn-border-color: #02c2dc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #28cbe1;
  --bs-btn-hover-border-color: #1bc8e0;
  --bs-btn-focus-shadow-rgb: 2, 165, 187;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #35cee3;
  --bs-btn-active-border-color: #1bc8e0;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #02c2dc;
  --bs-btn-disabled-border-color: #02c2dc;
}

.btn-grey {
  --bs-btn-color: #000;
  --bs-btn-bg: #BEB5B5;
  --bs-btn-border-color: #BEB5B5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c8c0c0;
  --bs-btn-hover-border-color: #c5bcbc;
  --bs-btn-focus-shadow-rgb: 162, 154, 154;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #cbc4c4;
  --bs-btn-active-border-color: #c5bcbc;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #BEB5B5;
  --bs-btn-disabled-border-color: #BEB5B5;
}

.btn-blue-alt {
  --bs-btn-color: #fff;
  --bs-btn-bg: #3453c2;
  --bs-btn-border-color: #3453c2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #2c47a5;
  --bs-btn-hover-border-color: #2a429b;
  --bs-btn-focus-shadow-rgb: 82, 109, 203;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #2a429b;
  --bs-btn-active-border-color: #273e92;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #3453c2;
  --bs-btn-disabled-border-color: #3453c2;
}

.btn-blue-90 {
  --bs-btn-color: #fff;
  --bs-btn-bg: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-border-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(25, 65, 81, 0.97);
  --bs-btn-hover-border-color: rgba(24, 61, 76, 0.9717647059);
  --bs-btn-focus-shadow-rgb: 66, 105, 121;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(24, 61, 76, 0.9717647059);
  --bs-btn-active-border-color: rgba(22, 57, 71, 0.9735294118);
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-disabled-border-color: rgba(30, 77, 96, 0.9647058824);
}

.btn-light-blue {
  --bs-btn-color: #000;
  --bs-btn-bg: #E6ECF0;
  --bs-btn-border-color: #E6ECF0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #eaeff2;
  --bs-btn-hover-border-color: #e9eef2;
  --bs-btn-focus-shadow-rgb: 196, 201, 204;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ebf0f3;
  --bs-btn-active-border-color: #e9eef2;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #E6ECF0;
  --bs-btn-disabled-border-color: #E6ECF0;
}

.btn-dark-blue {
  --bs-btn-color: #fff;
  --bs-btn-bg: #183E4D;
  --bs-btn-border-color: #183E4D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #143541;
  --bs-btn-hover-border-color: #13323e;
  --bs-btn-focus-shadow-rgb: 59, 91, 104;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #13323e;
  --bs-btn-active-border-color: #122f3a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #183E4D;
  --bs-btn-disabled-border-color: #183E4D;
}

.btn-light-secondary {
  --bs-btn-color: #000;
  --bs-btn-bg: #e5e5e5;
  --bs-btn-border-color: #e5e5e5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e9e9e9;
  --bs-btn-hover-border-color: #e8e8e8;
  --bs-btn-focus-shadow-rgb: 195, 195, 195;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #eaeaea;
  --bs-btn-active-border-color: #e8e8e8;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #e5e5e5;
  --bs-btn-disabled-border-color: #e5e5e5;
}

.btn-secondary-10 {
  --bs-btn-color: #000;
  --bs-btn-bg: #D5DBDF;
  --bs-btn-border-color: #D5DBDF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dbe0e4;
  --bs-btn-hover-border-color: #d9dfe2;
  --bs-btn-focus-shadow-rgb: 181, 186, 190;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #dde2e5;
  --bs-btn-active-border-color: #d9dfe2;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D5DBDF;
  --bs-btn-disabled-border-color: #D5DBDF;
}

.btn-secondary-20 {
  --bs-btn-color: #000;
  --bs-btn-bg: #FBFDFF;
  --bs-btn-border-color: #FBFDFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #fcfdff;
  --bs-btn-hover-border-color: #fbfdff;
  --bs-btn-focus-shadow-rgb: 213, 215, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #fcfdff;
  --bs-btn-active-border-color: #fbfdff;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #FBFDFF;
  --bs-btn-disabled-border-color: #FBFDFF;
}

.btn-dark-grey {
  --bs-btn-color: #000;
  --bs-btn-bg: #8A8A8A;
  --bs-btn-border-color: #8A8A8A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #9c9c9c;
  --bs-btn-hover-border-color: #969696;
  --bs-btn-focus-shadow-rgb: 117, 117, 117;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #a1a1a1;
  --bs-btn-active-border-color: #969696;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #8A8A8A;
  --bs-btn-disabled-border-color: #8A8A8A;
}

.btn-dark-pink {
  --bs-btn-color: #fff;
  --bs-btn-bg: #BD0071;
  --bs-btn-border-color: #BD0071;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #a10060;
  --bs-btn-hover-border-color: #97005a;
  --bs-btn-focus-shadow-rgb: 199, 38, 134;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #97005a;
  --bs-btn-active-border-color: #8e0055;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #BD0071;
  --bs-btn-disabled-border-color: #BD0071;
}

.btn-inactive {
  --bs-btn-color: #000;
  --bs-btn-bg: #D9D9D9;
  --bs-btn-border-color: #D9D9D9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #dfdfdf;
  --bs-btn-hover-border-color: #dddddd;
  --bs-btn-focus-shadow-rgb: 184, 184, 184;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e1e1e1;
  --bs-btn-active-border-color: #dddddd;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #D9D9D9;
  --bs-btn-disabled-border-color: #D9D9D9;
}

.btn-accent {
  --bs-btn-color: #000;
  --bs-btn-bg: #b5be00;
  --bs-btn-border-color: #b5be00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #c0c826;
  --bs-btn-hover-border-color: #bcc51a;
  --bs-btn-focus-shadow-rgb: 154, 162, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #c4cb33;
  --bs-btn-active-border-color: #bcc51a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #000;
  --bs-btn-disabled-bg: #b5be00;
  --bs-btn-disabled-border-color: #b5be00;
}

.btn-outline-primary {
  --bs-btn-color: #1e4d60;
  --bs-btn-border-color: #1e4d60;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #1e4d60;
  --bs-btn-hover-border-color: #1e4d60;
  --bs-btn-focus-shadow-rgb: 30, 77, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #1e4d60;
  --bs-btn-active-border-color: #1e4d60;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #1e4d60;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #1e4d60;
  --bs-gradient: none;
}

.btn-outline-secondary {
  --bs-btn-color: #6a8a9c;
  --bs-btn-border-color: #6a8a9c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #6a8a9c;
  --bs-btn-hover-border-color: #6a8a9c;
  --bs-btn-focus-shadow-rgb: 106, 138, 156;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #6a8a9c;
  --bs-btn-active-border-color: #6a8a9c;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #6a8a9c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #6a8a9c;
  --bs-gradient: none;
}

.btn-outline-tertiary {
  --bs-btn-color: #D3DCE1;
  --bs-btn-border-color: #D3DCE1;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D3DCE1;
  --bs-btn-hover-border-color: #D3DCE1;
  --bs-btn-focus-shadow-rgb: 211, 220, 225;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D3DCE1;
  --bs-btn-active-border-color: #D3DCE1;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #D3DCE1;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D3DCE1;
  --bs-gradient: none;
}

.btn-outline-success {
  --bs-btn-color: #b5be00;
  --bs-btn-border-color: #b5be00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b5be00;
  --bs-btn-hover-border-color: #b5be00;
  --bs-btn-focus-shadow-rgb: 181, 190, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b5be00;
  --bs-btn-active-border-color: #b5be00;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #b5be00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b5be00;
  --bs-gradient: none;
}

.btn-outline-dark {
  --bs-btn-color: #3a3a3a;
  --bs-btn-border-color: #3a3a3a;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3a3a3a;
  --bs-btn-hover-border-color: #3a3a3a;
  --bs-btn-focus-shadow-rgb: 58, 58, 58;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3a3a3a;
  --bs-btn-active-border-color: #3a3a3a;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #3a3a3a;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3a3a3a;
  --bs-gradient: none;
}

.btn-outline-light {
  --bs-btn-color: #f6f6f6;
  --bs-btn-border-color: #f6f6f6;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f6f6f6;
  --bs-btn-hover-border-color: #f6f6f6;
  --bs-btn-focus-shadow-rgb: 246, 246, 246;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f6f6f6;
  --bs-btn-active-border-color: #f6f6f6;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #f6f6f6;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f6f6f6;
  --bs-gradient: none;
}

.btn-outline-white {
  --bs-btn-color: #ffffff;
  --bs-btn-border-color: #ffffff;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ffffff;
  --bs-btn-hover-border-color: #ffffff;
  --bs-btn-focus-shadow-rgb: 255, 255, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ffffff;
  --bs-btn-active-border-color: #ffffff;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #ffffff;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ffffff;
  --bs-gradient: none;
}

.btn-outline-danger {
  --bs-btn-color: #080808;
  --bs-btn-border-color: #080808;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #080808;
  --bs-btn-hover-border-color: #080808;
  --bs-btn-focus-shadow-rgb: 8, 8, 8;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #080808;
  --bs-btn-active-border-color: #080808;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #080808;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #080808;
  --bs-gradient: none;
}

.btn-outline-red {
  --bs-btn-color: #DA0000;
  --bs-btn-border-color: #DA0000;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #DA0000;
  --bs-btn-hover-border-color: #DA0000;
  --bs-btn-focus-shadow-rgb: 218, 0, 0;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #DA0000;
  --bs-btn-active-border-color: #DA0000;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #DA0000;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #DA0000;
  --bs-gradient: none;
}

.btn-outline-pink {
  --bs-btn-color: #ec008c;
  --bs-btn-border-color: #ec008c;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #ec008c;
  --bs-btn-hover-border-color: #ec008c;
  --bs-btn-focus-shadow-rgb: 236, 0, 140;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #ec008c;
  --bs-btn-active-border-color: #ec008c;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #ec008c;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #ec008c;
  --bs-gradient: none;
}

.btn-outline-orange {
  --bs-btn-color: #f2c900;
  --bs-btn-border-color: #f2c900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2c900;
  --bs-btn-hover-border-color: #f2c900;
  --bs-btn-focus-shadow-rgb: 242, 201, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2c900;
  --bs-btn-active-border-color: #f2c900;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #f2c900;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2c900;
  --bs-gradient: none;
}

.btn-outline-yellow {
  --bs-btn-color: #f2c900;
  --bs-btn-border-color: #f2c900;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #f2c900;
  --bs-btn-hover-border-color: #f2c900;
  --bs-btn-focus-shadow-rgb: 242, 201, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #f2c900;
  --bs-btn-active-border-color: #f2c900;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #f2c900;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #f2c900;
  --bs-gradient: none;
}

.btn-outline-green {
  --bs-btn-color: #00c608;
  --bs-btn-border-color: #00c608;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #00c608;
  --bs-btn-hover-border-color: #00c608;
  --bs-btn-focus-shadow-rgb: 0, 198, 8;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #00c608;
  --bs-btn-active-border-color: #00c608;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #00c608;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #00c608;
  --bs-gradient: none;
}

.btn-outline-blue {
  --bs-btn-color: #0073b4;
  --bs-btn-border-color: #0073b4;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #0073b4;
  --bs-btn-hover-border-color: #0073b4;
  --bs-btn-focus-shadow-rgb: 0, 115, 180;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #0073b4;
  --bs-btn-active-border-color: #0073b4;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #0073b4;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #0073b4;
  --bs-gradient: none;
}

.btn-outline-cyan {
  --bs-btn-color: #02c2dc;
  --bs-btn-border-color: #02c2dc;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #02c2dc;
  --bs-btn-hover-border-color: #02c2dc;
  --bs-btn-focus-shadow-rgb: 2, 194, 220;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #02c2dc;
  --bs-btn-active-border-color: #02c2dc;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #02c2dc;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #02c2dc;
  --bs-gradient: none;
}

.btn-outline-grey {
  --bs-btn-color: #BEB5B5;
  --bs-btn-border-color: #BEB5B5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #BEB5B5;
  --bs-btn-hover-border-color: #BEB5B5;
  --bs-btn-focus-shadow-rgb: 190, 181, 181;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #BEB5B5;
  --bs-btn-active-border-color: #BEB5B5;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #BEB5B5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #BEB5B5;
  --bs-gradient: none;
}

.btn-outline-blue-alt {
  --bs-btn-color: #3453c2;
  --bs-btn-border-color: #3453c2;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #3453c2;
  --bs-btn-hover-border-color: #3453c2;
  --bs-btn-focus-shadow-rgb: 52, 83, 194;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #3453c2;
  --bs-btn-active-border-color: #3453c2;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #3453c2;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #3453c2;
  --bs-gradient: none;
}

.btn-outline-blue-90 {
  --bs-btn-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-border-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-hover-border-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-focus-shadow-rgb: 30, 77, 96;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-active-border-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: rgba(30, 77, 96, 0.9647058824);
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: rgba(30, 77, 96, 0.9647058824);
  --bs-gradient: none;
}

.btn-outline-light-blue {
  --bs-btn-color: #E6ECF0;
  --bs-btn-border-color: #E6ECF0;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #E6ECF0;
  --bs-btn-hover-border-color: #E6ECF0;
  --bs-btn-focus-shadow-rgb: 230, 236, 240;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #E6ECF0;
  --bs-btn-active-border-color: #E6ECF0;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #E6ECF0;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #E6ECF0;
  --bs-gradient: none;
}

.btn-outline-dark-blue {
  --bs-btn-color: #183E4D;
  --bs-btn-border-color: #183E4D;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #183E4D;
  --bs-btn-hover-border-color: #183E4D;
  --bs-btn-focus-shadow-rgb: 24, 62, 77;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #183E4D;
  --bs-btn-active-border-color: #183E4D;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #183E4D;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #183E4D;
  --bs-gradient: none;
}

.btn-outline-light-secondary {
  --bs-btn-color: #e5e5e5;
  --bs-btn-border-color: #e5e5e5;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #e5e5e5;
  --bs-btn-hover-border-color: #e5e5e5;
  --bs-btn-focus-shadow-rgb: 229, 229, 229;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #e5e5e5;
  --bs-btn-active-border-color: #e5e5e5;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #e5e5e5;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #e5e5e5;
  --bs-gradient: none;
}

.btn-outline-secondary-10 {
  --bs-btn-color: #D5DBDF;
  --bs-btn-border-color: #D5DBDF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D5DBDF;
  --bs-btn-hover-border-color: #D5DBDF;
  --bs-btn-focus-shadow-rgb: 213, 219, 223;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D5DBDF;
  --bs-btn-active-border-color: #D5DBDF;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #D5DBDF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D5DBDF;
  --bs-gradient: none;
}

.btn-outline-secondary-20 {
  --bs-btn-color: #FBFDFF;
  --bs-btn-border-color: #FBFDFF;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #FBFDFF;
  --bs-btn-hover-border-color: #FBFDFF;
  --bs-btn-focus-shadow-rgb: 251, 253, 255;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #FBFDFF;
  --bs-btn-active-border-color: #FBFDFF;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #FBFDFF;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #FBFDFF;
  --bs-gradient: none;
}

.btn-outline-dark-grey {
  --bs-btn-color: #8A8A8A;
  --bs-btn-border-color: #8A8A8A;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #8A8A8A;
  --bs-btn-hover-border-color: #8A8A8A;
  --bs-btn-focus-shadow-rgb: 138, 138, 138;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #8A8A8A;
  --bs-btn-active-border-color: #8A8A8A;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #8A8A8A;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #8A8A8A;
  --bs-gradient: none;
}

.btn-outline-dark-pink {
  --bs-btn-color: #BD0071;
  --bs-btn-border-color: #BD0071;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #BD0071;
  --bs-btn-hover-border-color: #BD0071;
  --bs-btn-focus-shadow-rgb: 189, 0, 113;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #BD0071;
  --bs-btn-active-border-color: #BD0071;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #BD0071;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #BD0071;
  --bs-gradient: none;
}

.btn-outline-inactive {
  --bs-btn-color: #D9D9D9;
  --bs-btn-border-color: #D9D9D9;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #D9D9D9;
  --bs-btn-hover-border-color: #D9D9D9;
  --bs-btn-focus-shadow-rgb: 217, 217, 217;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #D9D9D9;
  --bs-btn-active-border-color: #D9D9D9;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #D9D9D9;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #D9D9D9;
  --bs-gradient: none;
}

.btn-outline-accent {
  --bs-btn-color: #b5be00;
  --bs-btn-border-color: #b5be00;
  --bs-btn-hover-color: #000;
  --bs-btn-hover-bg: #b5be00;
  --bs-btn-hover-border-color: #b5be00;
  --bs-btn-focus-shadow-rgb: 181, 190, 0;
  --bs-btn-active-color: #000;
  --bs-btn-active-bg: #b5be00;
  --bs-btn-active-border-color: #b5be00;
  --bs-btn-active-shadow: 0;
  --bs-btn-disabled-color: #b5be00;
  --bs-btn-disabled-bg: transparent;
  --bs-btn-disabled-border-color: #b5be00;
  --bs-gradient: none;
}

.btn-link {
  --bs-btn-font-weight: 400;
  --bs-btn-color: var(--bs-link-color);
  --bs-btn-bg: transparent;
  --bs-btn-border-color: transparent;
  --bs-btn-hover-color: var(--bs-link-hover-color);
  --bs-btn-hover-border-color: transparent;
  --bs-btn-active-color: var(--bs-link-hover-color);
  --bs-btn-active-border-color: transparent;
  --bs-btn-disabled-color: #6c757d;
  --bs-btn-disabled-border-color: transparent;
  --bs-btn-box-shadow: 0 0 0 #000;
  --bs-btn-focus-shadow-rgb: 88, 88, 88;
  text-decoration: none;
}
.btn-link:hover, .btn-link:focus-visible {
  text-decoration: none;
}
.btn-link:focus-visible {
  color: var(--bs-btn-color);
}
.btn-link:hover {
  color: var(--bs-btn-hover-color);
}

.btn-lg, .btn-group-lg > .btn {
  --bs-btn-padding-y: 10px;
  --bs-btn-padding-x: 16px;
  --bs-btn-font-size: 1.1875rem;
  --bs-btn-border-radius: 30px;
}

.btn-sm, .btn-group-sm > .btn {
  --bs-btn-padding-y: 6px;
  --bs-btn-padding-x: 12px;
  --bs-btn-font-size: 0.875rem;
  --bs-btn-border-radius: 30px;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart,
.dropup-center,
.dropdown-center {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  --bs-dropdown-zindex: 1000;
  --bs-dropdown-min-width: 10rem;
  --bs-dropdown-padding-x: 0;
  --bs-dropdown-padding-y: 0.5rem;
  --bs-dropdown-spacer: 0.125rem;
  --bs-dropdown-font-size: 1.1rem;
  --bs-dropdown-color: var(--bs-body-color);
  --bs-dropdown-bg: var(--bs-body-bg);
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-border-radius: var(--bs-border-radius);
  --bs-dropdown-border-width: var(--bs-border-width);
  --bs-dropdown-inner-border-radius: calc(var(--bs-border-radius) - var(--bs-border-width));
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-divider-margin-y: 0.5rem;
  --bs-dropdown-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-dropdown-link-color: var(--bs-body-color);
  --bs-dropdown-link-hover-color: var(--bs-body-color);
  --bs-dropdown-link-hover-bg: var(--bs-tertiary-bg);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1e4d60;
  --bs-dropdown-link-disabled-color: var(--bs-tertiary-color);
  --bs-dropdown-item-padding-x: 1rem;
  --bs-dropdown-item-padding-y: 0.25rem;
  --bs-dropdown-header-color: #6c757d;
  --bs-dropdown-header-padding-x: 1rem;
  --bs-dropdown-header-padding-y: 0.5rem;
  position: absolute;
  z-index: var(--bs-dropdown-zindex);
  display: none;
  min-width: var(--bs-dropdown-min-width);
  padding: var(--bs-dropdown-padding-y) var(--bs-dropdown-padding-x);
  margin: 0;
  font-size: var(--bs-dropdown-font-size);
  color: var(--bs-dropdown-color);
  text-align: left;
  list-style: none;
  background-color: var(--bs-dropdown-bg);
  background-clip: padding-box;
  border: var(--bs-dropdown-border-width) solid var(--bs-dropdown-border-color);
  border-radius: var(--bs-dropdown-border-radius);
  box-shadow: var(--bs-dropdown-box-shadow);
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: var(--bs-dropdown-spacer);
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: var(--bs-dropdown-spacer);
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: var(--bs-dropdown-spacer);
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: var(--bs-dropdown-spacer);
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: var(--bs-dropdown-divider-margin-y) 0;
  overflow: hidden;
  border-top: 1px solid var(--bs-dropdown-divider-bg);
  opacity: 1;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  clear: both;
  font-weight: 400;
  color: var(--bs-dropdown-link-color);
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  border-radius: var(--bs-dropdown-item-border-radius, 0);
}
.dropdown-item:hover, .dropdown-item:focus {
  color: var(--bs-dropdown-link-hover-color);
  background-color: var(--bs-dropdown-link-hover-bg);
}
.dropdown-item.active, .dropdown-item:active {
  color: var(--bs-dropdown-link-active-color);
  text-decoration: none;
  background-color: var(--bs-dropdown-link-active-bg);
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: var(--bs-dropdown-link-disabled-color);
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: var(--bs-dropdown-header-padding-y) var(--bs-dropdown-header-padding-x);
  margin-bottom: 0;
  font-size: 0.9625rem;
  color: var(--bs-dropdown-header-color);
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: var(--bs-dropdown-item-padding-y) var(--bs-dropdown-item-padding-x);
  color: var(--bs-dropdown-link-color);
}

.dropdown-menu-dark {
  --bs-dropdown-color: #dee2e6;
  --bs-dropdown-bg: #343a40;
  --bs-dropdown-border-color: var(--bs-border-color-translucent);
  --bs-dropdown-box-shadow: ;
  --bs-dropdown-link-color: #dee2e6;
  --bs-dropdown-link-hover-color: #fff;
  --bs-dropdown-divider-bg: var(--bs-border-color-translucent);
  --bs-dropdown-link-hover-bg: rgba(255, 255, 255, 0.15);
  --bs-dropdown-link-active-color: #fff;
  --bs-dropdown-link-active-bg: #1e4d60;
  --bs-dropdown-link-disabled-color: #adb5bd;
  --bs-dropdown-header-color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group {
  border-radius: 30px;
}
.btn-group > :not(.btn-check:first-child) + .btn,
.btn-group > .btn-group:not(:first-child) {
  margin-left: calc(0 * -1);
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn.dropdown-toggle-split:first-child,
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 10.5px;
  padding-left: 10.5px;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 9px;
  padding-left: 9px;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 12px;
  padding-left: 12px;
}

.btn-group.show .dropdown-toggle {
  box-shadow: 0;
}
.btn-group.show .dropdown-toggle.btn-link {
  box-shadow: none;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: calc(0 * -1);
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  --bs-nav-link-padding-x: 1rem;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-link-color);
  --bs-nav-link-hover-color: var(--bs-link-hover-color);
  --bs-nav-link-disabled-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  font-size: var(--bs-nav-link-font-size);
  font-weight: var(--bs-nav-link-font-weight);
  color: var(--bs-nav-link-color);
  background: none;
  border: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: var(--bs-nav-link-hover-color);
}
.nav-link:focus-visible {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
}
.nav-link.disabled, .nav-link:disabled {
  color: var(--bs-nav-link-disabled-color);
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  --bs-nav-tabs-border-width: var(--bs-border-width);
  --bs-nav-tabs-border-color: var(--bs-border-color);
  --bs-nav-tabs-border-radius: var(--bs-border-radius);
  --bs-nav-tabs-link-hover-border-color: var(--bs-secondary-bg) var(--bs-secondary-bg) var(--bs-border-color);
  --bs-nav-tabs-link-active-color: var(--bs-emphasis-color);
  --bs-nav-tabs-link-active-bg: var(--bs-body-bg);
  --bs-nav-tabs-link-active-border-color: var(--bs-border-color) var(--bs-border-color) var(--bs-body-bg);
  border-bottom: var(--bs-nav-tabs-border-width) solid var(--bs-nav-tabs-border-color);
}
.nav-tabs .nav-link {
  margin-bottom: calc(-1 * var(--bs-nav-tabs-border-width));
  border: var(--bs-nav-tabs-border-width) solid transparent;
  border-top-left-radius: var(--bs-nav-tabs-border-radius);
  border-top-right-radius: var(--bs-nav-tabs-border-radius);
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  isolation: isolate;
  border-color: var(--bs-nav-tabs-link-hover-border-color);
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: var(--bs-nav-tabs-link-active-color);
  background-color: var(--bs-nav-tabs-link-active-bg);
  border-color: var(--bs-nav-tabs-link-active-border-color);
}
.nav-tabs .dropdown-menu {
  margin-top: calc(-1 * var(--bs-nav-tabs-border-width));
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills {
  --bs-nav-pills-border-radius: var(--bs-border-radius);
  --bs-nav-pills-link-active-color: #fff;
  --bs-nav-pills-link-active-bg: #1e4d60;
}
.nav-pills .nav-link {
  border-radius: var(--bs-nav-pills-border-radius);
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: var(--bs-nav-pills-link-active-color);
  background-color: var(--bs-nav-pills-link-active-bg);
}

.nav-underline {
  --bs-nav-underline-gap: 1rem;
  --bs-nav-underline-border-width: 0.125rem;
  --bs-nav-underline-link-active-color: var(--bs-emphasis-color);
  gap: var(--bs-nav-underline-gap);
}
.nav-underline .nav-link {
  padding-right: 0;
  padding-left: 0;
  border-bottom: var(--bs-nav-underline-border-width) solid transparent;
}
.nav-underline .nav-link:hover, .nav-underline .nav-link:focus {
  border-bottom-color: currentcolor;
}
.nav-underline .nav-link.active,
.nav-underline .show > .nav-link {
  font-weight: 700;
  color: var(--bs-nav-underline-link-active-color);
  border-bottom-color: currentcolor;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  --bs-navbar-padding-x: 0;
  --bs-navbar-padding-y: 0.5rem;
  --bs-navbar-color: rgba(var(--bs-emphasis-color-rgb), 0.65);
  --bs-navbar-hover-color: rgba(var(--bs-emphasis-color-rgb), 0.8);
  --bs-navbar-disabled-color: rgba(var(--bs-emphasis-color-rgb), 0.3);
  --bs-navbar-active-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-padding-y: 0.29375rem;
  --bs-navbar-brand-margin-end: 1rem;
  --bs-navbar-brand-font-size: 1.375rem;
  --bs-navbar-brand-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-brand-hover-color: rgba(var(--bs-emphasis-color-rgb), 1);
  --bs-navbar-nav-link-padding-x: 0.5rem;
  --bs-navbar-toggler-padding-y: 0.25rem;
  --bs-navbar-toggler-padding-x: 0.75rem;
  --bs-navbar-toggler-font-size: 1.375rem;
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%2858, 58, 58, 0.75%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  --bs-navbar-toggler-border-color: rgba(var(--bs-emphasis-color-rgb), 0.15);
  --bs-navbar-toggler-border-radius: 30px;
  --bs-navbar-toggler-focus-width: 0;
  --bs-navbar-toggler-transition: box-shadow 0.15s ease-in-out;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-navbar-padding-y) var(--bs-navbar-padding-x);
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: var(--bs-navbar-brand-padding-y);
  padding-bottom: var(--bs-navbar-brand-padding-y);
  margin-right: var(--bs-navbar-brand-margin-end);
  font-size: var(--bs-navbar-brand-font-size);
  color: var(--bs-navbar-brand-color);
  white-space: nowrap;
}
.navbar-brand:hover, .navbar-brand:focus {
  color: var(--bs-navbar-brand-hover-color);
}

.navbar-nav {
  --bs-nav-link-padding-x: 0;
  --bs-nav-link-padding-y: 0.5rem;
  --bs-nav-link-font-weight: ;
  --bs-nav-link-color: var(--bs-navbar-color);
  --bs-nav-link-hover-color: var(--bs-navbar-hover-color);
  --bs-nav-link-disabled-color: var(--bs-navbar-disabled-color);
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link.active, .navbar-nav .nav-link.show {
  color: var(--bs-navbar-active-color);
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: var(--bs-navbar-color);
}
.navbar-text a,
.navbar-text a:hover,
.navbar-text a:focus {
  color: var(--bs-navbar-active-color);
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: var(--bs-navbar-toggler-padding-y) var(--bs-navbar-toggler-padding-x);
  font-size: var(--bs-navbar-toggler-font-size);
  line-height: 1;
  color: var(--bs-navbar-color);
  background-color: transparent;
  border: var(--bs-border-width) solid var(--bs-navbar-toggler-border-color);
  border-radius: var(--bs-navbar-toggler-border-radius);
  transition: var(--bs-navbar-toggler-transition);
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 var(--bs-navbar-toggler-focus-width);
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-image: var(--bs-navbar-toggler-icon-bg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: var(--bs-navbar-nav-link-padding-x);
    padding-left: var(--bs-navbar-nav-link-padding-x);
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: static;
    z-index: auto;
    flex-grow: 1;
    width: auto !important;
    height: auto !important;
    visibility: visible !important;
    background-color: transparent !important;
    border: 0 !important;
    transform: none !important;
    box-shadow: none;
    transition: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: var(--bs-navbar-nav-link-padding-x);
  padding-left: var(--bs-navbar-nav-link-padding-x);
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas {
  position: static;
  z-index: auto;
  flex-grow: 1;
  width: auto !important;
  height: auto !important;
  visibility: visible !important;
  background-color: transparent !important;
  border: 0 !important;
  transform: none !important;
  box-shadow: none;
  transition: none;
}
.navbar-expand .offcanvas .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas .offcanvas-body {
  display: flex;
  flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-dark,
.navbar[data-bs-theme=dark] {
  --bs-navbar-color: rgba(255, 255, 255, 0.55);
  --bs-navbar-hover-color: rgba(255, 255, 255, 0.75);
  --bs-navbar-disabled-color: rgba(255, 255, 255, 0.25);
  --bs-navbar-active-color: #fff;
  --bs-navbar-brand-color: #fff;
  --bs-navbar-brand-hover-color: #fff;
  --bs-navbar-toggler-border-color: rgba(255, 255, 255, 0.1);
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

[data-bs-theme=dark] .navbar-toggler-icon {
  --bs-navbar-toggler-icon-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}

.card {
  --bs-card-spacer-y: 1rem;
  --bs-card-spacer-x: 1rem;
  --bs-card-title-spacer-y: 0.5rem;
  --bs-card-title-color: ;
  --bs-card-subtitle-color: ;
  --bs-card-border-width: var(--bs-border-width);
  --bs-card-border-color: var(--bs-border-color-translucent);
  --bs-card-border-radius: var(--bs-border-radius);
  --bs-card-box-shadow: ;
  --bs-card-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-card-cap-padding-y: 0.5rem;
  --bs-card-cap-padding-x: 1rem;
  --bs-card-cap-bg: rgba(var(--bs-body-color-rgb), 0.03);
  --bs-card-cap-color: ;
  --bs-card-height: ;
  --bs-card-color: ;
  --bs-card-bg: var(--bs-body-bg);
  --bs-card-img-overlay-padding: 1rem;
  --bs-card-group-margin: 0.75rem;
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  height: var(--bs-card-height);
  color: var(--bs-body-color);
  word-wrap: break-word;
  background-color: var(--bs-card-bg);
  background-clip: border-box;
  border: var(--bs-card-border-width) solid var(--bs-card-border-color);
  border-radius: var(--bs-card-border-radius);
  box-shadow: var(--bs-card-box-shadow);
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: var(--bs-card-spacer-y) var(--bs-card-spacer-x);
  color: var(--bs-card-color);
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

.card-subtitle {
  margin-top: calc(-0.5 * var(--bs-card-title-spacer-y));
  margin-bottom: 0;
  color: var(--bs-card-subtitle-color);
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: var(--bs-card-spacer-x);
}

.card-header {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  margin-bottom: 0;
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-bottom: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-header:first-child {
  border-radius: var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius) 0 0;
}

.card-footer {
  padding: var(--bs-card-cap-padding-y) var(--bs-card-cap-padding-x);
  color: var(--bs-card-cap-color);
  background-color: var(--bs-card-cap-bg);
  border-top: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.card-footer:last-child {
  border-radius: 0 0 var(--bs-card-inner-border-radius) var(--bs-card-inner-border-radius);
}

.card-header-tabs {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-bottom: calc(-1 * var(--bs-card-cap-padding-y));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: var(--bs-card-bg);
  border-bottom-color: var(--bs-card-bg);
}

.card-header-pills {
  margin-right: calc(-0.5 * var(--bs-card-cap-padding-x));
  margin-left: calc(-0.5 * var(--bs-card-cap-padding-x));
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: var(--bs-card-img-overlay-padding);
  border-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: var(--bs-card-inner-border-radius);
  border-top-right-radius: var(--bs-card-inner-border-radius);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: var(--bs-card-inner-border-radius);
  border-bottom-left-radius: var(--bs-card-inner-border-radius);
}

.card-group > .card {
  margin-bottom: var(--bs-card-group-margin);
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
  .card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
  .card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
  .card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
  .card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion {
  --bs-accordion-color: var(--bs-body-color);
  --bs-accordion-bg: var(--bs-body-bg);
  --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  --bs-accordion-border-color: var(--bs-border-color);
  --bs-accordion-border-width: var(--bs-border-width);
  --bs-accordion-border-radius: var(--bs-border-radius);
  --bs-accordion-inner-border-radius: calc(var(--bs-border-radius) - (var(--bs-border-width)));
  --bs-accordion-btn-padding-x: 1.25rem;
  --bs-accordion-btn-padding-y: 1rem;
  --bs-accordion-btn-color: var(--bs-body-color);
  --bs-accordion-btn-bg: var(--bs-accordion-bg);
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%233a3a3a'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-icon-width: 1.25rem;
  --bs-accordion-btn-icon-transform: rotate(-180deg);
  --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c1f26'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-focus-border-color: #8fa6b0;
  --bs-accordion-btn-focus-box-shadow: 0;
  --bs-accordion-body-padding-x: 1.25rem;
  --bs-accordion-body-padding-y: 1rem;
  --bs-accordion-active-color: var(--bs-primary-text-emphasis);
  --bs-accordion-active-bg: var(--bs-primary-bg-subtle);
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: var(--bs-accordion-btn-padding-y) var(--bs-accordion-btn-padding-x);
  font-size: 1.1rem;
  color: var(--bs-accordion-btn-color);
  text-align: left;
  background-color: var(--bs-accordion-btn-bg);
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: var(--bs-accordion-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: var(--bs-accordion-active-color);
  background-color: var(--bs-accordion-active-bg);
  box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}
.accordion-button:not(.collapsed)::after {
  background-image: var(--bs-accordion-btn-active-icon);
  transform: var(--bs-accordion-btn-icon-transform);
}
.accordion-button::after {
  flex-shrink: 0;
  width: var(--bs-accordion-btn-icon-width);
  height: var(--bs-accordion-btn-icon-width);
  margin-left: auto;
  content: "";
  background-image: var(--bs-accordion-btn-icon);
  background-repeat: no-repeat;
  background-size: var(--bs-accordion-btn-icon-width);
  transition: var(--bs-accordion-btn-icon-transition);
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: var(--bs-accordion-btn-focus-border-color);
  outline: 0;
  box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  color: var(--bs-accordion-color);
  background-color: var(--bs-accordion-bg);
  border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}
.accordion-item:first-of-type {
  border-top-left-radius: var(--bs-accordion-border-radius);
  border-top-right-radius: var(--bs-accordion-border-radius);
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: var(--bs-accordion-inner-border-radius);
  border-top-right-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
  border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: var(--bs-accordion-border-radius);
  border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
  padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
  border-radius: 0;
}

[data-bs-theme=dark] .accordion-button::after {
  --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237894a0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%237894a0'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.breadcrumb {
  --bs-breadcrumb-padding-x: 0;
  --bs-breadcrumb-padding-y: 0;
  --bs-breadcrumb-margin-bottom: 1rem;
  --bs-breadcrumb-bg: ;
  --bs-breadcrumb-border-radius: ;
  --bs-breadcrumb-divider-color: var(--bs-secondary-color);
  --bs-breadcrumb-item-padding-x: 0.5rem;
  --bs-breadcrumb-item-active-color: var(--bs-secondary-color);
  display: flex;
  flex-wrap: wrap;
  padding: var(--bs-breadcrumb-padding-y) var(--bs-breadcrumb-padding-x);
  margin-bottom: var(--bs-breadcrumb-margin-bottom);
  font-size: var(--bs-breadcrumb-font-size);
  list-style: none;
  background-color: var(--bs-breadcrumb-bg);
  border-radius: var(--bs-breadcrumb-border-radius);
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: var(--bs-breadcrumb-item-padding-x);
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: var(--bs-breadcrumb-item-padding-x);
  color: var(--bs-breadcrumb-divider-color);
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: var(--bs-breadcrumb-item-active-color);
}

.pagination {
  --bs-pagination-padding-x: 0.75rem;
  --bs-pagination-padding-y: 0.375rem;
  --bs-pagination-font-size: 1.1rem;
  --bs-pagination-color: var(--bs-link-color);
  --bs-pagination-bg: var(--bs-body-bg);
  --bs-pagination-border-width: var(--bs-border-width);
  --bs-pagination-border-color: var(--bs-border-color);
  --bs-pagination-border-radius: var(--bs-border-radius);
  --bs-pagination-hover-color: var(--bs-link-hover-color);
  --bs-pagination-hover-bg: var(--bs-tertiary-bg);
  --bs-pagination-hover-border-color: var(--bs-border-color);
  --bs-pagination-focus-color: var(--bs-link-hover-color);
  --bs-pagination-focus-bg: var(--bs-secondary-bg);
  --bs-pagination-focus-box-shadow: 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
  --bs-pagination-active-color: #fff;
  --bs-pagination-active-bg: #1e4d60;
  --bs-pagination-active-border-color: #1e4d60;
  --bs-pagination-disabled-color: var(--bs-secondary-color);
  --bs-pagination-disabled-bg: var(--bs-secondary-bg);
  --bs-pagination-disabled-border-color: var(--bs-border-color);
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  padding: var(--bs-pagination-padding-y) var(--bs-pagination-padding-x);
  font-size: var(--bs-pagination-font-size);
  color: var(--bs-pagination-color);
  background-color: var(--bs-pagination-bg);
  border: var(--bs-pagination-border-width) solid var(--bs-pagination-border-color);
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: var(--bs-pagination-hover-color);
  background-color: var(--bs-pagination-hover-bg);
  border-color: var(--bs-pagination-hover-border-color);
}
.page-link:focus {
  z-index: 3;
  color: var(--bs-pagination-focus-color);
  background-color: var(--bs-pagination-focus-bg);
  outline: 0;
  box-shadow: var(--bs-pagination-focus-box-shadow);
}
.page-link.active, .active > .page-link {
  z-index: 3;
  color: var(--bs-pagination-active-color);
  background-color: var(--bs-pagination-active-bg);
  border-color: var(--bs-pagination-active-border-color);
}
.page-link.disabled, .disabled > .page-link {
  color: var(--bs-pagination-disabled-color);
  pointer-events: none;
  background-color: var(--bs-pagination-disabled-bg);
  border-color: var(--bs-pagination-disabled-border-color);
}

.page-item:not(:first-child) .page-link {
  margin-left: calc(var(--bs-border-width) * -1);
}
.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
}
.page-item:last-child .page-link {
  border-top-right-radius: var(--bs-pagination-border-radius);
  border-bottom-right-radius: var(--bs-pagination-border-radius);
}

.pagination-lg {
  --bs-pagination-padding-x: 1.5rem;
  --bs-pagination-padding-y: 0.75rem;
  --bs-pagination-font-size: calc(1.2625rem + 0.15vw);
  --bs-pagination-border-radius: var(--bs-border-radius-lg);
}
@media (min-width: 1200px) {
  .pagination-lg {
    --bs-pagination-font-size: 1.375rem;
  }
}

.pagination-sm {
  --bs-pagination-padding-x: 0.5rem;
  --bs-pagination-padding-y: 0.25rem;
  --bs-pagination-font-size: 0.9625rem;
  --bs-pagination-border-radius: var(--bs-border-radius-sm);
}

.badge {
  --bs-badge-padding-x: 0.65em;
  --bs-badge-padding-y: 0.35em;
  --bs-badge-font-size: 0.8125rem;
  --bs-badge-font-weight: 700;
  --bs-badge-color: #fff;
  --bs-badge-border-radius: var(--bs-border-radius);
  display: inline-block;
  padding: var(--bs-badge-padding-y) var(--bs-badge-padding-x);
  font-size: var(--bs-badge-font-size);
  font-weight: var(--bs-badge-font-weight);
  line-height: 1;
  color: var(--bs-badge-color);
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: var(--bs-badge-border-radius);
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  --bs-alert-bg: transparent;
  --bs-alert-padding-x: 1rem;
  --bs-alert-padding-y: 1rem;
  --bs-alert-margin-bottom: 1rem;
  --bs-alert-color: inherit;
  --bs-alert-border-color: transparent;
  --bs-alert-border: var(--bs-border-width) solid var(--bs-alert-border-color);
  --bs-alert-border-radius: var(--bs-border-radius);
  --bs-alert-link-color: inherit;
  position: relative;
  padding: var(--bs-alert-padding-y) var(--bs-alert-padding-x);
  margin-bottom: var(--bs-alert-margin-bottom);
  color: var(--bs-alert-color);
  background-color: var(--bs-alert-bg);
  border: var(--bs-alert-border);
  border-radius: var(--bs-alert-border-radius);
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
  color: var(--bs-alert-link-color);
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  --bs-alert-color: var(--bs-primary-text-emphasis);
  --bs-alert-bg: var(--bs-primary-bg-subtle);
  --bs-alert-border-color: var(--bs-primary-border-subtle);
  --bs-alert-link-color: var(--bs-primary-text-emphasis);
}

.alert-secondary {
  --bs-alert-color: var(--bs-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-text-emphasis);
}

.alert-tertiary {
  --bs-alert-color: var(--bs-tertiary-text-emphasis);
  --bs-alert-bg: var(--bs-tertiary-bg-subtle);
  --bs-alert-border-color: var(--bs-tertiary-border-subtle);
  --bs-alert-link-color: var(--bs-tertiary-text-emphasis);
}

.alert-success {
  --bs-alert-color: var(--bs-success-text-emphasis);
  --bs-alert-bg: var(--bs-success-bg-subtle);
  --bs-alert-border-color: var(--bs-success-border-subtle);
  --bs-alert-link-color: var(--bs-success-text-emphasis);
}

.alert-dark {
  --bs-alert-color: var(--bs-dark-text-emphasis);
  --bs-alert-bg: var(--bs-dark-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-border-subtle);
  --bs-alert-link-color: var(--bs-dark-text-emphasis);
}

.alert-light {
  --bs-alert-color: var(--bs-light-text-emphasis);
  --bs-alert-bg: var(--bs-light-bg-subtle);
  --bs-alert-border-color: var(--bs-light-border-subtle);
  --bs-alert-link-color: var(--bs-light-text-emphasis);
}

.alert-white {
  --bs-alert-color: var(--bs-white-text-emphasis);
  --bs-alert-bg: var(--bs-white-bg-subtle);
  --bs-alert-border-color: var(--bs-white-border-subtle);
  --bs-alert-link-color: var(--bs-white-text-emphasis);
}

.alert-danger {
  --bs-alert-color: var(--bs-danger-text-emphasis);
  --bs-alert-bg: var(--bs-danger-bg-subtle);
  --bs-alert-border-color: var(--bs-danger-border-subtle);
  --bs-alert-link-color: var(--bs-danger-text-emphasis);
}

.alert-red {
  --bs-alert-color: var(--bs-red-text-emphasis);
  --bs-alert-bg: var(--bs-red-bg-subtle);
  --bs-alert-border-color: var(--bs-red-border-subtle);
  --bs-alert-link-color: var(--bs-red-text-emphasis);
}

.alert-pink {
  --bs-alert-color: var(--bs-pink-text-emphasis);
  --bs-alert-bg: var(--bs-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-pink-border-subtle);
  --bs-alert-link-color: var(--bs-pink-text-emphasis);
}

.alert-orange {
  --bs-alert-color: var(--bs-orange-text-emphasis);
  --bs-alert-bg: var(--bs-orange-bg-subtle);
  --bs-alert-border-color: var(--bs-orange-border-subtle);
  --bs-alert-link-color: var(--bs-orange-text-emphasis);
}

.alert-yellow {
  --bs-alert-color: var(--bs-yellow-text-emphasis);
  --bs-alert-bg: var(--bs-yellow-bg-subtle);
  --bs-alert-border-color: var(--bs-yellow-border-subtle);
  --bs-alert-link-color: var(--bs-yellow-text-emphasis);
}

.alert-green {
  --bs-alert-color: var(--bs-green-text-emphasis);
  --bs-alert-bg: var(--bs-green-bg-subtle);
  --bs-alert-border-color: var(--bs-green-border-subtle);
  --bs-alert-link-color: var(--bs-green-text-emphasis);
}

.alert-blue {
  --bs-alert-color: var(--bs-blue-text-emphasis);
  --bs-alert-bg: var(--bs-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-border-subtle);
  --bs-alert-link-color: var(--bs-blue-text-emphasis);
}

.alert-cyan {
  --bs-alert-color: var(--bs-cyan-text-emphasis);
  --bs-alert-bg: var(--bs-cyan-bg-subtle);
  --bs-alert-border-color: var(--bs-cyan-border-subtle);
  --bs-alert-link-color: var(--bs-cyan-text-emphasis);
}

.alert-grey {
  --bs-alert-color: var(--bs-grey-text-emphasis);
  --bs-alert-bg: var(--bs-grey-bg-subtle);
  --bs-alert-border-color: var(--bs-grey-border-subtle);
  --bs-alert-link-color: var(--bs-grey-text-emphasis);
}

.alert-blue-alt {
  --bs-alert-color: var(--bs-blue-alt-text-emphasis);
  --bs-alert-bg: var(--bs-blue-alt-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-alt-border-subtle);
  --bs-alert-link-color: var(--bs-blue-alt-text-emphasis);
}

.alert-blue-90 {
  --bs-alert-color: var(--bs-blue-90-text-emphasis);
  --bs-alert-bg: var(--bs-blue-90-bg-subtle);
  --bs-alert-border-color: var(--bs-blue-90-border-subtle);
  --bs-alert-link-color: var(--bs-blue-90-text-emphasis);
}

.alert-light-blue {
  --bs-alert-color: var(--bs-light-blue-text-emphasis);
  --bs-alert-bg: var(--bs-light-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-light-blue-border-subtle);
  --bs-alert-link-color: var(--bs-light-blue-text-emphasis);
}

.alert-dark-blue {
  --bs-alert-color: var(--bs-dark-blue-text-emphasis);
  --bs-alert-bg: var(--bs-dark-blue-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-blue-border-subtle);
  --bs-alert-link-color: var(--bs-dark-blue-text-emphasis);
}

.alert-light-secondary {
  --bs-alert-color: var(--bs-light-secondary-text-emphasis);
  --bs-alert-bg: var(--bs-light-secondary-bg-subtle);
  --bs-alert-border-color: var(--bs-light-secondary-border-subtle);
  --bs-alert-link-color: var(--bs-light-secondary-text-emphasis);
}

.alert-secondary-10 {
  --bs-alert-color: var(--bs-secondary-10-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-10-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-10-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-10-text-emphasis);
}

.alert-secondary-20 {
  --bs-alert-color: var(--bs-secondary-20-text-emphasis);
  --bs-alert-bg: var(--bs-secondary-20-bg-subtle);
  --bs-alert-border-color: var(--bs-secondary-20-border-subtle);
  --bs-alert-link-color: var(--bs-secondary-20-text-emphasis);
}

.alert-dark-grey {
  --bs-alert-color: var(--bs-dark-grey-text-emphasis);
  --bs-alert-bg: var(--bs-dark-grey-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-grey-border-subtle);
  --bs-alert-link-color: var(--bs-dark-grey-text-emphasis);
}

.alert-dark-pink {
  --bs-alert-color: var(--bs-dark-pink-text-emphasis);
  --bs-alert-bg: var(--bs-dark-pink-bg-subtle);
  --bs-alert-border-color: var(--bs-dark-pink-border-subtle);
  --bs-alert-link-color: var(--bs-dark-pink-text-emphasis);
}

.alert-inactive {
  --bs-alert-color: var(--bs-inactive-text-emphasis);
  --bs-alert-bg: var(--bs-inactive-bg-subtle);
  --bs-alert-border-color: var(--bs-inactive-border-subtle);
  --bs-alert-link-color: var(--bs-inactive-text-emphasis);
}

.alert-accent {
  --bs-alert-color: var(--bs-accent-text-emphasis);
  --bs-alert-bg: var(--bs-accent-bg-subtle);
  --bs-alert-border-color: var(--bs-accent-border-subtle);
  --bs-alert-link-color: var(--bs-accent-text-emphasis);
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress,
.progress-stacked {
  --bs-progress-height: 1rem;
  --bs-progress-font-size: 0.825rem;
  --bs-progress-bg: var(--bs-secondary-bg);
  --bs-progress-border-radius: var(--bs-border-radius);
  --bs-progress-box-shadow: var(--bs-box-shadow-inset);
  --bs-progress-bar-color: #fff;
  --bs-progress-bar-bg: #1e4d60;
  --bs-progress-bar-transition: width 0.6s ease;
  display: flex;
  height: var(--bs-progress-height);
  overflow: hidden;
  font-size: var(--bs-progress-font-size);
  background-color: var(--bs-progress-bg);
  border-radius: var(--bs-progress-border-radius);
  box-shadow: var(--bs-progress-box-shadow);
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: var(--bs-progress-bar-color);
  text-align: center;
  white-space: nowrap;
  background-color: var(--bs-progress-bar-bg);
  transition: var(--bs-progress-bar-transition);
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: var(--bs-progress-height) var(--bs-progress-height);
}

.progress-stacked > .progress {
  overflow: visible;
}

.progress-stacked > .progress > .progress-bar {
  width: 100%;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  --bs-list-group-color: var(--bs-body-color);
  --bs-list-group-bg: var(--bs-body-bg);
  --bs-list-group-border-color: var(--bs-border-color);
  --bs-list-group-border-width: var(--bs-border-width);
  --bs-list-group-border-radius: var(--bs-border-radius);
  --bs-list-group-item-padding-x: 1rem;
  --bs-list-group-item-padding-y: 0.5rem;
  --bs-list-group-action-color: var(--bs-secondary-color);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-bg);
  --bs-list-group-action-active-color: var(--bs-body-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-bg);
  --bs-list-group-disabled-color: var(--bs-secondary-color);
  --bs-list-group-disabled-bg: var(--bs-body-bg);
  --bs-list-group-active-color: #fff;
  --bs-list-group-active-bg: #1e4d60;
  --bs-list-group-active-border-color: #1e4d60;
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: var(--bs-list-group-border-radius);
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > .list-group-item::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: var(--bs-list-group-action-color);
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: var(--bs-list-group-action-hover-color);
  text-decoration: none;
  background-color: var(--bs-list-group-action-hover-bg);
}
.list-group-item-action:active {
  color: var(--bs-list-group-action-active-color);
  background-color: var(--bs-list-group-action-active-bg);
}

.list-group-item {
  position: relative;
  display: block;
  padding: var(--bs-list-group-item-padding-y) var(--bs-list-group-item-padding-x);
  color: var(--bs-list-group-color);
  background-color: var(--bs-list-group-bg);
  border: var(--bs-list-group-border-width) solid var(--bs-list-group-border-color);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: var(--bs-list-group-disabled-color);
  pointer-events: none;
  background-color: var(--bs-list-group-disabled-bg);
}
.list-group-item.active {
  z-index: 2;
  color: var(--bs-list-group-active-color);
  background-color: var(--bs-list-group-active-bg);
  border-color: var(--bs-list-group-active-border-color);
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: calc(-1 * var(--bs-list-group-border-width));
  border-top-width: var(--bs-list-group-border-width);
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child:not(:last-child) {
  border-bottom-left-radius: var(--bs-list-group-border-radius);
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child:not(:first-child) {
  border-top-right-radius: var(--bs-list-group-border-radius);
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: var(--bs-list-group-border-width);
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: calc(-1 * var(--bs-list-group-border-width));
  border-left-width: var(--bs-list-group-border-width);
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child:not(:last-child) {
    border-bottom-left-radius: var(--bs-list-group-border-radius);
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child:not(:first-child) {
    border-top-right-radius: var(--bs-list-group-border-radius);
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: var(--bs-list-group-border-width);
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: calc(-1 * var(--bs-list-group-border-width));
    border-left-width: var(--bs-list-group-border-width);
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 var(--bs-list-group-border-width);
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  --bs-list-group-color: var(--bs-primary-text-emphasis);
  --bs-list-group-bg: var(--bs-primary-bg-subtle);
  --bs-list-group-border-color: var(--bs-primary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-primary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-primary-border-subtle);
  --bs-list-group-active-color: var(--bs-primary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-primary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-primary-text-emphasis);
}

.list-group-item-secondary {
  --bs-list-group-color: var(--bs-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-text-emphasis);
}

.list-group-item-tertiary {
  --bs-list-group-color: var(--bs-tertiary-text-emphasis);
  --bs-list-group-bg: var(--bs-tertiary-bg-subtle);
  --bs-list-group-border-color: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-tertiary-border-subtle);
  --bs-list-group-active-color: var(--bs-tertiary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-tertiary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-tertiary-text-emphasis);
}

.list-group-item-success {
  --bs-list-group-color: var(--bs-success-text-emphasis);
  --bs-list-group-bg: var(--bs-success-bg-subtle);
  --bs-list-group-border-color: var(--bs-success-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-success-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-success-border-subtle);
  --bs-list-group-active-color: var(--bs-success-bg-subtle);
  --bs-list-group-active-bg: var(--bs-success-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-success-text-emphasis);
}

.list-group-item-dark {
  --bs-list-group-color: var(--bs-dark-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-text-emphasis);
}

.list-group-item-light {
  --bs-list-group-color: var(--bs-light-text-emphasis);
  --bs-list-group-bg: var(--bs-light-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-border-subtle);
  --bs-list-group-active-color: var(--bs-light-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-text-emphasis);
}

.list-group-item-white {
  --bs-list-group-color: var(--bs-white-text-emphasis);
  --bs-list-group-bg: var(--bs-white-bg-subtle);
  --bs-list-group-border-color: var(--bs-white-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-white-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-white-border-subtle);
  --bs-list-group-active-color: var(--bs-white-bg-subtle);
  --bs-list-group-active-bg: var(--bs-white-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-white-text-emphasis);
}

.list-group-item-danger {
  --bs-list-group-color: var(--bs-danger-text-emphasis);
  --bs-list-group-bg: var(--bs-danger-bg-subtle);
  --bs-list-group-border-color: var(--bs-danger-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-danger-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-danger-border-subtle);
  --bs-list-group-active-color: var(--bs-danger-bg-subtle);
  --bs-list-group-active-bg: var(--bs-danger-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-danger-text-emphasis);
}

.list-group-item-red {
  --bs-list-group-color: var(--bs-red-text-emphasis);
  --bs-list-group-bg: var(--bs-red-bg-subtle);
  --bs-list-group-border-color: var(--bs-red-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-red-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-red-border-subtle);
  --bs-list-group-active-color: var(--bs-red-bg-subtle);
  --bs-list-group-active-bg: var(--bs-red-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-red-text-emphasis);
}

.list-group-item-pink {
  --bs-list-group-color: var(--bs-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-pink-text-emphasis);
}

.list-group-item-orange {
  --bs-list-group-color: var(--bs-orange-text-emphasis);
  --bs-list-group-bg: var(--bs-orange-bg-subtle);
  --bs-list-group-border-color: var(--bs-orange-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-orange-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-orange-border-subtle);
  --bs-list-group-active-color: var(--bs-orange-bg-subtle);
  --bs-list-group-active-bg: var(--bs-orange-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-orange-text-emphasis);
}

.list-group-item-yellow {
  --bs-list-group-color: var(--bs-yellow-text-emphasis);
  --bs-list-group-bg: var(--bs-yellow-bg-subtle);
  --bs-list-group-border-color: var(--bs-yellow-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-yellow-border-subtle);
  --bs-list-group-active-color: var(--bs-yellow-bg-subtle);
  --bs-list-group-active-bg: var(--bs-yellow-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-yellow-text-emphasis);
}

.list-group-item-green {
  --bs-list-group-color: var(--bs-green-text-emphasis);
  --bs-list-group-bg: var(--bs-green-bg-subtle);
  --bs-list-group-border-color: var(--bs-green-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-green-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-green-border-subtle);
  --bs-list-group-active-color: var(--bs-green-bg-subtle);
  --bs-list-group-active-bg: var(--bs-green-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-green-text-emphasis);
}

.list-group-item-blue {
  --bs-list-group-color: var(--bs-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-text-emphasis);
}

.list-group-item-cyan {
  --bs-list-group-color: var(--bs-cyan-text-emphasis);
  --bs-list-group-bg: var(--bs-cyan-bg-subtle);
  --bs-list-group-border-color: var(--bs-cyan-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-cyan-border-subtle);
  --bs-list-group-active-color: var(--bs-cyan-bg-subtle);
  --bs-list-group-active-bg: var(--bs-cyan-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-cyan-text-emphasis);
}

.list-group-item-grey {
  --bs-list-group-color: var(--bs-grey-text-emphasis);
  --bs-list-group-bg: var(--bs-grey-bg-subtle);
  --bs-list-group-border-color: var(--bs-grey-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-grey-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-grey-border-subtle);
  --bs-list-group-active-color: var(--bs-grey-bg-subtle);
  --bs-list-group-active-bg: var(--bs-grey-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-grey-text-emphasis);
}

.list-group-item-blue-alt {
  --bs-list-group-color: var(--bs-blue-alt-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-alt-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-alt-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-alt-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-alt-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-alt-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-alt-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-alt-text-emphasis);
}

.list-group-item-blue-90 {
  --bs-list-group-color: var(--bs-blue-90-text-emphasis);
  --bs-list-group-bg: var(--bs-blue-90-bg-subtle);
  --bs-list-group-border-color: var(--bs-blue-90-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-blue-90-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-blue-90-border-subtle);
  --bs-list-group-active-color: var(--bs-blue-90-bg-subtle);
  --bs-list-group-active-bg: var(--bs-blue-90-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-blue-90-text-emphasis);
}

.list-group-item-light-blue {
  --bs-list-group-color: var(--bs-light-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-light-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-light-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-blue-text-emphasis);
}

.list-group-item-dark-blue {
  --bs-list-group-color: var(--bs-dark-blue-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-blue-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-blue-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-blue-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-blue-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-blue-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-blue-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-blue-text-emphasis);
}

.list-group-item-light-secondary {
  --bs-list-group-color: var(--bs-light-secondary-text-emphasis);
  --bs-list-group-bg: var(--bs-light-secondary-bg-subtle);
  --bs-list-group-border-color: var(--bs-light-secondary-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-light-secondary-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-light-secondary-border-subtle);
  --bs-list-group-active-color: var(--bs-light-secondary-bg-subtle);
  --bs-list-group-active-bg: var(--bs-light-secondary-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-light-secondary-text-emphasis);
}

.list-group-item-secondary-10 {
  --bs-list-group-color: var(--bs-secondary-10-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-10-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-10-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-10-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-10-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-10-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-10-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-10-text-emphasis);
}

.list-group-item-secondary-20 {
  --bs-list-group-color: var(--bs-secondary-20-text-emphasis);
  --bs-list-group-bg: var(--bs-secondary-20-bg-subtle);
  --bs-list-group-border-color: var(--bs-secondary-20-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-secondary-20-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-secondary-20-border-subtle);
  --bs-list-group-active-color: var(--bs-secondary-20-bg-subtle);
  --bs-list-group-active-bg: var(--bs-secondary-20-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-secondary-20-text-emphasis);
}

.list-group-item-dark-grey {
  --bs-list-group-color: var(--bs-dark-grey-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-grey-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-grey-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-grey-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-grey-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-grey-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-grey-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-grey-text-emphasis);
}

.list-group-item-dark-pink {
  --bs-list-group-color: var(--bs-dark-pink-text-emphasis);
  --bs-list-group-bg: var(--bs-dark-pink-bg-subtle);
  --bs-list-group-border-color: var(--bs-dark-pink-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-dark-pink-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-dark-pink-border-subtle);
  --bs-list-group-active-color: var(--bs-dark-pink-bg-subtle);
  --bs-list-group-active-bg: var(--bs-dark-pink-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-dark-pink-text-emphasis);
}

.list-group-item-inactive {
  --bs-list-group-color: var(--bs-inactive-text-emphasis);
  --bs-list-group-bg: var(--bs-inactive-bg-subtle);
  --bs-list-group-border-color: var(--bs-inactive-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-inactive-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-inactive-border-subtle);
  --bs-list-group-active-color: var(--bs-inactive-bg-subtle);
  --bs-list-group-active-bg: var(--bs-inactive-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-inactive-text-emphasis);
}

.list-group-item-accent {
  --bs-list-group-color: var(--bs-accent-text-emphasis);
  --bs-list-group-bg: var(--bs-accent-bg-subtle);
  --bs-list-group-border-color: var(--bs-accent-border-subtle);
  --bs-list-group-action-hover-color: var(--bs-emphasis-color);
  --bs-list-group-action-hover-bg: var(--bs-accent-border-subtle);
  --bs-list-group-action-active-color: var(--bs-emphasis-color);
  --bs-list-group-action-active-bg: var(--bs-accent-border-subtle);
  --bs-list-group-active-color: var(--bs-accent-bg-subtle);
  --bs-list-group-active-bg: var(--bs-accent-text-emphasis);
  --bs-list-group-active-border-color: var(--bs-accent-text-emphasis);
}

.btn-close {
  --bs-btn-close-color: #000;
  --bs-btn-close-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e");
  --bs-btn-close-opacity: 0.5;
  --bs-btn-close-hover-opacity: 0.75;
  --bs-btn-close-focus-shadow: 0 0 0 0.25rem rgba(30, 77, 96, 0.25);
  --bs-btn-close-focus-opacity: 1;
  --bs-btn-close-disabled-opacity: 0.25;
  --bs-btn-close-white-filter: invert(1) grayscale(100%) brightness(200%);
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: var(--bs-btn-close-color);
  background: transparent var(--bs-btn-close-bg) center/1em auto no-repeat;
  border: 0;
  border-radius: 0.375rem;
  opacity: var(--bs-btn-close-opacity);
}
.btn-close:hover {
  color: var(--bs-btn-close-color);
  text-decoration: none;
  opacity: var(--bs-btn-close-hover-opacity);
}
.btn-close:focus {
  outline: 0;
  box-shadow: var(--bs-btn-close-focus-shadow);
  opacity: var(--bs-btn-close-focus-opacity);
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: var(--bs-btn-close-disabled-opacity);
}

.btn-close-white {
  filter: var(--bs-btn-close-white-filter);
}

[data-bs-theme=dark] .btn-close {
  filter: var(--bs-btn-close-white-filter);
}

.toast {
  --bs-toast-zindex: 1090;
  --bs-toast-padding-x: 0.75rem;
  --bs-toast-padding-y: 0.5rem;
  --bs-toast-spacing: 1.5rem;
  --bs-toast-max-width: 350px;
  --bs-toast-font-size: 0.875rem;
  --bs-toast-color: ;
  --bs-toast-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-border-width: var(--bs-border-width);
  --bs-toast-border-color: var(--bs-border-color-translucent);
  --bs-toast-border-radius: var(--bs-border-radius);
  --bs-toast-box-shadow: var(--bs-box-shadow);
  --bs-toast-header-color: var(--bs-secondary-color);
  --bs-toast-header-bg: rgba(var(--bs-body-bg-rgb), 0.85);
  --bs-toast-header-border-color: var(--bs-border-color-translucent);
  width: var(--bs-toast-max-width);
  max-width: 100%;
  font-size: var(--bs-toast-font-size);
  color: var(--bs-toast-color);
  pointer-events: auto;
  background-color: var(--bs-toast-bg);
  background-clip: padding-box;
  border: var(--bs-toast-border-width) solid var(--bs-toast-border-color);
  box-shadow: var(--bs-toast-box-shadow);
  border-radius: var(--bs-toast-border-radius);
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  --bs-toast-zindex: 1090;
  position: absolute;
  z-index: var(--bs-toast-zindex);
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: var(--bs-toast-spacing);
}

.toast-header {
  display: flex;
  align-items: center;
  padding: var(--bs-toast-padding-y) var(--bs-toast-padding-x);
  color: var(--bs-toast-header-color);
  background-color: var(--bs-toast-header-bg);
  background-clip: padding-box;
  border-bottom: var(--bs-toast-border-width) solid var(--bs-toast-header-border-color);
  border-top-left-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
  border-top-right-radius: calc(var(--bs-toast-border-radius) - var(--bs-toast-border-width));
}
.toast-header .btn-close {
  margin-right: calc(-0.5 * var(--bs-toast-padding-x));
  margin-left: var(--bs-toast-padding-x);
}

.toast-body {
  padding: var(--bs-toast-padding-x);
  word-wrap: break-word;
}

.modal {
  --bs-modal-zindex: 1055;
  --bs-modal-width: 500px;
  --bs-modal-padding: 1rem;
  --bs-modal-margin: 0.5rem;
  --bs-modal-color: ;
  --bs-modal-bg: transparent;
  --bs-modal-border-color: var(--bs-border-color-translucent);
  --bs-modal-border-width: 0;
  --bs-modal-border-radius: var(--bs-border-radius-lg);
  --bs-modal-box-shadow: 0;
  --bs-modal-inner-border-radius: calc(var(--bs-border-radius-lg) - 0);
  --bs-modal-header-padding-x: 1rem;
  --bs-modal-header-padding-y: 1rem;
  --bs-modal-header-padding: 1rem 1rem;
  --bs-modal-header-border-color: var(--bs-border-color);
  --bs-modal-header-border-width: 0;
  --bs-modal-title-line-height: 1.5;
  --bs-modal-footer-gap: 0.5rem;
  --bs-modal-footer-bg: ;
  --bs-modal-footer-border-color: var(--bs-border-color);
  --bs-modal-footer-border-width: 0;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-modal-zindex);
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: var(--bs-modal-margin);
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - var(--bs-modal-margin) * 2);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - var(--bs-modal-margin) * 2);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--bs-modal-bg);
  background-clip: padding-box;
  border: var(--bs-modal-border-width) solid var(--bs-modal-border-color);
  border-radius: var(--bs-modal-border-radius);
  box-shadow: var(--bs-modal-box-shadow);
  outline: 0;
}

.modal-backdrop {
  --bs-backdrop-zindex: 1050;
  --bs-backdrop-bg: #000;
  --bs-backdrop-opacity: 0.5;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--bs-backdrop-zindex);
  width: 100vw;
  height: 100vh;
  background-color: var(--bs-backdrop-bg);
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: var(--bs-backdrop-opacity);
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
}
.modal-header .btn-close {
  padding: calc(var(--bs-modal-header-padding-y) * 0.5) calc(var(--bs-modal-header-padding-x) * 0.5);
  margin: calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x)) calc(-0.5 * var(--bs-modal-header-padding-y)) auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: var(--bs-modal-title-line-height);
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: var(--bs-modal-padding);
}

.modal-footer {
  display: flex;
  flex-shrink: 0;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: calc(var(--bs-modal-padding) - var(--bs-modal-footer-gap) * 0.5);
  background-color: var(--bs-modal-footer-bg);
  border-top: var(--bs-modal-footer-border-width) solid var(--bs-modal-footer-border-color);
  border-bottom-right-radius: var(--bs-modal-inner-border-radius);
  border-bottom-left-radius: var(--bs-modal-inner-border-radius);
}
.modal-footer > * {
  margin: calc(var(--bs-modal-footer-gap) * 0.5);
}

@media (min-width: 576px) {
  .modal {
    --bs-modal-margin: 1.75rem;
    --bs-modal-box-shadow: 0;
  }
  .modal-dialog {
    max-width: var(--bs-modal-width);
    margin-right: auto;
    margin-left: auto;
  }
  .modal-sm {
    --bs-modal-width: 300px;
  }
}
@media (min-width: 1400px) {
  .modal-lg,
  .modal-xl {
    --bs-modal-width: 800px;
  }
}
.modal-xl {
  --bs-modal-width: 1140px;
}

.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header,
.modal-fullscreen .modal-footer {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header,
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header,
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header,
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
}
.tooltip {
  --bs-tooltip-zindex: 1080;
  --bs-tooltip-max-width: 250px;
  --bs-tooltip-padding-x: 0;
  --bs-tooltip-padding-y: 0;
  --bs-tooltip-margin: ;
  --bs-tooltip-font-size: 0.9625rem;
  --bs-tooltip-color: #1e4d60;
  --bs-tooltip-bg: white;
  --bs-tooltip-border-radius: 10px;
  --bs-tooltip-opacity: 1;
  --bs-tooltip-arrow-width: 0.8rem;
  --bs-tooltip-arrow-height: 0.4rem;
  z-index: var(--bs-tooltip-zindex);
  display: block;
  margin: var(--bs-tooltip-margin);
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-tooltip-font-size);
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: var(--bs-tooltip-opacity);
}
.tooltip .tooltip-arrow {
  display: block;
  width: var(--bs-tooltip-arrow-width);
  height: var(--bs-tooltip-arrow-height);
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-top-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height) calc(var(--bs-tooltip-arrow-width) * 0.5) 0;
  border-right-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: calc(-1 * var(--bs-tooltip-arrow-height));
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-bottom-color: var(--bs-tooltip-bg);
}

/* rtl:begin:ignore */
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: calc(-1 * var(--bs-tooltip-arrow-height));
  width: var(--bs-tooltip-arrow-height);
  height: var(--bs-tooltip-arrow-width);
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: calc(var(--bs-tooltip-arrow-width) * 0.5) 0 calc(var(--bs-tooltip-arrow-width) * 0.5) var(--bs-tooltip-arrow-height);
  border-left-color: var(--bs-tooltip-bg);
}

/* rtl:end:ignore */
.tooltip-inner {
  max-width: var(--bs-tooltip-max-width);
  padding: var(--bs-tooltip-padding-y) var(--bs-tooltip-padding-x);
  color: var(--bs-tooltip-color);
  text-align: center;
  background-color: var(--bs-tooltip-bg);
  border-radius: var(--bs-tooltip-border-radius);
}

.popover {
  --bs-popover-zindex: 1070;
  --bs-popover-max-width: 276px;
  --bs-popover-font-size: 0.9625rem;
  --bs-popover-bg: var(--bs-body-bg);
  --bs-popover-border-width: var(--bs-border-width);
  --bs-popover-border-color: var(--bs-border-color-translucent);
  --bs-popover-border-radius: var(--bs-border-radius-lg);
  --bs-popover-inner-border-radius: calc(var(--bs-border-radius-lg) - var(--bs-border-width));
  --bs-popover-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  --bs-popover-header-padding-x: 1rem;
  --bs-popover-header-padding-y: 0.5rem;
  --bs-popover-header-font-size: 1.1rem;
  --bs-popover-header-color: #1e4d60;
  --bs-popover-header-bg: var(--bs-secondary-bg);
  --bs-popover-body-padding-x: 1rem;
  --bs-popover-body-padding-y: 1rem;
  --bs-popover-body-color: var(--bs-body-color);
  --bs-popover-arrow-width: 1rem;
  --bs-popover-arrow-height: 0.5rem;
  --bs-popover-arrow-border: var(--bs-popover-border-color);
  z-index: var(--bs-popover-zindex);
  display: block;
  max-width: var(--bs-popover-max-width);
  font-family: Lato, sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  white-space: normal;
  word-spacing: normal;
  line-break: auto;
  font-size: var(--bs-popover-font-size);
  word-wrap: break-word;
  background-color: var(--bs-popover-bg);
  background-clip: padding-box;
  border: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-radius: var(--bs-popover-border-radius);
  box-shadow: var(--bs-popover-box-shadow);
}
.popover .popover-arrow {
  display: block;
  width: var(--bs-popover-arrow-width);
  height: var(--bs-popover-arrow-height);
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
  border-width: 0;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before, .bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  border-width: var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-top-color: var(--bs-popover-arrow-border);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: var(--bs-popover-border-width);
  border-top-color: var(--bs-popover-bg);
}

/* rtl:begin:ignore */
.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before, .bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height) calc(var(--bs-popover-arrow-width) * 0.5) 0;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-right-color: var(--bs-popover-arrow-border);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: var(--bs-popover-border-width);
  border-right-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before, .bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  border-width: 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-bottom-color: var(--bs-popover-arrow-border);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: var(--bs-popover-border-width);
  border-bottom-color: var(--bs-popover-bg);
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: var(--bs-popover-arrow-width);
  margin-left: calc(-0.5 * var(--bs-popover-arrow-width));
  content: "";
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-header-bg);
}

/* rtl:begin:ignore */
.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-1 * (var(--bs-popover-arrow-height)) - var(--bs-popover-border-width));
  width: var(--bs-popover-arrow-height);
  height: var(--bs-popover-arrow-width);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before, .bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  border-width: calc(var(--bs-popover-arrow-width) * 0.5) 0 calc(var(--bs-popover-arrow-width) * 0.5) var(--bs-popover-arrow-height);
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-left-color: var(--bs-popover-arrow-border);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: var(--bs-popover-border-width);
  border-left-color: var(--bs-popover-bg);
}

/* rtl:end:ignore */
.popover-header {
  padding: var(--bs-popover-header-padding-y) var(--bs-popover-header-padding-x);
  margin-bottom: 0;
  font-size: var(--bs-popover-header-font-size);
  color: var(--bs-popover-header-color);
  background-color: var(--bs-popover-header-bg);
  border-bottom: var(--bs-popover-border-width) solid var(--bs-popover-border-color);
  border-top-left-radius: var(--bs-popover-inner-border-radius);
  border-top-right-radius: var(--bs-popover-inner-border-radius);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: var(--bs-popover-body-padding-y) var(--bs-popover-body-padding-x);
  color: var(--bs-popover-body-color);
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
  .carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 4%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
  .carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 4%;
  margin-bottom: 1rem;
  margin-left: 4%;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

[data-bs-theme=dark] .carousel .carousel-control-prev-icon,
[data-bs-theme=dark] .carousel .carousel-control-next-icon, [data-bs-theme=dark].carousel .carousel-control-prev-icon,
[data-bs-theme=dark].carousel .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
[data-bs-theme=dark] .carousel .carousel-indicators [data-bs-target], [data-bs-theme=dark].carousel .carousel-indicators [data-bs-target] {
  background-color: #000;
}
[data-bs-theme=dark] .carousel .carousel-caption, [data-bs-theme=dark].carousel .carousel-caption {
  color: #000;
}

.spinner-grow,
.spinner-border {
  display: inline-block;
  width: var(--bs-spinner-width);
  height: var(--bs-spinner-height);
  vertical-align: var(--bs-spinner-vertical-align);
  border-radius: 50%;
  animation: var(--bs-spinner-animation-speed) linear infinite var(--bs-spinner-animation-name);
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-border-width: 0.25em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-border;
  border: var(--bs-spinner-border-width) solid currentcolor;
  border-right-color: transparent;
}

.spinner-border-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
  --bs-spinner-border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  --bs-spinner-width: 2rem;
  --bs-spinner-height: 2rem;
  --bs-spinner-vertical-align: -0.125em;
  --bs-spinner-animation-speed: 0.75s;
  --bs-spinner-animation-name: spinner-grow;
  background-color: currentcolor;
  opacity: 0;
}

.spinner-grow-sm {
  --bs-spinner-width: 1rem;
  --bs-spinner-height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
  .spinner-grow {
    --bs-spinner-animation-speed: 1.5s;
  }
}
.offcanvas, .offcanvas-lg, .offcanvas-md, .offcanvas-sm {
  --bs-offcanvas-zindex: 1045;
  --bs-offcanvas-width: 165px;
  --bs-offcanvas-height: 30vh;
  --bs-offcanvas-padding-x: 1rem;
  --bs-offcanvas-padding-y: 1rem;
  --bs-offcanvas-color: var(--bs-body-color);
  --bs-offcanvas-bg: var(--bs-body-bg);
  --bs-offcanvas-border-width: 0;
  --bs-offcanvas-border-color: var(--bs-border-color-translucent);
  --bs-offcanvas-box-shadow: 0;
  --bs-offcanvas-transition: transform 0.3s ease-in-out;
  --bs-offcanvas-title-line-height: 1.5;
}

@media (max-width: 575.98px) {
  .offcanvas-sm {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 575.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-sm {
    transition: none;
  }
}
@media (max-width: 575.98px) {
  .offcanvas-sm.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-sm.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-sm.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-sm.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-sm.showing, .offcanvas-sm.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-sm.showing, .offcanvas-sm.hiding, .offcanvas-sm.show {
    visibility: visible;
  }
}
@media (min-width: 576px) {
  .offcanvas-sm {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-sm .offcanvas-header {
    display: none;
  }
  .offcanvas-sm .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 767.98px) {
  .offcanvas-md {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 767.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-md {
    transition: none;
  }
}
@media (max-width: 767.98px) {
  .offcanvas-md.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-md.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-md.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-md.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-md.showing, .offcanvas-md.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-md.showing, .offcanvas-md.hiding, .offcanvas-md.show {
    visibility: visible;
  }
}
@media (min-width: 768px) {
  .offcanvas-md {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-md .offcanvas-header {
    display: none;
  }
  .offcanvas-md .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

@media (max-width: 1399.98px) {
  .offcanvas-lg {
    position: fixed;
    bottom: 0;
    z-index: var(--bs-offcanvas-zindex);
    display: flex;
    flex-direction: column;
    max-width: 100%;
    color: var(--bs-offcanvas-color);
    visibility: hidden;
    background-color: var(--bs-offcanvas-bg);
    background-clip: padding-box;
    outline: 0;
    box-shadow: var(--bs-offcanvas-box-shadow);
    transition: var(--bs-offcanvas-transition);
  }
}
@media (max-width: 1399.98px) and (prefers-reduced-motion: reduce) {
  .offcanvas-lg {
    transition: none;
  }
}
@media (max-width: 1399.98px) {
  .offcanvas-lg.offcanvas-start {
    top: 0;
    left: 0;
    width: var(--bs-offcanvas-width);
    border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(-100%);
  }
  .offcanvas-lg.offcanvas-end {
    top: 0;
    right: 0;
    width: var(--bs-offcanvas-width);
    border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateX(100%);
  }
  .offcanvas-lg.offcanvas-top {
    top: 0;
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(-100%);
  }
  .offcanvas-lg.offcanvas-bottom {
    right: 0;
    left: 0;
    height: var(--bs-offcanvas-height);
    max-height: 100%;
    border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
    transform: translateY(100%);
  }
  .offcanvas-lg.showing, .offcanvas-lg.show:not(.hiding) {
    transform: none;
  }
  .offcanvas-lg.showing, .offcanvas-lg.hiding, .offcanvas-lg.show {
    visibility: visible;
  }
}
@media (min-width: 1400px) {
  .offcanvas-lg {
    --bs-offcanvas-height: auto;
    --bs-offcanvas-border-width: 0;
    background-color: transparent !important;
  }
  .offcanvas-lg .offcanvas-header {
    display: none;
  }
  .offcanvas-lg .offcanvas-body {
    display: flex;
    flex-grow: 0;
    padding: 0;
    overflow-y: visible;
    background-color: transparent !important;
  }
}

.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: var(--bs-offcanvas-zindex);
  display: flex;
  flex-direction: column;
  max-width: 100%;
  color: var(--bs-offcanvas-color);
  visibility: hidden;
  background-color: var(--bs-offcanvas-bg);
  background-clip: padding-box;
  outline: 0;
  box-shadow: var(--bs-offcanvas-box-shadow);
  transition: var(--bs-offcanvas-transition);
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}
.offcanvas.offcanvas-start {
  top: 0;
  left: 0;
  width: var(--bs-offcanvas-width);
  border-right: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(-100%);
}
.offcanvas.offcanvas-end {
  top: 0;
  right: 0;
  width: var(--bs-offcanvas-width);
  border-left: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateX(100%);
}
.offcanvas.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-bottom: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(-100%);
}
.offcanvas.offcanvas-bottom {
  right: 0;
  left: 0;
  height: var(--bs-offcanvas-height);
  max-height: 100%;
  border-top: var(--bs-offcanvas-border-width) solid var(--bs-offcanvas-border-color);
  transform: translateY(100%);
}
.offcanvas.showing, .offcanvas.show:not(.hiding) {
  transform: none;
}
.offcanvas.showing, .offcanvas.hiding, .offcanvas.show {
  visibility: visible;
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
}
.offcanvas-header .btn-close {
  padding: calc(var(--bs-offcanvas-padding-y) * 0.5) calc(var(--bs-offcanvas-padding-x) * 0.5);
  margin-top: calc(-0.5 * var(--bs-offcanvas-padding-y));
  margin-right: calc(-0.5 * var(--bs-offcanvas-padding-x));
  margin-bottom: calc(-0.5 * var(--bs-offcanvas-padding-y));
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: var(--bs-offcanvas-title-line-height);
}

.offcanvas-body {
  flex-grow: 1;
  padding: var(--bs-offcanvas-padding-y) var(--bs-offcanvas-padding-x);
  overflow-y: auto;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentcolor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  animation: placeholder-glow 2s ease-in-out infinite;
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  mask-size: 200% 100%;
  animation: placeholder-wave 2s linear infinite;
}

@keyframes placeholder-wave {
  100% {
    mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.text-bg-primary {
  color: #fff !important;
  background-color: RGBA(var(--bs-primary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-tertiary {
  color: #000 !important;
  background-color: RGBA(var(--bs-tertiary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-success {
  color: #000 !important;
  background-color: RGBA(var(--bs-success-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-white {
  color: #000 !important;
  background-color: RGBA(var(--bs-white-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-danger {
  color: #fff !important;
  background-color: RGBA(var(--bs-danger-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-red {
  color: #fff !important;
  background-color: RGBA(var(--bs-red-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-pink {
  color: #000 !important;
  background-color: RGBA(var(--bs-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-orange {
  color: #000 !important;
  background-color: RGBA(var(--bs-orange-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-yellow {
  color: #000 !important;
  background-color: RGBA(var(--bs-yellow-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-green {
  color: #000 !important;
  background-color: RGBA(var(--bs-green-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue {
  color: #fff !important;
  background-color: RGBA(var(--bs-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-cyan {
  color: #000 !important;
  background-color: RGBA(var(--bs-cyan-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-grey {
  color: #000 !important;
  background-color: RGBA(var(--bs-grey-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-alt {
  color: #fff !important;
  background-color: RGBA(var(--bs-blue-alt-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-blue-90 {
  color: #fff !important;
  background-color: RGBA(var(--bs-blue-90-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-blue {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark-blue {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-blue-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-light-secondary {
  color: #000 !important;
  background-color: RGBA(var(--bs-light-secondary-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-10 {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-10-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-secondary-20 {
  color: #000 !important;
  background-color: RGBA(var(--bs-secondary-20-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark-grey {
  color: #000 !important;
  background-color: RGBA(var(--bs-dark-grey-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-dark-pink {
  color: #fff !important;
  background-color: RGBA(var(--bs-dark-pink-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-inactive {
  color: #000 !important;
  background-color: RGBA(var(--bs-inactive-rgb), var(--bs-bg-opacity, 1)) !important;
}

.text-bg-accent {
  color: #000 !important;
  background-color: RGBA(var(--bs-accent-rgb), var(--bs-bg-opacity, 1)) !important;
}

.link-primary {
  color: RGBA(var(--bs-primary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-primary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-primary:hover, .link-primary:focus {
  color: RGBA(24, 62, 77, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 62, 77, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary {
  color: RGBA(var(--bs-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary:hover, .link-secondary:focus {
  color: RGBA(136, 161, 176, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(136, 161, 176, var(--bs-link-underline-opacity, 1)) !important;
}

.link-tertiary {
  color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-tertiary:hover, .link-tertiary:focus {
  color: RGBA(220, 227, 231, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(220, 227, 231, var(--bs-link-underline-opacity, 1)) !important;
}

.link-success {
  color: RGBA(var(--bs-success-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-success-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-success:hover, .link-success:focus {
  color: RGBA(196, 203, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(196, 203, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark {
  color: RGBA(var(--bs-dark-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark:hover, .link-dark:focus {
  color: RGBA(46, 46, 46, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(46, 46, 46, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light {
  color: RGBA(var(--bs-light-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light:hover, .link-light:focus {
  color: RGBA(248, 248, 248, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(248, 248, 248, var(--bs-link-underline-opacity, 1)) !important;
}

.link-white {
  color: RGBA(var(--bs-white-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-white-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-white:hover, .link-white:focus {
  color: RGBA(255, 255, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(255, 255, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-danger {
  color: RGBA(var(--bs-danger-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-danger-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-danger:hover, .link-danger:focus {
  color: RGBA(6, 6, 6, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(6, 6, 6, var(--bs-link-underline-opacity, 1)) !important;
}

.link-red {
  color: RGBA(var(--bs-red-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-red-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-red:hover, .link-red:focus {
  color: RGBA(174, 0, 0, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(174, 0, 0, var(--bs-link-underline-opacity, 1)) !important;
}

.link-pink {
  color: RGBA(var(--bs-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-pink:hover, .link-pink:focus {
  color: RGBA(240, 51, 163, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(240, 51, 163, var(--bs-link-underline-opacity, 1)) !important;
}

.link-orange {
  color: RGBA(var(--bs-orange-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-orange-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-orange:hover, .link-orange:focus {
  color: RGBA(245, 212, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 212, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-yellow {
  color: RGBA(var(--bs-yellow-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-yellow-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-yellow:hover, .link-yellow:focus {
  color: RGBA(245, 212, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(245, 212, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-green {
  color: RGBA(var(--bs-green-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-green-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-green:hover, .link-green:focus {
  color: RGBA(51, 209, 57, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(51, 209, 57, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue {
  color: RGBA(var(--bs-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue:hover, .link-blue:focus {
  color: RGBA(0, 92, 144, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(0, 92, 144, var(--bs-link-underline-opacity, 1)) !important;
}

.link-cyan {
  color: RGBA(var(--bs-cyan-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-cyan-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-cyan:hover, .link-cyan:focus {
  color: RGBA(53, 206, 227, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(53, 206, 227, var(--bs-link-underline-opacity, 1)) !important;
}

.link-grey {
  color: RGBA(var(--bs-grey-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-grey-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-grey:hover, .link-grey:focus {
  color: RGBA(203, 196, 196, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(203, 196, 196, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue-alt {
  color: RGBA(var(--bs-blue-alt-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-alt-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue-alt:hover, .link-blue-alt:focus {
  color: RGBA(42, 66, 155, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(42, 66, 155, var(--bs-link-underline-opacity, 1)) !important;
}

.link-blue-90 {
  color: RGBA(var(--bs-blue-90-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-blue-90-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-blue-90:hover, .link-blue-90:focus {
  color: RGBA(24, 61, 76, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(24, 61, 76, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light-blue {
  color: RGBA(var(--bs-light-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light-blue:hover, .link-light-blue:focus {
  color: RGBA(235, 240, 243, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(235, 240, 243, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark-blue {
  color: RGBA(var(--bs-dark-blue-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-blue-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark-blue:hover, .link-dark-blue:focus {
  color: RGBA(19, 50, 62, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(19, 50, 62, var(--bs-link-underline-opacity, 1)) !important;
}

.link-light-secondary {
  color: RGBA(var(--bs-light-secondary-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-light-secondary-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-light-secondary:hover, .link-light-secondary:focus {
  color: RGBA(234, 234, 234, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(234, 234, 234, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-10 {
  color: RGBA(var(--bs-secondary-10-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-10-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-10:hover, .link-secondary-10:focus {
  color: RGBA(221, 226, 229, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(221, 226, 229, var(--bs-link-underline-opacity, 1)) !important;
}

.link-secondary-20 {
  color: RGBA(var(--bs-secondary-20-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-secondary-20-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-secondary-20:hover, .link-secondary-20:focus {
  color: RGBA(252, 253, 255, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(252, 253, 255, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark-grey {
  color: RGBA(var(--bs-dark-grey-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-grey-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark-grey:hover, .link-dark-grey:focus {
  color: RGBA(161, 161, 161, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(161, 161, 161, var(--bs-link-underline-opacity, 1)) !important;
}

.link-dark-pink {
  color: RGBA(var(--bs-dark-pink-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-dark-pink-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-dark-pink:hover, .link-dark-pink:focus {
  color: RGBA(151, 0, 90, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(151, 0, 90, var(--bs-link-underline-opacity, 1)) !important;
}

.link-inactive {
  color: RGBA(var(--bs-inactive-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-inactive-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-inactive:hover, .link-inactive:focus {
  color: RGBA(225, 225, 225, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(225, 225, 225, var(--bs-link-underline-opacity, 1)) !important;
}

.link-accent {
  color: RGBA(var(--bs-accent-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-accent-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-accent:hover, .link-accent:focus {
  color: RGBA(196, 203, 51, var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(196, 203, 51, var(--bs-link-underline-opacity, 1)) !important;
}

.link-body-emphasis {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 1)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}
.link-body-emphasis:hover, .link-body-emphasis:focus {
  color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-opacity, 0.75)) !important;
  text-decoration-color: RGBA(var(--bs-emphasis-color-rgb), var(--bs-link-underline-opacity, 0.75)) !important;
}

.focus-ring:focus {
  outline: 0;
  box-shadow: var(--bs-focus-ring-x, 0) var(--bs-focus-ring-y, 0) var(--bs-focus-ring-blur, 0) var(--bs-focus-ring-width) var(--bs-focus-ring-color);
}

.icon-link {
  display: inline-flex;
  gap: 0.375rem;
  align-items: center;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-opacity, 0.5));
  text-underline-offset: 0.25em;
  backface-visibility: hidden;
}
.icon-link > .bi {
  flex-shrink: 0;
  width: 1em;
  height: 1em;
  fill: currentcolor;
  transition: 0.2s ease-in-out transform;
}
@media (prefers-reduced-motion: reduce) {
  .icon-link > .bi {
    transition: none;
  }
}

.icon-link-hover:hover > .bi, .icon-link-hover:focus-visible > .bi {
  transform: var(--bs-icon-link-transform, translate3d(0.25em, 0, 0));
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-sm-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-md-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
  .sticky-lg-bottom {
    position: sticky;
    bottom: 0;
    z-index: 1020;
  }
}
.hstack {
  display: flex;
  flex-direction: row;
  align-items: center;
  align-self: stretch;
}

.vstack {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}
.visually-hidden:not(caption),
.visually-hidden-focusable:not(:focus):not(:focus-within):not(caption) {
  position: absolute !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  align-self: stretch;
  width: var(--bs-border-width);
  min-height: 1em;
  background-color: currentcolor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.object-fit-contain {
  object-fit: contain !important;
}

.object-fit-cover {
  object-fit: cover !important;
}

.object-fit-fill {
  object-fit: fill !important;
}

.object-fit-scale {
  object-fit: scale-down !important;
}

.object-fit-none {
  object-fit: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-10 {
  opacity: 0.1 !important;
}

.opacity-20 {
  opacity: 0.2 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-35 {
  opacity: 0.35 !important;
}

.opacity-40 {
  opacity: 0.4 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-80 {
  opacity: 0.8 !important;
}

.opacity-90 {
  opacity: 0.9 !important;
}

.opacity-95 {
  opacity: 0.95 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.overflow-x-auto {
  overflow-x: auto !important;
}

.overflow-x-hidden {
  overflow-x: hidden !important;
}

.overflow-x-visible {
  overflow-x: visible !important;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.overflow-y-auto {
  overflow-y: auto !important;
}

.overflow-y-hidden {
  overflow-y: hidden !important;
}

.overflow-y-visible {
  overflow-y: visible !important;
}

.overflow-y-scroll {
  overflow-y: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-inline-grid {
  display: inline-grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.focus-ring-primary {
  --bs-focus-ring-color: rgba(var(--bs-primary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary {
  --bs-focus-ring-color: rgba(var(--bs-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-tertiary {
  --bs-focus-ring-color: rgba(var(--bs-tertiary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-success {
  --bs-focus-ring-color: rgba(var(--bs-success-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark {
  --bs-focus-ring-color: rgba(var(--bs-dark-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light {
  --bs-focus-ring-color: rgba(var(--bs-light-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-white {
  --bs-focus-ring-color: rgba(var(--bs-white-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-danger {
  --bs-focus-ring-color: rgba(var(--bs-danger-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-red {
  --bs-focus-ring-color: rgba(var(--bs-red-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-pink {
  --bs-focus-ring-color: rgba(var(--bs-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-orange {
  --bs-focus-ring-color: rgba(var(--bs-orange-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-yellow {
  --bs-focus-ring-color: rgba(var(--bs-yellow-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-green {
  --bs-focus-ring-color: rgba(var(--bs-green-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue {
  --bs-focus-ring-color: rgba(var(--bs-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-cyan {
  --bs-focus-ring-color: rgba(var(--bs-cyan-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-grey {
  --bs-focus-ring-color: rgba(var(--bs-grey-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue-alt {
  --bs-focus-ring-color: rgba(var(--bs-blue-alt-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-blue-90 {
  --bs-focus-ring-color: rgba(var(--bs-blue-90-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light-blue {
  --bs-focus-ring-color: rgba(var(--bs-light-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark-blue {
  --bs-focus-ring-color: rgba(var(--bs-dark-blue-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-light-secondary {
  --bs-focus-ring-color: rgba(var(--bs-light-secondary-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-10 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-10-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-secondary-20 {
  --bs-focus-ring-color: rgba(var(--bs-secondary-20-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark-grey {
  --bs-focus-ring-color: rgba(var(--bs-dark-grey-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-dark-pink {
  --bs-focus-ring-color: rgba(var(--bs-dark-pink-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-inactive {
  --bs-focus-ring-color: rgba(var(--bs-inactive-rgb), var(--bs-focus-ring-opacity));
}

.focus-ring-accent {
  --bs-focus-ring-color: rgba(var(--bs-accent-rgb), var(--bs-focus-ring-opacity));
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-primary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-tertiary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-tertiary-rgb), var(--bs-border-opacity)) !important;
}

.border-success {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-success-rgb), var(--bs-border-opacity)) !important;
}

.border-dark {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-rgb), var(--bs-border-opacity)) !important;
}

.border-light {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-rgb), var(--bs-border-opacity)) !important;
}

.border-white {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-white-rgb), var(--bs-border-opacity)) !important;
}

.border-danger {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-danger-rgb), var(--bs-border-opacity)) !important;
}

.border-red {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-red-rgb), var(--bs-border-opacity)) !important;
}

.border-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-orange {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-orange-rgb), var(--bs-border-opacity)) !important;
}

.border-yellow {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-yellow-rgb), var(--bs-border-opacity)) !important;
}

.border-green {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-green-rgb), var(--bs-border-opacity)) !important;
}

.border-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-cyan {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-cyan-rgb), var(--bs-border-opacity)) !important;
}

.border-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-alt {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-alt-rgb), var(--bs-border-opacity)) !important;
}

.border-blue-90 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-blue-90-rgb), var(--bs-border-opacity)) !important;
}

.border-light-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-blue {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-blue-rgb), var(--bs-border-opacity)) !important;
}

.border-light-secondary {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-light-secondary-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-10 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-10-rgb), var(--bs-border-opacity)) !important;
}

.border-secondary-20 {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-secondary-20-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-grey {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-grey-rgb), var(--bs-border-opacity)) !important;
}

.border-dark-pink {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-dark-pink-rgb), var(--bs-border-opacity)) !important;
}

.border-inactive {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-inactive-rgb), var(--bs-border-opacity)) !important;
}

.border-accent {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-accent-rgb), var(--bs-border-opacity)) !important;
}

.border-black {
  --bs-border-opacity: 1;
  border-color: rgba(var(--bs-black-rgb), var(--bs-border-opacity)) !important;
}

.border-primary-subtle {
  border-color: var(--bs-primary-border-subtle) !important;
}

.border-secondary-subtle {
  border-color: var(--bs-secondary-border-subtle) !important;
}

.border-success-subtle {
  border-color: var(--bs-success-border-subtle) !important;
}

.border-info-subtle {
  border-color: var(--bs-info-border-subtle) !important;
}

.border-warning-subtle {
  border-color: var(--bs-warning-border-subtle) !important;
}

.border-danger-subtle {
  border-color: var(--bs-danger-border-subtle) !important;
}

.border-light-subtle {
  border-color: var(--bs-light-border-subtle) !important;
}

.border-dark-subtle {
  border-color: var(--bs-dark-border-subtle) !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.border-opacity-10 {
  --bs-border-opacity: 0.1;
}

.border-opacity-25 {
  --bs-border-opacity: 0.25;
}

.border-opacity-50 {
  --bs-border-opacity: 0.5;
}

.border-opacity-75 {
  --bs-border-opacity: 0.75;
}

.border-opacity-100 {
  --bs-border-opacity: 1;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-5 {
  margin: 0.313rem !important;
}

.m-10 {
  margin: 0.625rem !important;
}

.m-15 {
  margin: 0.938rem !important;
}

.m-20 {
  margin: 1.25rem !important;
}

.m-25 {
  margin: 1.563rem !important;
}

.m-30 {
  margin: 1.875rem !important;
}

.m-35 {
  margin: 2.188rem !important;
}

.m-40 {
  margin: 2.5rem !important;
}

.m-45 {
  margin: 2.813rem !important;
}

.m-50 {
  margin: 3.125rem !important;
}

.m-55 {
  margin: 3.438rem !important;
}

.m-60 {
  margin: 3.75rem !important;
}

.m-65 {
  margin: 4.063rem !important;
}

.m-70 {
  margin: 4.375rem !important;
}

.m-75 {
  margin: 4.688rem !important;
}

.m-80 {
  margin: 5rem !important;
}

.m-85 {
  margin: 5.313rem !important;
}

.m-90 {
  margin: 5.625rem !important;
}

.m-95 {
  margin: 5.938rem !important;
}

.m-100 {
  margin: 6.25rem !important;
}

.m-105 {
  margin: 6.5625rem !important;
}

.m-110 {
  margin: 6.875rem !important;
}

.m-115 {
  margin: 7.1875rem !important;
}

.m-120 {
  margin: 7.5rem !important;
}

.m-125 {
  margin: 7.8125rem !important;
}

.m-130 {
  margin: 8.125rem !important;
}

.m-135 {
  margin: 8.4375rem !important;
}

.m-140 {
  margin: 8.75rem !important;
}

.m-145 {
  margin: 9.0625rem !important;
}

.m-150 {
  margin: 9.375rem !important;
}

.m-155 {
  margin: 9.6875rem !important;
}

.m-160 {
  margin: 10rem !important;
}

.m-165 {
  margin: 10.3125rem !important;
}

.m-170 {
  margin: 10.625rem !important;
}

.m-175 {
  margin: 10.9375rem !important;
}

.m-180 {
  margin: 11.25rem !important;
}

.m-185 {
  margin: 11.5625rem !important;
}

.m-190 {
  margin: 11.875rem !important;
}

.m-195 {
  margin: 12.1875rem !important;
}

.m-200 {
  margin: 12.5rem !important;
}

.m-205 {
  margin: 12.8125rem !important;
}

.m-210 {
  margin: 13.125rem !important;
}

.m-215 {
  margin: 13.4375rem !important;
}

.m-220 {
  margin: 13.75rem !important;
}

.m-225 {
  margin: 14.0625rem !important;
}

.m-230 {
  margin: 14.375rem !important;
}

.m-235 {
  margin: 14.6875rem !important;
}

.m-240 {
  margin: 15rem !important;
}

.m-245 {
  margin: 15.3125rem !important;
}

.m-250 {
  margin: 15.625rem !important;
}

.m-255 {
  margin: 15.9375rem !important;
}

.m-260 {
  margin: 16.25rem !important;
}

.m-265 {
  margin: 16.5625rem !important;
}

.m-270 {
  margin: 16.875rem !important;
}

.m-275 {
  margin: 17.1875rem !important;
}

.m-280 {
  margin: 17.5rem !important;
}

.m-285 {
  margin: 17.8125rem !important;
}

.m-290 {
  margin: 18.125rem !important;
}

.m-295 {
  margin: 18.4375rem !important;
}

.m-300 {
  margin: 18.75rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-5 {
  margin-right: 0.313rem !important;
  margin-left: 0.313rem !important;
}

.mx-10 {
  margin-right: 0.625rem !important;
  margin-left: 0.625rem !important;
}

.mx-15 {
  margin-right: 0.938rem !important;
  margin-left: 0.938rem !important;
}

.mx-20 {
  margin-right: 1.25rem !important;
  margin-left: 1.25rem !important;
}

.mx-25 {
  margin-right: 1.563rem !important;
  margin-left: 1.563rem !important;
}

.mx-30 {
  margin-right: 1.875rem !important;
  margin-left: 1.875rem !important;
}

.mx-35 {
  margin-right: 2.188rem !important;
  margin-left: 2.188rem !important;
}

.mx-40 {
  margin-right: 2.5rem !important;
  margin-left: 2.5rem !important;
}

.mx-45 {
  margin-right: 2.813rem !important;
  margin-left: 2.813rem !important;
}

.mx-50 {
  margin-right: 3.125rem !important;
  margin-left: 3.125rem !important;
}

.mx-55 {
  margin-right: 3.438rem !important;
  margin-left: 3.438rem !important;
}

.mx-60 {
  margin-right: 3.75rem !important;
  margin-left: 3.75rem !important;
}

.mx-65 {
  margin-right: 4.063rem !important;
  margin-left: 4.063rem !important;
}

.mx-70 {
  margin-right: 4.375rem !important;
  margin-left: 4.375rem !important;
}

.mx-75 {
  margin-right: 4.688rem !important;
  margin-left: 4.688rem !important;
}

.mx-80 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-85 {
  margin-right: 5.313rem !important;
  margin-left: 5.313rem !important;
}

.mx-90 {
  margin-right: 5.625rem !important;
  margin-left: 5.625rem !important;
}

.mx-95 {
  margin-right: 5.938rem !important;
  margin-left: 5.938rem !important;
}

.mx-100 {
  margin-right: 6.25rem !important;
  margin-left: 6.25rem !important;
}

.mx-105 {
  margin-right: 6.5625rem !important;
  margin-left: 6.5625rem !important;
}

.mx-110 {
  margin-right: 6.875rem !important;
  margin-left: 6.875rem !important;
}

.mx-115 {
  margin-right: 7.1875rem !important;
  margin-left: 7.1875rem !important;
}

.mx-120 {
  margin-right: 7.5rem !important;
  margin-left: 7.5rem !important;
}

.mx-125 {
  margin-right: 7.8125rem !important;
  margin-left: 7.8125rem !important;
}

.mx-130 {
  margin-right: 8.125rem !important;
  margin-left: 8.125rem !important;
}

.mx-135 {
  margin-right: 8.4375rem !important;
  margin-left: 8.4375rem !important;
}

.mx-140 {
  margin-right: 8.75rem !important;
  margin-left: 8.75rem !important;
}

.mx-145 {
  margin-right: 9.0625rem !important;
  margin-left: 9.0625rem !important;
}

.mx-150 {
  margin-right: 9.375rem !important;
  margin-left: 9.375rem !important;
}

.mx-155 {
  margin-right: 9.6875rem !important;
  margin-left: 9.6875rem !important;
}

.mx-160 {
  margin-right: 10rem !important;
  margin-left: 10rem !important;
}

.mx-165 {
  margin-right: 10.3125rem !important;
  margin-left: 10.3125rem !important;
}

.mx-170 {
  margin-right: 10.625rem !important;
  margin-left: 10.625rem !important;
}

.mx-175 {
  margin-right: 10.9375rem !important;
  margin-left: 10.9375rem !important;
}

.mx-180 {
  margin-right: 11.25rem !important;
  margin-left: 11.25rem !important;
}

.mx-185 {
  margin-right: 11.5625rem !important;
  margin-left: 11.5625rem !important;
}

.mx-190 {
  margin-right: 11.875rem !important;
  margin-left: 11.875rem !important;
}

.mx-195 {
  margin-right: 12.1875rem !important;
  margin-left: 12.1875rem !important;
}

.mx-200 {
  margin-right: 12.5rem !important;
  margin-left: 12.5rem !important;
}

.mx-205 {
  margin-right: 12.8125rem !important;
  margin-left: 12.8125rem !important;
}

.mx-210 {
  margin-right: 13.125rem !important;
  margin-left: 13.125rem !important;
}

.mx-215 {
  margin-right: 13.4375rem !important;
  margin-left: 13.4375rem !important;
}

.mx-220 {
  margin-right: 13.75rem !important;
  margin-left: 13.75rem !important;
}

.mx-225 {
  margin-right: 14.0625rem !important;
  margin-left: 14.0625rem !important;
}

.mx-230 {
  margin-right: 14.375rem !important;
  margin-left: 14.375rem !important;
}

.mx-235 {
  margin-right: 14.6875rem !important;
  margin-left: 14.6875rem !important;
}

.mx-240 {
  margin-right: 15rem !important;
  margin-left: 15rem !important;
}

.mx-245 {
  margin-right: 15.3125rem !important;
  margin-left: 15.3125rem !important;
}

.mx-250 {
  margin-right: 15.625rem !important;
  margin-left: 15.625rem !important;
}

.mx-255 {
  margin-right: 15.9375rem !important;
  margin-left: 15.9375rem !important;
}

.mx-260 {
  margin-right: 16.25rem !important;
  margin-left: 16.25rem !important;
}

.mx-265 {
  margin-right: 16.5625rem !important;
  margin-left: 16.5625rem !important;
}

.mx-270 {
  margin-right: 16.875rem !important;
  margin-left: 16.875rem !important;
}

.mx-275 {
  margin-right: 17.1875rem !important;
  margin-left: 17.1875rem !important;
}

.mx-280 {
  margin-right: 17.5rem !important;
  margin-left: 17.5rem !important;
}

.mx-285 {
  margin-right: 17.8125rem !important;
  margin-left: 17.8125rem !important;
}

.mx-290 {
  margin-right: 18.125rem !important;
  margin-left: 18.125rem !important;
}

.mx-295 {
  margin-right: 18.4375rem !important;
  margin-left: 18.4375rem !important;
}

.mx-300 {
  margin-right: 18.75rem !important;
  margin-left: 18.75rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-5 {
  margin-top: 0.313rem !important;
  margin-bottom: 0.313rem !important;
}

.my-10 {
  margin-top: 0.625rem !important;
  margin-bottom: 0.625rem !important;
}

.my-15 {
  margin-top: 0.938rem !important;
  margin-bottom: 0.938rem !important;
}

.my-20 {
  margin-top: 1.25rem !important;
  margin-bottom: 1.25rem !important;
}

.my-25 {
  margin-top: 1.563rem !important;
  margin-bottom: 1.563rem !important;
}

.my-30 {
  margin-top: 1.875rem !important;
  margin-bottom: 1.875rem !important;
}

.my-35 {
  margin-top: 2.188rem !important;
  margin-bottom: 2.188rem !important;
}

.my-40 {
  margin-top: 2.5rem !important;
  margin-bottom: 2.5rem !important;
}

.my-45 {
  margin-top: 2.813rem !important;
  margin-bottom: 2.813rem !important;
}

.my-50 {
  margin-top: 3.125rem !important;
  margin-bottom: 3.125rem !important;
}

.my-55 {
  margin-top: 3.438rem !important;
  margin-bottom: 3.438rem !important;
}

.my-60 {
  margin-top: 3.75rem !important;
  margin-bottom: 3.75rem !important;
}

.my-65 {
  margin-top: 4.063rem !important;
  margin-bottom: 4.063rem !important;
}

.my-70 {
  margin-top: 4.375rem !important;
  margin-bottom: 4.375rem !important;
}

.my-75 {
  margin-top: 4.688rem !important;
  margin-bottom: 4.688rem !important;
}

.my-80 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-85 {
  margin-top: 5.313rem !important;
  margin-bottom: 5.313rem !important;
}

.my-90 {
  margin-top: 5.625rem !important;
  margin-bottom: 5.625rem !important;
}

.my-95 {
  margin-top: 5.938rem !important;
  margin-bottom: 5.938rem !important;
}

.my-100 {
  margin-top: 6.25rem !important;
  margin-bottom: 6.25rem !important;
}

.my-105 {
  margin-top: 6.5625rem !important;
  margin-bottom: 6.5625rem !important;
}

.my-110 {
  margin-top: 6.875rem !important;
  margin-bottom: 6.875rem !important;
}

.my-115 {
  margin-top: 7.1875rem !important;
  margin-bottom: 7.1875rem !important;
}

.my-120 {
  margin-top: 7.5rem !important;
  margin-bottom: 7.5rem !important;
}

.my-125 {
  margin-top: 7.8125rem !important;
  margin-bottom: 7.8125rem !important;
}

.my-130 {
  margin-top: 8.125rem !important;
  margin-bottom: 8.125rem !important;
}

.my-135 {
  margin-top: 8.4375rem !important;
  margin-bottom: 8.4375rem !important;
}

.my-140 {
  margin-top: 8.75rem !important;
  margin-bottom: 8.75rem !important;
}

.my-145 {
  margin-top: 9.0625rem !important;
  margin-bottom: 9.0625rem !important;
}

.my-150 {
  margin-top: 9.375rem !important;
  margin-bottom: 9.375rem !important;
}

.my-155 {
  margin-top: 9.6875rem !important;
  margin-bottom: 9.6875rem !important;
}

.my-160 {
  margin-top: 10rem !important;
  margin-bottom: 10rem !important;
}

.my-165 {
  margin-top: 10.3125rem !important;
  margin-bottom: 10.3125rem !important;
}

.my-170 {
  margin-top: 10.625rem !important;
  margin-bottom: 10.625rem !important;
}

.my-175 {
  margin-top: 10.9375rem !important;
  margin-bottom: 10.9375rem !important;
}

.my-180 {
  margin-top: 11.25rem !important;
  margin-bottom: 11.25rem !important;
}

.my-185 {
  margin-top: 11.5625rem !important;
  margin-bottom: 11.5625rem !important;
}

.my-190 {
  margin-top: 11.875rem !important;
  margin-bottom: 11.875rem !important;
}

.my-195 {
  margin-top: 12.1875rem !important;
  margin-bottom: 12.1875rem !important;
}

.my-200 {
  margin-top: 12.5rem !important;
  margin-bottom: 12.5rem !important;
}

.my-205 {
  margin-top: 12.8125rem !important;
  margin-bottom: 12.8125rem !important;
}

.my-210 {
  margin-top: 13.125rem !important;
  margin-bottom: 13.125rem !important;
}

.my-215 {
  margin-top: 13.4375rem !important;
  margin-bottom: 13.4375rem !important;
}

.my-220 {
  margin-top: 13.75rem !important;
  margin-bottom: 13.75rem !important;
}

.my-225 {
  margin-top: 14.0625rem !important;
  margin-bottom: 14.0625rem !important;
}

.my-230 {
  margin-top: 14.375rem !important;
  margin-bottom: 14.375rem !important;
}

.my-235 {
  margin-top: 14.6875rem !important;
  margin-bottom: 14.6875rem !important;
}

.my-240 {
  margin-top: 15rem !important;
  margin-bottom: 15rem !important;
}

.my-245 {
  margin-top: 15.3125rem !important;
  margin-bottom: 15.3125rem !important;
}

.my-250 {
  margin-top: 15.625rem !important;
  margin-bottom: 15.625rem !important;
}

.my-255 {
  margin-top: 15.9375rem !important;
  margin-bottom: 15.9375rem !important;
}

.my-260 {
  margin-top: 16.25rem !important;
  margin-bottom: 16.25rem !important;
}

.my-265 {
  margin-top: 16.5625rem !important;
  margin-bottom: 16.5625rem !important;
}

.my-270 {
  margin-top: 16.875rem !important;
  margin-bottom: 16.875rem !important;
}

.my-275 {
  margin-top: 17.1875rem !important;
  margin-bottom: 17.1875rem !important;
}

.my-280 {
  margin-top: 17.5rem !important;
  margin-bottom: 17.5rem !important;
}

.my-285 {
  margin-top: 17.8125rem !important;
  margin-bottom: 17.8125rem !important;
}

.my-290 {
  margin-top: 18.125rem !important;
  margin-bottom: 18.125rem !important;
}

.my-295 {
  margin-top: 18.4375rem !important;
  margin-bottom: 18.4375rem !important;
}

.my-300 {
  margin-top: 18.75rem !important;
  margin-bottom: 18.75rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-5 {
  margin-top: 0.313rem !important;
}

.mt-10 {
  margin-top: 0.625rem !important;
}

.mt-15 {
  margin-top: 0.938rem !important;
}

.mt-20 {
  margin-top: 1.25rem !important;
}

.mt-25 {
  margin-top: 1.563rem !important;
}

.mt-30 {
  margin-top: 1.875rem !important;
}

.mt-35 {
  margin-top: 2.188rem !important;
}

.mt-40 {
  margin-top: 2.5rem !important;
}

.mt-45 {
  margin-top: 2.813rem !important;
}

.mt-50 {
  margin-top: 3.125rem !important;
}

.mt-55 {
  margin-top: 3.438rem !important;
}

.mt-60 {
  margin-top: 3.75rem !important;
}

.mt-65 {
  margin-top: 4.063rem !important;
}

.mt-70 {
  margin-top: 4.375rem !important;
}

.mt-75 {
  margin-top: 4.688rem !important;
}

.mt-80 {
  margin-top: 5rem !important;
}

.mt-85 {
  margin-top: 5.313rem !important;
}

.mt-90 {
  margin-top: 5.625rem !important;
}

.mt-95 {
  margin-top: 5.938rem !important;
}

.mt-100 {
  margin-top: 6.25rem !important;
}

.mt-105 {
  margin-top: 6.5625rem !important;
}

.mt-110 {
  margin-top: 6.875rem !important;
}

.mt-115 {
  margin-top: 7.1875rem !important;
}

.mt-120 {
  margin-top: 7.5rem !important;
}

.mt-125 {
  margin-top: 7.8125rem !important;
}

.mt-130 {
  margin-top: 8.125rem !important;
}

.mt-135 {
  margin-top: 8.4375rem !important;
}

.mt-140 {
  margin-top: 8.75rem !important;
}

.mt-145 {
  margin-top: 9.0625rem !important;
}

.mt-150 {
  margin-top: 9.375rem !important;
}

.mt-155 {
  margin-top: 9.6875rem !important;
}

.mt-160 {
  margin-top: 10rem !important;
}

.mt-165 {
  margin-top: 10.3125rem !important;
}

.mt-170 {
  margin-top: 10.625rem !important;
}

.mt-175 {
  margin-top: 10.9375rem !important;
}

.mt-180 {
  margin-top: 11.25rem !important;
}

.mt-185 {
  margin-top: 11.5625rem !important;
}

.mt-190 {
  margin-top: 11.875rem !important;
}

.mt-195 {
  margin-top: 12.1875rem !important;
}

.mt-200 {
  margin-top: 12.5rem !important;
}

.mt-205 {
  margin-top: 12.8125rem !important;
}

.mt-210 {
  margin-top: 13.125rem !important;
}

.mt-215 {
  margin-top: 13.4375rem !important;
}

.mt-220 {
  margin-top: 13.75rem !important;
}

.mt-225 {
  margin-top: 14.0625rem !important;
}

.mt-230 {
  margin-top: 14.375rem !important;
}

.mt-235 {
  margin-top: 14.6875rem !important;
}

.mt-240 {
  margin-top: 15rem !important;
}

.mt-245 {
  margin-top: 15.3125rem !important;
}

.mt-250 {
  margin-top: 15.625rem !important;
}

.mt-255 {
  margin-top: 15.9375rem !important;
}

.mt-260 {
  margin-top: 16.25rem !important;
}

.mt-265 {
  margin-top: 16.5625rem !important;
}

.mt-270 {
  margin-top: 16.875rem !important;
}

.mt-275 {
  margin-top: 17.1875rem !important;
}

.mt-280 {
  margin-top: 17.5rem !important;
}

.mt-285 {
  margin-top: 17.8125rem !important;
}

.mt-290 {
  margin-top: 18.125rem !important;
}

.mt-295 {
  margin-top: 18.4375rem !important;
}

.mt-300 {
  margin-top: 18.75rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-5 {
  margin-right: 0.313rem !important;
}

.me-10 {
  margin-right: 0.625rem !important;
}

.me-15 {
  margin-right: 0.938rem !important;
}

.me-20 {
  margin-right: 1.25rem !important;
}

.me-25 {
  margin-right: 1.563rem !important;
}

.me-30 {
  margin-right: 1.875rem !important;
}

.me-35 {
  margin-right: 2.188rem !important;
}

.me-40 {
  margin-right: 2.5rem !important;
}

.me-45 {
  margin-right: 2.813rem !important;
}

.me-50 {
  margin-right: 3.125rem !important;
}

.me-55 {
  margin-right: 3.438rem !important;
}

.me-60 {
  margin-right: 3.75rem !important;
}

.me-65 {
  margin-right: 4.063rem !important;
}

.me-70 {
  margin-right: 4.375rem !important;
}

.me-75 {
  margin-right: 4.688rem !important;
}

.me-80 {
  margin-right: 5rem !important;
}

.me-85 {
  margin-right: 5.313rem !important;
}

.me-90 {
  margin-right: 5.625rem !important;
}

.me-95 {
  margin-right: 5.938rem !important;
}

.me-100 {
  margin-right: 6.25rem !important;
}

.me-105 {
  margin-right: 6.5625rem !important;
}

.me-110 {
  margin-right: 6.875rem !important;
}

.me-115 {
  margin-right: 7.1875rem !important;
}

.me-120 {
  margin-right: 7.5rem !important;
}

.me-125 {
  margin-right: 7.8125rem !important;
}

.me-130 {
  margin-right: 8.125rem !important;
}

.me-135 {
  margin-right: 8.4375rem !important;
}

.me-140 {
  margin-right: 8.75rem !important;
}

.me-145 {
  margin-right: 9.0625rem !important;
}

.me-150 {
  margin-right: 9.375rem !important;
}

.me-155 {
  margin-right: 9.6875rem !important;
}

.me-160 {
  margin-right: 10rem !important;
}

.me-165 {
  margin-right: 10.3125rem !important;
}

.me-170 {
  margin-right: 10.625rem !important;
}

.me-175 {
  margin-right: 10.9375rem !important;
}

.me-180 {
  margin-right: 11.25rem !important;
}

.me-185 {
  margin-right: 11.5625rem !important;
}

.me-190 {
  margin-right: 11.875rem !important;
}

.me-195 {
  margin-right: 12.1875rem !important;
}

.me-200 {
  margin-right: 12.5rem !important;
}

.me-205 {
  margin-right: 12.8125rem !important;
}

.me-210 {
  margin-right: 13.125rem !important;
}

.me-215 {
  margin-right: 13.4375rem !important;
}

.me-220 {
  margin-right: 13.75rem !important;
}

.me-225 {
  margin-right: 14.0625rem !important;
}

.me-230 {
  margin-right: 14.375rem !important;
}

.me-235 {
  margin-right: 14.6875rem !important;
}

.me-240 {
  margin-right: 15rem !important;
}

.me-245 {
  margin-right: 15.3125rem !important;
}

.me-250 {
  margin-right: 15.625rem !important;
}

.me-255 {
  margin-right: 15.9375rem !important;
}

.me-260 {
  margin-right: 16.25rem !important;
}

.me-265 {
  margin-right: 16.5625rem !important;
}

.me-270 {
  margin-right: 16.875rem !important;
}

.me-275 {
  margin-right: 17.1875rem !important;
}

.me-280 {
  margin-right: 17.5rem !important;
}

.me-285 {
  margin-right: 17.8125rem !important;
}

.me-290 {
  margin-right: 18.125rem !important;
}

.me-295 {
  margin-right: 18.4375rem !important;
}

.me-300 {
  margin-right: 18.75rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 0.313rem !important;
}

.mb-10 {
  margin-bottom: 0.625rem !important;
}

.mb-15 {
  margin-bottom: 0.938rem !important;
}

.mb-20 {
  margin-bottom: 1.25rem !important;
}

.mb-25 {
  margin-bottom: 1.563rem !important;
}

.mb-30 {
  margin-bottom: 1.875rem !important;
}

.mb-35 {
  margin-bottom: 2.188rem !important;
}

.mb-40 {
  margin-bottom: 2.5rem !important;
}

.mb-45 {
  margin-bottom: 2.813rem !important;
}

.mb-50 {
  margin-bottom: 3.125rem !important;
}

.mb-55 {
  margin-bottom: 3.438rem !important;
}

.mb-60 {
  margin-bottom: 3.75rem !important;
}

.mb-65 {
  margin-bottom: 4.063rem !important;
}

.mb-70 {
  margin-bottom: 4.375rem !important;
}

.mb-75 {
  margin-bottom: 4.688rem !important;
}

.mb-80 {
  margin-bottom: 5rem !important;
}

.mb-85 {
  margin-bottom: 5.313rem !important;
}

.mb-90 {
  margin-bottom: 5.625rem !important;
}

.mb-95 {
  margin-bottom: 5.938rem !important;
}

.mb-100 {
  margin-bottom: 6.25rem !important;
}

.mb-105 {
  margin-bottom: 6.5625rem !important;
}

.mb-110 {
  margin-bottom: 6.875rem !important;
}

.mb-115 {
  margin-bottom: 7.1875rem !important;
}

.mb-120 {
  margin-bottom: 7.5rem !important;
}

.mb-125 {
  margin-bottom: 7.8125rem !important;
}

.mb-130 {
  margin-bottom: 8.125rem !important;
}

.mb-135 {
  margin-bottom: 8.4375rem !important;
}

.mb-140 {
  margin-bottom: 8.75rem !important;
}

.mb-145 {
  margin-bottom: 9.0625rem !important;
}

.mb-150 {
  margin-bottom: 9.375rem !important;
}

.mb-155 {
  margin-bottom: 9.6875rem !important;
}

.mb-160 {
  margin-bottom: 10rem !important;
}

.mb-165 {
  margin-bottom: 10.3125rem !important;
}

.mb-170 {
  margin-bottom: 10.625rem !important;
}

.mb-175 {
  margin-bottom: 10.9375rem !important;
}

.mb-180 {
  margin-bottom: 11.25rem !important;
}

.mb-185 {
  margin-bottom: 11.5625rem !important;
}

.mb-190 {
  margin-bottom: 11.875rem !important;
}

.mb-195 {
  margin-bottom: 12.1875rem !important;
}

.mb-200 {
  margin-bottom: 12.5rem !important;
}

.mb-205 {
  margin-bottom: 12.8125rem !important;
}

.mb-210 {
  margin-bottom: 13.125rem !important;
}

.mb-215 {
  margin-bottom: 13.4375rem !important;
}

.mb-220 {
  margin-bottom: 13.75rem !important;
}

.mb-225 {
  margin-bottom: 14.0625rem !important;
}

.mb-230 {
  margin-bottom: 14.375rem !important;
}

.mb-235 {
  margin-bottom: 14.6875rem !important;
}

.mb-240 {
  margin-bottom: 15rem !important;
}

.mb-245 {
  margin-bottom: 15.3125rem !important;
}

.mb-250 {
  margin-bottom: 15.625rem !important;
}

.mb-255 {
  margin-bottom: 15.9375rem !important;
}

.mb-260 {
  margin-bottom: 16.25rem !important;
}

.mb-265 {
  margin-bottom: 16.5625rem !important;
}

.mb-270 {
  margin-bottom: 16.875rem !important;
}

.mb-275 {
  margin-bottom: 17.1875rem !important;
}

.mb-280 {
  margin-bottom: 17.5rem !important;
}

.mb-285 {
  margin-bottom: 17.8125rem !important;
}

.mb-290 {
  margin-bottom: 18.125rem !important;
}

.mb-295 {
  margin-bottom: 18.4375rem !important;
}

.mb-300 {
  margin-bottom: 18.75rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-5 {
  margin-left: 0.313rem !important;
}

.ms-10 {
  margin-left: 0.625rem !important;
}

.ms-15 {
  margin-left: 0.938rem !important;
}

.ms-20 {
  margin-left: 1.25rem !important;
}

.ms-25 {
  margin-left: 1.563rem !important;
}

.ms-30 {
  margin-left: 1.875rem !important;
}

.ms-35 {
  margin-left: 2.188rem !important;
}

.ms-40 {
  margin-left: 2.5rem !important;
}

.ms-45 {
  margin-left: 2.813rem !important;
}

.ms-50 {
  margin-left: 3.125rem !important;
}

.ms-55 {
  margin-left: 3.438rem !important;
}

.ms-60 {
  margin-left: 3.75rem !important;
}

.ms-65 {
  margin-left: 4.063rem !important;
}

.ms-70 {
  margin-left: 4.375rem !important;
}

.ms-75 {
  margin-left: 4.688rem !important;
}

.ms-80 {
  margin-left: 5rem !important;
}

.ms-85 {
  margin-left: 5.313rem !important;
}

.ms-90 {
  margin-left: 5.625rem !important;
}

.ms-95 {
  margin-left: 5.938rem !important;
}

.ms-100 {
  margin-left: 6.25rem !important;
}

.ms-105 {
  margin-left: 6.5625rem !important;
}

.ms-110 {
  margin-left: 6.875rem !important;
}

.ms-115 {
  margin-left: 7.1875rem !important;
}

.ms-120 {
  margin-left: 7.5rem !important;
}

.ms-125 {
  margin-left: 7.8125rem !important;
}

.ms-130 {
  margin-left: 8.125rem !important;
}

.ms-135 {
  margin-left: 8.4375rem !important;
}

.ms-140 {
  margin-left: 8.75rem !important;
}

.ms-145 {
  margin-left: 9.0625rem !important;
}

.ms-150 {
  margin-left: 9.375rem !important;
}

.ms-155 {
  margin-left: 9.6875rem !important;
}

.ms-160 {
  margin-left: 10rem !important;
}

.ms-165 {
  margin-left: 10.3125rem !important;
}

.ms-170 {
  margin-left: 10.625rem !important;
}

.ms-175 {
  margin-left: 10.9375rem !important;
}

.ms-180 {
  margin-left: 11.25rem !important;
}

.ms-185 {
  margin-left: 11.5625rem !important;
}

.ms-190 {
  margin-left: 11.875rem !important;
}

.ms-195 {
  margin-left: 12.1875rem !important;
}

.ms-200 {
  margin-left: 12.5rem !important;
}

.ms-205 {
  margin-left: 12.8125rem !important;
}

.ms-210 {
  margin-left: 13.125rem !important;
}

.ms-215 {
  margin-left: 13.4375rem !important;
}

.ms-220 {
  margin-left: 13.75rem !important;
}

.ms-225 {
  margin-left: 14.0625rem !important;
}

.ms-230 {
  margin-left: 14.375rem !important;
}

.ms-235 {
  margin-left: 14.6875rem !important;
}

.ms-240 {
  margin-left: 15rem !important;
}

.ms-245 {
  margin-left: 15.3125rem !important;
}

.ms-250 {
  margin-left: 15.625rem !important;
}

.ms-255 {
  margin-left: 15.9375rem !important;
}

.ms-260 {
  margin-left: 16.25rem !important;
}

.ms-265 {
  margin-left: 16.5625rem !important;
}

.ms-270 {
  margin-left: 16.875rem !important;
}

.ms-275 {
  margin-left: 17.1875rem !important;
}

.ms-280 {
  margin-left: 17.5rem !important;
}

.ms-285 {
  margin-left: 17.8125rem !important;
}

.ms-290 {
  margin-left: 18.125rem !important;
}

.ms-295 {
  margin-left: 18.4375rem !important;
}

.ms-300 {
  margin-left: 18.75rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n5 {
  margin: -0.313rem !important;
}

.m-n10 {
  margin: -0.625rem !important;
}

.m-n15 {
  margin: -0.938rem !important;
}

.m-n20 {
  margin: -1.25rem !important;
}

.m-n25 {
  margin: -1.563rem !important;
}

.m-n30 {
  margin: -1.875rem !important;
}

.m-n35 {
  margin: -2.188rem !important;
}

.m-n40 {
  margin: -2.5rem !important;
}

.m-n45 {
  margin: -2.813rem !important;
}

.m-n50 {
  margin: -3.125rem !important;
}

.m-n55 {
  margin: -3.438rem !important;
}

.m-n60 {
  margin: -3.75rem !important;
}

.m-n65 {
  margin: -4.063rem !important;
}

.m-n70 {
  margin: -4.375rem !important;
}

.m-n75 {
  margin: -4.688rem !important;
}

.m-n80 {
  margin: -5rem !important;
}

.m-n85 {
  margin: -5.313rem !important;
}

.m-n90 {
  margin: -5.625rem !important;
}

.m-n95 {
  margin: -5.938rem !important;
}

.m-n100 {
  margin: -6.25rem !important;
}

.m-n105 {
  margin: -6.5625rem !important;
}

.m-n110 {
  margin: -6.875rem !important;
}

.m-n115 {
  margin: -7.1875rem !important;
}

.m-n120 {
  margin: -7.5rem !important;
}

.m-n125 {
  margin: -7.8125rem !important;
}

.m-n130 {
  margin: -8.125rem !important;
}

.m-n135 {
  margin: -8.4375rem !important;
}

.m-n140 {
  margin: -8.75rem !important;
}

.m-n145 {
  margin: -9.0625rem !important;
}

.m-n150 {
  margin: -9.375rem !important;
}

.m-n155 {
  margin: -9.6875rem !important;
}

.m-n160 {
  margin: -10rem !important;
}

.m-n165 {
  margin: -10.3125rem !important;
}

.m-n170 {
  margin: -10.625rem !important;
}

.m-n175 {
  margin: -10.9375rem !important;
}

.m-n180 {
  margin: -11.25rem !important;
}

.m-n185 {
  margin: -11.5625rem !important;
}

.m-n190 {
  margin: -11.875rem !important;
}

.m-n195 {
  margin: -12.1875rem !important;
}

.m-n200 {
  margin: -12.5rem !important;
}

.m-n205 {
  margin: -12.8125rem !important;
}

.m-n210 {
  margin: -13.125rem !important;
}

.m-n215 {
  margin: -13.4375rem !important;
}

.m-n220 {
  margin: -13.75rem !important;
}

.m-n225 {
  margin: -14.0625rem !important;
}

.m-n230 {
  margin: -14.375rem !important;
}

.m-n235 {
  margin: -14.6875rem !important;
}

.m-n240 {
  margin: -15rem !important;
}

.m-n245 {
  margin: -15.3125rem !important;
}

.m-n250 {
  margin: -15.625rem !important;
}

.m-n255 {
  margin: -15.9375rem !important;
}

.m-n260 {
  margin: -16.25rem !important;
}

.m-n265 {
  margin: -16.5625rem !important;
}

.m-n270 {
  margin: -16.875rem !important;
}

.m-n275 {
  margin: -17.1875rem !important;
}

.m-n280 {
  margin: -17.5rem !important;
}

.m-n285 {
  margin: -17.8125rem !important;
}

.m-n290 {
  margin: -18.125rem !important;
}

.m-n295 {
  margin: -18.4375rem !important;
}

.m-n300 {
  margin: -18.75rem !important;
}

.mx-n5 {
  margin-right: -0.313rem !important;
  margin-left: -0.313rem !important;
}

.mx-n10 {
  margin-right: -0.625rem !important;
  margin-left: -0.625rem !important;
}

.mx-n15 {
  margin-right: -0.938rem !important;
  margin-left: -0.938rem !important;
}

.mx-n20 {
  margin-right: -1.25rem !important;
  margin-left: -1.25rem !important;
}

.mx-n25 {
  margin-right: -1.563rem !important;
  margin-left: -1.563rem !important;
}

.mx-n30 {
  margin-right: -1.875rem !important;
  margin-left: -1.875rem !important;
}

.mx-n35 {
  margin-right: -2.188rem !important;
  margin-left: -2.188rem !important;
}

.mx-n40 {
  margin-right: -2.5rem !important;
  margin-left: -2.5rem !important;
}

.mx-n45 {
  margin-right: -2.813rem !important;
  margin-left: -2.813rem !important;
}

.mx-n50 {
  margin-right: -3.125rem !important;
  margin-left: -3.125rem !important;
}

.mx-n55 {
  margin-right: -3.438rem !important;
  margin-left: -3.438rem !important;
}

.mx-n60 {
  margin-right: -3.75rem !important;
  margin-left: -3.75rem !important;
}

.mx-n65 {
  margin-right: -4.063rem !important;
  margin-left: -4.063rem !important;
}

.mx-n70 {
  margin-right: -4.375rem !important;
  margin-left: -4.375rem !important;
}

.mx-n75 {
  margin-right: -4.688rem !important;
  margin-left: -4.688rem !important;
}

.mx-n80 {
  margin-right: -5rem !important;
  margin-left: -5rem !important;
}

.mx-n85 {
  margin-right: -5.313rem !important;
  margin-left: -5.313rem !important;
}

.mx-n90 {
  margin-right: -5.625rem !important;
  margin-left: -5.625rem !important;
}

.mx-n95 {
  margin-right: -5.938rem !important;
  margin-left: -5.938rem !important;
}

.mx-n100 {
  margin-right: -6.25rem !important;
  margin-left: -6.25rem !important;
}

.mx-n105 {
  margin-right: -6.5625rem !important;
  margin-left: -6.5625rem !important;
}

.mx-n110 {
  margin-right: -6.875rem !important;
  margin-left: -6.875rem !important;
}

.mx-n115 {
  margin-right: -7.1875rem !important;
  margin-left: -7.1875rem !important;
}

.mx-n120 {
  margin-right: -7.5rem !important;
  margin-left: -7.5rem !important;
}

.mx-n125 {
  margin-right: -7.8125rem !important;
  margin-left: -7.8125rem !important;
}

.mx-n130 {
  margin-right: -8.125rem !important;
  margin-left: -8.125rem !important;
}

.mx-n135 {
  margin-right: -8.4375rem !important;
  margin-left: -8.4375rem !important;
}

.mx-n140 {
  margin-right: -8.75rem !important;
  margin-left: -8.75rem !important;
}

.mx-n145 {
  margin-right: -9.0625rem !important;
  margin-left: -9.0625rem !important;
}

.mx-n150 {
  margin-right: -9.375rem !important;
  margin-left: -9.375rem !important;
}

.mx-n155 {
  margin-right: -9.6875rem !important;
  margin-left: -9.6875rem !important;
}

.mx-n160 {
  margin-right: -10rem !important;
  margin-left: -10rem !important;
}

.mx-n165 {
  margin-right: -10.3125rem !important;
  margin-left: -10.3125rem !important;
}

.mx-n170 {
  margin-right: -10.625rem !important;
  margin-left: -10.625rem !important;
}

.mx-n175 {
  margin-right: -10.9375rem !important;
  margin-left: -10.9375rem !important;
}

.mx-n180 {
  margin-right: -11.25rem !important;
  margin-left: -11.25rem !important;
}

.mx-n185 {
  margin-right: -11.5625rem !important;
  margin-left: -11.5625rem !important;
}

.mx-n190 {
  margin-right: -11.875rem !important;
  margin-left: -11.875rem !important;
}

.mx-n195 {
  margin-right: -12.1875rem !important;
  margin-left: -12.1875rem !important;
}

.mx-n200 {
  margin-right: -12.5rem !important;
  margin-left: -12.5rem !important;
}

.mx-n205 {
  margin-right: -12.8125rem !important;
  margin-left: -12.8125rem !important;
}

.mx-n210 {
  margin-right: -13.125rem !important;
  margin-left: -13.125rem !important;
}

.mx-n215 {
  margin-right: -13.4375rem !important;
  margin-left: -13.4375rem !important;
}

.mx-n220 {
  margin-right: -13.75rem !important;
  margin-left: -13.75rem !important;
}

.mx-n225 {
  margin-right: -14.0625rem !important;
  margin-left: -14.0625rem !important;
}

.mx-n230 {
  margin-right: -14.375rem !important;
  margin-left: -14.375rem !important;
}

.mx-n235 {
  margin-right: -14.6875rem !important;
  margin-left: -14.6875rem !important;
}

.mx-n240 {
  margin-right: -15rem !important;
  margin-left: -15rem !important;
}

.mx-n245 {
  margin-right: -15.3125rem !important;
  margin-left: -15.3125rem !important;
}

.mx-n250 {
  margin-right: -15.625rem !important;
  margin-left: -15.625rem !important;
}

.mx-n255 {
  margin-right: -15.9375rem !important;
  margin-left: -15.9375rem !important;
}

.mx-n260 {
  margin-right: -16.25rem !important;
  margin-left: -16.25rem !important;
}

.mx-n265 {
  margin-right: -16.5625rem !important;
  margin-left: -16.5625rem !important;
}

.mx-n270 {
  margin-right: -16.875rem !important;
  margin-left: -16.875rem !important;
}

.mx-n275 {
  margin-right: -17.1875rem !important;
  margin-left: -17.1875rem !important;
}

.mx-n280 {
  margin-right: -17.5rem !important;
  margin-left: -17.5rem !important;
}

.mx-n285 {
  margin-right: -17.8125rem !important;
  margin-left: -17.8125rem !important;
}

.mx-n290 {
  margin-right: -18.125rem !important;
  margin-left: -18.125rem !important;
}

.mx-n295 {
  margin-right: -18.4375rem !important;
  margin-left: -18.4375rem !important;
}

.mx-n300 {
  margin-right: -18.75rem !important;
  margin-left: -18.75rem !important;
}

.my-n5 {
  margin-top: -0.313rem !important;
  margin-bottom: -0.313rem !important;
}

.my-n10 {
  margin-top: -0.625rem !important;
  margin-bottom: -0.625rem !important;
}

.my-n15 {
  margin-top: -0.938rem !important;
  margin-bottom: -0.938rem !important;
}

.my-n20 {
  margin-top: -1.25rem !important;
  margin-bottom: -1.25rem !important;
}

.my-n25 {
  margin-top: -1.563rem !important;
  margin-bottom: -1.563rem !important;
}

.my-n30 {
  margin-top: -1.875rem !important;
  margin-bottom: -1.875rem !important;
}

.my-n35 {
  margin-top: -2.188rem !important;
  margin-bottom: -2.188rem !important;
}

.my-n40 {
  margin-top: -2.5rem !important;
  margin-bottom: -2.5rem !important;
}

.my-n45 {
  margin-top: -2.813rem !important;
  margin-bottom: -2.813rem !important;
}

.my-n50 {
  margin-top: -3.125rem !important;
  margin-bottom: -3.125rem !important;
}

.my-n55 {
  margin-top: -3.438rem !important;
  margin-bottom: -3.438rem !important;
}

.my-n60 {
  margin-top: -3.75rem !important;
  margin-bottom: -3.75rem !important;
}

.my-n65 {
  margin-top: -4.063rem !important;
  margin-bottom: -4.063rem !important;
}

.my-n70 {
  margin-top: -4.375rem !important;
  margin-bottom: -4.375rem !important;
}

.my-n75 {
  margin-top: -4.688rem !important;
  margin-bottom: -4.688rem !important;
}

.my-n80 {
  margin-top: -5rem !important;
  margin-bottom: -5rem !important;
}

.my-n85 {
  margin-top: -5.313rem !important;
  margin-bottom: -5.313rem !important;
}

.my-n90 {
  margin-top: -5.625rem !important;
  margin-bottom: -5.625rem !important;
}

.my-n95 {
  margin-top: -5.938rem !important;
  margin-bottom: -5.938rem !important;
}

.my-n100 {
  margin-top: -6.25rem !important;
  margin-bottom: -6.25rem !important;
}

.my-n105 {
  margin-top: -6.5625rem !important;
  margin-bottom: -6.5625rem !important;
}

.my-n110 {
  margin-top: -6.875rem !important;
  margin-bottom: -6.875rem !important;
}

.my-n115 {
  margin-top: -7.1875rem !important;
  margin-bottom: -7.1875rem !important;
}

.my-n120 {
  margin-top: -7.5rem !important;
  margin-bottom: -7.5rem !important;
}

.my-n125 {
  margin-top: -7.8125rem !important;
  margin-bottom: -7.8125rem !important;
}

.my-n130 {
  margin-top: -8.125rem !important;
  margin-bottom: -8.125rem !important;
}

.my-n135 {
  margin-top: -8.4375rem !important;
  margin-bottom: -8.4375rem !important;
}

.my-n140 {
  margin-top: -8.75rem !important;
  margin-bottom: -8.75rem !important;
}

.my-n145 {
  margin-top: -9.0625rem !important;
  margin-bottom: -9.0625rem !important;
}

.my-n150 {
  margin-top: -9.375rem !important;
  margin-bottom: -9.375rem !important;
}

.my-n155 {
  margin-top: -9.6875rem !important;
  margin-bottom: -9.6875rem !important;
}

.my-n160 {
  margin-top: -10rem !important;
  margin-bottom: -10rem !important;
}

.my-n165 {
  margin-top: -10.3125rem !important;
  margin-bottom: -10.3125rem !important;
}

.my-n170 {
  margin-top: -10.625rem !important;
  margin-bottom: -10.625rem !important;
}

.my-n175 {
  margin-top: -10.9375rem !important;
  margin-bottom: -10.9375rem !important;
}

.my-n180 {
  margin-top: -11.25rem !important;
  margin-bottom: -11.25rem !important;
}

.my-n185 {
  margin-top: -11.5625rem !important;
  margin-bottom: -11.5625rem !important;
}

.my-n190 {
  margin-top: -11.875rem !important;
  margin-bottom: -11.875rem !important;
}

.my-n195 {
  margin-top: -12.1875rem !important;
  margin-bottom: -12.1875rem !important;
}

.my-n200 {
  margin-top: -12.5rem !important;
  margin-bottom: -12.5rem !important;
}

.my-n205 {
  margin-top: -12.8125rem !important;
  margin-bottom: -12.8125rem !important;
}

.my-n210 {
  margin-top: -13.125rem !important;
  margin-bottom: -13.125rem !important;
}

.my-n215 {
  margin-top: -13.4375rem !important;
  margin-bottom: -13.4375rem !important;
}

.my-n220 {
  margin-top: -13.75rem !important;
  margin-bottom: -13.75rem !important;
}

.my-n225 {
  margin-top: -14.0625rem !important;
  margin-bottom: -14.0625rem !important;
}

.my-n230 {
  margin-top: -14.375rem !important;
  margin-bottom: -14.375rem !important;
}

.my-n235 {
  margin-top: -14.6875rem !important;
  margin-bottom: -14.6875rem !important;
}

.my-n240 {
  margin-top: -15rem !important;
  margin-bottom: -15rem !important;
}

.my-n245 {
  margin-top: -15.3125rem !important;
  margin-bottom: -15.3125rem !important;
}

.my-n250 {
  margin-top: -15.625rem !important;
  margin-bottom: -15.625rem !important;
}

.my-n255 {
  margin-top: -15.9375rem !important;
  margin-bottom: -15.9375rem !important;
}

.my-n260 {
  margin-top: -16.25rem !important;
  margin-bottom: -16.25rem !important;
}

.my-n265 {
  margin-top: -16.5625rem !important;
  margin-bottom: -16.5625rem !important;
}

.my-n270 {
  margin-top: -16.875rem !important;
  margin-bottom: -16.875rem !important;
}

.my-n275 {
  margin-top: -17.1875rem !important;
  margin-bottom: -17.1875rem !important;
}

.my-n280 {
  margin-top: -17.5rem !important;
  margin-bottom: -17.5rem !important;
}

.my-n285 {
  margin-top: -17.8125rem !important;
  margin-bottom: -17.8125rem !important;
}

.my-n290 {
  margin-top: -18.125rem !important;
  margin-bottom: -18.125rem !important;
}

.my-n295 {
  margin-top: -18.4375rem !important;
  margin-bottom: -18.4375rem !important;
}

.my-n300 {
  margin-top: -18.75rem !important;
  margin-bottom: -18.75rem !important;
}

.mt-n5 {
  margin-top: -0.313rem !important;
}

.mt-n10 {
  margin-top: -0.625rem !important;
}

.mt-n15 {
  margin-top: -0.938rem !important;
}

.mt-n20 {
  margin-top: -1.25rem !important;
}

.mt-n25 {
  margin-top: -1.563rem !important;
}

.mt-n30 {
  margin-top: -1.875rem !important;
}

.mt-n35 {
  margin-top: -2.188rem !important;
}

.mt-n40 {
  margin-top: -2.5rem !important;
}

.mt-n45 {
  margin-top: -2.813rem !important;
}

.mt-n50 {
  margin-top: -3.125rem !important;
}

.mt-n55 {
  margin-top: -3.438rem !important;
}

.mt-n60 {
  margin-top: -3.75rem !important;
}

.mt-n65 {
  margin-top: -4.063rem !important;
}

.mt-n70 {
  margin-top: -4.375rem !important;
}

.mt-n75 {
  margin-top: -4.688rem !important;
}

.mt-n80 {
  margin-top: -5rem !important;
}

.mt-n85 {
  margin-top: -5.313rem !important;
}

.mt-n90 {
  margin-top: -5.625rem !important;
}

.mt-n95 {
  margin-top: -5.938rem !important;
}

.mt-n100 {
  margin-top: -6.25rem !important;
}

.mt-n105 {
  margin-top: -6.5625rem !important;
}

.mt-n110 {
  margin-top: -6.875rem !important;
}

.mt-n115 {
  margin-top: -7.1875rem !important;
}

.mt-n120 {
  margin-top: -7.5rem !important;
}

.mt-n125 {
  margin-top: -7.8125rem !important;
}

.mt-n130 {
  margin-top: -8.125rem !important;
}

.mt-n135 {
  margin-top: -8.4375rem !important;
}

.mt-n140 {
  margin-top: -8.75rem !important;
}

.mt-n145 {
  margin-top: -9.0625rem !important;
}

.mt-n150 {
  margin-top: -9.375rem !important;
}

.mt-n155 {
  margin-top: -9.6875rem !important;
}

.mt-n160 {
  margin-top: -10rem !important;
}

.mt-n165 {
  margin-top: -10.3125rem !important;
}

.mt-n170 {
  margin-top: -10.625rem !important;
}

.mt-n175 {
  margin-top: -10.9375rem !important;
}

.mt-n180 {
  margin-top: -11.25rem !important;
}

.mt-n185 {
  margin-top: -11.5625rem !important;
}

.mt-n190 {
  margin-top: -11.875rem !important;
}

.mt-n195 {
  margin-top: -12.1875rem !important;
}

.mt-n200 {
  margin-top: -12.5rem !important;
}

.mt-n205 {
  margin-top: -12.8125rem !important;
}

.mt-n210 {
  margin-top: -13.125rem !important;
}

.mt-n215 {
  margin-top: -13.4375rem !important;
}

.mt-n220 {
  margin-top: -13.75rem !important;
}

.mt-n225 {
  margin-top: -14.0625rem !important;
}

.mt-n230 {
  margin-top: -14.375rem !important;
}

.mt-n235 {
  margin-top: -14.6875rem !important;
}

.mt-n240 {
  margin-top: -15rem !important;
}

.mt-n245 {
  margin-top: -15.3125rem !important;
}

.mt-n250 {
  margin-top: -15.625rem !important;
}

.mt-n255 {
  margin-top: -15.9375rem !important;
}

.mt-n260 {
  margin-top: -16.25rem !important;
}

.mt-n265 {
  margin-top: -16.5625rem !important;
}

.mt-n270 {
  margin-top: -16.875rem !important;
}

.mt-n275 {
  margin-top: -17.1875rem !important;
}

.mt-n280 {
  margin-top: -17.5rem !important;
}

.mt-n285 {
  margin-top: -17.8125rem !important;
}

.mt-n290 {
  margin-top: -18.125rem !important;
}

.mt-n295 {
  margin-top: -18.4375rem !important;
}

.mt-n300 {
  margin-top: -18.75rem !important;
}

.me-n5 {
  margin-right: -0.313rem !important;
}

.me-n10 {
  margin-right: -0.625rem !important;
}

.me-n15 {
  margin-right: -0.938rem !important;
}

.me-n20 {
  margin-right: -1.25rem !important;
}

.me-n25 {
  margin-right: -1.563rem !important;
}

.me-n30 {
  margin-right: -1.875rem !important;
}

.me-n35 {
  margin-right: -2.188rem !important;
}

.me-n40 {
  margin-right: -2.5rem !important;
}

.me-n45 {
  margin-right: -2.813rem !important;
}

.me-n50 {
  margin-right: -3.125rem !important;
}

.me-n55 {
  margin-right: -3.438rem !important;
}

.me-n60 {
  margin-right: -3.75rem !important;
}

.me-n65 {
  margin-right: -4.063rem !important;
}

.me-n70 {
  margin-right: -4.375rem !important;
}

.me-n75 {
  margin-right: -4.688rem !important;
}

.me-n80 {
  margin-right: -5rem !important;
}

.me-n85 {
  margin-right: -5.313rem !important;
}

.me-n90 {
  margin-right: -5.625rem !important;
}

.me-n95 {
  margin-right: -5.938rem !important;
}

.me-n100 {
  margin-right: -6.25rem !important;
}

.me-n105 {
  margin-right: -6.5625rem !important;
}

.me-n110 {
  margin-right: -6.875rem !important;
}

.me-n115 {
  margin-right: -7.1875rem !important;
}

.me-n120 {
  margin-right: -7.5rem !important;
}

.me-n125 {
  margin-right: -7.8125rem !important;
}

.me-n130 {
  margin-right: -8.125rem !important;
}

.me-n135 {
  margin-right: -8.4375rem !important;
}

.me-n140 {
  margin-right: -8.75rem !important;
}

.me-n145 {
  margin-right: -9.0625rem !important;
}

.me-n150 {
  margin-right: -9.375rem !important;
}

.me-n155 {
  margin-right: -9.6875rem !important;
}

.me-n160 {
  margin-right: -10rem !important;
}

.me-n165 {
  margin-right: -10.3125rem !important;
}

.me-n170 {
  margin-right: -10.625rem !important;
}

.me-n175 {
  margin-right: -10.9375rem !important;
}

.me-n180 {
  margin-right: -11.25rem !important;
}

.me-n185 {
  margin-right: -11.5625rem !important;
}

.me-n190 {
  margin-right: -11.875rem !important;
}

.me-n195 {
  margin-right: -12.1875rem !important;
}

.me-n200 {
  margin-right: -12.5rem !important;
}

.me-n205 {
  margin-right: -12.8125rem !important;
}

.me-n210 {
  margin-right: -13.125rem !important;
}

.me-n215 {
  margin-right: -13.4375rem !important;
}

.me-n220 {
  margin-right: -13.75rem !important;
}

.me-n225 {
  margin-right: -14.0625rem !important;
}

.me-n230 {
  margin-right: -14.375rem !important;
}

.me-n235 {
  margin-right: -14.6875rem !important;
}

.me-n240 {
  margin-right: -15rem !important;
}

.me-n245 {
  margin-right: -15.3125rem !important;
}

.me-n250 {
  margin-right: -15.625rem !important;
}

.me-n255 {
  margin-right: -15.9375rem !important;
}

.me-n260 {
  margin-right: -16.25rem !important;
}

.me-n265 {
  margin-right: -16.5625rem !important;
}

.me-n270 {
  margin-right: -16.875rem !important;
}

.me-n275 {
  margin-right: -17.1875rem !important;
}

.me-n280 {
  margin-right: -17.5rem !important;
}

.me-n285 {
  margin-right: -17.8125rem !important;
}

.me-n290 {
  margin-right: -18.125rem !important;
}

.me-n295 {
  margin-right: -18.4375rem !important;
}

.me-n300 {
  margin-right: -18.75rem !important;
}

.mb-n5 {
  margin-bottom: -0.313rem !important;
}

.mb-n10 {
  margin-bottom: -0.625rem !important;
}

.mb-n15 {
  margin-bottom: -0.938rem !important;
}

.mb-n20 {
  margin-bottom: -1.25rem !important;
}

.mb-n25 {
  margin-bottom: -1.563rem !important;
}

.mb-n30 {
  margin-bottom: -1.875rem !important;
}

.mb-n35 {
  margin-bottom: -2.188rem !important;
}

.mb-n40 {
  margin-bottom: -2.5rem !important;
}

.mb-n45 {
  margin-bottom: -2.813rem !important;
}

.mb-n50 {
  margin-bottom: -3.125rem !important;
}

.mb-n55 {
  margin-bottom: -3.438rem !important;
}

.mb-n60 {
  margin-bottom: -3.75rem !important;
}

.mb-n65 {
  margin-bottom: -4.063rem !important;
}

.mb-n70 {
  margin-bottom: -4.375rem !important;
}

.mb-n75 {
  margin-bottom: -4.688rem !important;
}

.mb-n80 {
  margin-bottom: -5rem !important;
}

.mb-n85 {
  margin-bottom: -5.313rem !important;
}

.mb-n90 {
  margin-bottom: -5.625rem !important;
}

.mb-n95 {
  margin-bottom: -5.938rem !important;
}

.mb-n100 {
  margin-bottom: -6.25rem !important;
}

.mb-n105 {
  margin-bottom: -6.5625rem !important;
}

.mb-n110 {
  margin-bottom: -6.875rem !important;
}

.mb-n115 {
  margin-bottom: -7.1875rem !important;
}

.mb-n120 {
  margin-bottom: -7.5rem !important;
}

.mb-n125 {
  margin-bottom: -7.8125rem !important;
}

.mb-n130 {
  margin-bottom: -8.125rem !important;
}

.mb-n135 {
  margin-bottom: -8.4375rem !important;
}

.mb-n140 {
  margin-bottom: -8.75rem !important;
}

.mb-n145 {
  margin-bottom: -9.0625rem !important;
}

.mb-n150 {
  margin-bottom: -9.375rem !important;
}

.mb-n155 {
  margin-bottom: -9.6875rem !important;
}

.mb-n160 {
  margin-bottom: -10rem !important;
}

.mb-n165 {
  margin-bottom: -10.3125rem !important;
}

.mb-n170 {
  margin-bottom: -10.625rem !important;
}

.mb-n175 {
  margin-bottom: -10.9375rem !important;
}

.mb-n180 {
  margin-bottom: -11.25rem !important;
}

.mb-n185 {
  margin-bottom: -11.5625rem !important;
}

.mb-n190 {
  margin-bottom: -11.875rem !important;
}

.mb-n195 {
  margin-bottom: -12.1875rem !important;
}

.mb-n200 {
  margin-bottom: -12.5rem !important;
}

.mb-n205 {
  margin-bottom: -12.8125rem !important;
}

.mb-n210 {
  margin-bottom: -13.125rem !important;
}

.mb-n215 {
  margin-bottom: -13.4375rem !important;
}

.mb-n220 {
  margin-bottom: -13.75rem !important;
}

.mb-n225 {
  margin-bottom: -14.0625rem !important;
}

.mb-n230 {
  margin-bottom: -14.375rem !important;
}

.mb-n235 {
  margin-bottom: -14.6875rem !important;
}

.mb-n240 {
  margin-bottom: -15rem !important;
}

.mb-n245 {
  margin-bottom: -15.3125rem !important;
}

.mb-n250 {
  margin-bottom: -15.625rem !important;
}

.mb-n255 {
  margin-bottom: -15.9375rem !important;
}

.mb-n260 {
  margin-bottom: -16.25rem !important;
}

.mb-n265 {
  margin-bottom: -16.5625rem !important;
}

.mb-n270 {
  margin-bottom: -16.875rem !important;
}

.mb-n275 {
  margin-bottom: -17.1875rem !important;
}

.mb-n280 {
  margin-bottom: -17.5rem !important;
}

.mb-n285 {
  margin-bottom: -17.8125rem !important;
}

.mb-n290 {
  margin-bottom: -18.125rem !important;
}

.mb-n295 {
  margin-bottom: -18.4375rem !important;
}

.mb-n300 {
  margin-bottom: -18.75rem !important;
}

.ms-n5 {
  margin-left: -0.313rem !important;
}

.ms-n10 {
  margin-left: -0.625rem !important;
}

.ms-n15 {
  margin-left: -0.938rem !important;
}

.ms-n20 {
  margin-left: -1.25rem !important;
}

.ms-n25 {
  margin-left: -1.563rem !important;
}

.ms-n30 {
  margin-left: -1.875rem !important;
}

.ms-n35 {
  margin-left: -2.188rem !important;
}

.ms-n40 {
  margin-left: -2.5rem !important;
}

.ms-n45 {
  margin-left: -2.813rem !important;
}

.ms-n50 {
  margin-left: -3.125rem !important;
}

.ms-n55 {
  margin-left: -3.438rem !important;
}

.ms-n60 {
  margin-left: -3.75rem !important;
}

.ms-n65 {
  margin-left: -4.063rem !important;
}

.ms-n70 {
  margin-left: -4.375rem !important;
}

.ms-n75 {
  margin-left: -4.688rem !important;
}

.ms-n80 {
  margin-left: -5rem !important;
}

.ms-n85 {
  margin-left: -5.313rem !important;
}

.ms-n90 {
  margin-left: -5.625rem !important;
}

.ms-n95 {
  margin-left: -5.938rem !important;
}

.ms-n100 {
  margin-left: -6.25rem !important;
}

.ms-n105 {
  margin-left: -6.5625rem !important;
}

.ms-n110 {
  margin-left: -6.875rem !important;
}

.ms-n115 {
  margin-left: -7.1875rem !important;
}

.ms-n120 {
  margin-left: -7.5rem !important;
}

.ms-n125 {
  margin-left: -7.8125rem !important;
}

.ms-n130 {
  margin-left: -8.125rem !important;
}

.ms-n135 {
  margin-left: -8.4375rem !important;
}

.ms-n140 {
  margin-left: -8.75rem !important;
}

.ms-n145 {
  margin-left: -9.0625rem !important;
}

.ms-n150 {
  margin-left: -9.375rem !important;
}

.ms-n155 {
  margin-left: -9.6875rem !important;
}

.ms-n160 {
  margin-left: -10rem !important;
}

.ms-n165 {
  margin-left: -10.3125rem !important;
}

.ms-n170 {
  margin-left: -10.625rem !important;
}

.ms-n175 {
  margin-left: -10.9375rem !important;
}

.ms-n180 {
  margin-left: -11.25rem !important;
}

.ms-n185 {
  margin-left: -11.5625rem !important;
}

.ms-n190 {
  margin-left: -11.875rem !important;
}

.ms-n195 {
  margin-left: -12.1875rem !important;
}

.ms-n200 {
  margin-left: -12.5rem !important;
}

.ms-n205 {
  margin-left: -12.8125rem !important;
}

.ms-n210 {
  margin-left: -13.125rem !important;
}

.ms-n215 {
  margin-left: -13.4375rem !important;
}

.ms-n220 {
  margin-left: -13.75rem !important;
}

.ms-n225 {
  margin-left: -14.0625rem !important;
}

.ms-n230 {
  margin-left: -14.375rem !important;
}

.ms-n235 {
  margin-left: -14.6875rem !important;
}

.ms-n240 {
  margin-left: -15rem !important;
}

.ms-n245 {
  margin-left: -15.3125rem !important;
}

.ms-n250 {
  margin-left: -15.625rem !important;
}

.ms-n255 {
  margin-left: -15.9375rem !important;
}

.ms-n260 {
  margin-left: -16.25rem !important;
}

.ms-n265 {
  margin-left: -16.5625rem !important;
}

.ms-n270 {
  margin-left: -16.875rem !important;
}

.ms-n275 {
  margin-left: -17.1875rem !important;
}

.ms-n280 {
  margin-left: -17.5rem !important;
}

.ms-n285 {
  margin-left: -17.8125rem !important;
}

.ms-n290 {
  margin-left: -18.125rem !important;
}

.ms-n295 {
  margin-left: -18.4375rem !important;
}

.ms-n300 {
  margin-left: -18.75rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-5 {
  padding: 0.313rem !important;
}

.p-10 {
  padding: 0.625rem !important;
}

.p-15 {
  padding: 0.938rem !important;
}

.p-20 {
  padding: 1.25rem !important;
}

.p-25 {
  padding: 1.563rem !important;
}

.p-30 {
  padding: 1.875rem !important;
}

.p-35 {
  padding: 2.188rem !important;
}

.p-40 {
  padding: 2.5rem !important;
}

.p-45 {
  padding: 2.813rem !important;
}

.p-50 {
  padding: 3.125rem !important;
}

.p-55 {
  padding: 3.438rem !important;
}

.p-60 {
  padding: 3.75rem !important;
}

.p-65 {
  padding: 4.063rem !important;
}

.p-70 {
  padding: 4.375rem !important;
}

.p-75 {
  padding: 4.688rem !important;
}

.p-80 {
  padding: 5rem !important;
}

.p-85 {
  padding: 5.313rem !important;
}

.p-90 {
  padding: 5.625rem !important;
}

.p-95 {
  padding: 5.938rem !important;
}

.p-100 {
  padding: 6.25rem !important;
}

.p-105 {
  padding: 6.5625rem !important;
}

.p-110 {
  padding: 6.875rem !important;
}

.p-115 {
  padding: 7.1875rem !important;
}

.p-120 {
  padding: 7.5rem !important;
}

.p-125 {
  padding: 7.8125rem !important;
}

.p-130 {
  padding: 8.125rem !important;
}

.p-135 {
  padding: 8.4375rem !important;
}

.p-140 {
  padding: 8.75rem !important;
}

.p-145 {
  padding: 9.0625rem !important;
}

.p-150 {
  padding: 9.375rem !important;
}

.p-155 {
  padding: 9.6875rem !important;
}

.p-160 {
  padding: 10rem !important;
}

.p-165 {
  padding: 10.3125rem !important;
}

.p-170 {
  padding: 10.625rem !important;
}

.p-175 {
  padding: 10.9375rem !important;
}

.p-180 {
  padding: 11.25rem !important;
}

.p-185 {
  padding: 11.5625rem !important;
}

.p-190 {
  padding: 11.875rem !important;
}

.p-195 {
  padding: 12.1875rem !important;
}

.p-200 {
  padding: 12.5rem !important;
}

.p-205 {
  padding: 12.8125rem !important;
}

.p-210 {
  padding: 13.125rem !important;
}

.p-215 {
  padding: 13.4375rem !important;
}

.p-220 {
  padding: 13.75rem !important;
}

.p-225 {
  padding: 14.0625rem !important;
}

.p-230 {
  padding: 14.375rem !important;
}

.p-235 {
  padding: 14.6875rem !important;
}

.p-240 {
  padding: 15rem !important;
}

.p-245 {
  padding: 15.3125rem !important;
}

.p-250 {
  padding: 15.625rem !important;
}

.p-255 {
  padding: 15.9375rem !important;
}

.p-260 {
  padding: 16.25rem !important;
}

.p-265 {
  padding: 16.5625rem !important;
}

.p-270 {
  padding: 16.875rem !important;
}

.p-275 {
  padding: 17.1875rem !important;
}

.p-280 {
  padding: 17.5rem !important;
}

.p-285 {
  padding: 17.8125rem !important;
}

.p-290 {
  padding: 18.125rem !important;
}

.p-295 {
  padding: 18.4375rem !important;
}

.p-300 {
  padding: 18.75rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-5 {
  padding-right: 0.313rem !important;
  padding-left: 0.313rem !important;
}

.px-10 {
  padding-right: 0.625rem !important;
  padding-left: 0.625rem !important;
}

.px-15 {
  padding-right: 0.938rem !important;
  padding-left: 0.938rem !important;
}

.px-20 {
  padding-right: 1.25rem !important;
  padding-left: 1.25rem !important;
}

.px-25 {
  padding-right: 1.563rem !important;
  padding-left: 1.563rem !important;
}

.px-30 {
  padding-right: 1.875rem !important;
  padding-left: 1.875rem !important;
}

.px-35 {
  padding-right: 2.188rem !important;
  padding-left: 2.188rem !important;
}

.px-40 {
  padding-right: 2.5rem !important;
  padding-left: 2.5rem !important;
}

.px-45 {
  padding-right: 2.813rem !important;
  padding-left: 2.813rem !important;
}

.px-50 {
  padding-right: 3.125rem !important;
  padding-left: 3.125rem !important;
}

.px-55 {
  padding-right: 3.438rem !important;
  padding-left: 3.438rem !important;
}

.px-60 {
  padding-right: 3.75rem !important;
  padding-left: 3.75rem !important;
}

.px-65 {
  padding-right: 4.063rem !important;
  padding-left: 4.063rem !important;
}

.px-70 {
  padding-right: 4.375rem !important;
  padding-left: 4.375rem !important;
}

.px-75 {
  padding-right: 4.688rem !important;
  padding-left: 4.688rem !important;
}

.px-80 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.px-85 {
  padding-right: 5.313rem !important;
  padding-left: 5.313rem !important;
}

.px-90 {
  padding-right: 5.625rem !important;
  padding-left: 5.625rem !important;
}

.px-95 {
  padding-right: 5.938rem !important;
  padding-left: 5.938rem !important;
}

.px-100 {
  padding-right: 6.25rem !important;
  padding-left: 6.25rem !important;
}

.px-105 {
  padding-right: 6.5625rem !important;
  padding-left: 6.5625rem !important;
}

.px-110 {
  padding-right: 6.875rem !important;
  padding-left: 6.875rem !important;
}

.px-115 {
  padding-right: 7.1875rem !important;
  padding-left: 7.1875rem !important;
}

.px-120 {
  padding-right: 7.5rem !important;
  padding-left: 7.5rem !important;
}

.px-125 {
  padding-right: 7.8125rem !important;
  padding-left: 7.8125rem !important;
}

.px-130 {
  padding-right: 8.125rem !important;
  padding-left: 8.125rem !important;
}

.px-135 {
  padding-right: 8.4375rem !important;
  padding-left: 8.4375rem !important;
}

.px-140 {
  padding-right: 8.75rem !important;
  padding-left: 8.75rem !important;
}

.px-145 {
  padding-right: 9.0625rem !important;
  padding-left: 9.0625rem !important;
}

.px-150 {
  padding-right: 9.375rem !important;
  padding-left: 9.375rem !important;
}

.px-155 {
  padding-right: 9.6875rem !important;
  padding-left: 9.6875rem !important;
}

.px-160 {
  padding-right: 10rem !important;
  padding-left: 10rem !important;
}

.px-165 {
  padding-right: 10.3125rem !important;
  padding-left: 10.3125rem !important;
}

.px-170 {
  padding-right: 10.625rem !important;
  padding-left: 10.625rem !important;
}

.px-175 {
  padding-right: 10.9375rem !important;
  padding-left: 10.9375rem !important;
}

.px-180 {
  padding-right: 11.25rem !important;
  padding-left: 11.25rem !important;
}

.px-185 {
  padding-right: 11.5625rem !important;
  padding-left: 11.5625rem !important;
}

.px-190 {
  padding-right: 11.875rem !important;
  padding-left: 11.875rem !important;
}

.px-195 {
  padding-right: 12.1875rem !important;
  padding-left: 12.1875rem !important;
}

.px-200 {
  padding-right: 12.5rem !important;
  padding-left: 12.5rem !important;
}

.px-205 {
  padding-right: 12.8125rem !important;
  padding-left: 12.8125rem !important;
}

.px-210 {
  padding-right: 13.125rem !important;
  padding-left: 13.125rem !important;
}

.px-215 {
  padding-right: 13.4375rem !important;
  padding-left: 13.4375rem !important;
}

.px-220 {
  padding-right: 13.75rem !important;
  padding-left: 13.75rem !important;
}

.px-225 {
  padding-right: 14.0625rem !important;
  padding-left: 14.0625rem !important;
}

.px-230 {
  padding-right: 14.375rem !important;
  padding-left: 14.375rem !important;
}

.px-235 {
  padding-right: 14.6875rem !important;
  padding-left: 14.6875rem !important;
}

.px-240 {
  padding-right: 15rem !important;
  padding-left: 15rem !important;
}

.px-245 {
  padding-right: 15.3125rem !important;
  padding-left: 15.3125rem !important;
}

.px-250 {
  padding-right: 15.625rem !important;
  padding-left: 15.625rem !important;
}

.px-255 {
  padding-right: 15.9375rem !important;
  padding-left: 15.9375rem !important;
}

.px-260 {
  padding-right: 16.25rem !important;
  padding-left: 16.25rem !important;
}

.px-265 {
  padding-right: 16.5625rem !important;
  padding-left: 16.5625rem !important;
}

.px-270 {
  padding-right: 16.875rem !important;
  padding-left: 16.875rem !important;
}

.px-275 {
  padding-right: 17.1875rem !important;
  padding-left: 17.1875rem !important;
}

.px-280 {
  padding-right: 17.5rem !important;
  padding-left: 17.5rem !important;
}

.px-285 {
  padding-right: 17.8125rem !important;
  padding-left: 17.8125rem !important;
}

.px-290 {
  padding-right: 18.125rem !important;
  padding-left: 18.125rem !important;
}

.px-295 {
  padding-right: 18.4375rem !important;
  padding-left: 18.4375rem !important;
}

.px-300 {
  padding-right: 18.75rem !important;
  padding-left: 18.75rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-5 {
  padding-top: 0.313rem !important;
  padding-bottom: 0.313rem !important;
}

.py-10 {
  padding-top: 0.625rem !important;
  padding-bottom: 0.625rem !important;
}

.py-15 {
  padding-top: 0.938rem !important;
  padding-bottom: 0.938rem !important;
}

.py-20 {
  padding-top: 1.25rem !important;
  padding-bottom: 1.25rem !important;
}

.py-25 {
  padding-top: 1.563rem !important;
  padding-bottom: 1.563rem !important;
}

.py-30 {
  padding-top: 1.875rem !important;
  padding-bottom: 1.875rem !important;
}

.py-35 {
  padding-top: 2.188rem !important;
  padding-bottom: 2.188rem !important;
}

.py-40 {
  padding-top: 2.5rem !important;
  padding-bottom: 2.5rem !important;
}

.py-45 {
  padding-top: 2.813rem !important;
  padding-bottom: 2.813rem !important;
}

.py-50 {
  padding-top: 3.125rem !important;
  padding-bottom: 3.125rem !important;
}

.py-55 {
  padding-top: 3.438rem !important;
  padding-bottom: 3.438rem !important;
}

.py-60 {
  padding-top: 3.75rem !important;
  padding-bottom: 3.75rem !important;
}

.py-65 {
  padding-top: 4.063rem !important;
  padding-bottom: 4.063rem !important;
}

.py-70 {
  padding-top: 4.375rem !important;
  padding-bottom: 4.375rem !important;
}

.py-75 {
  padding-top: 4.688rem !important;
  padding-bottom: 4.688rem !important;
}

.py-80 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.py-85 {
  padding-top: 5.313rem !important;
  padding-bottom: 5.313rem !important;
}

.py-90 {
  padding-top: 5.625rem !important;
  padding-bottom: 5.625rem !important;
}

.py-95 {
  padding-top: 5.938rem !important;
  padding-bottom: 5.938rem !important;
}

.py-100 {
  padding-top: 6.25rem !important;
  padding-bottom: 6.25rem !important;
}

.py-105 {
  padding-top: 6.5625rem !important;
  padding-bottom: 6.5625rem !important;
}

.py-110 {
  padding-top: 6.875rem !important;
  padding-bottom: 6.875rem !important;
}

.py-115 {
  padding-top: 7.1875rem !important;
  padding-bottom: 7.1875rem !important;
}

.py-120 {
  padding-top: 7.5rem !important;
  padding-bottom: 7.5rem !important;
}

.py-125 {
  padding-top: 7.8125rem !important;
  padding-bottom: 7.8125rem !important;
}

.py-130 {
  padding-top: 8.125rem !important;
  padding-bottom: 8.125rem !important;
}

.py-135 {
  padding-top: 8.4375rem !important;
  padding-bottom: 8.4375rem !important;
}

.py-140 {
  padding-top: 8.75rem !important;
  padding-bottom: 8.75rem !important;
}

.py-145 {
  padding-top: 9.0625rem !important;
  padding-bottom: 9.0625rem !important;
}

.py-150 {
  padding-top: 9.375rem !important;
  padding-bottom: 9.375rem !important;
}

.py-155 {
  padding-top: 9.6875rem !important;
  padding-bottom: 9.6875rem !important;
}

.py-160 {
  padding-top: 10rem !important;
  padding-bottom: 10rem !important;
}

.py-165 {
  padding-top: 10.3125rem !important;
  padding-bottom: 10.3125rem !important;
}

.py-170 {
  padding-top: 10.625rem !important;
  padding-bottom: 10.625rem !important;
}

.py-175 {
  padding-top: 10.9375rem !important;
  padding-bottom: 10.9375rem !important;
}

.py-180 {
  padding-top: 11.25rem !important;
  padding-bottom: 11.25rem !important;
}

.py-185 {
  padding-top: 11.5625rem !important;
  padding-bottom: 11.5625rem !important;
}

.py-190 {
  padding-top: 11.875rem !important;
  padding-bottom: 11.875rem !important;
}

.py-195 {
  padding-top: 12.1875rem !important;
  padding-bottom: 12.1875rem !important;
}

.py-200 {
  padding-top: 12.5rem !important;
  padding-bottom: 12.5rem !important;
}

.py-205 {
  padding-top: 12.8125rem !important;
  padding-bottom: 12.8125rem !important;
}

.py-210 {
  padding-top: 13.125rem !important;
  padding-bottom: 13.125rem !important;
}

.py-215 {
  padding-top: 13.4375rem !important;
  padding-bottom: 13.4375rem !important;
}

.py-220 {
  padding-top: 13.75rem !important;
  padding-bottom: 13.75rem !important;
}

.py-225 {
  padding-top: 14.0625rem !important;
  padding-bottom: 14.0625rem !important;
}

.py-230 {
  padding-top: 14.375rem !important;
  padding-bottom: 14.375rem !important;
}

.py-235 {
  padding-top: 14.6875rem !important;
  padding-bottom: 14.6875rem !important;
}

.py-240 {
  padding-top: 15rem !important;
  padding-bottom: 15rem !important;
}

.py-245 {
  padding-top: 15.3125rem !important;
  padding-bottom: 15.3125rem !important;
}

.py-250 {
  padding-top: 15.625rem !important;
  padding-bottom: 15.625rem !important;
}

.py-255 {
  padding-top: 15.9375rem !important;
  padding-bottom: 15.9375rem !important;
}

.py-260 {
  padding-top: 16.25rem !important;
  padding-bottom: 16.25rem !important;
}

.py-265 {
  padding-top: 16.5625rem !important;
  padding-bottom: 16.5625rem !important;
}

.py-270 {
  padding-top: 16.875rem !important;
  padding-bottom: 16.875rem !important;
}

.py-275 {
  padding-top: 17.1875rem !important;
  padding-bottom: 17.1875rem !important;
}

.py-280 {
  padding-top: 17.5rem !important;
  padding-bottom: 17.5rem !important;
}

.py-285 {
  padding-top: 17.8125rem !important;
  padding-bottom: 17.8125rem !important;
}

.py-290 {
  padding-top: 18.125rem !important;
  padding-bottom: 18.125rem !important;
}

.py-295 {
  padding-top: 18.4375rem !important;
  padding-bottom: 18.4375rem !important;
}

.py-300 {
  padding-top: 18.75rem !important;
  padding-bottom: 18.75rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-5 {
  padding-top: 0.313rem !important;
}

.pt-10 {
  padding-top: 0.625rem !important;
}

.pt-15 {
  padding-top: 0.938rem !important;
}

.pt-20 {
  padding-top: 1.25rem !important;
}

.pt-25 {
  padding-top: 1.563rem !important;
}

.pt-30 {
  padding-top: 1.875rem !important;
}

.pt-35 {
  padding-top: 2.188rem !important;
}

.pt-40 {
  padding-top: 2.5rem !important;
}

.pt-45 {
  padding-top: 2.813rem !important;
}

.pt-50 {
  padding-top: 3.125rem !important;
}

.pt-55 {
  padding-top: 3.438rem !important;
}

.pt-60 {
  padding-top: 3.75rem !important;
}

.pt-65 {
  padding-top: 4.063rem !important;
}

.pt-70 {
  padding-top: 4.375rem !important;
}

.pt-75 {
  padding-top: 4.688rem !important;
}

.pt-80 {
  padding-top: 5rem !important;
}

.pt-85 {
  padding-top: 5.313rem !important;
}

.pt-90 {
  padding-top: 5.625rem !important;
}

.pt-95 {
  padding-top: 5.938rem !important;
}

.pt-100 {
  padding-top: 6.25rem !important;
}

.pt-105 {
  padding-top: 6.5625rem !important;
}

.pt-110 {
  padding-top: 6.875rem !important;
}

.pt-115 {
  padding-top: 7.1875rem !important;
}

.pt-120 {
  padding-top: 7.5rem !important;
}

.pt-125 {
  padding-top: 7.8125rem !important;
}

.pt-130 {
  padding-top: 8.125rem !important;
}

.pt-135 {
  padding-top: 8.4375rem !important;
}

.pt-140 {
  padding-top: 8.75rem !important;
}

.pt-145 {
  padding-top: 9.0625rem !important;
}

.pt-150 {
  padding-top: 9.375rem !important;
}

.pt-155 {
  padding-top: 9.6875rem !important;
}

.pt-160 {
  padding-top: 10rem !important;
}

.pt-165 {
  padding-top: 10.3125rem !important;
}

.pt-170 {
  padding-top: 10.625rem !important;
}

.pt-175 {
  padding-top: 10.9375rem !important;
}

.pt-180 {
  padding-top: 11.25rem !important;
}

.pt-185 {
  padding-top: 11.5625rem !important;
}

.pt-190 {
  padding-top: 11.875rem !important;
}

.pt-195 {
  padding-top: 12.1875rem !important;
}

.pt-200 {
  padding-top: 12.5rem !important;
}

.pt-205 {
  padding-top: 12.8125rem !important;
}

.pt-210 {
  padding-top: 13.125rem !important;
}

.pt-215 {
  padding-top: 13.4375rem !important;
}

.pt-220 {
  padding-top: 13.75rem !important;
}

.pt-225 {
  padding-top: 14.0625rem !important;
}

.pt-230 {
  padding-top: 14.375rem !important;
}

.pt-235 {
  padding-top: 14.6875rem !important;
}

.pt-240 {
  padding-top: 15rem !important;
}

.pt-245 {
  padding-top: 15.3125rem !important;
}

.pt-250 {
  padding-top: 15.625rem !important;
}

.pt-255 {
  padding-top: 15.9375rem !important;
}

.pt-260 {
  padding-top: 16.25rem !important;
}

.pt-265 {
  padding-top: 16.5625rem !important;
}

.pt-270 {
  padding-top: 16.875rem !important;
}

.pt-275 {
  padding-top: 17.1875rem !important;
}

.pt-280 {
  padding-top: 17.5rem !important;
}

.pt-285 {
  padding-top: 17.8125rem !important;
}

.pt-290 {
  padding-top: 18.125rem !important;
}

.pt-295 {
  padding-top: 18.4375rem !important;
}

.pt-300 {
  padding-top: 18.75rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-5 {
  padding-right: 0.313rem !important;
}

.pe-10 {
  padding-right: 0.625rem !important;
}

.pe-15 {
  padding-right: 0.938rem !important;
}

.pe-20 {
  padding-right: 1.25rem !important;
}

.pe-25 {
  padding-right: 1.563rem !important;
}

.pe-30 {
  padding-right: 1.875rem !important;
}

.pe-35 {
  padding-right: 2.188rem !important;
}

.pe-40 {
  padding-right: 2.5rem !important;
}

.pe-45 {
  padding-right: 2.813rem !important;
}

.pe-50 {
  padding-right: 3.125rem !important;
}

.pe-55 {
  padding-right: 3.438rem !important;
}

.pe-60 {
  padding-right: 3.75rem !important;
}

.pe-65 {
  padding-right: 4.063rem !important;
}

.pe-70 {
  padding-right: 4.375rem !important;
}

.pe-75 {
  padding-right: 4.688rem !important;
}

.pe-80 {
  padding-right: 5rem !important;
}

.pe-85 {
  padding-right: 5.313rem !important;
}

.pe-90 {
  padding-right: 5.625rem !important;
}

.pe-95 {
  padding-right: 5.938rem !important;
}

.pe-100 {
  padding-right: 6.25rem !important;
}

.pe-105 {
  padding-right: 6.5625rem !important;
}

.pe-110 {
  padding-right: 6.875rem !important;
}

.pe-115 {
  padding-right: 7.1875rem !important;
}

.pe-120 {
  padding-right: 7.5rem !important;
}

.pe-125 {
  padding-right: 7.8125rem !important;
}

.pe-130 {
  padding-right: 8.125rem !important;
}

.pe-135 {
  padding-right: 8.4375rem !important;
}

.pe-140 {
  padding-right: 8.75rem !important;
}

.pe-145 {
  padding-right: 9.0625rem !important;
}

.pe-150 {
  padding-right: 9.375rem !important;
}

.pe-155 {
  padding-right: 9.6875rem !important;
}

.pe-160 {
  padding-right: 10rem !important;
}

.pe-165 {
  padding-right: 10.3125rem !important;
}

.pe-170 {
  padding-right: 10.625rem !important;
}

.pe-175 {
  padding-right: 10.9375rem !important;
}

.pe-180 {
  padding-right: 11.25rem !important;
}

.pe-185 {
  padding-right: 11.5625rem !important;
}

.pe-190 {
  padding-right: 11.875rem !important;
}

.pe-195 {
  padding-right: 12.1875rem !important;
}

.pe-200 {
  padding-right: 12.5rem !important;
}

.pe-205 {
  padding-right: 12.8125rem !important;
}

.pe-210 {
  padding-right: 13.125rem !important;
}

.pe-215 {
  padding-right: 13.4375rem !important;
}

.pe-220 {
  padding-right: 13.75rem !important;
}

.pe-225 {
  padding-right: 14.0625rem !important;
}

.pe-230 {
  padding-right: 14.375rem !important;
}

.pe-235 {
  padding-right: 14.6875rem !important;
}

.pe-240 {
  padding-right: 15rem !important;
}

.pe-245 {
  padding-right: 15.3125rem !important;
}

.pe-250 {
  padding-right: 15.625rem !important;
}

.pe-255 {
  padding-right: 15.9375rem !important;
}

.pe-260 {
  padding-right: 16.25rem !important;
}

.pe-265 {
  padding-right: 16.5625rem !important;
}

.pe-270 {
  padding-right: 16.875rem !important;
}

.pe-275 {
  padding-right: 17.1875rem !important;
}

.pe-280 {
  padding-right: 17.5rem !important;
}

.pe-285 {
  padding-right: 17.8125rem !important;
}

.pe-290 {
  padding-right: 18.125rem !important;
}

.pe-295 {
  padding-right: 18.4375rem !important;
}

.pe-300 {
  padding-right: 18.75rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-5 {
  padding-bottom: 0.313rem !important;
}

.pb-10 {
  padding-bottom: 0.625rem !important;
}

.pb-15 {
  padding-bottom: 0.938rem !important;
}

.pb-20 {
  padding-bottom: 1.25rem !important;
}

.pb-25 {
  padding-bottom: 1.563rem !important;
}

.pb-30 {
  padding-bottom: 1.875rem !important;
}

.pb-35 {
  padding-bottom: 2.188rem !important;
}

.pb-40 {
  padding-bottom: 2.5rem !important;
}

.pb-45 {
  padding-bottom: 2.813rem !important;
}

.pb-50 {
  padding-bottom: 3.125rem !important;
}

.pb-55 {
  padding-bottom: 3.438rem !important;
}

.pb-60 {
  padding-bottom: 3.75rem !important;
}

.pb-65 {
  padding-bottom: 4.063rem !important;
}

.pb-70 {
  padding-bottom: 4.375rem !important;
}

.pb-75 {
  padding-bottom: 4.688rem !important;
}

.pb-80 {
  padding-bottom: 5rem !important;
}

.pb-85 {
  padding-bottom: 5.313rem !important;
}

.pb-90 {
  padding-bottom: 5.625rem !important;
}

.pb-95 {
  padding-bottom: 5.938rem !important;
}

.pb-100 {
  padding-bottom: 6.25rem !important;
}

.pb-105 {
  padding-bottom: 6.5625rem !important;
}

.pb-110 {
  padding-bottom: 6.875rem !important;
}

.pb-115 {
  padding-bottom: 7.1875rem !important;
}

.pb-120 {
  padding-bottom: 7.5rem !important;
}

.pb-125 {
  padding-bottom: 7.8125rem !important;
}

.pb-130 {
  padding-bottom: 8.125rem !important;
}

.pb-135 {
  padding-bottom: 8.4375rem !important;
}

.pb-140 {
  padding-bottom: 8.75rem !important;
}

.pb-145 {
  padding-bottom: 9.0625rem !important;
}

.pb-150 {
  padding-bottom: 9.375rem !important;
}

.pb-155 {
  padding-bottom: 9.6875rem !important;
}

.pb-160 {
  padding-bottom: 10rem !important;
}

.pb-165 {
  padding-bottom: 10.3125rem !important;
}

.pb-170 {
  padding-bottom: 10.625rem !important;
}

.pb-175 {
  padding-bottom: 10.9375rem !important;
}

.pb-180 {
  padding-bottom: 11.25rem !important;
}

.pb-185 {
  padding-bottom: 11.5625rem !important;
}

.pb-190 {
  padding-bottom: 11.875rem !important;
}

.pb-195 {
  padding-bottom: 12.1875rem !important;
}

.pb-200 {
  padding-bottom: 12.5rem !important;
}

.pb-205 {
  padding-bottom: 12.8125rem !important;
}

.pb-210 {
  padding-bottom: 13.125rem !important;
}

.pb-215 {
  padding-bottom: 13.4375rem !important;
}

.pb-220 {
  padding-bottom: 13.75rem !important;
}

.pb-225 {
  padding-bottom: 14.0625rem !important;
}

.pb-230 {
  padding-bottom: 14.375rem !important;
}

.pb-235 {
  padding-bottom: 14.6875rem !important;
}

.pb-240 {
  padding-bottom: 15rem !important;
}

.pb-245 {
  padding-bottom: 15.3125rem !important;
}

.pb-250 {
  padding-bottom: 15.625rem !important;
}

.pb-255 {
  padding-bottom: 15.9375rem !important;
}

.pb-260 {
  padding-bottom: 16.25rem !important;
}

.pb-265 {
  padding-bottom: 16.5625rem !important;
}

.pb-270 {
  padding-bottom: 16.875rem !important;
}

.pb-275 {
  padding-bottom: 17.1875rem !important;
}

.pb-280 {
  padding-bottom: 17.5rem !important;
}

.pb-285 {
  padding-bottom: 17.8125rem !important;
}

.pb-290 {
  padding-bottom: 18.125rem !important;
}

.pb-295 {
  padding-bottom: 18.4375rem !important;
}

.pb-300 {
  padding-bottom: 18.75rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-5 {
  padding-left: 0.313rem !important;
}

.ps-10 {
  padding-left: 0.625rem !important;
}

.ps-15 {
  padding-left: 0.938rem !important;
}

.ps-20 {
  padding-left: 1.25rem !important;
}

.ps-25 {
  padding-left: 1.563rem !important;
}

.ps-30 {
  padding-left: 1.875rem !important;
}

.ps-35 {
  padding-left: 2.188rem !important;
}

.ps-40 {
  padding-left: 2.5rem !important;
}

.ps-45 {
  padding-left: 2.813rem !important;
}

.ps-50 {
  padding-left: 3.125rem !important;
}

.ps-55 {
  padding-left: 3.438rem !important;
}

.ps-60 {
  padding-left: 3.75rem !important;
}

.ps-65 {
  padding-left: 4.063rem !important;
}

.ps-70 {
  padding-left: 4.375rem !important;
}

.ps-75 {
  padding-left: 4.688rem !important;
}

.ps-80 {
  padding-left: 5rem !important;
}

.ps-85 {
  padding-left: 5.313rem !important;
}

.ps-90 {
  padding-left: 5.625rem !important;
}

.ps-95 {
  padding-left: 5.938rem !important;
}

.ps-100 {
  padding-left: 6.25rem !important;
}

.ps-105 {
  padding-left: 6.5625rem !important;
}

.ps-110 {
  padding-left: 6.875rem !important;
}

.ps-115 {
  padding-left: 7.1875rem !important;
}

.ps-120 {
  padding-left: 7.5rem !important;
}

.ps-125 {
  padding-left: 7.8125rem !important;
}

.ps-130 {
  padding-left: 8.125rem !important;
}

.ps-135 {
  padding-left: 8.4375rem !important;
}

.ps-140 {
  padding-left: 8.75rem !important;
}

.ps-145 {
  padding-left: 9.0625rem !important;
}

.ps-150 {
  padding-left: 9.375rem !important;
}

.ps-155 {
  padding-left: 9.6875rem !important;
}

.ps-160 {
  padding-left: 10rem !important;
}

.ps-165 {
  padding-left: 10.3125rem !important;
}

.ps-170 {
  padding-left: 10.625rem !important;
}

.ps-175 {
  padding-left: 10.9375rem !important;
}

.ps-180 {
  padding-left: 11.25rem !important;
}

.ps-185 {
  padding-left: 11.5625rem !important;
}

.ps-190 {
  padding-left: 11.875rem !important;
}

.ps-195 {
  padding-left: 12.1875rem !important;
}

.ps-200 {
  padding-left: 12.5rem !important;
}

.ps-205 {
  padding-left: 12.8125rem !important;
}

.ps-210 {
  padding-left: 13.125rem !important;
}

.ps-215 {
  padding-left: 13.4375rem !important;
}

.ps-220 {
  padding-left: 13.75rem !important;
}

.ps-225 {
  padding-left: 14.0625rem !important;
}

.ps-230 {
  padding-left: 14.375rem !important;
}

.ps-235 {
  padding-left: 14.6875rem !important;
}

.ps-240 {
  padding-left: 15rem !important;
}

.ps-245 {
  padding-left: 15.3125rem !important;
}

.ps-250 {
  padding-left: 15.625rem !important;
}

.ps-255 {
  padding-left: 15.9375rem !important;
}

.ps-260 {
  padding-left: 16.25rem !important;
}

.ps-265 {
  padding-left: 16.5625rem !important;
}

.ps-270 {
  padding-left: 16.875rem !important;
}

.ps-275 {
  padding-left: 17.1875rem !important;
}

.ps-280 {
  padding-left: 17.5rem !important;
}

.ps-285 {
  padding-left: 17.8125rem !important;
}

.ps-290 {
  padding-left: 18.125rem !important;
}

.ps-295 {
  padding-left: 18.4375rem !important;
}

.ps-300 {
  padding-left: 18.75rem !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-5 {
  gap: 0.313rem !important;
}

.gap-10 {
  gap: 0.625rem !important;
}

.gap-15 {
  gap: 0.938rem !important;
}

.gap-20 {
  gap: 1.25rem !important;
}

.gap-25 {
  gap: 1.563rem !important;
}

.gap-30 {
  gap: 1.875rem !important;
}

.gap-35 {
  gap: 2.188rem !important;
}

.gap-40 {
  gap: 2.5rem !important;
}

.gap-45 {
  gap: 2.813rem !important;
}

.gap-50 {
  gap: 3.125rem !important;
}

.gap-55 {
  gap: 3.438rem !important;
}

.gap-60 {
  gap: 3.75rem !important;
}

.gap-65 {
  gap: 4.063rem !important;
}

.gap-70 {
  gap: 4.375rem !important;
}

.gap-75 {
  gap: 4.688rem !important;
}

.gap-80 {
  gap: 5rem !important;
}

.gap-85 {
  gap: 5.313rem !important;
}

.gap-90 {
  gap: 5.625rem !important;
}

.gap-95 {
  gap: 5.938rem !important;
}

.gap-100 {
  gap: 6.25rem !important;
}

.gap-105 {
  gap: 6.5625rem !important;
}

.gap-110 {
  gap: 6.875rem !important;
}

.gap-115 {
  gap: 7.1875rem !important;
}

.gap-120 {
  gap: 7.5rem !important;
}

.gap-125 {
  gap: 7.8125rem !important;
}

.gap-130 {
  gap: 8.125rem !important;
}

.gap-135 {
  gap: 8.4375rem !important;
}

.gap-140 {
  gap: 8.75rem !important;
}

.gap-145 {
  gap: 9.0625rem !important;
}

.gap-150 {
  gap: 9.375rem !important;
}

.gap-155 {
  gap: 9.6875rem !important;
}

.gap-160 {
  gap: 10rem !important;
}

.gap-165 {
  gap: 10.3125rem !important;
}

.gap-170 {
  gap: 10.625rem !important;
}

.gap-175 {
  gap: 10.9375rem !important;
}

.gap-180 {
  gap: 11.25rem !important;
}

.gap-185 {
  gap: 11.5625rem !important;
}

.gap-190 {
  gap: 11.875rem !important;
}

.gap-195 {
  gap: 12.1875rem !important;
}

.gap-200 {
  gap: 12.5rem !important;
}

.gap-205 {
  gap: 12.8125rem !important;
}

.gap-210 {
  gap: 13.125rem !important;
}

.gap-215 {
  gap: 13.4375rem !important;
}

.gap-220 {
  gap: 13.75rem !important;
}

.gap-225 {
  gap: 14.0625rem !important;
}

.gap-230 {
  gap: 14.375rem !important;
}

.gap-235 {
  gap: 14.6875rem !important;
}

.gap-240 {
  gap: 15rem !important;
}

.gap-245 {
  gap: 15.3125rem !important;
}

.gap-250 {
  gap: 15.625rem !important;
}

.gap-255 {
  gap: 15.9375rem !important;
}

.gap-260 {
  gap: 16.25rem !important;
}

.gap-265 {
  gap: 16.5625rem !important;
}

.gap-270 {
  gap: 16.875rem !important;
}

.gap-275 {
  gap: 17.1875rem !important;
}

.gap-280 {
  gap: 17.5rem !important;
}

.gap-285 {
  gap: 17.8125rem !important;
}

.gap-290 {
  gap: 18.125rem !important;
}

.gap-295 {
  gap: 18.4375rem !important;
}

.gap-300 {
  gap: 18.75rem !important;
}

.row-gap-0 {
  row-gap: 0 !important;
}

.row-gap-5 {
  row-gap: 0.313rem !important;
}

.row-gap-10 {
  row-gap: 0.625rem !important;
}

.row-gap-15 {
  row-gap: 0.938rem !important;
}

.row-gap-20 {
  row-gap: 1.25rem !important;
}

.row-gap-25 {
  row-gap: 1.563rem !important;
}

.row-gap-30 {
  row-gap: 1.875rem !important;
}

.row-gap-35 {
  row-gap: 2.188rem !important;
}

.row-gap-40 {
  row-gap: 2.5rem !important;
}

.row-gap-45 {
  row-gap: 2.813rem !important;
}

.row-gap-50 {
  row-gap: 3.125rem !important;
}

.row-gap-55 {
  row-gap: 3.438rem !important;
}

.row-gap-60 {
  row-gap: 3.75rem !important;
}

.row-gap-65 {
  row-gap: 4.063rem !important;
}

.row-gap-70 {
  row-gap: 4.375rem !important;
}

.row-gap-75 {
  row-gap: 4.688rem !important;
}

.row-gap-80 {
  row-gap: 5rem !important;
}

.row-gap-85 {
  row-gap: 5.313rem !important;
}

.row-gap-90 {
  row-gap: 5.625rem !important;
}

.row-gap-95 {
  row-gap: 5.938rem !important;
}

.row-gap-100 {
  row-gap: 6.25rem !important;
}

.row-gap-105 {
  row-gap: 6.5625rem !important;
}

.row-gap-110 {
  row-gap: 6.875rem !important;
}

.row-gap-115 {
  row-gap: 7.1875rem !important;
}

.row-gap-120 {
  row-gap: 7.5rem !important;
}

.row-gap-125 {
  row-gap: 7.8125rem !important;
}

.row-gap-130 {
  row-gap: 8.125rem !important;
}

.row-gap-135 {
  row-gap: 8.4375rem !important;
}

.row-gap-140 {
  row-gap: 8.75rem !important;
}

.row-gap-145 {
  row-gap: 9.0625rem !important;
}

.row-gap-150 {
  row-gap: 9.375rem !important;
}

.row-gap-155 {
  row-gap: 9.6875rem !important;
}

.row-gap-160 {
  row-gap: 10rem !important;
}

.row-gap-165 {
  row-gap: 10.3125rem !important;
}

.row-gap-170 {
  row-gap: 10.625rem !important;
}

.row-gap-175 {
  row-gap: 10.9375rem !important;
}

.row-gap-180 {
  row-gap: 11.25rem !important;
}

.row-gap-185 {
  row-gap: 11.5625rem !important;
}

.row-gap-190 {
  row-gap: 11.875rem !important;
}

.row-gap-195 {
  row-gap: 12.1875rem !important;
}

.row-gap-200 {
  row-gap: 12.5rem !important;
}

.row-gap-205 {
  row-gap: 12.8125rem !important;
}

.row-gap-210 {
  row-gap: 13.125rem !important;
}

.row-gap-215 {
  row-gap: 13.4375rem !important;
}

.row-gap-220 {
  row-gap: 13.75rem !important;
}

.row-gap-225 {
  row-gap: 14.0625rem !important;
}

.row-gap-230 {
  row-gap: 14.375rem !important;
}

.row-gap-235 {
  row-gap: 14.6875rem !important;
}

.row-gap-240 {
  row-gap: 15rem !important;
}

.row-gap-245 {
  row-gap: 15.3125rem !important;
}

.row-gap-250 {
  row-gap: 15.625rem !important;
}

.row-gap-255 {
  row-gap: 15.9375rem !important;
}

.row-gap-260 {
  row-gap: 16.25rem !important;
}

.row-gap-265 {
  row-gap: 16.5625rem !important;
}

.row-gap-270 {
  row-gap: 16.875rem !important;
}

.row-gap-275 {
  row-gap: 17.1875rem !important;
}

.row-gap-280 {
  row-gap: 17.5rem !important;
}

.row-gap-285 {
  row-gap: 17.8125rem !important;
}

.row-gap-290 {
  row-gap: 18.125rem !important;
}

.row-gap-295 {
  row-gap: 18.4375rem !important;
}

.row-gap-300 {
  row-gap: 18.75rem !important;
}

.column-gap-0 {
  column-gap: 0 !important;
}

.column-gap-5 {
  column-gap: 0.313rem !important;
}

.column-gap-10 {
  column-gap: 0.625rem !important;
}

.column-gap-15 {
  column-gap: 0.938rem !important;
}

.column-gap-20 {
  column-gap: 1.25rem !important;
}

.column-gap-25 {
  column-gap: 1.563rem !important;
}

.column-gap-30 {
  column-gap: 1.875rem !important;
}

.column-gap-35 {
  column-gap: 2.188rem !important;
}

.column-gap-40 {
  column-gap: 2.5rem !important;
}

.column-gap-45 {
  column-gap: 2.813rem !important;
}

.column-gap-50 {
  column-gap: 3.125rem !important;
}

.column-gap-55 {
  column-gap: 3.438rem !important;
}

.column-gap-60 {
  column-gap: 3.75rem !important;
}

.column-gap-65 {
  column-gap: 4.063rem !important;
}

.column-gap-70 {
  column-gap: 4.375rem !important;
}

.column-gap-75 {
  column-gap: 4.688rem !important;
}

.column-gap-80 {
  column-gap: 5rem !important;
}

.column-gap-85 {
  column-gap: 5.313rem !important;
}

.column-gap-90 {
  column-gap: 5.625rem !important;
}

.column-gap-95 {
  column-gap: 5.938rem !important;
}

.column-gap-100 {
  column-gap: 6.25rem !important;
}

.column-gap-105 {
  column-gap: 6.5625rem !important;
}

.column-gap-110 {
  column-gap: 6.875rem !important;
}

.column-gap-115 {
  column-gap: 7.1875rem !important;
}

.column-gap-120 {
  column-gap: 7.5rem !important;
}

.column-gap-125 {
  column-gap: 7.8125rem !important;
}

.column-gap-130 {
  column-gap: 8.125rem !important;
}

.column-gap-135 {
  column-gap: 8.4375rem !important;
}

.column-gap-140 {
  column-gap: 8.75rem !important;
}

.column-gap-145 {
  column-gap: 9.0625rem !important;
}

.column-gap-150 {
  column-gap: 9.375rem !important;
}

.column-gap-155 {
  column-gap: 9.6875rem !important;
}

.column-gap-160 {
  column-gap: 10rem !important;
}

.column-gap-165 {
  column-gap: 10.3125rem !important;
}

.column-gap-170 {
  column-gap: 10.625rem !important;
}

.column-gap-175 {
  column-gap: 10.9375rem !important;
}

.column-gap-180 {
  column-gap: 11.25rem !important;
}

.column-gap-185 {
  column-gap: 11.5625rem !important;
}

.column-gap-190 {
  column-gap: 11.875rem !important;
}

.column-gap-195 {
  column-gap: 12.1875rem !important;
}

.column-gap-200 {
  column-gap: 12.5rem !important;
}

.column-gap-205 {
  column-gap: 12.8125rem !important;
}

.column-gap-210 {
  column-gap: 13.125rem !important;
}

.column-gap-215 {
  column-gap: 13.4375rem !important;
}

.column-gap-220 {
  column-gap: 13.75rem !important;
}

.column-gap-225 {
  column-gap: 14.0625rem !important;
}

.column-gap-230 {
  column-gap: 14.375rem !important;
}

.column-gap-235 {
  column-gap: 14.6875rem !important;
}

.column-gap-240 {
  column-gap: 15rem !important;
}

.column-gap-245 {
  column-gap: 15.3125rem !important;
}

.column-gap-250 {
  column-gap: 15.625rem !important;
}

.column-gap-255 {
  column-gap: 15.9375rem !important;
}

.column-gap-260 {
  column-gap: 16.25rem !important;
}

.column-gap-265 {
  column-gap: 16.5625rem !important;
}

.column-gap-270 {
  column-gap: 16.875rem !important;
}

.column-gap-275 {
  column-gap: 17.1875rem !important;
}

.column-gap-280 {
  column-gap: 17.5rem !important;
}

.column-gap-285 {
  column-gap: 17.8125rem !important;
}

.column-gap-290 {
  column-gap: 18.125rem !important;
}

.column-gap-295 {
  column-gap: 18.4375rem !important;
}

.column-gap-300 {
  column-gap: 18.75rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.4375rem + 2.25vw) !important;
}

.fs-2 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-3 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-4 {
  font-size: calc(1.28125rem + 0.375vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: 900 !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-tertiary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-tertiary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-red {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-red-rgb), var(--bs-text-opacity)) !important;
}

.text-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-orange {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-orange-rgb), var(--bs-text-opacity)) !important;
}

.text-yellow {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-yellow-rgb), var(--bs-text-opacity)) !important;
}

.text-green {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-green-rgb), var(--bs-text-opacity)) !important;
}

.text-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-cyan {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-cyan-rgb), var(--bs-text-opacity)) !important;
}

.text-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-alt {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-alt-rgb), var(--bs-text-opacity)) !important;
}

.text-blue-90 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-blue-90-rgb), var(--bs-text-opacity)) !important;
}

.text-light-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-blue {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-blue-rgb), var(--bs-text-opacity)) !important;
}

.text-light-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-10 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-10-rgb), var(--bs-text-opacity)) !important;
}

.text-secondary-20 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-20-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-grey {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-grey-rgb), var(--bs-text-opacity)) !important;
}

.text-dark-pink {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-pink-rgb), var(--bs-text-opacity)) !important;
}

.text-inactive {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-inactive-rgb), var(--bs-text-opacity)) !important;
}

.text-accent {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-accent-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-body-secondary {
  --bs-text-opacity: 1;
  color: var(--bs-secondary-color) !important;
}

.text-body-tertiary {
  --bs-text-opacity: 1;
  color: var(--bs-tertiary-color) !important;
}

.text-body-emphasis {
  --bs-text-opacity: 1;
  color: var(--bs-emphasis-color) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.text-primary-emphasis {
  color: var(--bs-primary-text-emphasis) !important;
}

.text-secondary-emphasis {
  color: var(--bs-secondary-text-emphasis) !important;
}

.text-success-emphasis {
  color: var(--bs-success-text-emphasis) !important;
}

.text-info-emphasis {
  color: var(--bs-info-text-emphasis) !important;
}

.text-warning-emphasis {
  color: var(--bs-warning-text-emphasis) !important;
}

.text-danger-emphasis {
  color: var(--bs-danger-text-emphasis) !important;
}

.text-light-emphasis {
  color: var(--bs-light-text-emphasis) !important;
}

.text-dark-emphasis {
  color: var(--bs-dark-text-emphasis) !important;
}

.link-opacity-10 {
  --bs-link-opacity: 0.1;
}

.link-opacity-10-hover:hover {
  --bs-link-opacity: 0.1;
}

.link-opacity-25 {
  --bs-link-opacity: 0.25;
}

.link-opacity-25-hover:hover {
  --bs-link-opacity: 0.25;
}

.link-opacity-50 {
  --bs-link-opacity: 0.5;
}

.link-opacity-50-hover:hover {
  --bs-link-opacity: 0.5;
}

.link-opacity-75 {
  --bs-link-opacity: 0.75;
}

.link-opacity-75-hover:hover {
  --bs-link-opacity: 0.75;
}

.link-opacity-100 {
  --bs-link-opacity: 1;
}

.link-opacity-100-hover:hover {
  --bs-link-opacity: 1;
}

.link-offset-1 {
  text-underline-offset: 0.125em !important;
}

.link-offset-1-hover:hover {
  text-underline-offset: 0.125em !important;
}

.link-offset-2 {
  text-underline-offset: 0.25em !important;
}

.link-offset-2-hover:hover {
  text-underline-offset: 0.25em !important;
}

.link-offset-3 {
  text-underline-offset: 0.375em !important;
}

.link-offset-3-hover:hover {
  text-underline-offset: 0.375em !important;
}

.link-underline-primary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-primary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-tertiary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-tertiary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-success {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-success-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-white {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-white-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-danger {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-danger-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-red {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-red-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-orange {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-orange-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-yellow {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-yellow-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-green {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-green-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-cyan {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-cyan-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-grey {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-grey-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue-alt {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-alt-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-blue-90 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-blue-90-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark-blue {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-blue-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-light-secondary {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-light-secondary-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-10 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-10-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-secondary-20 {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-secondary-20-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark-grey {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-grey-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-dark-pink {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-dark-pink-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-inactive {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-inactive-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline-accent {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-accent-rgb), var(--bs-link-underline-opacity)) !important;
}

.link-underline {
  --bs-link-underline-opacity: 1;
  text-decoration-color: rgba(var(--bs-link-color-rgb), var(--bs-link-underline-opacity, 1)) !important;
}

.link-underline-opacity-0 {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-0-hover:hover {
  --bs-link-underline-opacity: 0;
}

.link-underline-opacity-10 {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-10-hover:hover {
  --bs-link-underline-opacity: 0.1;
}

.link-underline-opacity-25 {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-25-hover:hover {
  --bs-link-underline-opacity: 0.25;
}

.link-underline-opacity-50 {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-50-hover:hover {
  --bs-link-underline-opacity: 0.5;
}

.link-underline-opacity-75 {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-75-hover:hover {
  --bs-link-underline-opacity: 0.75;
}

.link-underline-opacity-100 {
  --bs-link-underline-opacity: 1;
}

.link-underline-opacity-100-hover:hover {
  --bs-link-underline-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-primary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-red {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-red-rgb), var(--bs-bg-opacity)) !important;
}

.bg-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-orange {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-orange-rgb), var(--bs-bg-opacity)) !important;
}

.bg-yellow {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-yellow-rgb), var(--bs-bg-opacity)) !important;
}

.bg-green {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-green-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-cyan {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-cyan-rgb), var(--bs-bg-opacity)) !important;
}

.bg-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-alt {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-alt-rgb), var(--bs-bg-opacity)) !important;
}

.bg-blue-90 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-blue-90-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-blue {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-blue-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-10 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-10-rgb), var(--bs-bg-opacity)) !important;
}

.bg-secondary-20 {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-20-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-grey {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-grey-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark-pink {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-pink-rgb), var(--bs-bg-opacity)) !important;
}

.bg-inactive {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-inactive-rgb), var(--bs-bg-opacity)) !important;
}

.bg-accent {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-accent-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-body-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body-tertiary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-tertiary-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-opacity-0 {
  --bs-bg-opacity: 0;
}

.bg-opacity-20 {
  --bs-bg-opacity: 0.2;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-55 {
  --bs-bg-opacity: 0.55;
}

.bg-opacity-60 {
  --bs-bg-opacity: 0.6;
}

.bg-opacity-65 {
  --bs-bg-opacity: 0.65;
}

.bg-opacity-70 {
  --bs-bg-opacity: 0.7;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-80 {
  --bs-bg-opacity: 0.8;
}

.bg-opacity-85 {
  --bs-bg-opacity: 0.85;
}

.bg-opacity-90 {
  --bs-bg-opacity: 0.9;
}

.bg-opacity-95 {
  --bs-bg-opacity: 0.95;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-primary-subtle {
  background-color: var(--bs-primary-bg-subtle) !important;
}

.bg-secondary-subtle {
  background-color: var(--bs-secondary-bg-subtle) !important;
}

.bg-success-subtle {
  background-color: var(--bs-success-bg-subtle) !important;
}

.bg-info-subtle {
  background-color: var(--bs-info-bg-subtle) !important;
}

.bg-warning-subtle {
  background-color: var(--bs-warning-bg-subtle) !important;
}

.bg-danger-subtle {
  background-color: var(--bs-danger-bg-subtle) !important;
}

.bg-light-subtle {
  background-color: var(--bs-light-bg-subtle) !important;
}

.bg-dark-subtle {
  background-color: var(--bs-dark-bg-subtle) !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: var(--bs-border-radius-sm) !important;
}

.rounded-2 {
  border-radius: var(--bs-border-radius) !important;
}

.rounded-3 {
  border-radius: var(--bs-border-radius-lg) !important;
}

.rounded-4 {
  border-radius: var(--bs-border-radius-xl) !important;
}

.rounded-5 {
  border-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: var(--bs-border-radius-pill) !important;
}

.rounded-top {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-top-1 {
  border-top-left-radius: var(--bs-border-radius-sm) !important;
  border-top-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-top-2 {
  border-top-left-radius: var(--bs-border-radius) !important;
  border-top-right-radius: var(--bs-border-radius) !important;
}

.rounded-top-3 {
  border-top-left-radius: var(--bs-border-radius-lg) !important;
  border-top-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-top-4 {
  border-top-left-radius: var(--bs-border-radius-xl) !important;
  border-top-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-top-5 {
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-top-circle {
  border-top-left-radius: 50% !important;
  border-top-right-radius: 50% !important;
}

.rounded-top-pill {
  border-top-left-radius: var(--bs-border-radius-pill) !important;
  border-top-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-end {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-0 {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.rounded-end-1 {
  border-top-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
}

.rounded-end-2 {
  border-top-right-radius: var(--bs-border-radius) !important;
  border-bottom-right-radius: var(--bs-border-radius) !important;
}

.rounded-end-3 {
  border-top-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
}

.rounded-end-4 {
  border-top-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
}

.rounded-end-5 {
  border-top-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-end-circle {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
}

.rounded-end-pill {
  border-top-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
}

.rounded-bottom {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-0 {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.rounded-bottom-1 {
  border-bottom-right-radius: var(--bs-border-radius-sm) !important;
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-bottom-2 {
  border-bottom-right-radius: var(--bs-border-radius) !important;
  border-bottom-left-radius: var(--bs-border-radius) !important;
}

.rounded-bottom-3 {
  border-bottom-right-radius: var(--bs-border-radius-lg) !important;
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-bottom-4 {
  border-bottom-right-radius: var(--bs-border-radius-xl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-bottom-5 {
  border-bottom-right-radius: var(--bs-border-radius-xxl) !important;
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-bottom-circle {
  border-bottom-right-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
}

.rounded-bottom-pill {
  border-bottom-right-radius: var(--bs-border-radius-pill) !important;
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
}

.rounded-start {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-0 {
  border-bottom-left-radius: 0 !important;
  border-top-left-radius: 0 !important;
}

.rounded-start-1 {
  border-bottom-left-radius: var(--bs-border-radius-sm) !important;
  border-top-left-radius: var(--bs-border-radius-sm) !important;
}

.rounded-start-2 {
  border-bottom-left-radius: var(--bs-border-radius) !important;
  border-top-left-radius: var(--bs-border-radius) !important;
}

.rounded-start-3 {
  border-bottom-left-radius: var(--bs-border-radius-lg) !important;
  border-top-left-radius: var(--bs-border-radius-lg) !important;
}

.rounded-start-4 {
  border-bottom-left-radius: var(--bs-border-radius-xl) !important;
  border-top-left-radius: var(--bs-border-radius-xl) !important;
}

.rounded-start-5 {
  border-bottom-left-radius: var(--bs-border-radius-xxl) !important;
  border-top-left-radius: var(--bs-border-radius-xxl) !important;
}

.rounded-start-circle {
  border-bottom-left-radius: 50% !important;
  border-top-left-radius: 50% !important;
}

.rounded-start-pill {
  border-bottom-left-radius: var(--bs-border-radius-pill) !important;
  border-top-left-radius: var(--bs-border-radius-pill) !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

.z-n1 {
  z-index: -1 !important;
}

.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }
  .float-sm-end {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
  .object-fit-sm-contain {
    object-fit: contain !important;
  }
  .object-fit-sm-cover {
    object-fit: cover !important;
  }
  .object-fit-sm-fill {
    object-fit: fill !important;
  }
  .object-fit-sm-scale {
    object-fit: scale-down !important;
  }
  .object-fit-sm-none {
    object-fit: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-grid {
    display: grid !important;
  }
  .d-sm-inline-grid {
    display: inline-grid !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
  .d-sm-none {
    display: none !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
  .order-sm-first {
    order: -1 !important;
  }
  .order-sm-0 {
    order: 0 !important;
  }
  .order-sm-1 {
    order: 1 !important;
  }
  .order-sm-2 {
    order: 2 !important;
  }
  .order-sm-3 {
    order: 3 !important;
  }
  .order-sm-4 {
    order: 4 !important;
  }
  .order-sm-5 {
    order: 5 !important;
  }
  .order-sm-last {
    order: 6 !important;
  }
  .m-sm-0 {
    margin: 0 !important;
  }
  .m-sm-5 {
    margin: 0.313rem !important;
  }
  .m-sm-10 {
    margin: 0.625rem !important;
  }
  .m-sm-15 {
    margin: 0.938rem !important;
  }
  .m-sm-20 {
    margin: 1.25rem !important;
  }
  .m-sm-25 {
    margin: 1.563rem !important;
  }
  .m-sm-30 {
    margin: 1.875rem !important;
  }
  .m-sm-35 {
    margin: 2.188rem !important;
  }
  .m-sm-40 {
    margin: 2.5rem !important;
  }
  .m-sm-45 {
    margin: 2.813rem !important;
  }
  .m-sm-50 {
    margin: 3.125rem !important;
  }
  .m-sm-55 {
    margin: 3.438rem !important;
  }
  .m-sm-60 {
    margin: 3.75rem !important;
  }
  .m-sm-65 {
    margin: 4.063rem !important;
  }
  .m-sm-70 {
    margin: 4.375rem !important;
  }
  .m-sm-75 {
    margin: 4.688rem !important;
  }
  .m-sm-80 {
    margin: 5rem !important;
  }
  .m-sm-85 {
    margin: 5.313rem !important;
  }
  .m-sm-90 {
    margin: 5.625rem !important;
  }
  .m-sm-95 {
    margin: 5.938rem !important;
  }
  .m-sm-100 {
    margin: 6.25rem !important;
  }
  .m-sm-105 {
    margin: 6.5625rem !important;
  }
  .m-sm-110 {
    margin: 6.875rem !important;
  }
  .m-sm-115 {
    margin: 7.1875rem !important;
  }
  .m-sm-120 {
    margin: 7.5rem !important;
  }
  .m-sm-125 {
    margin: 7.8125rem !important;
  }
  .m-sm-130 {
    margin: 8.125rem !important;
  }
  .m-sm-135 {
    margin: 8.4375rem !important;
  }
  .m-sm-140 {
    margin: 8.75rem !important;
  }
  .m-sm-145 {
    margin: 9.0625rem !important;
  }
  .m-sm-150 {
    margin: 9.375rem !important;
  }
  .m-sm-155 {
    margin: 9.6875rem !important;
  }
  .m-sm-160 {
    margin: 10rem !important;
  }
  .m-sm-165 {
    margin: 10.3125rem !important;
  }
  .m-sm-170 {
    margin: 10.625rem !important;
  }
  .m-sm-175 {
    margin: 10.9375rem !important;
  }
  .m-sm-180 {
    margin: 11.25rem !important;
  }
  .m-sm-185 {
    margin: 11.5625rem !important;
  }
  .m-sm-190 {
    margin: 11.875rem !important;
  }
  .m-sm-195 {
    margin: 12.1875rem !important;
  }
  .m-sm-200 {
    margin: 12.5rem !important;
  }
  .m-sm-205 {
    margin: 12.8125rem !important;
  }
  .m-sm-210 {
    margin: 13.125rem !important;
  }
  .m-sm-215 {
    margin: 13.4375rem !important;
  }
  .m-sm-220 {
    margin: 13.75rem !important;
  }
  .m-sm-225 {
    margin: 14.0625rem !important;
  }
  .m-sm-230 {
    margin: 14.375rem !important;
  }
  .m-sm-235 {
    margin: 14.6875rem !important;
  }
  .m-sm-240 {
    margin: 15rem !important;
  }
  .m-sm-245 {
    margin: 15.3125rem !important;
  }
  .m-sm-250 {
    margin: 15.625rem !important;
  }
  .m-sm-255 {
    margin: 15.9375rem !important;
  }
  .m-sm-260 {
    margin: 16.25rem !important;
  }
  .m-sm-265 {
    margin: 16.5625rem !important;
  }
  .m-sm-270 {
    margin: 16.875rem !important;
  }
  .m-sm-275 {
    margin: 17.1875rem !important;
  }
  .m-sm-280 {
    margin: 17.5rem !important;
  }
  .m-sm-285 {
    margin: 17.8125rem !important;
  }
  .m-sm-290 {
    margin: 18.125rem !important;
  }
  .m-sm-295 {
    margin: 18.4375rem !important;
  }
  .m-sm-300 {
    margin: 18.75rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-sm-5 {
    margin-right: 0.313rem !important;
    margin-left: 0.313rem !important;
  }
  .mx-sm-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-sm-15 {
    margin-right: 0.938rem !important;
    margin-left: 0.938rem !important;
  }
  .mx-sm-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-sm-25 {
    margin-right: 1.563rem !important;
    margin-left: 1.563rem !important;
  }
  .mx-sm-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-sm-35 {
    margin-right: 2.188rem !important;
    margin-left: 2.188rem !important;
  }
  .mx-sm-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-sm-45 {
    margin-right: 2.813rem !important;
    margin-left: 2.813rem !important;
  }
  .mx-sm-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-sm-55 {
    margin-right: 3.438rem !important;
    margin-left: 3.438rem !important;
  }
  .mx-sm-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-sm-65 {
    margin-right: 4.063rem !important;
    margin-left: 4.063rem !important;
  }
  .mx-sm-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-sm-75 {
    margin-right: 4.688rem !important;
    margin-left: 4.688rem !important;
  }
  .mx-sm-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-sm-85 {
    margin-right: 5.313rem !important;
    margin-left: 5.313rem !important;
  }
  .mx-sm-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-sm-95 {
    margin-right: 5.938rem !important;
    margin-left: 5.938rem !important;
  }
  .mx-sm-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-sm-105 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important;
  }
  .mx-sm-110 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important;
  }
  .mx-sm-115 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important;
  }
  .mx-sm-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-sm-125 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important;
  }
  .mx-sm-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-sm-135 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important;
  }
  .mx-sm-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-sm-145 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important;
  }
  .mx-sm-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-sm-155 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important;
  }
  .mx-sm-160 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-sm-165 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important;
  }
  .mx-sm-170 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important;
  }
  .mx-sm-175 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important;
  }
  .mx-sm-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-sm-185 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important;
  }
  .mx-sm-190 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important;
  }
  .mx-sm-195 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important;
  }
  .mx-sm-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-sm-205 {
    margin-right: 12.8125rem !important;
    margin-left: 12.8125rem !important;
  }
  .mx-sm-210 {
    margin-right: 13.125rem !important;
    margin-left: 13.125rem !important;
  }
  .mx-sm-215 {
    margin-right: 13.4375rem !important;
    margin-left: 13.4375rem !important;
  }
  .mx-sm-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-sm-225 {
    margin-right: 14.0625rem !important;
    margin-left: 14.0625rem !important;
  }
  .mx-sm-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-sm-235 {
    margin-right: 14.6875rem !important;
    margin-left: 14.6875rem !important;
  }
  .mx-sm-240 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-sm-245 {
    margin-right: 15.3125rem !important;
    margin-left: 15.3125rem !important;
  }
  .mx-sm-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-sm-255 {
    margin-right: 15.9375rem !important;
    margin-left: 15.9375rem !important;
  }
  .mx-sm-260 {
    margin-right: 16.25rem !important;
    margin-left: 16.25rem !important;
  }
  .mx-sm-265 {
    margin-right: 16.5625rem !important;
    margin-left: 16.5625rem !important;
  }
  .mx-sm-270 {
    margin-right: 16.875rem !important;
    margin-left: 16.875rem !important;
  }
  .mx-sm-275 {
    margin-right: 17.1875rem !important;
    margin-left: 17.1875rem !important;
  }
  .mx-sm-280 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }
  .mx-sm-285 {
    margin-right: 17.8125rem !important;
    margin-left: 17.8125rem !important;
  }
  .mx-sm-290 {
    margin-right: 18.125rem !important;
    margin-left: 18.125rem !important;
  }
  .mx-sm-295 {
    margin-right: 18.4375rem !important;
    margin-left: 18.4375rem !important;
  }
  .mx-sm-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-sm-5 {
    margin-top: 0.313rem !important;
    margin-bottom: 0.313rem !important;
  }
  .my-sm-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-sm-15 {
    margin-top: 0.938rem !important;
    margin-bottom: 0.938rem !important;
  }
  .my-sm-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-sm-25 {
    margin-top: 1.563rem !important;
    margin-bottom: 1.563rem !important;
  }
  .my-sm-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-sm-35 {
    margin-top: 2.188rem !important;
    margin-bottom: 2.188rem !important;
  }
  .my-sm-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-sm-45 {
    margin-top: 2.813rem !important;
    margin-bottom: 2.813rem !important;
  }
  .my-sm-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-sm-55 {
    margin-top: 3.438rem !important;
    margin-bottom: 3.438rem !important;
  }
  .my-sm-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-sm-65 {
    margin-top: 4.063rem !important;
    margin-bottom: 4.063rem !important;
  }
  .my-sm-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-sm-75 {
    margin-top: 4.688rem !important;
    margin-bottom: 4.688rem !important;
  }
  .my-sm-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-sm-85 {
    margin-top: 5.313rem !important;
    margin-bottom: 5.313rem !important;
  }
  .my-sm-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-sm-95 {
    margin-top: 5.938rem !important;
    margin-bottom: 5.938rem !important;
  }
  .my-sm-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-sm-105 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important;
  }
  .my-sm-110 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important;
  }
  .my-sm-115 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important;
  }
  .my-sm-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-sm-125 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important;
  }
  .my-sm-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-sm-135 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important;
  }
  .my-sm-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-sm-145 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important;
  }
  .my-sm-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-sm-155 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important;
  }
  .my-sm-160 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-sm-165 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important;
  }
  .my-sm-170 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important;
  }
  .my-sm-175 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important;
  }
  .my-sm-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-sm-185 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important;
  }
  .my-sm-190 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important;
  }
  .my-sm-195 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important;
  }
  .my-sm-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-sm-205 {
    margin-top: 12.8125rem !important;
    margin-bottom: 12.8125rem !important;
  }
  .my-sm-210 {
    margin-top: 13.125rem !important;
    margin-bottom: 13.125rem !important;
  }
  .my-sm-215 {
    margin-top: 13.4375rem !important;
    margin-bottom: 13.4375rem !important;
  }
  .my-sm-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-sm-225 {
    margin-top: 14.0625rem !important;
    margin-bottom: 14.0625rem !important;
  }
  .my-sm-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-sm-235 {
    margin-top: 14.6875rem !important;
    margin-bottom: 14.6875rem !important;
  }
  .my-sm-240 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-sm-245 {
    margin-top: 15.3125rem !important;
    margin-bottom: 15.3125rem !important;
  }
  .my-sm-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-sm-255 {
    margin-top: 15.9375rem !important;
    margin-bottom: 15.9375rem !important;
  }
  .my-sm-260 {
    margin-top: 16.25rem !important;
    margin-bottom: 16.25rem !important;
  }
  .my-sm-265 {
    margin-top: 16.5625rem !important;
    margin-bottom: 16.5625rem !important;
  }
  .my-sm-270 {
    margin-top: 16.875rem !important;
    margin-bottom: 16.875rem !important;
  }
  .my-sm-275 {
    margin-top: 17.1875rem !important;
    margin-bottom: 17.1875rem !important;
  }
  .my-sm-280 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }
  .my-sm-285 {
    margin-top: 17.8125rem !important;
    margin-bottom: 17.8125rem !important;
  }
  .my-sm-290 {
    margin-top: 18.125rem !important;
    margin-bottom: 18.125rem !important;
  }
  .my-sm-295 {
    margin-top: 18.4375rem !important;
    margin-bottom: 18.4375rem !important;
  }
  .my-sm-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-sm-0 {
    margin-top: 0 !important;
  }
  .mt-sm-5 {
    margin-top: 0.313rem !important;
  }
  .mt-sm-10 {
    margin-top: 0.625rem !important;
  }
  .mt-sm-15 {
    margin-top: 0.938rem !important;
  }
  .mt-sm-20 {
    margin-top: 1.25rem !important;
  }
  .mt-sm-25 {
    margin-top: 1.563rem !important;
  }
  .mt-sm-30 {
    margin-top: 1.875rem !important;
  }
  .mt-sm-35 {
    margin-top: 2.188rem !important;
  }
  .mt-sm-40 {
    margin-top: 2.5rem !important;
  }
  .mt-sm-45 {
    margin-top: 2.813rem !important;
  }
  .mt-sm-50 {
    margin-top: 3.125rem !important;
  }
  .mt-sm-55 {
    margin-top: 3.438rem !important;
  }
  .mt-sm-60 {
    margin-top: 3.75rem !important;
  }
  .mt-sm-65 {
    margin-top: 4.063rem !important;
  }
  .mt-sm-70 {
    margin-top: 4.375rem !important;
  }
  .mt-sm-75 {
    margin-top: 4.688rem !important;
  }
  .mt-sm-80 {
    margin-top: 5rem !important;
  }
  .mt-sm-85 {
    margin-top: 5.313rem !important;
  }
  .mt-sm-90 {
    margin-top: 5.625rem !important;
  }
  .mt-sm-95 {
    margin-top: 5.938rem !important;
  }
  .mt-sm-100 {
    margin-top: 6.25rem !important;
  }
  .mt-sm-105 {
    margin-top: 6.5625rem !important;
  }
  .mt-sm-110 {
    margin-top: 6.875rem !important;
  }
  .mt-sm-115 {
    margin-top: 7.1875rem !important;
  }
  .mt-sm-120 {
    margin-top: 7.5rem !important;
  }
  .mt-sm-125 {
    margin-top: 7.8125rem !important;
  }
  .mt-sm-130 {
    margin-top: 8.125rem !important;
  }
  .mt-sm-135 {
    margin-top: 8.4375rem !important;
  }
  .mt-sm-140 {
    margin-top: 8.75rem !important;
  }
  .mt-sm-145 {
    margin-top: 9.0625rem !important;
  }
  .mt-sm-150 {
    margin-top: 9.375rem !important;
  }
  .mt-sm-155 {
    margin-top: 9.6875rem !important;
  }
  .mt-sm-160 {
    margin-top: 10rem !important;
  }
  .mt-sm-165 {
    margin-top: 10.3125rem !important;
  }
  .mt-sm-170 {
    margin-top: 10.625rem !important;
  }
  .mt-sm-175 {
    margin-top: 10.9375rem !important;
  }
  .mt-sm-180 {
    margin-top: 11.25rem !important;
  }
  .mt-sm-185 {
    margin-top: 11.5625rem !important;
  }
  .mt-sm-190 {
    margin-top: 11.875rem !important;
  }
  .mt-sm-195 {
    margin-top: 12.1875rem !important;
  }
  .mt-sm-200 {
    margin-top: 12.5rem !important;
  }
  .mt-sm-205 {
    margin-top: 12.8125rem !important;
  }
  .mt-sm-210 {
    margin-top: 13.125rem !important;
  }
  .mt-sm-215 {
    margin-top: 13.4375rem !important;
  }
  .mt-sm-220 {
    margin-top: 13.75rem !important;
  }
  .mt-sm-225 {
    margin-top: 14.0625rem !important;
  }
  .mt-sm-230 {
    margin-top: 14.375rem !important;
  }
  .mt-sm-235 {
    margin-top: 14.6875rem !important;
  }
  .mt-sm-240 {
    margin-top: 15rem !important;
  }
  .mt-sm-245 {
    margin-top: 15.3125rem !important;
  }
  .mt-sm-250 {
    margin-top: 15.625rem !important;
  }
  .mt-sm-255 {
    margin-top: 15.9375rem !important;
  }
  .mt-sm-260 {
    margin-top: 16.25rem !important;
  }
  .mt-sm-265 {
    margin-top: 16.5625rem !important;
  }
  .mt-sm-270 {
    margin-top: 16.875rem !important;
  }
  .mt-sm-275 {
    margin-top: 17.1875rem !important;
  }
  .mt-sm-280 {
    margin-top: 17.5rem !important;
  }
  .mt-sm-285 {
    margin-top: 17.8125rem !important;
  }
  .mt-sm-290 {
    margin-top: 18.125rem !important;
  }
  .mt-sm-295 {
    margin-top: 18.4375rem !important;
  }
  .mt-sm-300 {
    margin-top: 18.75rem !important;
  }
  .mt-sm-auto {
    margin-top: auto !important;
  }
  .me-sm-0 {
    margin-right: 0 !important;
  }
  .me-sm-5 {
    margin-right: 0.313rem !important;
  }
  .me-sm-10 {
    margin-right: 0.625rem !important;
  }
  .me-sm-15 {
    margin-right: 0.938rem !important;
  }
  .me-sm-20 {
    margin-right: 1.25rem !important;
  }
  .me-sm-25 {
    margin-right: 1.563rem !important;
  }
  .me-sm-30 {
    margin-right: 1.875rem !important;
  }
  .me-sm-35 {
    margin-right: 2.188rem !important;
  }
  .me-sm-40 {
    margin-right: 2.5rem !important;
  }
  .me-sm-45 {
    margin-right: 2.813rem !important;
  }
  .me-sm-50 {
    margin-right: 3.125rem !important;
  }
  .me-sm-55 {
    margin-right: 3.438rem !important;
  }
  .me-sm-60 {
    margin-right: 3.75rem !important;
  }
  .me-sm-65 {
    margin-right: 4.063rem !important;
  }
  .me-sm-70 {
    margin-right: 4.375rem !important;
  }
  .me-sm-75 {
    margin-right: 4.688rem !important;
  }
  .me-sm-80 {
    margin-right: 5rem !important;
  }
  .me-sm-85 {
    margin-right: 5.313rem !important;
  }
  .me-sm-90 {
    margin-right: 5.625rem !important;
  }
  .me-sm-95 {
    margin-right: 5.938rem !important;
  }
  .me-sm-100 {
    margin-right: 6.25rem !important;
  }
  .me-sm-105 {
    margin-right: 6.5625rem !important;
  }
  .me-sm-110 {
    margin-right: 6.875rem !important;
  }
  .me-sm-115 {
    margin-right: 7.1875rem !important;
  }
  .me-sm-120 {
    margin-right: 7.5rem !important;
  }
  .me-sm-125 {
    margin-right: 7.8125rem !important;
  }
  .me-sm-130 {
    margin-right: 8.125rem !important;
  }
  .me-sm-135 {
    margin-right: 8.4375rem !important;
  }
  .me-sm-140 {
    margin-right: 8.75rem !important;
  }
  .me-sm-145 {
    margin-right: 9.0625rem !important;
  }
  .me-sm-150 {
    margin-right: 9.375rem !important;
  }
  .me-sm-155 {
    margin-right: 9.6875rem !important;
  }
  .me-sm-160 {
    margin-right: 10rem !important;
  }
  .me-sm-165 {
    margin-right: 10.3125rem !important;
  }
  .me-sm-170 {
    margin-right: 10.625rem !important;
  }
  .me-sm-175 {
    margin-right: 10.9375rem !important;
  }
  .me-sm-180 {
    margin-right: 11.25rem !important;
  }
  .me-sm-185 {
    margin-right: 11.5625rem !important;
  }
  .me-sm-190 {
    margin-right: 11.875rem !important;
  }
  .me-sm-195 {
    margin-right: 12.1875rem !important;
  }
  .me-sm-200 {
    margin-right: 12.5rem !important;
  }
  .me-sm-205 {
    margin-right: 12.8125rem !important;
  }
  .me-sm-210 {
    margin-right: 13.125rem !important;
  }
  .me-sm-215 {
    margin-right: 13.4375rem !important;
  }
  .me-sm-220 {
    margin-right: 13.75rem !important;
  }
  .me-sm-225 {
    margin-right: 14.0625rem !important;
  }
  .me-sm-230 {
    margin-right: 14.375rem !important;
  }
  .me-sm-235 {
    margin-right: 14.6875rem !important;
  }
  .me-sm-240 {
    margin-right: 15rem !important;
  }
  .me-sm-245 {
    margin-right: 15.3125rem !important;
  }
  .me-sm-250 {
    margin-right: 15.625rem !important;
  }
  .me-sm-255 {
    margin-right: 15.9375rem !important;
  }
  .me-sm-260 {
    margin-right: 16.25rem !important;
  }
  .me-sm-265 {
    margin-right: 16.5625rem !important;
  }
  .me-sm-270 {
    margin-right: 16.875rem !important;
  }
  .me-sm-275 {
    margin-right: 17.1875rem !important;
  }
  .me-sm-280 {
    margin-right: 17.5rem !important;
  }
  .me-sm-285 {
    margin-right: 17.8125rem !important;
  }
  .me-sm-290 {
    margin-right: 18.125rem !important;
  }
  .me-sm-295 {
    margin-right: 18.4375rem !important;
  }
  .me-sm-300 {
    margin-right: 18.75rem !important;
  }
  .me-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-0 {
    margin-bottom: 0 !important;
  }
  .mb-sm-5 {
    margin-bottom: 0.313rem !important;
  }
  .mb-sm-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-sm-15 {
    margin-bottom: 0.938rem !important;
  }
  .mb-sm-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-sm-25 {
    margin-bottom: 1.563rem !important;
  }
  .mb-sm-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-sm-35 {
    margin-bottom: 2.188rem !important;
  }
  .mb-sm-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-sm-45 {
    margin-bottom: 2.813rem !important;
  }
  .mb-sm-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-sm-55 {
    margin-bottom: 3.438rem !important;
  }
  .mb-sm-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-sm-65 {
    margin-bottom: 4.063rem !important;
  }
  .mb-sm-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-sm-75 {
    margin-bottom: 4.688rem !important;
  }
  .mb-sm-80 {
    margin-bottom: 5rem !important;
  }
  .mb-sm-85 {
    margin-bottom: 5.313rem !important;
  }
  .mb-sm-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-sm-95 {
    margin-bottom: 5.938rem !important;
  }
  .mb-sm-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-sm-105 {
    margin-bottom: 6.5625rem !important;
  }
  .mb-sm-110 {
    margin-bottom: 6.875rem !important;
  }
  .mb-sm-115 {
    margin-bottom: 7.1875rem !important;
  }
  .mb-sm-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-sm-125 {
    margin-bottom: 7.8125rem !important;
  }
  .mb-sm-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-sm-135 {
    margin-bottom: 8.4375rem !important;
  }
  .mb-sm-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-sm-145 {
    margin-bottom: 9.0625rem !important;
  }
  .mb-sm-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-sm-155 {
    margin-bottom: 9.6875rem !important;
  }
  .mb-sm-160 {
    margin-bottom: 10rem !important;
  }
  .mb-sm-165 {
    margin-bottom: 10.3125rem !important;
  }
  .mb-sm-170 {
    margin-bottom: 10.625rem !important;
  }
  .mb-sm-175 {
    margin-bottom: 10.9375rem !important;
  }
  .mb-sm-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-sm-185 {
    margin-bottom: 11.5625rem !important;
  }
  .mb-sm-190 {
    margin-bottom: 11.875rem !important;
  }
  .mb-sm-195 {
    margin-bottom: 12.1875rem !important;
  }
  .mb-sm-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-sm-205 {
    margin-bottom: 12.8125rem !important;
  }
  .mb-sm-210 {
    margin-bottom: 13.125rem !important;
  }
  .mb-sm-215 {
    margin-bottom: 13.4375rem !important;
  }
  .mb-sm-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-sm-225 {
    margin-bottom: 14.0625rem !important;
  }
  .mb-sm-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-sm-235 {
    margin-bottom: 14.6875rem !important;
  }
  .mb-sm-240 {
    margin-bottom: 15rem !important;
  }
  .mb-sm-245 {
    margin-bottom: 15.3125rem !important;
  }
  .mb-sm-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-sm-255 {
    margin-bottom: 15.9375rem !important;
  }
  .mb-sm-260 {
    margin-bottom: 16.25rem !important;
  }
  .mb-sm-265 {
    margin-bottom: 16.5625rem !important;
  }
  .mb-sm-270 {
    margin-bottom: 16.875rem !important;
  }
  .mb-sm-275 {
    margin-bottom: 17.1875rem !important;
  }
  .mb-sm-280 {
    margin-bottom: 17.5rem !important;
  }
  .mb-sm-285 {
    margin-bottom: 17.8125rem !important;
  }
  .mb-sm-290 {
    margin-bottom: 18.125rem !important;
  }
  .mb-sm-295 {
    margin-bottom: 18.4375rem !important;
  }
  .mb-sm-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-sm-auto {
    margin-bottom: auto !important;
  }
  .ms-sm-0 {
    margin-left: 0 !important;
  }
  .ms-sm-5 {
    margin-left: 0.313rem !important;
  }
  .ms-sm-10 {
    margin-left: 0.625rem !important;
  }
  .ms-sm-15 {
    margin-left: 0.938rem !important;
  }
  .ms-sm-20 {
    margin-left: 1.25rem !important;
  }
  .ms-sm-25 {
    margin-left: 1.563rem !important;
  }
  .ms-sm-30 {
    margin-left: 1.875rem !important;
  }
  .ms-sm-35 {
    margin-left: 2.188rem !important;
  }
  .ms-sm-40 {
    margin-left: 2.5rem !important;
  }
  .ms-sm-45 {
    margin-left: 2.813rem !important;
  }
  .ms-sm-50 {
    margin-left: 3.125rem !important;
  }
  .ms-sm-55 {
    margin-left: 3.438rem !important;
  }
  .ms-sm-60 {
    margin-left: 3.75rem !important;
  }
  .ms-sm-65 {
    margin-left: 4.063rem !important;
  }
  .ms-sm-70 {
    margin-left: 4.375rem !important;
  }
  .ms-sm-75 {
    margin-left: 4.688rem !important;
  }
  .ms-sm-80 {
    margin-left: 5rem !important;
  }
  .ms-sm-85 {
    margin-left: 5.313rem !important;
  }
  .ms-sm-90 {
    margin-left: 5.625rem !important;
  }
  .ms-sm-95 {
    margin-left: 5.938rem !important;
  }
  .ms-sm-100 {
    margin-left: 6.25rem !important;
  }
  .ms-sm-105 {
    margin-left: 6.5625rem !important;
  }
  .ms-sm-110 {
    margin-left: 6.875rem !important;
  }
  .ms-sm-115 {
    margin-left: 7.1875rem !important;
  }
  .ms-sm-120 {
    margin-left: 7.5rem !important;
  }
  .ms-sm-125 {
    margin-left: 7.8125rem !important;
  }
  .ms-sm-130 {
    margin-left: 8.125rem !important;
  }
  .ms-sm-135 {
    margin-left: 8.4375rem !important;
  }
  .ms-sm-140 {
    margin-left: 8.75rem !important;
  }
  .ms-sm-145 {
    margin-left: 9.0625rem !important;
  }
  .ms-sm-150 {
    margin-left: 9.375rem !important;
  }
  .ms-sm-155 {
    margin-left: 9.6875rem !important;
  }
  .ms-sm-160 {
    margin-left: 10rem !important;
  }
  .ms-sm-165 {
    margin-left: 10.3125rem !important;
  }
  .ms-sm-170 {
    margin-left: 10.625rem !important;
  }
  .ms-sm-175 {
    margin-left: 10.9375rem !important;
  }
  .ms-sm-180 {
    margin-left: 11.25rem !important;
  }
  .ms-sm-185 {
    margin-left: 11.5625rem !important;
  }
  .ms-sm-190 {
    margin-left: 11.875rem !important;
  }
  .ms-sm-195 {
    margin-left: 12.1875rem !important;
  }
  .ms-sm-200 {
    margin-left: 12.5rem !important;
  }
  .ms-sm-205 {
    margin-left: 12.8125rem !important;
  }
  .ms-sm-210 {
    margin-left: 13.125rem !important;
  }
  .ms-sm-215 {
    margin-left: 13.4375rem !important;
  }
  .ms-sm-220 {
    margin-left: 13.75rem !important;
  }
  .ms-sm-225 {
    margin-left: 14.0625rem !important;
  }
  .ms-sm-230 {
    margin-left: 14.375rem !important;
  }
  .ms-sm-235 {
    margin-left: 14.6875rem !important;
  }
  .ms-sm-240 {
    margin-left: 15rem !important;
  }
  .ms-sm-245 {
    margin-left: 15.3125rem !important;
  }
  .ms-sm-250 {
    margin-left: 15.625rem !important;
  }
  .ms-sm-255 {
    margin-left: 15.9375rem !important;
  }
  .ms-sm-260 {
    margin-left: 16.25rem !important;
  }
  .ms-sm-265 {
    margin-left: 16.5625rem !important;
  }
  .ms-sm-270 {
    margin-left: 16.875rem !important;
  }
  .ms-sm-275 {
    margin-left: 17.1875rem !important;
  }
  .ms-sm-280 {
    margin-left: 17.5rem !important;
  }
  .ms-sm-285 {
    margin-left: 17.8125rem !important;
  }
  .ms-sm-290 {
    margin-left: 18.125rem !important;
  }
  .ms-sm-295 {
    margin-left: 18.4375rem !important;
  }
  .ms-sm-300 {
    margin-left: 18.75rem !important;
  }
  .ms-sm-auto {
    margin-left: auto !important;
  }
  .m-sm-n5 {
    margin: -0.313rem !important;
  }
  .m-sm-n10 {
    margin: -0.625rem !important;
  }
  .m-sm-n15 {
    margin: -0.938rem !important;
  }
  .m-sm-n20 {
    margin: -1.25rem !important;
  }
  .m-sm-n25 {
    margin: -1.563rem !important;
  }
  .m-sm-n30 {
    margin: -1.875rem !important;
  }
  .m-sm-n35 {
    margin: -2.188rem !important;
  }
  .m-sm-n40 {
    margin: -2.5rem !important;
  }
  .m-sm-n45 {
    margin: -2.813rem !important;
  }
  .m-sm-n50 {
    margin: -3.125rem !important;
  }
  .m-sm-n55 {
    margin: -3.438rem !important;
  }
  .m-sm-n60 {
    margin: -3.75rem !important;
  }
  .m-sm-n65 {
    margin: -4.063rem !important;
  }
  .m-sm-n70 {
    margin: -4.375rem !important;
  }
  .m-sm-n75 {
    margin: -4.688rem !important;
  }
  .m-sm-n80 {
    margin: -5rem !important;
  }
  .m-sm-n85 {
    margin: -5.313rem !important;
  }
  .m-sm-n90 {
    margin: -5.625rem !important;
  }
  .m-sm-n95 {
    margin: -5.938rem !important;
  }
  .m-sm-n100 {
    margin: -6.25rem !important;
  }
  .m-sm-n105 {
    margin: -6.5625rem !important;
  }
  .m-sm-n110 {
    margin: -6.875rem !important;
  }
  .m-sm-n115 {
    margin: -7.1875rem !important;
  }
  .m-sm-n120 {
    margin: -7.5rem !important;
  }
  .m-sm-n125 {
    margin: -7.8125rem !important;
  }
  .m-sm-n130 {
    margin: -8.125rem !important;
  }
  .m-sm-n135 {
    margin: -8.4375rem !important;
  }
  .m-sm-n140 {
    margin: -8.75rem !important;
  }
  .m-sm-n145 {
    margin: -9.0625rem !important;
  }
  .m-sm-n150 {
    margin: -9.375rem !important;
  }
  .m-sm-n155 {
    margin: -9.6875rem !important;
  }
  .m-sm-n160 {
    margin: -10rem !important;
  }
  .m-sm-n165 {
    margin: -10.3125rem !important;
  }
  .m-sm-n170 {
    margin: -10.625rem !important;
  }
  .m-sm-n175 {
    margin: -10.9375rem !important;
  }
  .m-sm-n180 {
    margin: -11.25rem !important;
  }
  .m-sm-n185 {
    margin: -11.5625rem !important;
  }
  .m-sm-n190 {
    margin: -11.875rem !important;
  }
  .m-sm-n195 {
    margin: -12.1875rem !important;
  }
  .m-sm-n200 {
    margin: -12.5rem !important;
  }
  .m-sm-n205 {
    margin: -12.8125rem !important;
  }
  .m-sm-n210 {
    margin: -13.125rem !important;
  }
  .m-sm-n215 {
    margin: -13.4375rem !important;
  }
  .m-sm-n220 {
    margin: -13.75rem !important;
  }
  .m-sm-n225 {
    margin: -14.0625rem !important;
  }
  .m-sm-n230 {
    margin: -14.375rem !important;
  }
  .m-sm-n235 {
    margin: -14.6875rem !important;
  }
  .m-sm-n240 {
    margin: -15rem !important;
  }
  .m-sm-n245 {
    margin: -15.3125rem !important;
  }
  .m-sm-n250 {
    margin: -15.625rem !important;
  }
  .m-sm-n255 {
    margin: -15.9375rem !important;
  }
  .m-sm-n260 {
    margin: -16.25rem !important;
  }
  .m-sm-n265 {
    margin: -16.5625rem !important;
  }
  .m-sm-n270 {
    margin: -16.875rem !important;
  }
  .m-sm-n275 {
    margin: -17.1875rem !important;
  }
  .m-sm-n280 {
    margin: -17.5rem !important;
  }
  .m-sm-n285 {
    margin: -17.8125rem !important;
  }
  .m-sm-n290 {
    margin: -18.125rem !important;
  }
  .m-sm-n295 {
    margin: -18.4375rem !important;
  }
  .m-sm-n300 {
    margin: -18.75rem !important;
  }
  .mx-sm-n5 {
    margin-right: -0.313rem !important;
    margin-left: -0.313rem !important;
  }
  .mx-sm-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-sm-n15 {
    margin-right: -0.938rem !important;
    margin-left: -0.938rem !important;
  }
  .mx-sm-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-sm-n25 {
    margin-right: -1.563rem !important;
    margin-left: -1.563rem !important;
  }
  .mx-sm-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-sm-n35 {
    margin-right: -2.188rem !important;
    margin-left: -2.188rem !important;
  }
  .mx-sm-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-sm-n45 {
    margin-right: -2.813rem !important;
    margin-left: -2.813rem !important;
  }
  .mx-sm-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-sm-n55 {
    margin-right: -3.438rem !important;
    margin-left: -3.438rem !important;
  }
  .mx-sm-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-sm-n65 {
    margin-right: -4.063rem !important;
    margin-left: -4.063rem !important;
  }
  .mx-sm-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-sm-n75 {
    margin-right: -4.688rem !important;
    margin-left: -4.688rem !important;
  }
  .mx-sm-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-sm-n85 {
    margin-right: -5.313rem !important;
    margin-left: -5.313rem !important;
  }
  .mx-sm-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-sm-n95 {
    margin-right: -5.938rem !important;
    margin-left: -5.938rem !important;
  }
  .mx-sm-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-sm-n105 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important;
  }
  .mx-sm-n110 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important;
  }
  .mx-sm-n115 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important;
  }
  .mx-sm-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-sm-n125 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important;
  }
  .mx-sm-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-sm-n135 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important;
  }
  .mx-sm-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-sm-n145 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important;
  }
  .mx-sm-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-sm-n155 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important;
  }
  .mx-sm-n160 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-sm-n165 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important;
  }
  .mx-sm-n170 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important;
  }
  .mx-sm-n175 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important;
  }
  .mx-sm-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-sm-n185 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important;
  }
  .mx-sm-n190 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important;
  }
  .mx-sm-n195 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important;
  }
  .mx-sm-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-sm-n205 {
    margin-right: -12.8125rem !important;
    margin-left: -12.8125rem !important;
  }
  .mx-sm-n210 {
    margin-right: -13.125rem !important;
    margin-left: -13.125rem !important;
  }
  .mx-sm-n215 {
    margin-right: -13.4375rem !important;
    margin-left: -13.4375rem !important;
  }
  .mx-sm-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-sm-n225 {
    margin-right: -14.0625rem !important;
    margin-left: -14.0625rem !important;
  }
  .mx-sm-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-sm-n235 {
    margin-right: -14.6875rem !important;
    margin-left: -14.6875rem !important;
  }
  .mx-sm-n240 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-sm-n245 {
    margin-right: -15.3125rem !important;
    margin-left: -15.3125rem !important;
  }
  .mx-sm-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-sm-n255 {
    margin-right: -15.9375rem !important;
    margin-left: -15.9375rem !important;
  }
  .mx-sm-n260 {
    margin-right: -16.25rem !important;
    margin-left: -16.25rem !important;
  }
  .mx-sm-n265 {
    margin-right: -16.5625rem !important;
    margin-left: -16.5625rem !important;
  }
  .mx-sm-n270 {
    margin-right: -16.875rem !important;
    margin-left: -16.875rem !important;
  }
  .mx-sm-n275 {
    margin-right: -17.1875rem !important;
    margin-left: -17.1875rem !important;
  }
  .mx-sm-n280 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }
  .mx-sm-n285 {
    margin-right: -17.8125rem !important;
    margin-left: -17.8125rem !important;
  }
  .mx-sm-n290 {
    margin-right: -18.125rem !important;
    margin-left: -18.125rem !important;
  }
  .mx-sm-n295 {
    margin-right: -18.4375rem !important;
    margin-left: -18.4375rem !important;
  }
  .mx-sm-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-sm-n5 {
    margin-top: -0.313rem !important;
    margin-bottom: -0.313rem !important;
  }
  .my-sm-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-sm-n15 {
    margin-top: -0.938rem !important;
    margin-bottom: -0.938rem !important;
  }
  .my-sm-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-sm-n25 {
    margin-top: -1.563rem !important;
    margin-bottom: -1.563rem !important;
  }
  .my-sm-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-sm-n35 {
    margin-top: -2.188rem !important;
    margin-bottom: -2.188rem !important;
  }
  .my-sm-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-sm-n45 {
    margin-top: -2.813rem !important;
    margin-bottom: -2.813rem !important;
  }
  .my-sm-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-sm-n55 {
    margin-top: -3.438rem !important;
    margin-bottom: -3.438rem !important;
  }
  .my-sm-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-sm-n65 {
    margin-top: -4.063rem !important;
    margin-bottom: -4.063rem !important;
  }
  .my-sm-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-sm-n75 {
    margin-top: -4.688rem !important;
    margin-bottom: -4.688rem !important;
  }
  .my-sm-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-sm-n85 {
    margin-top: -5.313rem !important;
    margin-bottom: -5.313rem !important;
  }
  .my-sm-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-sm-n95 {
    margin-top: -5.938rem !important;
    margin-bottom: -5.938rem !important;
  }
  .my-sm-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-sm-n105 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important;
  }
  .my-sm-n110 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important;
  }
  .my-sm-n115 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important;
  }
  .my-sm-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-sm-n125 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important;
  }
  .my-sm-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-sm-n135 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important;
  }
  .my-sm-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-sm-n145 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important;
  }
  .my-sm-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-sm-n155 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important;
  }
  .my-sm-n160 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-sm-n165 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important;
  }
  .my-sm-n170 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important;
  }
  .my-sm-n175 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important;
  }
  .my-sm-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-sm-n185 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important;
  }
  .my-sm-n190 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important;
  }
  .my-sm-n195 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important;
  }
  .my-sm-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-sm-n205 {
    margin-top: -12.8125rem !important;
    margin-bottom: -12.8125rem !important;
  }
  .my-sm-n210 {
    margin-top: -13.125rem !important;
    margin-bottom: -13.125rem !important;
  }
  .my-sm-n215 {
    margin-top: -13.4375rem !important;
    margin-bottom: -13.4375rem !important;
  }
  .my-sm-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-sm-n225 {
    margin-top: -14.0625rem !important;
    margin-bottom: -14.0625rem !important;
  }
  .my-sm-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-sm-n235 {
    margin-top: -14.6875rem !important;
    margin-bottom: -14.6875rem !important;
  }
  .my-sm-n240 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-sm-n245 {
    margin-top: -15.3125rem !important;
    margin-bottom: -15.3125rem !important;
  }
  .my-sm-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-sm-n255 {
    margin-top: -15.9375rem !important;
    margin-bottom: -15.9375rem !important;
  }
  .my-sm-n260 {
    margin-top: -16.25rem !important;
    margin-bottom: -16.25rem !important;
  }
  .my-sm-n265 {
    margin-top: -16.5625rem !important;
    margin-bottom: -16.5625rem !important;
  }
  .my-sm-n270 {
    margin-top: -16.875rem !important;
    margin-bottom: -16.875rem !important;
  }
  .my-sm-n275 {
    margin-top: -17.1875rem !important;
    margin-bottom: -17.1875rem !important;
  }
  .my-sm-n280 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }
  .my-sm-n285 {
    margin-top: -17.8125rem !important;
    margin-bottom: -17.8125rem !important;
  }
  .my-sm-n290 {
    margin-top: -18.125rem !important;
    margin-bottom: -18.125rem !important;
  }
  .my-sm-n295 {
    margin-top: -18.4375rem !important;
    margin-bottom: -18.4375rem !important;
  }
  .my-sm-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-sm-n5 {
    margin-top: -0.313rem !important;
  }
  .mt-sm-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-sm-n15 {
    margin-top: -0.938rem !important;
  }
  .mt-sm-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-sm-n25 {
    margin-top: -1.563rem !important;
  }
  .mt-sm-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-sm-n35 {
    margin-top: -2.188rem !important;
  }
  .mt-sm-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-sm-n45 {
    margin-top: -2.813rem !important;
  }
  .mt-sm-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-sm-n55 {
    margin-top: -3.438rem !important;
  }
  .mt-sm-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-sm-n65 {
    margin-top: -4.063rem !important;
  }
  .mt-sm-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-sm-n75 {
    margin-top: -4.688rem !important;
  }
  .mt-sm-n80 {
    margin-top: -5rem !important;
  }
  .mt-sm-n85 {
    margin-top: -5.313rem !important;
  }
  .mt-sm-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-sm-n95 {
    margin-top: -5.938rem !important;
  }
  .mt-sm-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-sm-n105 {
    margin-top: -6.5625rem !important;
  }
  .mt-sm-n110 {
    margin-top: -6.875rem !important;
  }
  .mt-sm-n115 {
    margin-top: -7.1875rem !important;
  }
  .mt-sm-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-sm-n125 {
    margin-top: -7.8125rem !important;
  }
  .mt-sm-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-sm-n135 {
    margin-top: -8.4375rem !important;
  }
  .mt-sm-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-sm-n145 {
    margin-top: -9.0625rem !important;
  }
  .mt-sm-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-sm-n155 {
    margin-top: -9.6875rem !important;
  }
  .mt-sm-n160 {
    margin-top: -10rem !important;
  }
  .mt-sm-n165 {
    margin-top: -10.3125rem !important;
  }
  .mt-sm-n170 {
    margin-top: -10.625rem !important;
  }
  .mt-sm-n175 {
    margin-top: -10.9375rem !important;
  }
  .mt-sm-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-sm-n185 {
    margin-top: -11.5625rem !important;
  }
  .mt-sm-n190 {
    margin-top: -11.875rem !important;
  }
  .mt-sm-n195 {
    margin-top: -12.1875rem !important;
  }
  .mt-sm-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-sm-n205 {
    margin-top: -12.8125rem !important;
  }
  .mt-sm-n210 {
    margin-top: -13.125rem !important;
  }
  .mt-sm-n215 {
    margin-top: -13.4375rem !important;
  }
  .mt-sm-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-sm-n225 {
    margin-top: -14.0625rem !important;
  }
  .mt-sm-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-sm-n235 {
    margin-top: -14.6875rem !important;
  }
  .mt-sm-n240 {
    margin-top: -15rem !important;
  }
  .mt-sm-n245 {
    margin-top: -15.3125rem !important;
  }
  .mt-sm-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-sm-n255 {
    margin-top: -15.9375rem !important;
  }
  .mt-sm-n260 {
    margin-top: -16.25rem !important;
  }
  .mt-sm-n265 {
    margin-top: -16.5625rem !important;
  }
  .mt-sm-n270 {
    margin-top: -16.875rem !important;
  }
  .mt-sm-n275 {
    margin-top: -17.1875rem !important;
  }
  .mt-sm-n280 {
    margin-top: -17.5rem !important;
  }
  .mt-sm-n285 {
    margin-top: -17.8125rem !important;
  }
  .mt-sm-n290 {
    margin-top: -18.125rem !important;
  }
  .mt-sm-n295 {
    margin-top: -18.4375rem !important;
  }
  .mt-sm-n300 {
    margin-top: -18.75rem !important;
  }
  .me-sm-n5 {
    margin-right: -0.313rem !important;
  }
  .me-sm-n10 {
    margin-right: -0.625rem !important;
  }
  .me-sm-n15 {
    margin-right: -0.938rem !important;
  }
  .me-sm-n20 {
    margin-right: -1.25rem !important;
  }
  .me-sm-n25 {
    margin-right: -1.563rem !important;
  }
  .me-sm-n30 {
    margin-right: -1.875rem !important;
  }
  .me-sm-n35 {
    margin-right: -2.188rem !important;
  }
  .me-sm-n40 {
    margin-right: -2.5rem !important;
  }
  .me-sm-n45 {
    margin-right: -2.813rem !important;
  }
  .me-sm-n50 {
    margin-right: -3.125rem !important;
  }
  .me-sm-n55 {
    margin-right: -3.438rem !important;
  }
  .me-sm-n60 {
    margin-right: -3.75rem !important;
  }
  .me-sm-n65 {
    margin-right: -4.063rem !important;
  }
  .me-sm-n70 {
    margin-right: -4.375rem !important;
  }
  .me-sm-n75 {
    margin-right: -4.688rem !important;
  }
  .me-sm-n80 {
    margin-right: -5rem !important;
  }
  .me-sm-n85 {
    margin-right: -5.313rem !important;
  }
  .me-sm-n90 {
    margin-right: -5.625rem !important;
  }
  .me-sm-n95 {
    margin-right: -5.938rem !important;
  }
  .me-sm-n100 {
    margin-right: -6.25rem !important;
  }
  .me-sm-n105 {
    margin-right: -6.5625rem !important;
  }
  .me-sm-n110 {
    margin-right: -6.875rem !important;
  }
  .me-sm-n115 {
    margin-right: -7.1875rem !important;
  }
  .me-sm-n120 {
    margin-right: -7.5rem !important;
  }
  .me-sm-n125 {
    margin-right: -7.8125rem !important;
  }
  .me-sm-n130 {
    margin-right: -8.125rem !important;
  }
  .me-sm-n135 {
    margin-right: -8.4375rem !important;
  }
  .me-sm-n140 {
    margin-right: -8.75rem !important;
  }
  .me-sm-n145 {
    margin-right: -9.0625rem !important;
  }
  .me-sm-n150 {
    margin-right: -9.375rem !important;
  }
  .me-sm-n155 {
    margin-right: -9.6875rem !important;
  }
  .me-sm-n160 {
    margin-right: -10rem !important;
  }
  .me-sm-n165 {
    margin-right: -10.3125rem !important;
  }
  .me-sm-n170 {
    margin-right: -10.625rem !important;
  }
  .me-sm-n175 {
    margin-right: -10.9375rem !important;
  }
  .me-sm-n180 {
    margin-right: -11.25rem !important;
  }
  .me-sm-n185 {
    margin-right: -11.5625rem !important;
  }
  .me-sm-n190 {
    margin-right: -11.875rem !important;
  }
  .me-sm-n195 {
    margin-right: -12.1875rem !important;
  }
  .me-sm-n200 {
    margin-right: -12.5rem !important;
  }
  .me-sm-n205 {
    margin-right: -12.8125rem !important;
  }
  .me-sm-n210 {
    margin-right: -13.125rem !important;
  }
  .me-sm-n215 {
    margin-right: -13.4375rem !important;
  }
  .me-sm-n220 {
    margin-right: -13.75rem !important;
  }
  .me-sm-n225 {
    margin-right: -14.0625rem !important;
  }
  .me-sm-n230 {
    margin-right: -14.375rem !important;
  }
  .me-sm-n235 {
    margin-right: -14.6875rem !important;
  }
  .me-sm-n240 {
    margin-right: -15rem !important;
  }
  .me-sm-n245 {
    margin-right: -15.3125rem !important;
  }
  .me-sm-n250 {
    margin-right: -15.625rem !important;
  }
  .me-sm-n255 {
    margin-right: -15.9375rem !important;
  }
  .me-sm-n260 {
    margin-right: -16.25rem !important;
  }
  .me-sm-n265 {
    margin-right: -16.5625rem !important;
  }
  .me-sm-n270 {
    margin-right: -16.875rem !important;
  }
  .me-sm-n275 {
    margin-right: -17.1875rem !important;
  }
  .me-sm-n280 {
    margin-right: -17.5rem !important;
  }
  .me-sm-n285 {
    margin-right: -17.8125rem !important;
  }
  .me-sm-n290 {
    margin-right: -18.125rem !important;
  }
  .me-sm-n295 {
    margin-right: -18.4375rem !important;
  }
  .me-sm-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-sm-n5 {
    margin-bottom: -0.313rem !important;
  }
  .mb-sm-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-sm-n15 {
    margin-bottom: -0.938rem !important;
  }
  .mb-sm-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-sm-n25 {
    margin-bottom: -1.563rem !important;
  }
  .mb-sm-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-sm-n35 {
    margin-bottom: -2.188rem !important;
  }
  .mb-sm-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-sm-n45 {
    margin-bottom: -2.813rem !important;
  }
  .mb-sm-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-sm-n55 {
    margin-bottom: -3.438rem !important;
  }
  .mb-sm-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-sm-n65 {
    margin-bottom: -4.063rem !important;
  }
  .mb-sm-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-sm-n75 {
    margin-bottom: -4.688rem !important;
  }
  .mb-sm-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-sm-n85 {
    margin-bottom: -5.313rem !important;
  }
  .mb-sm-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-sm-n95 {
    margin-bottom: -5.938rem !important;
  }
  .mb-sm-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-sm-n105 {
    margin-bottom: -6.5625rem !important;
  }
  .mb-sm-n110 {
    margin-bottom: -6.875rem !important;
  }
  .mb-sm-n115 {
    margin-bottom: -7.1875rem !important;
  }
  .mb-sm-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-sm-n125 {
    margin-bottom: -7.8125rem !important;
  }
  .mb-sm-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-sm-n135 {
    margin-bottom: -8.4375rem !important;
  }
  .mb-sm-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-sm-n145 {
    margin-bottom: -9.0625rem !important;
  }
  .mb-sm-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-sm-n155 {
    margin-bottom: -9.6875rem !important;
  }
  .mb-sm-n160 {
    margin-bottom: -10rem !important;
  }
  .mb-sm-n165 {
    margin-bottom: -10.3125rem !important;
  }
  .mb-sm-n170 {
    margin-bottom: -10.625rem !important;
  }
  .mb-sm-n175 {
    margin-bottom: -10.9375rem !important;
  }
  .mb-sm-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-sm-n185 {
    margin-bottom: -11.5625rem !important;
  }
  .mb-sm-n190 {
    margin-bottom: -11.875rem !important;
  }
  .mb-sm-n195 {
    margin-bottom: -12.1875rem !important;
  }
  .mb-sm-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-sm-n205 {
    margin-bottom: -12.8125rem !important;
  }
  .mb-sm-n210 {
    margin-bottom: -13.125rem !important;
  }
  .mb-sm-n215 {
    margin-bottom: -13.4375rem !important;
  }
  .mb-sm-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-sm-n225 {
    margin-bottom: -14.0625rem !important;
  }
  .mb-sm-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-sm-n235 {
    margin-bottom: -14.6875rem !important;
  }
  .mb-sm-n240 {
    margin-bottom: -15rem !important;
  }
  .mb-sm-n245 {
    margin-bottom: -15.3125rem !important;
  }
  .mb-sm-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-sm-n255 {
    margin-bottom: -15.9375rem !important;
  }
  .mb-sm-n260 {
    margin-bottom: -16.25rem !important;
  }
  .mb-sm-n265 {
    margin-bottom: -16.5625rem !important;
  }
  .mb-sm-n270 {
    margin-bottom: -16.875rem !important;
  }
  .mb-sm-n275 {
    margin-bottom: -17.1875rem !important;
  }
  .mb-sm-n280 {
    margin-bottom: -17.5rem !important;
  }
  .mb-sm-n285 {
    margin-bottom: -17.8125rem !important;
  }
  .mb-sm-n290 {
    margin-bottom: -18.125rem !important;
  }
  .mb-sm-n295 {
    margin-bottom: -18.4375rem !important;
  }
  .mb-sm-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-sm-n5 {
    margin-left: -0.313rem !important;
  }
  .ms-sm-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-sm-n15 {
    margin-left: -0.938rem !important;
  }
  .ms-sm-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-sm-n25 {
    margin-left: -1.563rem !important;
  }
  .ms-sm-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-sm-n35 {
    margin-left: -2.188rem !important;
  }
  .ms-sm-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-sm-n45 {
    margin-left: -2.813rem !important;
  }
  .ms-sm-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-sm-n55 {
    margin-left: -3.438rem !important;
  }
  .ms-sm-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-sm-n65 {
    margin-left: -4.063rem !important;
  }
  .ms-sm-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-sm-n75 {
    margin-left: -4.688rem !important;
  }
  .ms-sm-n80 {
    margin-left: -5rem !important;
  }
  .ms-sm-n85 {
    margin-left: -5.313rem !important;
  }
  .ms-sm-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-sm-n95 {
    margin-left: -5.938rem !important;
  }
  .ms-sm-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-sm-n105 {
    margin-left: -6.5625rem !important;
  }
  .ms-sm-n110 {
    margin-left: -6.875rem !important;
  }
  .ms-sm-n115 {
    margin-left: -7.1875rem !important;
  }
  .ms-sm-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-sm-n125 {
    margin-left: -7.8125rem !important;
  }
  .ms-sm-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-sm-n135 {
    margin-left: -8.4375rem !important;
  }
  .ms-sm-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-sm-n145 {
    margin-left: -9.0625rem !important;
  }
  .ms-sm-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-sm-n155 {
    margin-left: -9.6875rem !important;
  }
  .ms-sm-n160 {
    margin-left: -10rem !important;
  }
  .ms-sm-n165 {
    margin-left: -10.3125rem !important;
  }
  .ms-sm-n170 {
    margin-left: -10.625rem !important;
  }
  .ms-sm-n175 {
    margin-left: -10.9375rem !important;
  }
  .ms-sm-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-sm-n185 {
    margin-left: -11.5625rem !important;
  }
  .ms-sm-n190 {
    margin-left: -11.875rem !important;
  }
  .ms-sm-n195 {
    margin-left: -12.1875rem !important;
  }
  .ms-sm-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-sm-n205 {
    margin-left: -12.8125rem !important;
  }
  .ms-sm-n210 {
    margin-left: -13.125rem !important;
  }
  .ms-sm-n215 {
    margin-left: -13.4375rem !important;
  }
  .ms-sm-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-sm-n225 {
    margin-left: -14.0625rem !important;
  }
  .ms-sm-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-sm-n235 {
    margin-left: -14.6875rem !important;
  }
  .ms-sm-n240 {
    margin-left: -15rem !important;
  }
  .ms-sm-n245 {
    margin-left: -15.3125rem !important;
  }
  .ms-sm-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-sm-n255 {
    margin-left: -15.9375rem !important;
  }
  .ms-sm-n260 {
    margin-left: -16.25rem !important;
  }
  .ms-sm-n265 {
    margin-left: -16.5625rem !important;
  }
  .ms-sm-n270 {
    margin-left: -16.875rem !important;
  }
  .ms-sm-n275 {
    margin-left: -17.1875rem !important;
  }
  .ms-sm-n280 {
    margin-left: -17.5rem !important;
  }
  .ms-sm-n285 {
    margin-left: -17.8125rem !important;
  }
  .ms-sm-n290 {
    margin-left: -18.125rem !important;
  }
  .ms-sm-n295 {
    margin-left: -18.4375rem !important;
  }
  .ms-sm-n300 {
    margin-left: -18.75rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .p-sm-5 {
    padding: 0.313rem !important;
  }
  .p-sm-10 {
    padding: 0.625rem !important;
  }
  .p-sm-15 {
    padding: 0.938rem !important;
  }
  .p-sm-20 {
    padding: 1.25rem !important;
  }
  .p-sm-25 {
    padding: 1.563rem !important;
  }
  .p-sm-30 {
    padding: 1.875rem !important;
  }
  .p-sm-35 {
    padding: 2.188rem !important;
  }
  .p-sm-40 {
    padding: 2.5rem !important;
  }
  .p-sm-45 {
    padding: 2.813rem !important;
  }
  .p-sm-50 {
    padding: 3.125rem !important;
  }
  .p-sm-55 {
    padding: 3.438rem !important;
  }
  .p-sm-60 {
    padding: 3.75rem !important;
  }
  .p-sm-65 {
    padding: 4.063rem !important;
  }
  .p-sm-70 {
    padding: 4.375rem !important;
  }
  .p-sm-75 {
    padding: 4.688rem !important;
  }
  .p-sm-80 {
    padding: 5rem !important;
  }
  .p-sm-85 {
    padding: 5.313rem !important;
  }
  .p-sm-90 {
    padding: 5.625rem !important;
  }
  .p-sm-95 {
    padding: 5.938rem !important;
  }
  .p-sm-100 {
    padding: 6.25rem !important;
  }
  .p-sm-105 {
    padding: 6.5625rem !important;
  }
  .p-sm-110 {
    padding: 6.875rem !important;
  }
  .p-sm-115 {
    padding: 7.1875rem !important;
  }
  .p-sm-120 {
    padding: 7.5rem !important;
  }
  .p-sm-125 {
    padding: 7.8125rem !important;
  }
  .p-sm-130 {
    padding: 8.125rem !important;
  }
  .p-sm-135 {
    padding: 8.4375rem !important;
  }
  .p-sm-140 {
    padding: 8.75rem !important;
  }
  .p-sm-145 {
    padding: 9.0625rem !important;
  }
  .p-sm-150 {
    padding: 9.375rem !important;
  }
  .p-sm-155 {
    padding: 9.6875rem !important;
  }
  .p-sm-160 {
    padding: 10rem !important;
  }
  .p-sm-165 {
    padding: 10.3125rem !important;
  }
  .p-sm-170 {
    padding: 10.625rem !important;
  }
  .p-sm-175 {
    padding: 10.9375rem !important;
  }
  .p-sm-180 {
    padding: 11.25rem !important;
  }
  .p-sm-185 {
    padding: 11.5625rem !important;
  }
  .p-sm-190 {
    padding: 11.875rem !important;
  }
  .p-sm-195 {
    padding: 12.1875rem !important;
  }
  .p-sm-200 {
    padding: 12.5rem !important;
  }
  .p-sm-205 {
    padding: 12.8125rem !important;
  }
  .p-sm-210 {
    padding: 13.125rem !important;
  }
  .p-sm-215 {
    padding: 13.4375rem !important;
  }
  .p-sm-220 {
    padding: 13.75rem !important;
  }
  .p-sm-225 {
    padding: 14.0625rem !important;
  }
  .p-sm-230 {
    padding: 14.375rem !important;
  }
  .p-sm-235 {
    padding: 14.6875rem !important;
  }
  .p-sm-240 {
    padding: 15rem !important;
  }
  .p-sm-245 {
    padding: 15.3125rem !important;
  }
  .p-sm-250 {
    padding: 15.625rem !important;
  }
  .p-sm-255 {
    padding: 15.9375rem !important;
  }
  .p-sm-260 {
    padding: 16.25rem !important;
  }
  .p-sm-265 {
    padding: 16.5625rem !important;
  }
  .p-sm-270 {
    padding: 16.875rem !important;
  }
  .p-sm-275 {
    padding: 17.1875rem !important;
  }
  .p-sm-280 {
    padding: 17.5rem !important;
  }
  .p-sm-285 {
    padding: 17.8125rem !important;
  }
  .p-sm-290 {
    padding: 18.125rem !important;
  }
  .p-sm-295 {
    padding: 18.4375rem !important;
  }
  .p-sm-300 {
    padding: 18.75rem !important;
  }
  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-sm-5 {
    padding-right: 0.313rem !important;
    padding-left: 0.313rem !important;
  }
  .px-sm-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-sm-15 {
    padding-right: 0.938rem !important;
    padding-left: 0.938rem !important;
  }
  .px-sm-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-sm-25 {
    padding-right: 1.563rem !important;
    padding-left: 1.563rem !important;
  }
  .px-sm-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-sm-35 {
    padding-right: 2.188rem !important;
    padding-left: 2.188rem !important;
  }
  .px-sm-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-sm-45 {
    padding-right: 2.813rem !important;
    padding-left: 2.813rem !important;
  }
  .px-sm-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-sm-55 {
    padding-right: 3.438rem !important;
    padding-left: 3.438rem !important;
  }
  .px-sm-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-sm-65 {
    padding-right: 4.063rem !important;
    padding-left: 4.063rem !important;
  }
  .px-sm-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-sm-75 {
    padding-right: 4.688rem !important;
    padding-left: 4.688rem !important;
  }
  .px-sm-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-sm-85 {
    padding-right: 5.313rem !important;
    padding-left: 5.313rem !important;
  }
  .px-sm-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-sm-95 {
    padding-right: 5.938rem !important;
    padding-left: 5.938rem !important;
  }
  .px-sm-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-sm-105 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important;
  }
  .px-sm-110 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important;
  }
  .px-sm-115 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important;
  }
  .px-sm-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-sm-125 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important;
  }
  .px-sm-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-sm-135 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important;
  }
  .px-sm-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-sm-145 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important;
  }
  .px-sm-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-sm-155 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important;
  }
  .px-sm-160 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-sm-165 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important;
  }
  .px-sm-170 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important;
  }
  .px-sm-175 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important;
  }
  .px-sm-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-sm-185 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important;
  }
  .px-sm-190 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important;
  }
  .px-sm-195 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important;
  }
  .px-sm-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-sm-205 {
    padding-right: 12.8125rem !important;
    padding-left: 12.8125rem !important;
  }
  .px-sm-210 {
    padding-right: 13.125rem !important;
    padding-left: 13.125rem !important;
  }
  .px-sm-215 {
    padding-right: 13.4375rem !important;
    padding-left: 13.4375rem !important;
  }
  .px-sm-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-sm-225 {
    padding-right: 14.0625rem !important;
    padding-left: 14.0625rem !important;
  }
  .px-sm-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-sm-235 {
    padding-right: 14.6875rem !important;
    padding-left: 14.6875rem !important;
  }
  .px-sm-240 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-sm-245 {
    padding-right: 15.3125rem !important;
    padding-left: 15.3125rem !important;
  }
  .px-sm-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-sm-255 {
    padding-right: 15.9375rem !important;
    padding-left: 15.9375rem !important;
  }
  .px-sm-260 {
    padding-right: 16.25rem !important;
    padding-left: 16.25rem !important;
  }
  .px-sm-265 {
    padding-right: 16.5625rem !important;
    padding-left: 16.5625rem !important;
  }
  .px-sm-270 {
    padding-right: 16.875rem !important;
    padding-left: 16.875rem !important;
  }
  .px-sm-275 {
    padding-right: 17.1875rem !important;
    padding-left: 17.1875rem !important;
  }
  .px-sm-280 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }
  .px-sm-285 {
    padding-right: 17.8125rem !important;
    padding-left: 17.8125rem !important;
  }
  .px-sm-290 {
    padding-right: 18.125rem !important;
    padding-left: 18.125rem !important;
  }
  .px-sm-295 {
    padding-right: 18.4375rem !important;
    padding-left: 18.4375rem !important;
  }
  .px-sm-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-sm-5 {
    padding-top: 0.313rem !important;
    padding-bottom: 0.313rem !important;
  }
  .py-sm-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-sm-15 {
    padding-top: 0.938rem !important;
    padding-bottom: 0.938rem !important;
  }
  .py-sm-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-sm-25 {
    padding-top: 1.563rem !important;
    padding-bottom: 1.563rem !important;
  }
  .py-sm-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-sm-35 {
    padding-top: 2.188rem !important;
    padding-bottom: 2.188rem !important;
  }
  .py-sm-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-sm-45 {
    padding-top: 2.813rem !important;
    padding-bottom: 2.813rem !important;
  }
  .py-sm-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-sm-55 {
    padding-top: 3.438rem !important;
    padding-bottom: 3.438rem !important;
  }
  .py-sm-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-sm-65 {
    padding-top: 4.063rem !important;
    padding-bottom: 4.063rem !important;
  }
  .py-sm-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-sm-75 {
    padding-top: 4.688rem !important;
    padding-bottom: 4.688rem !important;
  }
  .py-sm-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-sm-85 {
    padding-top: 5.313rem !important;
    padding-bottom: 5.313rem !important;
  }
  .py-sm-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-sm-95 {
    padding-top: 5.938rem !important;
    padding-bottom: 5.938rem !important;
  }
  .py-sm-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-sm-105 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important;
  }
  .py-sm-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .py-sm-115 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important;
  }
  .py-sm-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-sm-125 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important;
  }
  .py-sm-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-sm-135 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important;
  }
  .py-sm-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-sm-145 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important;
  }
  .py-sm-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-sm-155 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important;
  }
  .py-sm-160 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-sm-165 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important;
  }
  .py-sm-170 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important;
  }
  .py-sm-175 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important;
  }
  .py-sm-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-sm-185 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important;
  }
  .py-sm-190 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important;
  }
  .py-sm-195 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important;
  }
  .py-sm-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-sm-205 {
    padding-top: 12.8125rem !important;
    padding-bottom: 12.8125rem !important;
  }
  .py-sm-210 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important;
  }
  .py-sm-215 {
    padding-top: 13.4375rem !important;
    padding-bottom: 13.4375rem !important;
  }
  .py-sm-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-sm-225 {
    padding-top: 14.0625rem !important;
    padding-bottom: 14.0625rem !important;
  }
  .py-sm-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-sm-235 {
    padding-top: 14.6875rem !important;
    padding-bottom: 14.6875rem !important;
  }
  .py-sm-240 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-sm-245 {
    padding-top: 15.3125rem !important;
    padding-bottom: 15.3125rem !important;
  }
  .py-sm-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-sm-255 {
    padding-top: 15.9375rem !important;
    padding-bottom: 15.9375rem !important;
  }
  .py-sm-260 {
    padding-top: 16.25rem !important;
    padding-bottom: 16.25rem !important;
  }
  .py-sm-265 {
    padding-top: 16.5625rem !important;
    padding-bottom: 16.5625rem !important;
  }
  .py-sm-270 {
    padding-top: 16.875rem !important;
    padding-bottom: 16.875rem !important;
  }
  .py-sm-275 {
    padding-top: 17.1875rem !important;
    padding-bottom: 17.1875rem !important;
  }
  .py-sm-280 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }
  .py-sm-285 {
    padding-top: 17.8125rem !important;
    padding-bottom: 17.8125rem !important;
  }
  .py-sm-290 {
    padding-top: 18.125rem !important;
    padding-bottom: 18.125rem !important;
  }
  .py-sm-295 {
    padding-top: 18.4375rem !important;
    padding-bottom: 18.4375rem !important;
  }
  .py-sm-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .pt-sm-5 {
    padding-top: 0.313rem !important;
  }
  .pt-sm-10 {
    padding-top: 0.625rem !important;
  }
  .pt-sm-15 {
    padding-top: 0.938rem !important;
  }
  .pt-sm-20 {
    padding-top: 1.25rem !important;
  }
  .pt-sm-25 {
    padding-top: 1.563rem !important;
  }
  .pt-sm-30 {
    padding-top: 1.875rem !important;
  }
  .pt-sm-35 {
    padding-top: 2.188rem !important;
  }
  .pt-sm-40 {
    padding-top: 2.5rem !important;
  }
  .pt-sm-45 {
    padding-top: 2.813rem !important;
  }
  .pt-sm-50 {
    padding-top: 3.125rem !important;
  }
  .pt-sm-55 {
    padding-top: 3.438rem !important;
  }
  .pt-sm-60 {
    padding-top: 3.75rem !important;
  }
  .pt-sm-65 {
    padding-top: 4.063rem !important;
  }
  .pt-sm-70 {
    padding-top: 4.375rem !important;
  }
  .pt-sm-75 {
    padding-top: 4.688rem !important;
  }
  .pt-sm-80 {
    padding-top: 5rem !important;
  }
  .pt-sm-85 {
    padding-top: 5.313rem !important;
  }
  .pt-sm-90 {
    padding-top: 5.625rem !important;
  }
  .pt-sm-95 {
    padding-top: 5.938rem !important;
  }
  .pt-sm-100 {
    padding-top: 6.25rem !important;
  }
  .pt-sm-105 {
    padding-top: 6.5625rem !important;
  }
  .pt-sm-110 {
    padding-top: 6.875rem !important;
  }
  .pt-sm-115 {
    padding-top: 7.1875rem !important;
  }
  .pt-sm-120 {
    padding-top: 7.5rem !important;
  }
  .pt-sm-125 {
    padding-top: 7.8125rem !important;
  }
  .pt-sm-130 {
    padding-top: 8.125rem !important;
  }
  .pt-sm-135 {
    padding-top: 8.4375rem !important;
  }
  .pt-sm-140 {
    padding-top: 8.75rem !important;
  }
  .pt-sm-145 {
    padding-top: 9.0625rem !important;
  }
  .pt-sm-150 {
    padding-top: 9.375rem !important;
  }
  .pt-sm-155 {
    padding-top: 9.6875rem !important;
  }
  .pt-sm-160 {
    padding-top: 10rem !important;
  }
  .pt-sm-165 {
    padding-top: 10.3125rem !important;
  }
  .pt-sm-170 {
    padding-top: 10.625rem !important;
  }
  .pt-sm-175 {
    padding-top: 10.9375rem !important;
  }
  .pt-sm-180 {
    padding-top: 11.25rem !important;
  }
  .pt-sm-185 {
    padding-top: 11.5625rem !important;
  }
  .pt-sm-190 {
    padding-top: 11.875rem !important;
  }
  .pt-sm-195 {
    padding-top: 12.1875rem !important;
  }
  .pt-sm-200 {
    padding-top: 12.5rem !important;
  }
  .pt-sm-205 {
    padding-top: 12.8125rem !important;
  }
  .pt-sm-210 {
    padding-top: 13.125rem !important;
  }
  .pt-sm-215 {
    padding-top: 13.4375rem !important;
  }
  .pt-sm-220 {
    padding-top: 13.75rem !important;
  }
  .pt-sm-225 {
    padding-top: 14.0625rem !important;
  }
  .pt-sm-230 {
    padding-top: 14.375rem !important;
  }
  .pt-sm-235 {
    padding-top: 14.6875rem !important;
  }
  .pt-sm-240 {
    padding-top: 15rem !important;
  }
  .pt-sm-245 {
    padding-top: 15.3125rem !important;
  }
  .pt-sm-250 {
    padding-top: 15.625rem !important;
  }
  .pt-sm-255 {
    padding-top: 15.9375rem !important;
  }
  .pt-sm-260 {
    padding-top: 16.25rem !important;
  }
  .pt-sm-265 {
    padding-top: 16.5625rem !important;
  }
  .pt-sm-270 {
    padding-top: 16.875rem !important;
  }
  .pt-sm-275 {
    padding-top: 17.1875rem !important;
  }
  .pt-sm-280 {
    padding-top: 17.5rem !important;
  }
  .pt-sm-285 {
    padding-top: 17.8125rem !important;
  }
  .pt-sm-290 {
    padding-top: 18.125rem !important;
  }
  .pt-sm-295 {
    padding-top: 18.4375rem !important;
  }
  .pt-sm-300 {
    padding-top: 18.75rem !important;
  }
  .pe-sm-0 {
    padding-right: 0 !important;
  }
  .pe-sm-5 {
    padding-right: 0.313rem !important;
  }
  .pe-sm-10 {
    padding-right: 0.625rem !important;
  }
  .pe-sm-15 {
    padding-right: 0.938rem !important;
  }
  .pe-sm-20 {
    padding-right: 1.25rem !important;
  }
  .pe-sm-25 {
    padding-right: 1.563rem !important;
  }
  .pe-sm-30 {
    padding-right: 1.875rem !important;
  }
  .pe-sm-35 {
    padding-right: 2.188rem !important;
  }
  .pe-sm-40 {
    padding-right: 2.5rem !important;
  }
  .pe-sm-45 {
    padding-right: 2.813rem !important;
  }
  .pe-sm-50 {
    padding-right: 3.125rem !important;
  }
  .pe-sm-55 {
    padding-right: 3.438rem !important;
  }
  .pe-sm-60 {
    padding-right: 3.75rem !important;
  }
  .pe-sm-65 {
    padding-right: 4.063rem !important;
  }
  .pe-sm-70 {
    padding-right: 4.375rem !important;
  }
  .pe-sm-75 {
    padding-right: 4.688rem !important;
  }
  .pe-sm-80 {
    padding-right: 5rem !important;
  }
  .pe-sm-85 {
    padding-right: 5.313rem !important;
  }
  .pe-sm-90 {
    padding-right: 5.625rem !important;
  }
  .pe-sm-95 {
    padding-right: 5.938rem !important;
  }
  .pe-sm-100 {
    padding-right: 6.25rem !important;
  }
  .pe-sm-105 {
    padding-right: 6.5625rem !important;
  }
  .pe-sm-110 {
    padding-right: 6.875rem !important;
  }
  .pe-sm-115 {
    padding-right: 7.1875rem !important;
  }
  .pe-sm-120 {
    padding-right: 7.5rem !important;
  }
  .pe-sm-125 {
    padding-right: 7.8125rem !important;
  }
  .pe-sm-130 {
    padding-right: 8.125rem !important;
  }
  .pe-sm-135 {
    padding-right: 8.4375rem !important;
  }
  .pe-sm-140 {
    padding-right: 8.75rem !important;
  }
  .pe-sm-145 {
    padding-right: 9.0625rem !important;
  }
  .pe-sm-150 {
    padding-right: 9.375rem !important;
  }
  .pe-sm-155 {
    padding-right: 9.6875rem !important;
  }
  .pe-sm-160 {
    padding-right: 10rem !important;
  }
  .pe-sm-165 {
    padding-right: 10.3125rem !important;
  }
  .pe-sm-170 {
    padding-right: 10.625rem !important;
  }
  .pe-sm-175 {
    padding-right: 10.9375rem !important;
  }
  .pe-sm-180 {
    padding-right: 11.25rem !important;
  }
  .pe-sm-185 {
    padding-right: 11.5625rem !important;
  }
  .pe-sm-190 {
    padding-right: 11.875rem !important;
  }
  .pe-sm-195 {
    padding-right: 12.1875rem !important;
  }
  .pe-sm-200 {
    padding-right: 12.5rem !important;
  }
  .pe-sm-205 {
    padding-right: 12.8125rem !important;
  }
  .pe-sm-210 {
    padding-right: 13.125rem !important;
  }
  .pe-sm-215 {
    padding-right: 13.4375rem !important;
  }
  .pe-sm-220 {
    padding-right: 13.75rem !important;
  }
  .pe-sm-225 {
    padding-right: 14.0625rem !important;
  }
  .pe-sm-230 {
    padding-right: 14.375rem !important;
  }
  .pe-sm-235 {
    padding-right: 14.6875rem !important;
  }
  .pe-sm-240 {
    padding-right: 15rem !important;
  }
  .pe-sm-245 {
    padding-right: 15.3125rem !important;
  }
  .pe-sm-250 {
    padding-right: 15.625rem !important;
  }
  .pe-sm-255 {
    padding-right: 15.9375rem !important;
  }
  .pe-sm-260 {
    padding-right: 16.25rem !important;
  }
  .pe-sm-265 {
    padding-right: 16.5625rem !important;
  }
  .pe-sm-270 {
    padding-right: 16.875rem !important;
  }
  .pe-sm-275 {
    padding-right: 17.1875rem !important;
  }
  .pe-sm-280 {
    padding-right: 17.5rem !important;
  }
  .pe-sm-285 {
    padding-right: 17.8125rem !important;
  }
  .pe-sm-290 {
    padding-right: 18.125rem !important;
  }
  .pe-sm-295 {
    padding-right: 18.4375rem !important;
  }
  .pe-sm-300 {
    padding-right: 18.75rem !important;
  }
  .pb-sm-0 {
    padding-bottom: 0 !important;
  }
  .pb-sm-5 {
    padding-bottom: 0.313rem !important;
  }
  .pb-sm-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-sm-15 {
    padding-bottom: 0.938rem !important;
  }
  .pb-sm-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-sm-25 {
    padding-bottom: 1.563rem !important;
  }
  .pb-sm-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-sm-35 {
    padding-bottom: 2.188rem !important;
  }
  .pb-sm-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-sm-45 {
    padding-bottom: 2.813rem !important;
  }
  .pb-sm-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-sm-55 {
    padding-bottom: 3.438rem !important;
  }
  .pb-sm-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-sm-65 {
    padding-bottom: 4.063rem !important;
  }
  .pb-sm-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-sm-75 {
    padding-bottom: 4.688rem !important;
  }
  .pb-sm-80 {
    padding-bottom: 5rem !important;
  }
  .pb-sm-85 {
    padding-bottom: 5.313rem !important;
  }
  .pb-sm-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-sm-95 {
    padding-bottom: 5.938rem !important;
  }
  .pb-sm-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-sm-105 {
    padding-bottom: 6.5625rem !important;
  }
  .pb-sm-110 {
    padding-bottom: 6.875rem !important;
  }
  .pb-sm-115 {
    padding-bottom: 7.1875rem !important;
  }
  .pb-sm-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-sm-125 {
    padding-bottom: 7.8125rem !important;
  }
  .pb-sm-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-sm-135 {
    padding-bottom: 8.4375rem !important;
  }
  .pb-sm-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-sm-145 {
    padding-bottom: 9.0625rem !important;
  }
  .pb-sm-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-sm-155 {
    padding-bottom: 9.6875rem !important;
  }
  .pb-sm-160 {
    padding-bottom: 10rem !important;
  }
  .pb-sm-165 {
    padding-bottom: 10.3125rem !important;
  }
  .pb-sm-170 {
    padding-bottom: 10.625rem !important;
  }
  .pb-sm-175 {
    padding-bottom: 10.9375rem !important;
  }
  .pb-sm-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-sm-185 {
    padding-bottom: 11.5625rem !important;
  }
  .pb-sm-190 {
    padding-bottom: 11.875rem !important;
  }
  .pb-sm-195 {
    padding-bottom: 12.1875rem !important;
  }
  .pb-sm-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-sm-205 {
    padding-bottom: 12.8125rem !important;
  }
  .pb-sm-210 {
    padding-bottom: 13.125rem !important;
  }
  .pb-sm-215 {
    padding-bottom: 13.4375rem !important;
  }
  .pb-sm-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-sm-225 {
    padding-bottom: 14.0625rem !important;
  }
  .pb-sm-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-sm-235 {
    padding-bottom: 14.6875rem !important;
  }
  .pb-sm-240 {
    padding-bottom: 15rem !important;
  }
  .pb-sm-245 {
    padding-bottom: 15.3125rem !important;
  }
  .pb-sm-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-sm-255 {
    padding-bottom: 15.9375rem !important;
  }
  .pb-sm-260 {
    padding-bottom: 16.25rem !important;
  }
  .pb-sm-265 {
    padding-bottom: 16.5625rem !important;
  }
  .pb-sm-270 {
    padding-bottom: 16.875rem !important;
  }
  .pb-sm-275 {
    padding-bottom: 17.1875rem !important;
  }
  .pb-sm-280 {
    padding-bottom: 17.5rem !important;
  }
  .pb-sm-285 {
    padding-bottom: 17.8125rem !important;
  }
  .pb-sm-290 {
    padding-bottom: 18.125rem !important;
  }
  .pb-sm-295 {
    padding-bottom: 18.4375rem !important;
  }
  .pb-sm-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-sm-0 {
    padding-left: 0 !important;
  }
  .ps-sm-5 {
    padding-left: 0.313rem !important;
  }
  .ps-sm-10 {
    padding-left: 0.625rem !important;
  }
  .ps-sm-15 {
    padding-left: 0.938rem !important;
  }
  .ps-sm-20 {
    padding-left: 1.25rem !important;
  }
  .ps-sm-25 {
    padding-left: 1.563rem !important;
  }
  .ps-sm-30 {
    padding-left: 1.875rem !important;
  }
  .ps-sm-35 {
    padding-left: 2.188rem !important;
  }
  .ps-sm-40 {
    padding-left: 2.5rem !important;
  }
  .ps-sm-45 {
    padding-left: 2.813rem !important;
  }
  .ps-sm-50 {
    padding-left: 3.125rem !important;
  }
  .ps-sm-55 {
    padding-left: 3.438rem !important;
  }
  .ps-sm-60 {
    padding-left: 3.75rem !important;
  }
  .ps-sm-65 {
    padding-left: 4.063rem !important;
  }
  .ps-sm-70 {
    padding-left: 4.375rem !important;
  }
  .ps-sm-75 {
    padding-left: 4.688rem !important;
  }
  .ps-sm-80 {
    padding-left: 5rem !important;
  }
  .ps-sm-85 {
    padding-left: 5.313rem !important;
  }
  .ps-sm-90 {
    padding-left: 5.625rem !important;
  }
  .ps-sm-95 {
    padding-left: 5.938rem !important;
  }
  .ps-sm-100 {
    padding-left: 6.25rem !important;
  }
  .ps-sm-105 {
    padding-left: 6.5625rem !important;
  }
  .ps-sm-110 {
    padding-left: 6.875rem !important;
  }
  .ps-sm-115 {
    padding-left: 7.1875rem !important;
  }
  .ps-sm-120 {
    padding-left: 7.5rem !important;
  }
  .ps-sm-125 {
    padding-left: 7.8125rem !important;
  }
  .ps-sm-130 {
    padding-left: 8.125rem !important;
  }
  .ps-sm-135 {
    padding-left: 8.4375rem !important;
  }
  .ps-sm-140 {
    padding-left: 8.75rem !important;
  }
  .ps-sm-145 {
    padding-left: 9.0625rem !important;
  }
  .ps-sm-150 {
    padding-left: 9.375rem !important;
  }
  .ps-sm-155 {
    padding-left: 9.6875rem !important;
  }
  .ps-sm-160 {
    padding-left: 10rem !important;
  }
  .ps-sm-165 {
    padding-left: 10.3125rem !important;
  }
  .ps-sm-170 {
    padding-left: 10.625rem !important;
  }
  .ps-sm-175 {
    padding-left: 10.9375rem !important;
  }
  .ps-sm-180 {
    padding-left: 11.25rem !important;
  }
  .ps-sm-185 {
    padding-left: 11.5625rem !important;
  }
  .ps-sm-190 {
    padding-left: 11.875rem !important;
  }
  .ps-sm-195 {
    padding-left: 12.1875rem !important;
  }
  .ps-sm-200 {
    padding-left: 12.5rem !important;
  }
  .ps-sm-205 {
    padding-left: 12.8125rem !important;
  }
  .ps-sm-210 {
    padding-left: 13.125rem !important;
  }
  .ps-sm-215 {
    padding-left: 13.4375rem !important;
  }
  .ps-sm-220 {
    padding-left: 13.75rem !important;
  }
  .ps-sm-225 {
    padding-left: 14.0625rem !important;
  }
  .ps-sm-230 {
    padding-left: 14.375rem !important;
  }
  .ps-sm-235 {
    padding-left: 14.6875rem !important;
  }
  .ps-sm-240 {
    padding-left: 15rem !important;
  }
  .ps-sm-245 {
    padding-left: 15.3125rem !important;
  }
  .ps-sm-250 {
    padding-left: 15.625rem !important;
  }
  .ps-sm-255 {
    padding-left: 15.9375rem !important;
  }
  .ps-sm-260 {
    padding-left: 16.25rem !important;
  }
  .ps-sm-265 {
    padding-left: 16.5625rem !important;
  }
  .ps-sm-270 {
    padding-left: 16.875rem !important;
  }
  .ps-sm-275 {
    padding-left: 17.1875rem !important;
  }
  .ps-sm-280 {
    padding-left: 17.5rem !important;
  }
  .ps-sm-285 {
    padding-left: 17.8125rem !important;
  }
  .ps-sm-290 {
    padding-left: 18.125rem !important;
  }
  .ps-sm-295 {
    padding-left: 18.4375rem !important;
  }
  .ps-sm-300 {
    padding-left: 18.75rem !important;
  }
  .gap-sm-0 {
    gap: 0 !important;
  }
  .gap-sm-5 {
    gap: 0.313rem !important;
  }
  .gap-sm-10 {
    gap: 0.625rem !important;
  }
  .gap-sm-15 {
    gap: 0.938rem !important;
  }
  .gap-sm-20 {
    gap: 1.25rem !important;
  }
  .gap-sm-25 {
    gap: 1.563rem !important;
  }
  .gap-sm-30 {
    gap: 1.875rem !important;
  }
  .gap-sm-35 {
    gap: 2.188rem !important;
  }
  .gap-sm-40 {
    gap: 2.5rem !important;
  }
  .gap-sm-45 {
    gap: 2.813rem !important;
  }
  .gap-sm-50 {
    gap: 3.125rem !important;
  }
  .gap-sm-55 {
    gap: 3.438rem !important;
  }
  .gap-sm-60 {
    gap: 3.75rem !important;
  }
  .gap-sm-65 {
    gap: 4.063rem !important;
  }
  .gap-sm-70 {
    gap: 4.375rem !important;
  }
  .gap-sm-75 {
    gap: 4.688rem !important;
  }
  .gap-sm-80 {
    gap: 5rem !important;
  }
  .gap-sm-85 {
    gap: 5.313rem !important;
  }
  .gap-sm-90 {
    gap: 5.625rem !important;
  }
  .gap-sm-95 {
    gap: 5.938rem !important;
  }
  .gap-sm-100 {
    gap: 6.25rem !important;
  }
  .gap-sm-105 {
    gap: 6.5625rem !important;
  }
  .gap-sm-110 {
    gap: 6.875rem !important;
  }
  .gap-sm-115 {
    gap: 7.1875rem !important;
  }
  .gap-sm-120 {
    gap: 7.5rem !important;
  }
  .gap-sm-125 {
    gap: 7.8125rem !important;
  }
  .gap-sm-130 {
    gap: 8.125rem !important;
  }
  .gap-sm-135 {
    gap: 8.4375rem !important;
  }
  .gap-sm-140 {
    gap: 8.75rem !important;
  }
  .gap-sm-145 {
    gap: 9.0625rem !important;
  }
  .gap-sm-150 {
    gap: 9.375rem !important;
  }
  .gap-sm-155 {
    gap: 9.6875rem !important;
  }
  .gap-sm-160 {
    gap: 10rem !important;
  }
  .gap-sm-165 {
    gap: 10.3125rem !important;
  }
  .gap-sm-170 {
    gap: 10.625rem !important;
  }
  .gap-sm-175 {
    gap: 10.9375rem !important;
  }
  .gap-sm-180 {
    gap: 11.25rem !important;
  }
  .gap-sm-185 {
    gap: 11.5625rem !important;
  }
  .gap-sm-190 {
    gap: 11.875rem !important;
  }
  .gap-sm-195 {
    gap: 12.1875rem !important;
  }
  .gap-sm-200 {
    gap: 12.5rem !important;
  }
  .gap-sm-205 {
    gap: 12.8125rem !important;
  }
  .gap-sm-210 {
    gap: 13.125rem !important;
  }
  .gap-sm-215 {
    gap: 13.4375rem !important;
  }
  .gap-sm-220 {
    gap: 13.75rem !important;
  }
  .gap-sm-225 {
    gap: 14.0625rem !important;
  }
  .gap-sm-230 {
    gap: 14.375rem !important;
  }
  .gap-sm-235 {
    gap: 14.6875rem !important;
  }
  .gap-sm-240 {
    gap: 15rem !important;
  }
  .gap-sm-245 {
    gap: 15.3125rem !important;
  }
  .gap-sm-250 {
    gap: 15.625rem !important;
  }
  .gap-sm-255 {
    gap: 15.9375rem !important;
  }
  .gap-sm-260 {
    gap: 16.25rem !important;
  }
  .gap-sm-265 {
    gap: 16.5625rem !important;
  }
  .gap-sm-270 {
    gap: 16.875rem !important;
  }
  .gap-sm-275 {
    gap: 17.1875rem !important;
  }
  .gap-sm-280 {
    gap: 17.5rem !important;
  }
  .gap-sm-285 {
    gap: 17.8125rem !important;
  }
  .gap-sm-290 {
    gap: 18.125rem !important;
  }
  .gap-sm-295 {
    gap: 18.4375rem !important;
  }
  .gap-sm-300 {
    gap: 18.75rem !important;
  }
  .row-gap-sm-0 {
    row-gap: 0 !important;
  }
  .row-gap-sm-5 {
    row-gap: 0.313rem !important;
  }
  .row-gap-sm-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-sm-15 {
    row-gap: 0.938rem !important;
  }
  .row-gap-sm-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-sm-25 {
    row-gap: 1.563rem !important;
  }
  .row-gap-sm-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-sm-35 {
    row-gap: 2.188rem !important;
  }
  .row-gap-sm-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-sm-45 {
    row-gap: 2.813rem !important;
  }
  .row-gap-sm-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-sm-55 {
    row-gap: 3.438rem !important;
  }
  .row-gap-sm-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-sm-65 {
    row-gap: 4.063rem !important;
  }
  .row-gap-sm-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-sm-75 {
    row-gap: 4.688rem !important;
  }
  .row-gap-sm-80 {
    row-gap: 5rem !important;
  }
  .row-gap-sm-85 {
    row-gap: 5.313rem !important;
  }
  .row-gap-sm-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-sm-95 {
    row-gap: 5.938rem !important;
  }
  .row-gap-sm-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-sm-105 {
    row-gap: 6.5625rem !important;
  }
  .row-gap-sm-110 {
    row-gap: 6.875rem !important;
  }
  .row-gap-sm-115 {
    row-gap: 7.1875rem !important;
  }
  .row-gap-sm-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-sm-125 {
    row-gap: 7.8125rem !important;
  }
  .row-gap-sm-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-sm-135 {
    row-gap: 8.4375rem !important;
  }
  .row-gap-sm-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-sm-145 {
    row-gap: 9.0625rem !important;
  }
  .row-gap-sm-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-sm-155 {
    row-gap: 9.6875rem !important;
  }
  .row-gap-sm-160 {
    row-gap: 10rem !important;
  }
  .row-gap-sm-165 {
    row-gap: 10.3125rem !important;
  }
  .row-gap-sm-170 {
    row-gap: 10.625rem !important;
  }
  .row-gap-sm-175 {
    row-gap: 10.9375rem !important;
  }
  .row-gap-sm-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-sm-185 {
    row-gap: 11.5625rem !important;
  }
  .row-gap-sm-190 {
    row-gap: 11.875rem !important;
  }
  .row-gap-sm-195 {
    row-gap: 12.1875rem !important;
  }
  .row-gap-sm-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-sm-205 {
    row-gap: 12.8125rem !important;
  }
  .row-gap-sm-210 {
    row-gap: 13.125rem !important;
  }
  .row-gap-sm-215 {
    row-gap: 13.4375rem !important;
  }
  .row-gap-sm-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-sm-225 {
    row-gap: 14.0625rem !important;
  }
  .row-gap-sm-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-sm-235 {
    row-gap: 14.6875rem !important;
  }
  .row-gap-sm-240 {
    row-gap: 15rem !important;
  }
  .row-gap-sm-245 {
    row-gap: 15.3125rem !important;
  }
  .row-gap-sm-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-sm-255 {
    row-gap: 15.9375rem !important;
  }
  .row-gap-sm-260 {
    row-gap: 16.25rem !important;
  }
  .row-gap-sm-265 {
    row-gap: 16.5625rem !important;
  }
  .row-gap-sm-270 {
    row-gap: 16.875rem !important;
  }
  .row-gap-sm-275 {
    row-gap: 17.1875rem !important;
  }
  .row-gap-sm-280 {
    row-gap: 17.5rem !important;
  }
  .row-gap-sm-285 {
    row-gap: 17.8125rem !important;
  }
  .row-gap-sm-290 {
    row-gap: 18.125rem !important;
  }
  .row-gap-sm-295 {
    row-gap: 18.4375rem !important;
  }
  .row-gap-sm-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-sm-0 {
    column-gap: 0 !important;
  }
  .column-gap-sm-5 {
    column-gap: 0.313rem !important;
  }
  .column-gap-sm-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-sm-15 {
    column-gap: 0.938rem !important;
  }
  .column-gap-sm-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-sm-25 {
    column-gap: 1.563rem !important;
  }
  .column-gap-sm-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-sm-35 {
    column-gap: 2.188rem !important;
  }
  .column-gap-sm-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-sm-45 {
    column-gap: 2.813rem !important;
  }
  .column-gap-sm-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-sm-55 {
    column-gap: 3.438rem !important;
  }
  .column-gap-sm-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-sm-65 {
    column-gap: 4.063rem !important;
  }
  .column-gap-sm-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-sm-75 {
    column-gap: 4.688rem !important;
  }
  .column-gap-sm-80 {
    column-gap: 5rem !important;
  }
  .column-gap-sm-85 {
    column-gap: 5.313rem !important;
  }
  .column-gap-sm-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-sm-95 {
    column-gap: 5.938rem !important;
  }
  .column-gap-sm-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-sm-105 {
    column-gap: 6.5625rem !important;
  }
  .column-gap-sm-110 {
    column-gap: 6.875rem !important;
  }
  .column-gap-sm-115 {
    column-gap: 7.1875rem !important;
  }
  .column-gap-sm-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-sm-125 {
    column-gap: 7.8125rem !important;
  }
  .column-gap-sm-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-sm-135 {
    column-gap: 8.4375rem !important;
  }
  .column-gap-sm-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-sm-145 {
    column-gap: 9.0625rem !important;
  }
  .column-gap-sm-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-sm-155 {
    column-gap: 9.6875rem !important;
  }
  .column-gap-sm-160 {
    column-gap: 10rem !important;
  }
  .column-gap-sm-165 {
    column-gap: 10.3125rem !important;
  }
  .column-gap-sm-170 {
    column-gap: 10.625rem !important;
  }
  .column-gap-sm-175 {
    column-gap: 10.9375rem !important;
  }
  .column-gap-sm-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-sm-185 {
    column-gap: 11.5625rem !important;
  }
  .column-gap-sm-190 {
    column-gap: 11.875rem !important;
  }
  .column-gap-sm-195 {
    column-gap: 12.1875rem !important;
  }
  .column-gap-sm-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-sm-205 {
    column-gap: 12.8125rem !important;
  }
  .column-gap-sm-210 {
    column-gap: 13.125rem !important;
  }
  .column-gap-sm-215 {
    column-gap: 13.4375rem !important;
  }
  .column-gap-sm-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-sm-225 {
    column-gap: 14.0625rem !important;
  }
  .column-gap-sm-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-sm-235 {
    column-gap: 14.6875rem !important;
  }
  .column-gap-sm-240 {
    column-gap: 15rem !important;
  }
  .column-gap-sm-245 {
    column-gap: 15.3125rem !important;
  }
  .column-gap-sm-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-sm-255 {
    column-gap: 15.9375rem !important;
  }
  .column-gap-sm-260 {
    column-gap: 16.25rem !important;
  }
  .column-gap-sm-265 {
    column-gap: 16.5625rem !important;
  }
  .column-gap-sm-270 {
    column-gap: 16.875rem !important;
  }
  .column-gap-sm-275 {
    column-gap: 17.1875rem !important;
  }
  .column-gap-sm-280 {
    column-gap: 17.5rem !important;
  }
  .column-gap-sm-285 {
    column-gap: 17.8125rem !important;
  }
  .column-gap-sm-290 {
    column-gap: 18.125rem !important;
  }
  .column-gap-sm-295 {
    column-gap: 18.4375rem !important;
  }
  .column-gap-sm-300 {
    column-gap: 18.75rem !important;
  }
  .text-sm-start {
    text-align: left !important;
  }
  .text-sm-end {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }
  .float-md-end {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
  .object-fit-md-contain {
    object-fit: contain !important;
  }
  .object-fit-md-cover {
    object-fit: cover !important;
  }
  .object-fit-md-fill {
    object-fit: fill !important;
  }
  .object-fit-md-scale {
    object-fit: scale-down !important;
  }
  .object-fit-md-none {
    object-fit: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-grid {
    display: grid !important;
  }
  .d-md-inline-grid {
    display: inline-grid !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
  .d-md-none {
    display: none !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
  .order-md-first {
    order: -1 !important;
  }
  .order-md-0 {
    order: 0 !important;
  }
  .order-md-1 {
    order: 1 !important;
  }
  .order-md-2 {
    order: 2 !important;
  }
  .order-md-3 {
    order: 3 !important;
  }
  .order-md-4 {
    order: 4 !important;
  }
  .order-md-5 {
    order: 5 !important;
  }
  .order-md-last {
    order: 6 !important;
  }
  .m-md-0 {
    margin: 0 !important;
  }
  .m-md-5 {
    margin: 0.313rem !important;
  }
  .m-md-10 {
    margin: 0.625rem !important;
  }
  .m-md-15 {
    margin: 0.938rem !important;
  }
  .m-md-20 {
    margin: 1.25rem !important;
  }
  .m-md-25 {
    margin: 1.563rem !important;
  }
  .m-md-30 {
    margin: 1.875rem !important;
  }
  .m-md-35 {
    margin: 2.188rem !important;
  }
  .m-md-40 {
    margin: 2.5rem !important;
  }
  .m-md-45 {
    margin: 2.813rem !important;
  }
  .m-md-50 {
    margin: 3.125rem !important;
  }
  .m-md-55 {
    margin: 3.438rem !important;
  }
  .m-md-60 {
    margin: 3.75rem !important;
  }
  .m-md-65 {
    margin: 4.063rem !important;
  }
  .m-md-70 {
    margin: 4.375rem !important;
  }
  .m-md-75 {
    margin: 4.688rem !important;
  }
  .m-md-80 {
    margin: 5rem !important;
  }
  .m-md-85 {
    margin: 5.313rem !important;
  }
  .m-md-90 {
    margin: 5.625rem !important;
  }
  .m-md-95 {
    margin: 5.938rem !important;
  }
  .m-md-100 {
    margin: 6.25rem !important;
  }
  .m-md-105 {
    margin: 6.5625rem !important;
  }
  .m-md-110 {
    margin: 6.875rem !important;
  }
  .m-md-115 {
    margin: 7.1875rem !important;
  }
  .m-md-120 {
    margin: 7.5rem !important;
  }
  .m-md-125 {
    margin: 7.8125rem !important;
  }
  .m-md-130 {
    margin: 8.125rem !important;
  }
  .m-md-135 {
    margin: 8.4375rem !important;
  }
  .m-md-140 {
    margin: 8.75rem !important;
  }
  .m-md-145 {
    margin: 9.0625rem !important;
  }
  .m-md-150 {
    margin: 9.375rem !important;
  }
  .m-md-155 {
    margin: 9.6875rem !important;
  }
  .m-md-160 {
    margin: 10rem !important;
  }
  .m-md-165 {
    margin: 10.3125rem !important;
  }
  .m-md-170 {
    margin: 10.625rem !important;
  }
  .m-md-175 {
    margin: 10.9375rem !important;
  }
  .m-md-180 {
    margin: 11.25rem !important;
  }
  .m-md-185 {
    margin: 11.5625rem !important;
  }
  .m-md-190 {
    margin: 11.875rem !important;
  }
  .m-md-195 {
    margin: 12.1875rem !important;
  }
  .m-md-200 {
    margin: 12.5rem !important;
  }
  .m-md-205 {
    margin: 12.8125rem !important;
  }
  .m-md-210 {
    margin: 13.125rem !important;
  }
  .m-md-215 {
    margin: 13.4375rem !important;
  }
  .m-md-220 {
    margin: 13.75rem !important;
  }
  .m-md-225 {
    margin: 14.0625rem !important;
  }
  .m-md-230 {
    margin: 14.375rem !important;
  }
  .m-md-235 {
    margin: 14.6875rem !important;
  }
  .m-md-240 {
    margin: 15rem !important;
  }
  .m-md-245 {
    margin: 15.3125rem !important;
  }
  .m-md-250 {
    margin: 15.625rem !important;
  }
  .m-md-255 {
    margin: 15.9375rem !important;
  }
  .m-md-260 {
    margin: 16.25rem !important;
  }
  .m-md-265 {
    margin: 16.5625rem !important;
  }
  .m-md-270 {
    margin: 16.875rem !important;
  }
  .m-md-275 {
    margin: 17.1875rem !important;
  }
  .m-md-280 {
    margin: 17.5rem !important;
  }
  .m-md-285 {
    margin: 17.8125rem !important;
  }
  .m-md-290 {
    margin: 18.125rem !important;
  }
  .m-md-295 {
    margin: 18.4375rem !important;
  }
  .m-md-300 {
    margin: 18.75rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-md-5 {
    margin-right: 0.313rem !important;
    margin-left: 0.313rem !important;
  }
  .mx-md-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-md-15 {
    margin-right: 0.938rem !important;
    margin-left: 0.938rem !important;
  }
  .mx-md-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-md-25 {
    margin-right: 1.563rem !important;
    margin-left: 1.563rem !important;
  }
  .mx-md-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-md-35 {
    margin-right: 2.188rem !important;
    margin-left: 2.188rem !important;
  }
  .mx-md-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-md-45 {
    margin-right: 2.813rem !important;
    margin-left: 2.813rem !important;
  }
  .mx-md-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-md-55 {
    margin-right: 3.438rem !important;
    margin-left: 3.438rem !important;
  }
  .mx-md-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-md-65 {
    margin-right: 4.063rem !important;
    margin-left: 4.063rem !important;
  }
  .mx-md-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-md-75 {
    margin-right: 4.688rem !important;
    margin-left: 4.688rem !important;
  }
  .mx-md-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-md-85 {
    margin-right: 5.313rem !important;
    margin-left: 5.313rem !important;
  }
  .mx-md-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-md-95 {
    margin-right: 5.938rem !important;
    margin-left: 5.938rem !important;
  }
  .mx-md-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-md-105 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important;
  }
  .mx-md-110 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important;
  }
  .mx-md-115 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important;
  }
  .mx-md-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-md-125 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important;
  }
  .mx-md-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-md-135 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important;
  }
  .mx-md-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-md-145 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important;
  }
  .mx-md-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-md-155 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important;
  }
  .mx-md-160 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-md-165 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important;
  }
  .mx-md-170 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important;
  }
  .mx-md-175 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important;
  }
  .mx-md-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-md-185 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important;
  }
  .mx-md-190 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important;
  }
  .mx-md-195 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important;
  }
  .mx-md-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-md-205 {
    margin-right: 12.8125rem !important;
    margin-left: 12.8125rem !important;
  }
  .mx-md-210 {
    margin-right: 13.125rem !important;
    margin-left: 13.125rem !important;
  }
  .mx-md-215 {
    margin-right: 13.4375rem !important;
    margin-left: 13.4375rem !important;
  }
  .mx-md-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-md-225 {
    margin-right: 14.0625rem !important;
    margin-left: 14.0625rem !important;
  }
  .mx-md-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-md-235 {
    margin-right: 14.6875rem !important;
    margin-left: 14.6875rem !important;
  }
  .mx-md-240 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-md-245 {
    margin-right: 15.3125rem !important;
    margin-left: 15.3125rem !important;
  }
  .mx-md-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-md-255 {
    margin-right: 15.9375rem !important;
    margin-left: 15.9375rem !important;
  }
  .mx-md-260 {
    margin-right: 16.25rem !important;
    margin-left: 16.25rem !important;
  }
  .mx-md-265 {
    margin-right: 16.5625rem !important;
    margin-left: 16.5625rem !important;
  }
  .mx-md-270 {
    margin-right: 16.875rem !important;
    margin-left: 16.875rem !important;
  }
  .mx-md-275 {
    margin-right: 17.1875rem !important;
    margin-left: 17.1875rem !important;
  }
  .mx-md-280 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }
  .mx-md-285 {
    margin-right: 17.8125rem !important;
    margin-left: 17.8125rem !important;
  }
  .mx-md-290 {
    margin-right: 18.125rem !important;
    margin-left: 18.125rem !important;
  }
  .mx-md-295 {
    margin-right: 18.4375rem !important;
    margin-left: 18.4375rem !important;
  }
  .mx-md-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-md-5 {
    margin-top: 0.313rem !important;
    margin-bottom: 0.313rem !important;
  }
  .my-md-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-md-15 {
    margin-top: 0.938rem !important;
    margin-bottom: 0.938rem !important;
  }
  .my-md-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-md-25 {
    margin-top: 1.563rem !important;
    margin-bottom: 1.563rem !important;
  }
  .my-md-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-md-35 {
    margin-top: 2.188rem !important;
    margin-bottom: 2.188rem !important;
  }
  .my-md-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-md-45 {
    margin-top: 2.813rem !important;
    margin-bottom: 2.813rem !important;
  }
  .my-md-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-md-55 {
    margin-top: 3.438rem !important;
    margin-bottom: 3.438rem !important;
  }
  .my-md-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-md-65 {
    margin-top: 4.063rem !important;
    margin-bottom: 4.063rem !important;
  }
  .my-md-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-md-75 {
    margin-top: 4.688rem !important;
    margin-bottom: 4.688rem !important;
  }
  .my-md-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-md-85 {
    margin-top: 5.313rem !important;
    margin-bottom: 5.313rem !important;
  }
  .my-md-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-md-95 {
    margin-top: 5.938rem !important;
    margin-bottom: 5.938rem !important;
  }
  .my-md-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-md-105 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important;
  }
  .my-md-110 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important;
  }
  .my-md-115 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important;
  }
  .my-md-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-md-125 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important;
  }
  .my-md-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-md-135 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important;
  }
  .my-md-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-md-145 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important;
  }
  .my-md-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-md-155 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important;
  }
  .my-md-160 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-md-165 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important;
  }
  .my-md-170 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important;
  }
  .my-md-175 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important;
  }
  .my-md-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-md-185 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important;
  }
  .my-md-190 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important;
  }
  .my-md-195 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important;
  }
  .my-md-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-md-205 {
    margin-top: 12.8125rem !important;
    margin-bottom: 12.8125rem !important;
  }
  .my-md-210 {
    margin-top: 13.125rem !important;
    margin-bottom: 13.125rem !important;
  }
  .my-md-215 {
    margin-top: 13.4375rem !important;
    margin-bottom: 13.4375rem !important;
  }
  .my-md-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-md-225 {
    margin-top: 14.0625rem !important;
    margin-bottom: 14.0625rem !important;
  }
  .my-md-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-md-235 {
    margin-top: 14.6875rem !important;
    margin-bottom: 14.6875rem !important;
  }
  .my-md-240 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-md-245 {
    margin-top: 15.3125rem !important;
    margin-bottom: 15.3125rem !important;
  }
  .my-md-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-md-255 {
    margin-top: 15.9375rem !important;
    margin-bottom: 15.9375rem !important;
  }
  .my-md-260 {
    margin-top: 16.25rem !important;
    margin-bottom: 16.25rem !important;
  }
  .my-md-265 {
    margin-top: 16.5625rem !important;
    margin-bottom: 16.5625rem !important;
  }
  .my-md-270 {
    margin-top: 16.875rem !important;
    margin-bottom: 16.875rem !important;
  }
  .my-md-275 {
    margin-top: 17.1875rem !important;
    margin-bottom: 17.1875rem !important;
  }
  .my-md-280 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }
  .my-md-285 {
    margin-top: 17.8125rem !important;
    margin-bottom: 17.8125rem !important;
  }
  .my-md-290 {
    margin-top: 18.125rem !important;
    margin-bottom: 18.125rem !important;
  }
  .my-md-295 {
    margin-top: 18.4375rem !important;
    margin-bottom: 18.4375rem !important;
  }
  .my-md-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-md-0 {
    margin-top: 0 !important;
  }
  .mt-md-5 {
    margin-top: 0.313rem !important;
  }
  .mt-md-10 {
    margin-top: 0.625rem !important;
  }
  .mt-md-15 {
    margin-top: 0.938rem !important;
  }
  .mt-md-20 {
    margin-top: 1.25rem !important;
  }
  .mt-md-25 {
    margin-top: 1.563rem !important;
  }
  .mt-md-30 {
    margin-top: 1.875rem !important;
  }
  .mt-md-35 {
    margin-top: 2.188rem !important;
  }
  .mt-md-40 {
    margin-top: 2.5rem !important;
  }
  .mt-md-45 {
    margin-top: 2.813rem !important;
  }
  .mt-md-50 {
    margin-top: 3.125rem !important;
  }
  .mt-md-55 {
    margin-top: 3.438rem !important;
  }
  .mt-md-60 {
    margin-top: 3.75rem !important;
  }
  .mt-md-65 {
    margin-top: 4.063rem !important;
  }
  .mt-md-70 {
    margin-top: 4.375rem !important;
  }
  .mt-md-75 {
    margin-top: 4.688rem !important;
  }
  .mt-md-80 {
    margin-top: 5rem !important;
  }
  .mt-md-85 {
    margin-top: 5.313rem !important;
  }
  .mt-md-90 {
    margin-top: 5.625rem !important;
  }
  .mt-md-95 {
    margin-top: 5.938rem !important;
  }
  .mt-md-100 {
    margin-top: 6.25rem !important;
  }
  .mt-md-105 {
    margin-top: 6.5625rem !important;
  }
  .mt-md-110 {
    margin-top: 6.875rem !important;
  }
  .mt-md-115 {
    margin-top: 7.1875rem !important;
  }
  .mt-md-120 {
    margin-top: 7.5rem !important;
  }
  .mt-md-125 {
    margin-top: 7.8125rem !important;
  }
  .mt-md-130 {
    margin-top: 8.125rem !important;
  }
  .mt-md-135 {
    margin-top: 8.4375rem !important;
  }
  .mt-md-140 {
    margin-top: 8.75rem !important;
  }
  .mt-md-145 {
    margin-top: 9.0625rem !important;
  }
  .mt-md-150 {
    margin-top: 9.375rem !important;
  }
  .mt-md-155 {
    margin-top: 9.6875rem !important;
  }
  .mt-md-160 {
    margin-top: 10rem !important;
  }
  .mt-md-165 {
    margin-top: 10.3125rem !important;
  }
  .mt-md-170 {
    margin-top: 10.625rem !important;
  }
  .mt-md-175 {
    margin-top: 10.9375rem !important;
  }
  .mt-md-180 {
    margin-top: 11.25rem !important;
  }
  .mt-md-185 {
    margin-top: 11.5625rem !important;
  }
  .mt-md-190 {
    margin-top: 11.875rem !important;
  }
  .mt-md-195 {
    margin-top: 12.1875rem !important;
  }
  .mt-md-200 {
    margin-top: 12.5rem !important;
  }
  .mt-md-205 {
    margin-top: 12.8125rem !important;
  }
  .mt-md-210 {
    margin-top: 13.125rem !important;
  }
  .mt-md-215 {
    margin-top: 13.4375rem !important;
  }
  .mt-md-220 {
    margin-top: 13.75rem !important;
  }
  .mt-md-225 {
    margin-top: 14.0625rem !important;
  }
  .mt-md-230 {
    margin-top: 14.375rem !important;
  }
  .mt-md-235 {
    margin-top: 14.6875rem !important;
  }
  .mt-md-240 {
    margin-top: 15rem !important;
  }
  .mt-md-245 {
    margin-top: 15.3125rem !important;
  }
  .mt-md-250 {
    margin-top: 15.625rem !important;
  }
  .mt-md-255 {
    margin-top: 15.9375rem !important;
  }
  .mt-md-260 {
    margin-top: 16.25rem !important;
  }
  .mt-md-265 {
    margin-top: 16.5625rem !important;
  }
  .mt-md-270 {
    margin-top: 16.875rem !important;
  }
  .mt-md-275 {
    margin-top: 17.1875rem !important;
  }
  .mt-md-280 {
    margin-top: 17.5rem !important;
  }
  .mt-md-285 {
    margin-top: 17.8125rem !important;
  }
  .mt-md-290 {
    margin-top: 18.125rem !important;
  }
  .mt-md-295 {
    margin-top: 18.4375rem !important;
  }
  .mt-md-300 {
    margin-top: 18.75rem !important;
  }
  .mt-md-auto {
    margin-top: auto !important;
  }
  .me-md-0 {
    margin-right: 0 !important;
  }
  .me-md-5 {
    margin-right: 0.313rem !important;
  }
  .me-md-10 {
    margin-right: 0.625rem !important;
  }
  .me-md-15 {
    margin-right: 0.938rem !important;
  }
  .me-md-20 {
    margin-right: 1.25rem !important;
  }
  .me-md-25 {
    margin-right: 1.563rem !important;
  }
  .me-md-30 {
    margin-right: 1.875rem !important;
  }
  .me-md-35 {
    margin-right: 2.188rem !important;
  }
  .me-md-40 {
    margin-right: 2.5rem !important;
  }
  .me-md-45 {
    margin-right: 2.813rem !important;
  }
  .me-md-50 {
    margin-right: 3.125rem !important;
  }
  .me-md-55 {
    margin-right: 3.438rem !important;
  }
  .me-md-60 {
    margin-right: 3.75rem !important;
  }
  .me-md-65 {
    margin-right: 4.063rem !important;
  }
  .me-md-70 {
    margin-right: 4.375rem !important;
  }
  .me-md-75 {
    margin-right: 4.688rem !important;
  }
  .me-md-80 {
    margin-right: 5rem !important;
  }
  .me-md-85 {
    margin-right: 5.313rem !important;
  }
  .me-md-90 {
    margin-right: 5.625rem !important;
  }
  .me-md-95 {
    margin-right: 5.938rem !important;
  }
  .me-md-100 {
    margin-right: 6.25rem !important;
  }
  .me-md-105 {
    margin-right: 6.5625rem !important;
  }
  .me-md-110 {
    margin-right: 6.875rem !important;
  }
  .me-md-115 {
    margin-right: 7.1875rem !important;
  }
  .me-md-120 {
    margin-right: 7.5rem !important;
  }
  .me-md-125 {
    margin-right: 7.8125rem !important;
  }
  .me-md-130 {
    margin-right: 8.125rem !important;
  }
  .me-md-135 {
    margin-right: 8.4375rem !important;
  }
  .me-md-140 {
    margin-right: 8.75rem !important;
  }
  .me-md-145 {
    margin-right: 9.0625rem !important;
  }
  .me-md-150 {
    margin-right: 9.375rem !important;
  }
  .me-md-155 {
    margin-right: 9.6875rem !important;
  }
  .me-md-160 {
    margin-right: 10rem !important;
  }
  .me-md-165 {
    margin-right: 10.3125rem !important;
  }
  .me-md-170 {
    margin-right: 10.625rem !important;
  }
  .me-md-175 {
    margin-right: 10.9375rem !important;
  }
  .me-md-180 {
    margin-right: 11.25rem !important;
  }
  .me-md-185 {
    margin-right: 11.5625rem !important;
  }
  .me-md-190 {
    margin-right: 11.875rem !important;
  }
  .me-md-195 {
    margin-right: 12.1875rem !important;
  }
  .me-md-200 {
    margin-right: 12.5rem !important;
  }
  .me-md-205 {
    margin-right: 12.8125rem !important;
  }
  .me-md-210 {
    margin-right: 13.125rem !important;
  }
  .me-md-215 {
    margin-right: 13.4375rem !important;
  }
  .me-md-220 {
    margin-right: 13.75rem !important;
  }
  .me-md-225 {
    margin-right: 14.0625rem !important;
  }
  .me-md-230 {
    margin-right: 14.375rem !important;
  }
  .me-md-235 {
    margin-right: 14.6875rem !important;
  }
  .me-md-240 {
    margin-right: 15rem !important;
  }
  .me-md-245 {
    margin-right: 15.3125rem !important;
  }
  .me-md-250 {
    margin-right: 15.625rem !important;
  }
  .me-md-255 {
    margin-right: 15.9375rem !important;
  }
  .me-md-260 {
    margin-right: 16.25rem !important;
  }
  .me-md-265 {
    margin-right: 16.5625rem !important;
  }
  .me-md-270 {
    margin-right: 16.875rem !important;
  }
  .me-md-275 {
    margin-right: 17.1875rem !important;
  }
  .me-md-280 {
    margin-right: 17.5rem !important;
  }
  .me-md-285 {
    margin-right: 17.8125rem !important;
  }
  .me-md-290 {
    margin-right: 18.125rem !important;
  }
  .me-md-295 {
    margin-right: 18.4375rem !important;
  }
  .me-md-300 {
    margin-right: 18.75rem !important;
  }
  .me-md-auto {
    margin-right: auto !important;
  }
  .mb-md-0 {
    margin-bottom: 0 !important;
  }
  .mb-md-5 {
    margin-bottom: 0.313rem !important;
  }
  .mb-md-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-md-15 {
    margin-bottom: 0.938rem !important;
  }
  .mb-md-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-md-25 {
    margin-bottom: 1.563rem !important;
  }
  .mb-md-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-md-35 {
    margin-bottom: 2.188rem !important;
  }
  .mb-md-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-md-45 {
    margin-bottom: 2.813rem !important;
  }
  .mb-md-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-md-55 {
    margin-bottom: 3.438rem !important;
  }
  .mb-md-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-md-65 {
    margin-bottom: 4.063rem !important;
  }
  .mb-md-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-md-75 {
    margin-bottom: 4.688rem !important;
  }
  .mb-md-80 {
    margin-bottom: 5rem !important;
  }
  .mb-md-85 {
    margin-bottom: 5.313rem !important;
  }
  .mb-md-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-md-95 {
    margin-bottom: 5.938rem !important;
  }
  .mb-md-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-md-105 {
    margin-bottom: 6.5625rem !important;
  }
  .mb-md-110 {
    margin-bottom: 6.875rem !important;
  }
  .mb-md-115 {
    margin-bottom: 7.1875rem !important;
  }
  .mb-md-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-md-125 {
    margin-bottom: 7.8125rem !important;
  }
  .mb-md-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-md-135 {
    margin-bottom: 8.4375rem !important;
  }
  .mb-md-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-md-145 {
    margin-bottom: 9.0625rem !important;
  }
  .mb-md-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-md-155 {
    margin-bottom: 9.6875rem !important;
  }
  .mb-md-160 {
    margin-bottom: 10rem !important;
  }
  .mb-md-165 {
    margin-bottom: 10.3125rem !important;
  }
  .mb-md-170 {
    margin-bottom: 10.625rem !important;
  }
  .mb-md-175 {
    margin-bottom: 10.9375rem !important;
  }
  .mb-md-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-md-185 {
    margin-bottom: 11.5625rem !important;
  }
  .mb-md-190 {
    margin-bottom: 11.875rem !important;
  }
  .mb-md-195 {
    margin-bottom: 12.1875rem !important;
  }
  .mb-md-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-md-205 {
    margin-bottom: 12.8125rem !important;
  }
  .mb-md-210 {
    margin-bottom: 13.125rem !important;
  }
  .mb-md-215 {
    margin-bottom: 13.4375rem !important;
  }
  .mb-md-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-md-225 {
    margin-bottom: 14.0625rem !important;
  }
  .mb-md-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-md-235 {
    margin-bottom: 14.6875rem !important;
  }
  .mb-md-240 {
    margin-bottom: 15rem !important;
  }
  .mb-md-245 {
    margin-bottom: 15.3125rem !important;
  }
  .mb-md-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-md-255 {
    margin-bottom: 15.9375rem !important;
  }
  .mb-md-260 {
    margin-bottom: 16.25rem !important;
  }
  .mb-md-265 {
    margin-bottom: 16.5625rem !important;
  }
  .mb-md-270 {
    margin-bottom: 16.875rem !important;
  }
  .mb-md-275 {
    margin-bottom: 17.1875rem !important;
  }
  .mb-md-280 {
    margin-bottom: 17.5rem !important;
  }
  .mb-md-285 {
    margin-bottom: 17.8125rem !important;
  }
  .mb-md-290 {
    margin-bottom: 18.125rem !important;
  }
  .mb-md-295 {
    margin-bottom: 18.4375rem !important;
  }
  .mb-md-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-md-auto {
    margin-bottom: auto !important;
  }
  .ms-md-0 {
    margin-left: 0 !important;
  }
  .ms-md-5 {
    margin-left: 0.313rem !important;
  }
  .ms-md-10 {
    margin-left: 0.625rem !important;
  }
  .ms-md-15 {
    margin-left: 0.938rem !important;
  }
  .ms-md-20 {
    margin-left: 1.25rem !important;
  }
  .ms-md-25 {
    margin-left: 1.563rem !important;
  }
  .ms-md-30 {
    margin-left: 1.875rem !important;
  }
  .ms-md-35 {
    margin-left: 2.188rem !important;
  }
  .ms-md-40 {
    margin-left: 2.5rem !important;
  }
  .ms-md-45 {
    margin-left: 2.813rem !important;
  }
  .ms-md-50 {
    margin-left: 3.125rem !important;
  }
  .ms-md-55 {
    margin-left: 3.438rem !important;
  }
  .ms-md-60 {
    margin-left: 3.75rem !important;
  }
  .ms-md-65 {
    margin-left: 4.063rem !important;
  }
  .ms-md-70 {
    margin-left: 4.375rem !important;
  }
  .ms-md-75 {
    margin-left: 4.688rem !important;
  }
  .ms-md-80 {
    margin-left: 5rem !important;
  }
  .ms-md-85 {
    margin-left: 5.313rem !important;
  }
  .ms-md-90 {
    margin-left: 5.625rem !important;
  }
  .ms-md-95 {
    margin-left: 5.938rem !important;
  }
  .ms-md-100 {
    margin-left: 6.25rem !important;
  }
  .ms-md-105 {
    margin-left: 6.5625rem !important;
  }
  .ms-md-110 {
    margin-left: 6.875rem !important;
  }
  .ms-md-115 {
    margin-left: 7.1875rem !important;
  }
  .ms-md-120 {
    margin-left: 7.5rem !important;
  }
  .ms-md-125 {
    margin-left: 7.8125rem !important;
  }
  .ms-md-130 {
    margin-left: 8.125rem !important;
  }
  .ms-md-135 {
    margin-left: 8.4375rem !important;
  }
  .ms-md-140 {
    margin-left: 8.75rem !important;
  }
  .ms-md-145 {
    margin-left: 9.0625rem !important;
  }
  .ms-md-150 {
    margin-left: 9.375rem !important;
  }
  .ms-md-155 {
    margin-left: 9.6875rem !important;
  }
  .ms-md-160 {
    margin-left: 10rem !important;
  }
  .ms-md-165 {
    margin-left: 10.3125rem !important;
  }
  .ms-md-170 {
    margin-left: 10.625rem !important;
  }
  .ms-md-175 {
    margin-left: 10.9375rem !important;
  }
  .ms-md-180 {
    margin-left: 11.25rem !important;
  }
  .ms-md-185 {
    margin-left: 11.5625rem !important;
  }
  .ms-md-190 {
    margin-left: 11.875rem !important;
  }
  .ms-md-195 {
    margin-left: 12.1875rem !important;
  }
  .ms-md-200 {
    margin-left: 12.5rem !important;
  }
  .ms-md-205 {
    margin-left: 12.8125rem !important;
  }
  .ms-md-210 {
    margin-left: 13.125rem !important;
  }
  .ms-md-215 {
    margin-left: 13.4375rem !important;
  }
  .ms-md-220 {
    margin-left: 13.75rem !important;
  }
  .ms-md-225 {
    margin-left: 14.0625rem !important;
  }
  .ms-md-230 {
    margin-left: 14.375rem !important;
  }
  .ms-md-235 {
    margin-left: 14.6875rem !important;
  }
  .ms-md-240 {
    margin-left: 15rem !important;
  }
  .ms-md-245 {
    margin-left: 15.3125rem !important;
  }
  .ms-md-250 {
    margin-left: 15.625rem !important;
  }
  .ms-md-255 {
    margin-left: 15.9375rem !important;
  }
  .ms-md-260 {
    margin-left: 16.25rem !important;
  }
  .ms-md-265 {
    margin-left: 16.5625rem !important;
  }
  .ms-md-270 {
    margin-left: 16.875rem !important;
  }
  .ms-md-275 {
    margin-left: 17.1875rem !important;
  }
  .ms-md-280 {
    margin-left: 17.5rem !important;
  }
  .ms-md-285 {
    margin-left: 17.8125rem !important;
  }
  .ms-md-290 {
    margin-left: 18.125rem !important;
  }
  .ms-md-295 {
    margin-left: 18.4375rem !important;
  }
  .ms-md-300 {
    margin-left: 18.75rem !important;
  }
  .ms-md-auto {
    margin-left: auto !important;
  }
  .m-md-n5 {
    margin: -0.313rem !important;
  }
  .m-md-n10 {
    margin: -0.625rem !important;
  }
  .m-md-n15 {
    margin: -0.938rem !important;
  }
  .m-md-n20 {
    margin: -1.25rem !important;
  }
  .m-md-n25 {
    margin: -1.563rem !important;
  }
  .m-md-n30 {
    margin: -1.875rem !important;
  }
  .m-md-n35 {
    margin: -2.188rem !important;
  }
  .m-md-n40 {
    margin: -2.5rem !important;
  }
  .m-md-n45 {
    margin: -2.813rem !important;
  }
  .m-md-n50 {
    margin: -3.125rem !important;
  }
  .m-md-n55 {
    margin: -3.438rem !important;
  }
  .m-md-n60 {
    margin: -3.75rem !important;
  }
  .m-md-n65 {
    margin: -4.063rem !important;
  }
  .m-md-n70 {
    margin: -4.375rem !important;
  }
  .m-md-n75 {
    margin: -4.688rem !important;
  }
  .m-md-n80 {
    margin: -5rem !important;
  }
  .m-md-n85 {
    margin: -5.313rem !important;
  }
  .m-md-n90 {
    margin: -5.625rem !important;
  }
  .m-md-n95 {
    margin: -5.938rem !important;
  }
  .m-md-n100 {
    margin: -6.25rem !important;
  }
  .m-md-n105 {
    margin: -6.5625rem !important;
  }
  .m-md-n110 {
    margin: -6.875rem !important;
  }
  .m-md-n115 {
    margin: -7.1875rem !important;
  }
  .m-md-n120 {
    margin: -7.5rem !important;
  }
  .m-md-n125 {
    margin: -7.8125rem !important;
  }
  .m-md-n130 {
    margin: -8.125rem !important;
  }
  .m-md-n135 {
    margin: -8.4375rem !important;
  }
  .m-md-n140 {
    margin: -8.75rem !important;
  }
  .m-md-n145 {
    margin: -9.0625rem !important;
  }
  .m-md-n150 {
    margin: -9.375rem !important;
  }
  .m-md-n155 {
    margin: -9.6875rem !important;
  }
  .m-md-n160 {
    margin: -10rem !important;
  }
  .m-md-n165 {
    margin: -10.3125rem !important;
  }
  .m-md-n170 {
    margin: -10.625rem !important;
  }
  .m-md-n175 {
    margin: -10.9375rem !important;
  }
  .m-md-n180 {
    margin: -11.25rem !important;
  }
  .m-md-n185 {
    margin: -11.5625rem !important;
  }
  .m-md-n190 {
    margin: -11.875rem !important;
  }
  .m-md-n195 {
    margin: -12.1875rem !important;
  }
  .m-md-n200 {
    margin: -12.5rem !important;
  }
  .m-md-n205 {
    margin: -12.8125rem !important;
  }
  .m-md-n210 {
    margin: -13.125rem !important;
  }
  .m-md-n215 {
    margin: -13.4375rem !important;
  }
  .m-md-n220 {
    margin: -13.75rem !important;
  }
  .m-md-n225 {
    margin: -14.0625rem !important;
  }
  .m-md-n230 {
    margin: -14.375rem !important;
  }
  .m-md-n235 {
    margin: -14.6875rem !important;
  }
  .m-md-n240 {
    margin: -15rem !important;
  }
  .m-md-n245 {
    margin: -15.3125rem !important;
  }
  .m-md-n250 {
    margin: -15.625rem !important;
  }
  .m-md-n255 {
    margin: -15.9375rem !important;
  }
  .m-md-n260 {
    margin: -16.25rem !important;
  }
  .m-md-n265 {
    margin: -16.5625rem !important;
  }
  .m-md-n270 {
    margin: -16.875rem !important;
  }
  .m-md-n275 {
    margin: -17.1875rem !important;
  }
  .m-md-n280 {
    margin: -17.5rem !important;
  }
  .m-md-n285 {
    margin: -17.8125rem !important;
  }
  .m-md-n290 {
    margin: -18.125rem !important;
  }
  .m-md-n295 {
    margin: -18.4375rem !important;
  }
  .m-md-n300 {
    margin: -18.75rem !important;
  }
  .mx-md-n5 {
    margin-right: -0.313rem !important;
    margin-left: -0.313rem !important;
  }
  .mx-md-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-md-n15 {
    margin-right: -0.938rem !important;
    margin-left: -0.938rem !important;
  }
  .mx-md-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-md-n25 {
    margin-right: -1.563rem !important;
    margin-left: -1.563rem !important;
  }
  .mx-md-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-md-n35 {
    margin-right: -2.188rem !important;
    margin-left: -2.188rem !important;
  }
  .mx-md-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-md-n45 {
    margin-right: -2.813rem !important;
    margin-left: -2.813rem !important;
  }
  .mx-md-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-md-n55 {
    margin-right: -3.438rem !important;
    margin-left: -3.438rem !important;
  }
  .mx-md-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-md-n65 {
    margin-right: -4.063rem !important;
    margin-left: -4.063rem !important;
  }
  .mx-md-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-md-n75 {
    margin-right: -4.688rem !important;
    margin-left: -4.688rem !important;
  }
  .mx-md-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-md-n85 {
    margin-right: -5.313rem !important;
    margin-left: -5.313rem !important;
  }
  .mx-md-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-md-n95 {
    margin-right: -5.938rem !important;
    margin-left: -5.938rem !important;
  }
  .mx-md-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-md-n105 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important;
  }
  .mx-md-n110 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important;
  }
  .mx-md-n115 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important;
  }
  .mx-md-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-md-n125 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important;
  }
  .mx-md-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-md-n135 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important;
  }
  .mx-md-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-md-n145 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important;
  }
  .mx-md-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-md-n155 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important;
  }
  .mx-md-n160 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-md-n165 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important;
  }
  .mx-md-n170 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important;
  }
  .mx-md-n175 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important;
  }
  .mx-md-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-md-n185 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important;
  }
  .mx-md-n190 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important;
  }
  .mx-md-n195 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important;
  }
  .mx-md-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-md-n205 {
    margin-right: -12.8125rem !important;
    margin-left: -12.8125rem !important;
  }
  .mx-md-n210 {
    margin-right: -13.125rem !important;
    margin-left: -13.125rem !important;
  }
  .mx-md-n215 {
    margin-right: -13.4375rem !important;
    margin-left: -13.4375rem !important;
  }
  .mx-md-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-md-n225 {
    margin-right: -14.0625rem !important;
    margin-left: -14.0625rem !important;
  }
  .mx-md-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-md-n235 {
    margin-right: -14.6875rem !important;
    margin-left: -14.6875rem !important;
  }
  .mx-md-n240 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-md-n245 {
    margin-right: -15.3125rem !important;
    margin-left: -15.3125rem !important;
  }
  .mx-md-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-md-n255 {
    margin-right: -15.9375rem !important;
    margin-left: -15.9375rem !important;
  }
  .mx-md-n260 {
    margin-right: -16.25rem !important;
    margin-left: -16.25rem !important;
  }
  .mx-md-n265 {
    margin-right: -16.5625rem !important;
    margin-left: -16.5625rem !important;
  }
  .mx-md-n270 {
    margin-right: -16.875rem !important;
    margin-left: -16.875rem !important;
  }
  .mx-md-n275 {
    margin-right: -17.1875rem !important;
    margin-left: -17.1875rem !important;
  }
  .mx-md-n280 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }
  .mx-md-n285 {
    margin-right: -17.8125rem !important;
    margin-left: -17.8125rem !important;
  }
  .mx-md-n290 {
    margin-right: -18.125rem !important;
    margin-left: -18.125rem !important;
  }
  .mx-md-n295 {
    margin-right: -18.4375rem !important;
    margin-left: -18.4375rem !important;
  }
  .mx-md-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-md-n5 {
    margin-top: -0.313rem !important;
    margin-bottom: -0.313rem !important;
  }
  .my-md-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-md-n15 {
    margin-top: -0.938rem !important;
    margin-bottom: -0.938rem !important;
  }
  .my-md-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-md-n25 {
    margin-top: -1.563rem !important;
    margin-bottom: -1.563rem !important;
  }
  .my-md-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-md-n35 {
    margin-top: -2.188rem !important;
    margin-bottom: -2.188rem !important;
  }
  .my-md-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-md-n45 {
    margin-top: -2.813rem !important;
    margin-bottom: -2.813rem !important;
  }
  .my-md-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-md-n55 {
    margin-top: -3.438rem !important;
    margin-bottom: -3.438rem !important;
  }
  .my-md-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-md-n65 {
    margin-top: -4.063rem !important;
    margin-bottom: -4.063rem !important;
  }
  .my-md-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-md-n75 {
    margin-top: -4.688rem !important;
    margin-bottom: -4.688rem !important;
  }
  .my-md-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-md-n85 {
    margin-top: -5.313rem !important;
    margin-bottom: -5.313rem !important;
  }
  .my-md-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-md-n95 {
    margin-top: -5.938rem !important;
    margin-bottom: -5.938rem !important;
  }
  .my-md-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-md-n105 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important;
  }
  .my-md-n110 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important;
  }
  .my-md-n115 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important;
  }
  .my-md-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-md-n125 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important;
  }
  .my-md-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-md-n135 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important;
  }
  .my-md-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-md-n145 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important;
  }
  .my-md-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-md-n155 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important;
  }
  .my-md-n160 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-md-n165 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important;
  }
  .my-md-n170 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important;
  }
  .my-md-n175 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important;
  }
  .my-md-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-md-n185 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important;
  }
  .my-md-n190 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important;
  }
  .my-md-n195 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important;
  }
  .my-md-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-md-n205 {
    margin-top: -12.8125rem !important;
    margin-bottom: -12.8125rem !important;
  }
  .my-md-n210 {
    margin-top: -13.125rem !important;
    margin-bottom: -13.125rem !important;
  }
  .my-md-n215 {
    margin-top: -13.4375rem !important;
    margin-bottom: -13.4375rem !important;
  }
  .my-md-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-md-n225 {
    margin-top: -14.0625rem !important;
    margin-bottom: -14.0625rem !important;
  }
  .my-md-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-md-n235 {
    margin-top: -14.6875rem !important;
    margin-bottom: -14.6875rem !important;
  }
  .my-md-n240 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-md-n245 {
    margin-top: -15.3125rem !important;
    margin-bottom: -15.3125rem !important;
  }
  .my-md-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-md-n255 {
    margin-top: -15.9375rem !important;
    margin-bottom: -15.9375rem !important;
  }
  .my-md-n260 {
    margin-top: -16.25rem !important;
    margin-bottom: -16.25rem !important;
  }
  .my-md-n265 {
    margin-top: -16.5625rem !important;
    margin-bottom: -16.5625rem !important;
  }
  .my-md-n270 {
    margin-top: -16.875rem !important;
    margin-bottom: -16.875rem !important;
  }
  .my-md-n275 {
    margin-top: -17.1875rem !important;
    margin-bottom: -17.1875rem !important;
  }
  .my-md-n280 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }
  .my-md-n285 {
    margin-top: -17.8125rem !important;
    margin-bottom: -17.8125rem !important;
  }
  .my-md-n290 {
    margin-top: -18.125rem !important;
    margin-bottom: -18.125rem !important;
  }
  .my-md-n295 {
    margin-top: -18.4375rem !important;
    margin-bottom: -18.4375rem !important;
  }
  .my-md-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-md-n5 {
    margin-top: -0.313rem !important;
  }
  .mt-md-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-md-n15 {
    margin-top: -0.938rem !important;
  }
  .mt-md-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-md-n25 {
    margin-top: -1.563rem !important;
  }
  .mt-md-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-md-n35 {
    margin-top: -2.188rem !important;
  }
  .mt-md-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-md-n45 {
    margin-top: -2.813rem !important;
  }
  .mt-md-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-md-n55 {
    margin-top: -3.438rem !important;
  }
  .mt-md-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-md-n65 {
    margin-top: -4.063rem !important;
  }
  .mt-md-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-md-n75 {
    margin-top: -4.688rem !important;
  }
  .mt-md-n80 {
    margin-top: -5rem !important;
  }
  .mt-md-n85 {
    margin-top: -5.313rem !important;
  }
  .mt-md-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-md-n95 {
    margin-top: -5.938rem !important;
  }
  .mt-md-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-md-n105 {
    margin-top: -6.5625rem !important;
  }
  .mt-md-n110 {
    margin-top: -6.875rem !important;
  }
  .mt-md-n115 {
    margin-top: -7.1875rem !important;
  }
  .mt-md-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-md-n125 {
    margin-top: -7.8125rem !important;
  }
  .mt-md-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-md-n135 {
    margin-top: -8.4375rem !important;
  }
  .mt-md-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-md-n145 {
    margin-top: -9.0625rem !important;
  }
  .mt-md-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-md-n155 {
    margin-top: -9.6875rem !important;
  }
  .mt-md-n160 {
    margin-top: -10rem !important;
  }
  .mt-md-n165 {
    margin-top: -10.3125rem !important;
  }
  .mt-md-n170 {
    margin-top: -10.625rem !important;
  }
  .mt-md-n175 {
    margin-top: -10.9375rem !important;
  }
  .mt-md-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-md-n185 {
    margin-top: -11.5625rem !important;
  }
  .mt-md-n190 {
    margin-top: -11.875rem !important;
  }
  .mt-md-n195 {
    margin-top: -12.1875rem !important;
  }
  .mt-md-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-md-n205 {
    margin-top: -12.8125rem !important;
  }
  .mt-md-n210 {
    margin-top: -13.125rem !important;
  }
  .mt-md-n215 {
    margin-top: -13.4375rem !important;
  }
  .mt-md-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-md-n225 {
    margin-top: -14.0625rem !important;
  }
  .mt-md-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-md-n235 {
    margin-top: -14.6875rem !important;
  }
  .mt-md-n240 {
    margin-top: -15rem !important;
  }
  .mt-md-n245 {
    margin-top: -15.3125rem !important;
  }
  .mt-md-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-md-n255 {
    margin-top: -15.9375rem !important;
  }
  .mt-md-n260 {
    margin-top: -16.25rem !important;
  }
  .mt-md-n265 {
    margin-top: -16.5625rem !important;
  }
  .mt-md-n270 {
    margin-top: -16.875rem !important;
  }
  .mt-md-n275 {
    margin-top: -17.1875rem !important;
  }
  .mt-md-n280 {
    margin-top: -17.5rem !important;
  }
  .mt-md-n285 {
    margin-top: -17.8125rem !important;
  }
  .mt-md-n290 {
    margin-top: -18.125rem !important;
  }
  .mt-md-n295 {
    margin-top: -18.4375rem !important;
  }
  .mt-md-n300 {
    margin-top: -18.75rem !important;
  }
  .me-md-n5 {
    margin-right: -0.313rem !important;
  }
  .me-md-n10 {
    margin-right: -0.625rem !important;
  }
  .me-md-n15 {
    margin-right: -0.938rem !important;
  }
  .me-md-n20 {
    margin-right: -1.25rem !important;
  }
  .me-md-n25 {
    margin-right: -1.563rem !important;
  }
  .me-md-n30 {
    margin-right: -1.875rem !important;
  }
  .me-md-n35 {
    margin-right: -2.188rem !important;
  }
  .me-md-n40 {
    margin-right: -2.5rem !important;
  }
  .me-md-n45 {
    margin-right: -2.813rem !important;
  }
  .me-md-n50 {
    margin-right: -3.125rem !important;
  }
  .me-md-n55 {
    margin-right: -3.438rem !important;
  }
  .me-md-n60 {
    margin-right: -3.75rem !important;
  }
  .me-md-n65 {
    margin-right: -4.063rem !important;
  }
  .me-md-n70 {
    margin-right: -4.375rem !important;
  }
  .me-md-n75 {
    margin-right: -4.688rem !important;
  }
  .me-md-n80 {
    margin-right: -5rem !important;
  }
  .me-md-n85 {
    margin-right: -5.313rem !important;
  }
  .me-md-n90 {
    margin-right: -5.625rem !important;
  }
  .me-md-n95 {
    margin-right: -5.938rem !important;
  }
  .me-md-n100 {
    margin-right: -6.25rem !important;
  }
  .me-md-n105 {
    margin-right: -6.5625rem !important;
  }
  .me-md-n110 {
    margin-right: -6.875rem !important;
  }
  .me-md-n115 {
    margin-right: -7.1875rem !important;
  }
  .me-md-n120 {
    margin-right: -7.5rem !important;
  }
  .me-md-n125 {
    margin-right: -7.8125rem !important;
  }
  .me-md-n130 {
    margin-right: -8.125rem !important;
  }
  .me-md-n135 {
    margin-right: -8.4375rem !important;
  }
  .me-md-n140 {
    margin-right: -8.75rem !important;
  }
  .me-md-n145 {
    margin-right: -9.0625rem !important;
  }
  .me-md-n150 {
    margin-right: -9.375rem !important;
  }
  .me-md-n155 {
    margin-right: -9.6875rem !important;
  }
  .me-md-n160 {
    margin-right: -10rem !important;
  }
  .me-md-n165 {
    margin-right: -10.3125rem !important;
  }
  .me-md-n170 {
    margin-right: -10.625rem !important;
  }
  .me-md-n175 {
    margin-right: -10.9375rem !important;
  }
  .me-md-n180 {
    margin-right: -11.25rem !important;
  }
  .me-md-n185 {
    margin-right: -11.5625rem !important;
  }
  .me-md-n190 {
    margin-right: -11.875rem !important;
  }
  .me-md-n195 {
    margin-right: -12.1875rem !important;
  }
  .me-md-n200 {
    margin-right: -12.5rem !important;
  }
  .me-md-n205 {
    margin-right: -12.8125rem !important;
  }
  .me-md-n210 {
    margin-right: -13.125rem !important;
  }
  .me-md-n215 {
    margin-right: -13.4375rem !important;
  }
  .me-md-n220 {
    margin-right: -13.75rem !important;
  }
  .me-md-n225 {
    margin-right: -14.0625rem !important;
  }
  .me-md-n230 {
    margin-right: -14.375rem !important;
  }
  .me-md-n235 {
    margin-right: -14.6875rem !important;
  }
  .me-md-n240 {
    margin-right: -15rem !important;
  }
  .me-md-n245 {
    margin-right: -15.3125rem !important;
  }
  .me-md-n250 {
    margin-right: -15.625rem !important;
  }
  .me-md-n255 {
    margin-right: -15.9375rem !important;
  }
  .me-md-n260 {
    margin-right: -16.25rem !important;
  }
  .me-md-n265 {
    margin-right: -16.5625rem !important;
  }
  .me-md-n270 {
    margin-right: -16.875rem !important;
  }
  .me-md-n275 {
    margin-right: -17.1875rem !important;
  }
  .me-md-n280 {
    margin-right: -17.5rem !important;
  }
  .me-md-n285 {
    margin-right: -17.8125rem !important;
  }
  .me-md-n290 {
    margin-right: -18.125rem !important;
  }
  .me-md-n295 {
    margin-right: -18.4375rem !important;
  }
  .me-md-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-md-n5 {
    margin-bottom: -0.313rem !important;
  }
  .mb-md-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-md-n15 {
    margin-bottom: -0.938rem !important;
  }
  .mb-md-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-md-n25 {
    margin-bottom: -1.563rem !important;
  }
  .mb-md-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-md-n35 {
    margin-bottom: -2.188rem !important;
  }
  .mb-md-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-md-n45 {
    margin-bottom: -2.813rem !important;
  }
  .mb-md-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-md-n55 {
    margin-bottom: -3.438rem !important;
  }
  .mb-md-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-md-n65 {
    margin-bottom: -4.063rem !important;
  }
  .mb-md-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-md-n75 {
    margin-bottom: -4.688rem !important;
  }
  .mb-md-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-md-n85 {
    margin-bottom: -5.313rem !important;
  }
  .mb-md-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-md-n95 {
    margin-bottom: -5.938rem !important;
  }
  .mb-md-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-md-n105 {
    margin-bottom: -6.5625rem !important;
  }
  .mb-md-n110 {
    margin-bottom: -6.875rem !important;
  }
  .mb-md-n115 {
    margin-bottom: -7.1875rem !important;
  }
  .mb-md-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-md-n125 {
    margin-bottom: -7.8125rem !important;
  }
  .mb-md-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-md-n135 {
    margin-bottom: -8.4375rem !important;
  }
  .mb-md-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-md-n145 {
    margin-bottom: -9.0625rem !important;
  }
  .mb-md-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-md-n155 {
    margin-bottom: -9.6875rem !important;
  }
  .mb-md-n160 {
    margin-bottom: -10rem !important;
  }
  .mb-md-n165 {
    margin-bottom: -10.3125rem !important;
  }
  .mb-md-n170 {
    margin-bottom: -10.625rem !important;
  }
  .mb-md-n175 {
    margin-bottom: -10.9375rem !important;
  }
  .mb-md-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-md-n185 {
    margin-bottom: -11.5625rem !important;
  }
  .mb-md-n190 {
    margin-bottom: -11.875rem !important;
  }
  .mb-md-n195 {
    margin-bottom: -12.1875rem !important;
  }
  .mb-md-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-md-n205 {
    margin-bottom: -12.8125rem !important;
  }
  .mb-md-n210 {
    margin-bottom: -13.125rem !important;
  }
  .mb-md-n215 {
    margin-bottom: -13.4375rem !important;
  }
  .mb-md-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-md-n225 {
    margin-bottom: -14.0625rem !important;
  }
  .mb-md-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-md-n235 {
    margin-bottom: -14.6875rem !important;
  }
  .mb-md-n240 {
    margin-bottom: -15rem !important;
  }
  .mb-md-n245 {
    margin-bottom: -15.3125rem !important;
  }
  .mb-md-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-md-n255 {
    margin-bottom: -15.9375rem !important;
  }
  .mb-md-n260 {
    margin-bottom: -16.25rem !important;
  }
  .mb-md-n265 {
    margin-bottom: -16.5625rem !important;
  }
  .mb-md-n270 {
    margin-bottom: -16.875rem !important;
  }
  .mb-md-n275 {
    margin-bottom: -17.1875rem !important;
  }
  .mb-md-n280 {
    margin-bottom: -17.5rem !important;
  }
  .mb-md-n285 {
    margin-bottom: -17.8125rem !important;
  }
  .mb-md-n290 {
    margin-bottom: -18.125rem !important;
  }
  .mb-md-n295 {
    margin-bottom: -18.4375rem !important;
  }
  .mb-md-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-md-n5 {
    margin-left: -0.313rem !important;
  }
  .ms-md-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-md-n15 {
    margin-left: -0.938rem !important;
  }
  .ms-md-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-md-n25 {
    margin-left: -1.563rem !important;
  }
  .ms-md-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-md-n35 {
    margin-left: -2.188rem !important;
  }
  .ms-md-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-md-n45 {
    margin-left: -2.813rem !important;
  }
  .ms-md-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-md-n55 {
    margin-left: -3.438rem !important;
  }
  .ms-md-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-md-n65 {
    margin-left: -4.063rem !important;
  }
  .ms-md-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-md-n75 {
    margin-left: -4.688rem !important;
  }
  .ms-md-n80 {
    margin-left: -5rem !important;
  }
  .ms-md-n85 {
    margin-left: -5.313rem !important;
  }
  .ms-md-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-md-n95 {
    margin-left: -5.938rem !important;
  }
  .ms-md-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-md-n105 {
    margin-left: -6.5625rem !important;
  }
  .ms-md-n110 {
    margin-left: -6.875rem !important;
  }
  .ms-md-n115 {
    margin-left: -7.1875rem !important;
  }
  .ms-md-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-md-n125 {
    margin-left: -7.8125rem !important;
  }
  .ms-md-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-md-n135 {
    margin-left: -8.4375rem !important;
  }
  .ms-md-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-md-n145 {
    margin-left: -9.0625rem !important;
  }
  .ms-md-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-md-n155 {
    margin-left: -9.6875rem !important;
  }
  .ms-md-n160 {
    margin-left: -10rem !important;
  }
  .ms-md-n165 {
    margin-left: -10.3125rem !important;
  }
  .ms-md-n170 {
    margin-left: -10.625rem !important;
  }
  .ms-md-n175 {
    margin-left: -10.9375rem !important;
  }
  .ms-md-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-md-n185 {
    margin-left: -11.5625rem !important;
  }
  .ms-md-n190 {
    margin-left: -11.875rem !important;
  }
  .ms-md-n195 {
    margin-left: -12.1875rem !important;
  }
  .ms-md-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-md-n205 {
    margin-left: -12.8125rem !important;
  }
  .ms-md-n210 {
    margin-left: -13.125rem !important;
  }
  .ms-md-n215 {
    margin-left: -13.4375rem !important;
  }
  .ms-md-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-md-n225 {
    margin-left: -14.0625rem !important;
  }
  .ms-md-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-md-n235 {
    margin-left: -14.6875rem !important;
  }
  .ms-md-n240 {
    margin-left: -15rem !important;
  }
  .ms-md-n245 {
    margin-left: -15.3125rem !important;
  }
  .ms-md-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-md-n255 {
    margin-left: -15.9375rem !important;
  }
  .ms-md-n260 {
    margin-left: -16.25rem !important;
  }
  .ms-md-n265 {
    margin-left: -16.5625rem !important;
  }
  .ms-md-n270 {
    margin-left: -16.875rem !important;
  }
  .ms-md-n275 {
    margin-left: -17.1875rem !important;
  }
  .ms-md-n280 {
    margin-left: -17.5rem !important;
  }
  .ms-md-n285 {
    margin-left: -17.8125rem !important;
  }
  .ms-md-n290 {
    margin-left: -18.125rem !important;
  }
  .ms-md-n295 {
    margin-left: -18.4375rem !important;
  }
  .ms-md-n300 {
    margin-left: -18.75rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .p-md-5 {
    padding: 0.313rem !important;
  }
  .p-md-10 {
    padding: 0.625rem !important;
  }
  .p-md-15 {
    padding: 0.938rem !important;
  }
  .p-md-20 {
    padding: 1.25rem !important;
  }
  .p-md-25 {
    padding: 1.563rem !important;
  }
  .p-md-30 {
    padding: 1.875rem !important;
  }
  .p-md-35 {
    padding: 2.188rem !important;
  }
  .p-md-40 {
    padding: 2.5rem !important;
  }
  .p-md-45 {
    padding: 2.813rem !important;
  }
  .p-md-50 {
    padding: 3.125rem !important;
  }
  .p-md-55 {
    padding: 3.438rem !important;
  }
  .p-md-60 {
    padding: 3.75rem !important;
  }
  .p-md-65 {
    padding: 4.063rem !important;
  }
  .p-md-70 {
    padding: 4.375rem !important;
  }
  .p-md-75 {
    padding: 4.688rem !important;
  }
  .p-md-80 {
    padding: 5rem !important;
  }
  .p-md-85 {
    padding: 5.313rem !important;
  }
  .p-md-90 {
    padding: 5.625rem !important;
  }
  .p-md-95 {
    padding: 5.938rem !important;
  }
  .p-md-100 {
    padding: 6.25rem !important;
  }
  .p-md-105 {
    padding: 6.5625rem !important;
  }
  .p-md-110 {
    padding: 6.875rem !important;
  }
  .p-md-115 {
    padding: 7.1875rem !important;
  }
  .p-md-120 {
    padding: 7.5rem !important;
  }
  .p-md-125 {
    padding: 7.8125rem !important;
  }
  .p-md-130 {
    padding: 8.125rem !important;
  }
  .p-md-135 {
    padding: 8.4375rem !important;
  }
  .p-md-140 {
    padding: 8.75rem !important;
  }
  .p-md-145 {
    padding: 9.0625rem !important;
  }
  .p-md-150 {
    padding: 9.375rem !important;
  }
  .p-md-155 {
    padding: 9.6875rem !important;
  }
  .p-md-160 {
    padding: 10rem !important;
  }
  .p-md-165 {
    padding: 10.3125rem !important;
  }
  .p-md-170 {
    padding: 10.625rem !important;
  }
  .p-md-175 {
    padding: 10.9375rem !important;
  }
  .p-md-180 {
    padding: 11.25rem !important;
  }
  .p-md-185 {
    padding: 11.5625rem !important;
  }
  .p-md-190 {
    padding: 11.875rem !important;
  }
  .p-md-195 {
    padding: 12.1875rem !important;
  }
  .p-md-200 {
    padding: 12.5rem !important;
  }
  .p-md-205 {
    padding: 12.8125rem !important;
  }
  .p-md-210 {
    padding: 13.125rem !important;
  }
  .p-md-215 {
    padding: 13.4375rem !important;
  }
  .p-md-220 {
    padding: 13.75rem !important;
  }
  .p-md-225 {
    padding: 14.0625rem !important;
  }
  .p-md-230 {
    padding: 14.375rem !important;
  }
  .p-md-235 {
    padding: 14.6875rem !important;
  }
  .p-md-240 {
    padding: 15rem !important;
  }
  .p-md-245 {
    padding: 15.3125rem !important;
  }
  .p-md-250 {
    padding: 15.625rem !important;
  }
  .p-md-255 {
    padding: 15.9375rem !important;
  }
  .p-md-260 {
    padding: 16.25rem !important;
  }
  .p-md-265 {
    padding: 16.5625rem !important;
  }
  .p-md-270 {
    padding: 16.875rem !important;
  }
  .p-md-275 {
    padding: 17.1875rem !important;
  }
  .p-md-280 {
    padding: 17.5rem !important;
  }
  .p-md-285 {
    padding: 17.8125rem !important;
  }
  .p-md-290 {
    padding: 18.125rem !important;
  }
  .p-md-295 {
    padding: 18.4375rem !important;
  }
  .p-md-300 {
    padding: 18.75rem !important;
  }
  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-md-5 {
    padding-right: 0.313rem !important;
    padding-left: 0.313rem !important;
  }
  .px-md-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-md-15 {
    padding-right: 0.938rem !important;
    padding-left: 0.938rem !important;
  }
  .px-md-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-md-25 {
    padding-right: 1.563rem !important;
    padding-left: 1.563rem !important;
  }
  .px-md-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-md-35 {
    padding-right: 2.188rem !important;
    padding-left: 2.188rem !important;
  }
  .px-md-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-md-45 {
    padding-right: 2.813rem !important;
    padding-left: 2.813rem !important;
  }
  .px-md-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-md-55 {
    padding-right: 3.438rem !important;
    padding-left: 3.438rem !important;
  }
  .px-md-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-md-65 {
    padding-right: 4.063rem !important;
    padding-left: 4.063rem !important;
  }
  .px-md-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-md-75 {
    padding-right: 4.688rem !important;
    padding-left: 4.688rem !important;
  }
  .px-md-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-md-85 {
    padding-right: 5.313rem !important;
    padding-left: 5.313rem !important;
  }
  .px-md-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-md-95 {
    padding-right: 5.938rem !important;
    padding-left: 5.938rem !important;
  }
  .px-md-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-md-105 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important;
  }
  .px-md-110 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important;
  }
  .px-md-115 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important;
  }
  .px-md-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-md-125 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important;
  }
  .px-md-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-md-135 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important;
  }
  .px-md-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-md-145 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important;
  }
  .px-md-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-md-155 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important;
  }
  .px-md-160 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-md-165 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important;
  }
  .px-md-170 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important;
  }
  .px-md-175 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important;
  }
  .px-md-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-md-185 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important;
  }
  .px-md-190 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important;
  }
  .px-md-195 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important;
  }
  .px-md-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-md-205 {
    padding-right: 12.8125rem !important;
    padding-left: 12.8125rem !important;
  }
  .px-md-210 {
    padding-right: 13.125rem !important;
    padding-left: 13.125rem !important;
  }
  .px-md-215 {
    padding-right: 13.4375rem !important;
    padding-left: 13.4375rem !important;
  }
  .px-md-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-md-225 {
    padding-right: 14.0625rem !important;
    padding-left: 14.0625rem !important;
  }
  .px-md-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-md-235 {
    padding-right: 14.6875rem !important;
    padding-left: 14.6875rem !important;
  }
  .px-md-240 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-md-245 {
    padding-right: 15.3125rem !important;
    padding-left: 15.3125rem !important;
  }
  .px-md-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-md-255 {
    padding-right: 15.9375rem !important;
    padding-left: 15.9375rem !important;
  }
  .px-md-260 {
    padding-right: 16.25rem !important;
    padding-left: 16.25rem !important;
  }
  .px-md-265 {
    padding-right: 16.5625rem !important;
    padding-left: 16.5625rem !important;
  }
  .px-md-270 {
    padding-right: 16.875rem !important;
    padding-left: 16.875rem !important;
  }
  .px-md-275 {
    padding-right: 17.1875rem !important;
    padding-left: 17.1875rem !important;
  }
  .px-md-280 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }
  .px-md-285 {
    padding-right: 17.8125rem !important;
    padding-left: 17.8125rem !important;
  }
  .px-md-290 {
    padding-right: 18.125rem !important;
    padding-left: 18.125rem !important;
  }
  .px-md-295 {
    padding-right: 18.4375rem !important;
    padding-left: 18.4375rem !important;
  }
  .px-md-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-md-5 {
    padding-top: 0.313rem !important;
    padding-bottom: 0.313rem !important;
  }
  .py-md-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-md-15 {
    padding-top: 0.938rem !important;
    padding-bottom: 0.938rem !important;
  }
  .py-md-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-md-25 {
    padding-top: 1.563rem !important;
    padding-bottom: 1.563rem !important;
  }
  .py-md-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-md-35 {
    padding-top: 2.188rem !important;
    padding-bottom: 2.188rem !important;
  }
  .py-md-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-md-45 {
    padding-top: 2.813rem !important;
    padding-bottom: 2.813rem !important;
  }
  .py-md-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-md-55 {
    padding-top: 3.438rem !important;
    padding-bottom: 3.438rem !important;
  }
  .py-md-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-md-65 {
    padding-top: 4.063rem !important;
    padding-bottom: 4.063rem !important;
  }
  .py-md-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-md-75 {
    padding-top: 4.688rem !important;
    padding-bottom: 4.688rem !important;
  }
  .py-md-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-md-85 {
    padding-top: 5.313rem !important;
    padding-bottom: 5.313rem !important;
  }
  .py-md-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-md-95 {
    padding-top: 5.938rem !important;
    padding-bottom: 5.938rem !important;
  }
  .py-md-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-md-105 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important;
  }
  .py-md-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .py-md-115 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important;
  }
  .py-md-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-md-125 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important;
  }
  .py-md-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-md-135 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important;
  }
  .py-md-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-md-145 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important;
  }
  .py-md-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-md-155 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important;
  }
  .py-md-160 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-md-165 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important;
  }
  .py-md-170 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important;
  }
  .py-md-175 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important;
  }
  .py-md-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-md-185 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important;
  }
  .py-md-190 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important;
  }
  .py-md-195 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important;
  }
  .py-md-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-md-205 {
    padding-top: 12.8125rem !important;
    padding-bottom: 12.8125rem !important;
  }
  .py-md-210 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important;
  }
  .py-md-215 {
    padding-top: 13.4375rem !important;
    padding-bottom: 13.4375rem !important;
  }
  .py-md-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-md-225 {
    padding-top: 14.0625rem !important;
    padding-bottom: 14.0625rem !important;
  }
  .py-md-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-md-235 {
    padding-top: 14.6875rem !important;
    padding-bottom: 14.6875rem !important;
  }
  .py-md-240 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-md-245 {
    padding-top: 15.3125rem !important;
    padding-bottom: 15.3125rem !important;
  }
  .py-md-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-md-255 {
    padding-top: 15.9375rem !important;
    padding-bottom: 15.9375rem !important;
  }
  .py-md-260 {
    padding-top: 16.25rem !important;
    padding-bottom: 16.25rem !important;
  }
  .py-md-265 {
    padding-top: 16.5625rem !important;
    padding-bottom: 16.5625rem !important;
  }
  .py-md-270 {
    padding-top: 16.875rem !important;
    padding-bottom: 16.875rem !important;
  }
  .py-md-275 {
    padding-top: 17.1875rem !important;
    padding-bottom: 17.1875rem !important;
  }
  .py-md-280 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }
  .py-md-285 {
    padding-top: 17.8125rem !important;
    padding-bottom: 17.8125rem !important;
  }
  .py-md-290 {
    padding-top: 18.125rem !important;
    padding-bottom: 18.125rem !important;
  }
  .py-md-295 {
    padding-top: 18.4375rem !important;
    padding-bottom: 18.4375rem !important;
  }
  .py-md-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-md-0 {
    padding-top: 0 !important;
  }
  .pt-md-5 {
    padding-top: 0.313rem !important;
  }
  .pt-md-10 {
    padding-top: 0.625rem !important;
  }
  .pt-md-15 {
    padding-top: 0.938rem !important;
  }
  .pt-md-20 {
    padding-top: 1.25rem !important;
  }
  .pt-md-25 {
    padding-top: 1.563rem !important;
  }
  .pt-md-30 {
    padding-top: 1.875rem !important;
  }
  .pt-md-35 {
    padding-top: 2.188rem !important;
  }
  .pt-md-40 {
    padding-top: 2.5rem !important;
  }
  .pt-md-45 {
    padding-top: 2.813rem !important;
  }
  .pt-md-50 {
    padding-top: 3.125rem !important;
  }
  .pt-md-55 {
    padding-top: 3.438rem !important;
  }
  .pt-md-60 {
    padding-top: 3.75rem !important;
  }
  .pt-md-65 {
    padding-top: 4.063rem !important;
  }
  .pt-md-70 {
    padding-top: 4.375rem !important;
  }
  .pt-md-75 {
    padding-top: 4.688rem !important;
  }
  .pt-md-80 {
    padding-top: 5rem !important;
  }
  .pt-md-85 {
    padding-top: 5.313rem !important;
  }
  .pt-md-90 {
    padding-top: 5.625rem !important;
  }
  .pt-md-95 {
    padding-top: 5.938rem !important;
  }
  .pt-md-100 {
    padding-top: 6.25rem !important;
  }
  .pt-md-105 {
    padding-top: 6.5625rem !important;
  }
  .pt-md-110 {
    padding-top: 6.875rem !important;
  }
  .pt-md-115 {
    padding-top: 7.1875rem !important;
  }
  .pt-md-120 {
    padding-top: 7.5rem !important;
  }
  .pt-md-125 {
    padding-top: 7.8125rem !important;
  }
  .pt-md-130 {
    padding-top: 8.125rem !important;
  }
  .pt-md-135 {
    padding-top: 8.4375rem !important;
  }
  .pt-md-140 {
    padding-top: 8.75rem !important;
  }
  .pt-md-145 {
    padding-top: 9.0625rem !important;
  }
  .pt-md-150 {
    padding-top: 9.375rem !important;
  }
  .pt-md-155 {
    padding-top: 9.6875rem !important;
  }
  .pt-md-160 {
    padding-top: 10rem !important;
  }
  .pt-md-165 {
    padding-top: 10.3125rem !important;
  }
  .pt-md-170 {
    padding-top: 10.625rem !important;
  }
  .pt-md-175 {
    padding-top: 10.9375rem !important;
  }
  .pt-md-180 {
    padding-top: 11.25rem !important;
  }
  .pt-md-185 {
    padding-top: 11.5625rem !important;
  }
  .pt-md-190 {
    padding-top: 11.875rem !important;
  }
  .pt-md-195 {
    padding-top: 12.1875rem !important;
  }
  .pt-md-200 {
    padding-top: 12.5rem !important;
  }
  .pt-md-205 {
    padding-top: 12.8125rem !important;
  }
  .pt-md-210 {
    padding-top: 13.125rem !important;
  }
  .pt-md-215 {
    padding-top: 13.4375rem !important;
  }
  .pt-md-220 {
    padding-top: 13.75rem !important;
  }
  .pt-md-225 {
    padding-top: 14.0625rem !important;
  }
  .pt-md-230 {
    padding-top: 14.375rem !important;
  }
  .pt-md-235 {
    padding-top: 14.6875rem !important;
  }
  .pt-md-240 {
    padding-top: 15rem !important;
  }
  .pt-md-245 {
    padding-top: 15.3125rem !important;
  }
  .pt-md-250 {
    padding-top: 15.625rem !important;
  }
  .pt-md-255 {
    padding-top: 15.9375rem !important;
  }
  .pt-md-260 {
    padding-top: 16.25rem !important;
  }
  .pt-md-265 {
    padding-top: 16.5625rem !important;
  }
  .pt-md-270 {
    padding-top: 16.875rem !important;
  }
  .pt-md-275 {
    padding-top: 17.1875rem !important;
  }
  .pt-md-280 {
    padding-top: 17.5rem !important;
  }
  .pt-md-285 {
    padding-top: 17.8125rem !important;
  }
  .pt-md-290 {
    padding-top: 18.125rem !important;
  }
  .pt-md-295 {
    padding-top: 18.4375rem !important;
  }
  .pt-md-300 {
    padding-top: 18.75rem !important;
  }
  .pe-md-0 {
    padding-right: 0 !important;
  }
  .pe-md-5 {
    padding-right: 0.313rem !important;
  }
  .pe-md-10 {
    padding-right: 0.625rem !important;
  }
  .pe-md-15 {
    padding-right: 0.938rem !important;
  }
  .pe-md-20 {
    padding-right: 1.25rem !important;
  }
  .pe-md-25 {
    padding-right: 1.563rem !important;
  }
  .pe-md-30 {
    padding-right: 1.875rem !important;
  }
  .pe-md-35 {
    padding-right: 2.188rem !important;
  }
  .pe-md-40 {
    padding-right: 2.5rem !important;
  }
  .pe-md-45 {
    padding-right: 2.813rem !important;
  }
  .pe-md-50 {
    padding-right: 3.125rem !important;
  }
  .pe-md-55 {
    padding-right: 3.438rem !important;
  }
  .pe-md-60 {
    padding-right: 3.75rem !important;
  }
  .pe-md-65 {
    padding-right: 4.063rem !important;
  }
  .pe-md-70 {
    padding-right: 4.375rem !important;
  }
  .pe-md-75 {
    padding-right: 4.688rem !important;
  }
  .pe-md-80 {
    padding-right: 5rem !important;
  }
  .pe-md-85 {
    padding-right: 5.313rem !important;
  }
  .pe-md-90 {
    padding-right: 5.625rem !important;
  }
  .pe-md-95 {
    padding-right: 5.938rem !important;
  }
  .pe-md-100 {
    padding-right: 6.25rem !important;
  }
  .pe-md-105 {
    padding-right: 6.5625rem !important;
  }
  .pe-md-110 {
    padding-right: 6.875rem !important;
  }
  .pe-md-115 {
    padding-right: 7.1875rem !important;
  }
  .pe-md-120 {
    padding-right: 7.5rem !important;
  }
  .pe-md-125 {
    padding-right: 7.8125rem !important;
  }
  .pe-md-130 {
    padding-right: 8.125rem !important;
  }
  .pe-md-135 {
    padding-right: 8.4375rem !important;
  }
  .pe-md-140 {
    padding-right: 8.75rem !important;
  }
  .pe-md-145 {
    padding-right: 9.0625rem !important;
  }
  .pe-md-150 {
    padding-right: 9.375rem !important;
  }
  .pe-md-155 {
    padding-right: 9.6875rem !important;
  }
  .pe-md-160 {
    padding-right: 10rem !important;
  }
  .pe-md-165 {
    padding-right: 10.3125rem !important;
  }
  .pe-md-170 {
    padding-right: 10.625rem !important;
  }
  .pe-md-175 {
    padding-right: 10.9375rem !important;
  }
  .pe-md-180 {
    padding-right: 11.25rem !important;
  }
  .pe-md-185 {
    padding-right: 11.5625rem !important;
  }
  .pe-md-190 {
    padding-right: 11.875rem !important;
  }
  .pe-md-195 {
    padding-right: 12.1875rem !important;
  }
  .pe-md-200 {
    padding-right: 12.5rem !important;
  }
  .pe-md-205 {
    padding-right: 12.8125rem !important;
  }
  .pe-md-210 {
    padding-right: 13.125rem !important;
  }
  .pe-md-215 {
    padding-right: 13.4375rem !important;
  }
  .pe-md-220 {
    padding-right: 13.75rem !important;
  }
  .pe-md-225 {
    padding-right: 14.0625rem !important;
  }
  .pe-md-230 {
    padding-right: 14.375rem !important;
  }
  .pe-md-235 {
    padding-right: 14.6875rem !important;
  }
  .pe-md-240 {
    padding-right: 15rem !important;
  }
  .pe-md-245 {
    padding-right: 15.3125rem !important;
  }
  .pe-md-250 {
    padding-right: 15.625rem !important;
  }
  .pe-md-255 {
    padding-right: 15.9375rem !important;
  }
  .pe-md-260 {
    padding-right: 16.25rem !important;
  }
  .pe-md-265 {
    padding-right: 16.5625rem !important;
  }
  .pe-md-270 {
    padding-right: 16.875rem !important;
  }
  .pe-md-275 {
    padding-right: 17.1875rem !important;
  }
  .pe-md-280 {
    padding-right: 17.5rem !important;
  }
  .pe-md-285 {
    padding-right: 17.8125rem !important;
  }
  .pe-md-290 {
    padding-right: 18.125rem !important;
  }
  .pe-md-295 {
    padding-right: 18.4375rem !important;
  }
  .pe-md-300 {
    padding-right: 18.75rem !important;
  }
  .pb-md-0 {
    padding-bottom: 0 !important;
  }
  .pb-md-5 {
    padding-bottom: 0.313rem !important;
  }
  .pb-md-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-md-15 {
    padding-bottom: 0.938rem !important;
  }
  .pb-md-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-md-25 {
    padding-bottom: 1.563rem !important;
  }
  .pb-md-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-md-35 {
    padding-bottom: 2.188rem !important;
  }
  .pb-md-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-md-45 {
    padding-bottom: 2.813rem !important;
  }
  .pb-md-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-md-55 {
    padding-bottom: 3.438rem !important;
  }
  .pb-md-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-md-65 {
    padding-bottom: 4.063rem !important;
  }
  .pb-md-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-md-75 {
    padding-bottom: 4.688rem !important;
  }
  .pb-md-80 {
    padding-bottom: 5rem !important;
  }
  .pb-md-85 {
    padding-bottom: 5.313rem !important;
  }
  .pb-md-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-md-95 {
    padding-bottom: 5.938rem !important;
  }
  .pb-md-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-md-105 {
    padding-bottom: 6.5625rem !important;
  }
  .pb-md-110 {
    padding-bottom: 6.875rem !important;
  }
  .pb-md-115 {
    padding-bottom: 7.1875rem !important;
  }
  .pb-md-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-md-125 {
    padding-bottom: 7.8125rem !important;
  }
  .pb-md-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-md-135 {
    padding-bottom: 8.4375rem !important;
  }
  .pb-md-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-md-145 {
    padding-bottom: 9.0625rem !important;
  }
  .pb-md-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-md-155 {
    padding-bottom: 9.6875rem !important;
  }
  .pb-md-160 {
    padding-bottom: 10rem !important;
  }
  .pb-md-165 {
    padding-bottom: 10.3125rem !important;
  }
  .pb-md-170 {
    padding-bottom: 10.625rem !important;
  }
  .pb-md-175 {
    padding-bottom: 10.9375rem !important;
  }
  .pb-md-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-md-185 {
    padding-bottom: 11.5625rem !important;
  }
  .pb-md-190 {
    padding-bottom: 11.875rem !important;
  }
  .pb-md-195 {
    padding-bottom: 12.1875rem !important;
  }
  .pb-md-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-md-205 {
    padding-bottom: 12.8125rem !important;
  }
  .pb-md-210 {
    padding-bottom: 13.125rem !important;
  }
  .pb-md-215 {
    padding-bottom: 13.4375rem !important;
  }
  .pb-md-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-md-225 {
    padding-bottom: 14.0625rem !important;
  }
  .pb-md-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-md-235 {
    padding-bottom: 14.6875rem !important;
  }
  .pb-md-240 {
    padding-bottom: 15rem !important;
  }
  .pb-md-245 {
    padding-bottom: 15.3125rem !important;
  }
  .pb-md-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-md-255 {
    padding-bottom: 15.9375rem !important;
  }
  .pb-md-260 {
    padding-bottom: 16.25rem !important;
  }
  .pb-md-265 {
    padding-bottom: 16.5625rem !important;
  }
  .pb-md-270 {
    padding-bottom: 16.875rem !important;
  }
  .pb-md-275 {
    padding-bottom: 17.1875rem !important;
  }
  .pb-md-280 {
    padding-bottom: 17.5rem !important;
  }
  .pb-md-285 {
    padding-bottom: 17.8125rem !important;
  }
  .pb-md-290 {
    padding-bottom: 18.125rem !important;
  }
  .pb-md-295 {
    padding-bottom: 18.4375rem !important;
  }
  .pb-md-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-md-0 {
    padding-left: 0 !important;
  }
  .ps-md-5 {
    padding-left: 0.313rem !important;
  }
  .ps-md-10 {
    padding-left: 0.625rem !important;
  }
  .ps-md-15 {
    padding-left: 0.938rem !important;
  }
  .ps-md-20 {
    padding-left: 1.25rem !important;
  }
  .ps-md-25 {
    padding-left: 1.563rem !important;
  }
  .ps-md-30 {
    padding-left: 1.875rem !important;
  }
  .ps-md-35 {
    padding-left: 2.188rem !important;
  }
  .ps-md-40 {
    padding-left: 2.5rem !important;
  }
  .ps-md-45 {
    padding-left: 2.813rem !important;
  }
  .ps-md-50 {
    padding-left: 3.125rem !important;
  }
  .ps-md-55 {
    padding-left: 3.438rem !important;
  }
  .ps-md-60 {
    padding-left: 3.75rem !important;
  }
  .ps-md-65 {
    padding-left: 4.063rem !important;
  }
  .ps-md-70 {
    padding-left: 4.375rem !important;
  }
  .ps-md-75 {
    padding-left: 4.688rem !important;
  }
  .ps-md-80 {
    padding-left: 5rem !important;
  }
  .ps-md-85 {
    padding-left: 5.313rem !important;
  }
  .ps-md-90 {
    padding-left: 5.625rem !important;
  }
  .ps-md-95 {
    padding-left: 5.938rem !important;
  }
  .ps-md-100 {
    padding-left: 6.25rem !important;
  }
  .ps-md-105 {
    padding-left: 6.5625rem !important;
  }
  .ps-md-110 {
    padding-left: 6.875rem !important;
  }
  .ps-md-115 {
    padding-left: 7.1875rem !important;
  }
  .ps-md-120 {
    padding-left: 7.5rem !important;
  }
  .ps-md-125 {
    padding-left: 7.8125rem !important;
  }
  .ps-md-130 {
    padding-left: 8.125rem !important;
  }
  .ps-md-135 {
    padding-left: 8.4375rem !important;
  }
  .ps-md-140 {
    padding-left: 8.75rem !important;
  }
  .ps-md-145 {
    padding-left: 9.0625rem !important;
  }
  .ps-md-150 {
    padding-left: 9.375rem !important;
  }
  .ps-md-155 {
    padding-left: 9.6875rem !important;
  }
  .ps-md-160 {
    padding-left: 10rem !important;
  }
  .ps-md-165 {
    padding-left: 10.3125rem !important;
  }
  .ps-md-170 {
    padding-left: 10.625rem !important;
  }
  .ps-md-175 {
    padding-left: 10.9375rem !important;
  }
  .ps-md-180 {
    padding-left: 11.25rem !important;
  }
  .ps-md-185 {
    padding-left: 11.5625rem !important;
  }
  .ps-md-190 {
    padding-left: 11.875rem !important;
  }
  .ps-md-195 {
    padding-left: 12.1875rem !important;
  }
  .ps-md-200 {
    padding-left: 12.5rem !important;
  }
  .ps-md-205 {
    padding-left: 12.8125rem !important;
  }
  .ps-md-210 {
    padding-left: 13.125rem !important;
  }
  .ps-md-215 {
    padding-left: 13.4375rem !important;
  }
  .ps-md-220 {
    padding-left: 13.75rem !important;
  }
  .ps-md-225 {
    padding-left: 14.0625rem !important;
  }
  .ps-md-230 {
    padding-left: 14.375rem !important;
  }
  .ps-md-235 {
    padding-left: 14.6875rem !important;
  }
  .ps-md-240 {
    padding-left: 15rem !important;
  }
  .ps-md-245 {
    padding-left: 15.3125rem !important;
  }
  .ps-md-250 {
    padding-left: 15.625rem !important;
  }
  .ps-md-255 {
    padding-left: 15.9375rem !important;
  }
  .ps-md-260 {
    padding-left: 16.25rem !important;
  }
  .ps-md-265 {
    padding-left: 16.5625rem !important;
  }
  .ps-md-270 {
    padding-left: 16.875rem !important;
  }
  .ps-md-275 {
    padding-left: 17.1875rem !important;
  }
  .ps-md-280 {
    padding-left: 17.5rem !important;
  }
  .ps-md-285 {
    padding-left: 17.8125rem !important;
  }
  .ps-md-290 {
    padding-left: 18.125rem !important;
  }
  .ps-md-295 {
    padding-left: 18.4375rem !important;
  }
  .ps-md-300 {
    padding-left: 18.75rem !important;
  }
  .gap-md-0 {
    gap: 0 !important;
  }
  .gap-md-5 {
    gap: 0.313rem !important;
  }
  .gap-md-10 {
    gap: 0.625rem !important;
  }
  .gap-md-15 {
    gap: 0.938rem !important;
  }
  .gap-md-20 {
    gap: 1.25rem !important;
  }
  .gap-md-25 {
    gap: 1.563rem !important;
  }
  .gap-md-30 {
    gap: 1.875rem !important;
  }
  .gap-md-35 {
    gap: 2.188rem !important;
  }
  .gap-md-40 {
    gap: 2.5rem !important;
  }
  .gap-md-45 {
    gap: 2.813rem !important;
  }
  .gap-md-50 {
    gap: 3.125rem !important;
  }
  .gap-md-55 {
    gap: 3.438rem !important;
  }
  .gap-md-60 {
    gap: 3.75rem !important;
  }
  .gap-md-65 {
    gap: 4.063rem !important;
  }
  .gap-md-70 {
    gap: 4.375rem !important;
  }
  .gap-md-75 {
    gap: 4.688rem !important;
  }
  .gap-md-80 {
    gap: 5rem !important;
  }
  .gap-md-85 {
    gap: 5.313rem !important;
  }
  .gap-md-90 {
    gap: 5.625rem !important;
  }
  .gap-md-95 {
    gap: 5.938rem !important;
  }
  .gap-md-100 {
    gap: 6.25rem !important;
  }
  .gap-md-105 {
    gap: 6.5625rem !important;
  }
  .gap-md-110 {
    gap: 6.875rem !important;
  }
  .gap-md-115 {
    gap: 7.1875rem !important;
  }
  .gap-md-120 {
    gap: 7.5rem !important;
  }
  .gap-md-125 {
    gap: 7.8125rem !important;
  }
  .gap-md-130 {
    gap: 8.125rem !important;
  }
  .gap-md-135 {
    gap: 8.4375rem !important;
  }
  .gap-md-140 {
    gap: 8.75rem !important;
  }
  .gap-md-145 {
    gap: 9.0625rem !important;
  }
  .gap-md-150 {
    gap: 9.375rem !important;
  }
  .gap-md-155 {
    gap: 9.6875rem !important;
  }
  .gap-md-160 {
    gap: 10rem !important;
  }
  .gap-md-165 {
    gap: 10.3125rem !important;
  }
  .gap-md-170 {
    gap: 10.625rem !important;
  }
  .gap-md-175 {
    gap: 10.9375rem !important;
  }
  .gap-md-180 {
    gap: 11.25rem !important;
  }
  .gap-md-185 {
    gap: 11.5625rem !important;
  }
  .gap-md-190 {
    gap: 11.875rem !important;
  }
  .gap-md-195 {
    gap: 12.1875rem !important;
  }
  .gap-md-200 {
    gap: 12.5rem !important;
  }
  .gap-md-205 {
    gap: 12.8125rem !important;
  }
  .gap-md-210 {
    gap: 13.125rem !important;
  }
  .gap-md-215 {
    gap: 13.4375rem !important;
  }
  .gap-md-220 {
    gap: 13.75rem !important;
  }
  .gap-md-225 {
    gap: 14.0625rem !important;
  }
  .gap-md-230 {
    gap: 14.375rem !important;
  }
  .gap-md-235 {
    gap: 14.6875rem !important;
  }
  .gap-md-240 {
    gap: 15rem !important;
  }
  .gap-md-245 {
    gap: 15.3125rem !important;
  }
  .gap-md-250 {
    gap: 15.625rem !important;
  }
  .gap-md-255 {
    gap: 15.9375rem !important;
  }
  .gap-md-260 {
    gap: 16.25rem !important;
  }
  .gap-md-265 {
    gap: 16.5625rem !important;
  }
  .gap-md-270 {
    gap: 16.875rem !important;
  }
  .gap-md-275 {
    gap: 17.1875rem !important;
  }
  .gap-md-280 {
    gap: 17.5rem !important;
  }
  .gap-md-285 {
    gap: 17.8125rem !important;
  }
  .gap-md-290 {
    gap: 18.125rem !important;
  }
  .gap-md-295 {
    gap: 18.4375rem !important;
  }
  .gap-md-300 {
    gap: 18.75rem !important;
  }
  .row-gap-md-0 {
    row-gap: 0 !important;
  }
  .row-gap-md-5 {
    row-gap: 0.313rem !important;
  }
  .row-gap-md-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-md-15 {
    row-gap: 0.938rem !important;
  }
  .row-gap-md-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-md-25 {
    row-gap: 1.563rem !important;
  }
  .row-gap-md-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-md-35 {
    row-gap: 2.188rem !important;
  }
  .row-gap-md-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-md-45 {
    row-gap: 2.813rem !important;
  }
  .row-gap-md-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-md-55 {
    row-gap: 3.438rem !important;
  }
  .row-gap-md-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-md-65 {
    row-gap: 4.063rem !important;
  }
  .row-gap-md-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-md-75 {
    row-gap: 4.688rem !important;
  }
  .row-gap-md-80 {
    row-gap: 5rem !important;
  }
  .row-gap-md-85 {
    row-gap: 5.313rem !important;
  }
  .row-gap-md-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-md-95 {
    row-gap: 5.938rem !important;
  }
  .row-gap-md-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-md-105 {
    row-gap: 6.5625rem !important;
  }
  .row-gap-md-110 {
    row-gap: 6.875rem !important;
  }
  .row-gap-md-115 {
    row-gap: 7.1875rem !important;
  }
  .row-gap-md-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-md-125 {
    row-gap: 7.8125rem !important;
  }
  .row-gap-md-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-md-135 {
    row-gap: 8.4375rem !important;
  }
  .row-gap-md-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-md-145 {
    row-gap: 9.0625rem !important;
  }
  .row-gap-md-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-md-155 {
    row-gap: 9.6875rem !important;
  }
  .row-gap-md-160 {
    row-gap: 10rem !important;
  }
  .row-gap-md-165 {
    row-gap: 10.3125rem !important;
  }
  .row-gap-md-170 {
    row-gap: 10.625rem !important;
  }
  .row-gap-md-175 {
    row-gap: 10.9375rem !important;
  }
  .row-gap-md-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-md-185 {
    row-gap: 11.5625rem !important;
  }
  .row-gap-md-190 {
    row-gap: 11.875rem !important;
  }
  .row-gap-md-195 {
    row-gap: 12.1875rem !important;
  }
  .row-gap-md-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-md-205 {
    row-gap: 12.8125rem !important;
  }
  .row-gap-md-210 {
    row-gap: 13.125rem !important;
  }
  .row-gap-md-215 {
    row-gap: 13.4375rem !important;
  }
  .row-gap-md-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-md-225 {
    row-gap: 14.0625rem !important;
  }
  .row-gap-md-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-md-235 {
    row-gap: 14.6875rem !important;
  }
  .row-gap-md-240 {
    row-gap: 15rem !important;
  }
  .row-gap-md-245 {
    row-gap: 15.3125rem !important;
  }
  .row-gap-md-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-md-255 {
    row-gap: 15.9375rem !important;
  }
  .row-gap-md-260 {
    row-gap: 16.25rem !important;
  }
  .row-gap-md-265 {
    row-gap: 16.5625rem !important;
  }
  .row-gap-md-270 {
    row-gap: 16.875rem !important;
  }
  .row-gap-md-275 {
    row-gap: 17.1875rem !important;
  }
  .row-gap-md-280 {
    row-gap: 17.5rem !important;
  }
  .row-gap-md-285 {
    row-gap: 17.8125rem !important;
  }
  .row-gap-md-290 {
    row-gap: 18.125rem !important;
  }
  .row-gap-md-295 {
    row-gap: 18.4375rem !important;
  }
  .row-gap-md-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-md-0 {
    column-gap: 0 !important;
  }
  .column-gap-md-5 {
    column-gap: 0.313rem !important;
  }
  .column-gap-md-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-md-15 {
    column-gap: 0.938rem !important;
  }
  .column-gap-md-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-md-25 {
    column-gap: 1.563rem !important;
  }
  .column-gap-md-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-md-35 {
    column-gap: 2.188rem !important;
  }
  .column-gap-md-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-md-45 {
    column-gap: 2.813rem !important;
  }
  .column-gap-md-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-md-55 {
    column-gap: 3.438rem !important;
  }
  .column-gap-md-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-md-65 {
    column-gap: 4.063rem !important;
  }
  .column-gap-md-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-md-75 {
    column-gap: 4.688rem !important;
  }
  .column-gap-md-80 {
    column-gap: 5rem !important;
  }
  .column-gap-md-85 {
    column-gap: 5.313rem !important;
  }
  .column-gap-md-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-md-95 {
    column-gap: 5.938rem !important;
  }
  .column-gap-md-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-md-105 {
    column-gap: 6.5625rem !important;
  }
  .column-gap-md-110 {
    column-gap: 6.875rem !important;
  }
  .column-gap-md-115 {
    column-gap: 7.1875rem !important;
  }
  .column-gap-md-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-md-125 {
    column-gap: 7.8125rem !important;
  }
  .column-gap-md-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-md-135 {
    column-gap: 8.4375rem !important;
  }
  .column-gap-md-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-md-145 {
    column-gap: 9.0625rem !important;
  }
  .column-gap-md-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-md-155 {
    column-gap: 9.6875rem !important;
  }
  .column-gap-md-160 {
    column-gap: 10rem !important;
  }
  .column-gap-md-165 {
    column-gap: 10.3125rem !important;
  }
  .column-gap-md-170 {
    column-gap: 10.625rem !important;
  }
  .column-gap-md-175 {
    column-gap: 10.9375rem !important;
  }
  .column-gap-md-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-md-185 {
    column-gap: 11.5625rem !important;
  }
  .column-gap-md-190 {
    column-gap: 11.875rem !important;
  }
  .column-gap-md-195 {
    column-gap: 12.1875rem !important;
  }
  .column-gap-md-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-md-205 {
    column-gap: 12.8125rem !important;
  }
  .column-gap-md-210 {
    column-gap: 13.125rem !important;
  }
  .column-gap-md-215 {
    column-gap: 13.4375rem !important;
  }
  .column-gap-md-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-md-225 {
    column-gap: 14.0625rem !important;
  }
  .column-gap-md-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-md-235 {
    column-gap: 14.6875rem !important;
  }
  .column-gap-md-240 {
    column-gap: 15rem !important;
  }
  .column-gap-md-245 {
    column-gap: 15.3125rem !important;
  }
  .column-gap-md-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-md-255 {
    column-gap: 15.9375rem !important;
  }
  .column-gap-md-260 {
    column-gap: 16.25rem !important;
  }
  .column-gap-md-265 {
    column-gap: 16.5625rem !important;
  }
  .column-gap-md-270 {
    column-gap: 16.875rem !important;
  }
  .column-gap-md-275 {
    column-gap: 17.1875rem !important;
  }
  .column-gap-md-280 {
    column-gap: 17.5rem !important;
  }
  .column-gap-md-285 {
    column-gap: 17.8125rem !important;
  }
  .column-gap-md-290 {
    column-gap: 18.125rem !important;
  }
  .column-gap-md-295 {
    column-gap: 18.4375rem !important;
  }
  .column-gap-md-300 {
    column-gap: 18.75rem !important;
  }
  .text-md-start {
    text-align: left !important;
  }
  .text-md-end {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-lg-start {
    float: left !important;
  }
  .float-lg-end {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
  .object-fit-lg-contain {
    object-fit: contain !important;
  }
  .object-fit-lg-cover {
    object-fit: cover !important;
  }
  .object-fit-lg-fill {
    object-fit: fill !important;
  }
  .object-fit-lg-scale {
    object-fit: scale-down !important;
  }
  .object-fit-lg-none {
    object-fit: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-grid {
    display: grid !important;
  }
  .d-lg-inline-grid {
    display: inline-grid !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
  .d-lg-none {
    display: none !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
  .order-lg-first {
    order: -1 !important;
  }
  .order-lg-0 {
    order: 0 !important;
  }
  .order-lg-1 {
    order: 1 !important;
  }
  .order-lg-2 {
    order: 2 !important;
  }
  .order-lg-3 {
    order: 3 !important;
  }
  .order-lg-4 {
    order: 4 !important;
  }
  .order-lg-5 {
    order: 5 !important;
  }
  .order-lg-last {
    order: 6 !important;
  }
  .m-lg-0 {
    margin: 0 !important;
  }
  .m-lg-5 {
    margin: 0.313rem !important;
  }
  .m-lg-10 {
    margin: 0.625rem !important;
  }
  .m-lg-15 {
    margin: 0.938rem !important;
  }
  .m-lg-20 {
    margin: 1.25rem !important;
  }
  .m-lg-25 {
    margin: 1.563rem !important;
  }
  .m-lg-30 {
    margin: 1.875rem !important;
  }
  .m-lg-35 {
    margin: 2.188rem !important;
  }
  .m-lg-40 {
    margin: 2.5rem !important;
  }
  .m-lg-45 {
    margin: 2.813rem !important;
  }
  .m-lg-50 {
    margin: 3.125rem !important;
  }
  .m-lg-55 {
    margin: 3.438rem !important;
  }
  .m-lg-60 {
    margin: 3.75rem !important;
  }
  .m-lg-65 {
    margin: 4.063rem !important;
  }
  .m-lg-70 {
    margin: 4.375rem !important;
  }
  .m-lg-75 {
    margin: 4.688rem !important;
  }
  .m-lg-80 {
    margin: 5rem !important;
  }
  .m-lg-85 {
    margin: 5.313rem !important;
  }
  .m-lg-90 {
    margin: 5.625rem !important;
  }
  .m-lg-95 {
    margin: 5.938rem !important;
  }
  .m-lg-100 {
    margin: 6.25rem !important;
  }
  .m-lg-105 {
    margin: 6.5625rem !important;
  }
  .m-lg-110 {
    margin: 6.875rem !important;
  }
  .m-lg-115 {
    margin: 7.1875rem !important;
  }
  .m-lg-120 {
    margin: 7.5rem !important;
  }
  .m-lg-125 {
    margin: 7.8125rem !important;
  }
  .m-lg-130 {
    margin: 8.125rem !important;
  }
  .m-lg-135 {
    margin: 8.4375rem !important;
  }
  .m-lg-140 {
    margin: 8.75rem !important;
  }
  .m-lg-145 {
    margin: 9.0625rem !important;
  }
  .m-lg-150 {
    margin: 9.375rem !important;
  }
  .m-lg-155 {
    margin: 9.6875rem !important;
  }
  .m-lg-160 {
    margin: 10rem !important;
  }
  .m-lg-165 {
    margin: 10.3125rem !important;
  }
  .m-lg-170 {
    margin: 10.625rem !important;
  }
  .m-lg-175 {
    margin: 10.9375rem !important;
  }
  .m-lg-180 {
    margin: 11.25rem !important;
  }
  .m-lg-185 {
    margin: 11.5625rem !important;
  }
  .m-lg-190 {
    margin: 11.875rem !important;
  }
  .m-lg-195 {
    margin: 12.1875rem !important;
  }
  .m-lg-200 {
    margin: 12.5rem !important;
  }
  .m-lg-205 {
    margin: 12.8125rem !important;
  }
  .m-lg-210 {
    margin: 13.125rem !important;
  }
  .m-lg-215 {
    margin: 13.4375rem !important;
  }
  .m-lg-220 {
    margin: 13.75rem !important;
  }
  .m-lg-225 {
    margin: 14.0625rem !important;
  }
  .m-lg-230 {
    margin: 14.375rem !important;
  }
  .m-lg-235 {
    margin: 14.6875rem !important;
  }
  .m-lg-240 {
    margin: 15rem !important;
  }
  .m-lg-245 {
    margin: 15.3125rem !important;
  }
  .m-lg-250 {
    margin: 15.625rem !important;
  }
  .m-lg-255 {
    margin: 15.9375rem !important;
  }
  .m-lg-260 {
    margin: 16.25rem !important;
  }
  .m-lg-265 {
    margin: 16.5625rem !important;
  }
  .m-lg-270 {
    margin: 16.875rem !important;
  }
  .m-lg-275 {
    margin: 17.1875rem !important;
  }
  .m-lg-280 {
    margin: 17.5rem !important;
  }
  .m-lg-285 {
    margin: 17.8125rem !important;
  }
  .m-lg-290 {
    margin: 18.125rem !important;
  }
  .m-lg-295 {
    margin: 18.4375rem !important;
  }
  .m-lg-300 {
    margin: 18.75rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .mx-lg-5 {
    margin-right: 0.313rem !important;
    margin-left: 0.313rem !important;
  }
  .mx-lg-10 {
    margin-right: 0.625rem !important;
    margin-left: 0.625rem !important;
  }
  .mx-lg-15 {
    margin-right: 0.938rem !important;
    margin-left: 0.938rem !important;
  }
  .mx-lg-20 {
    margin-right: 1.25rem !important;
    margin-left: 1.25rem !important;
  }
  .mx-lg-25 {
    margin-right: 1.563rem !important;
    margin-left: 1.563rem !important;
  }
  .mx-lg-30 {
    margin-right: 1.875rem !important;
    margin-left: 1.875rem !important;
  }
  .mx-lg-35 {
    margin-right: 2.188rem !important;
    margin-left: 2.188rem !important;
  }
  .mx-lg-40 {
    margin-right: 2.5rem !important;
    margin-left: 2.5rem !important;
  }
  .mx-lg-45 {
    margin-right: 2.813rem !important;
    margin-left: 2.813rem !important;
  }
  .mx-lg-50 {
    margin-right: 3.125rem !important;
    margin-left: 3.125rem !important;
  }
  .mx-lg-55 {
    margin-right: 3.438rem !important;
    margin-left: 3.438rem !important;
  }
  .mx-lg-60 {
    margin-right: 3.75rem !important;
    margin-left: 3.75rem !important;
  }
  .mx-lg-65 {
    margin-right: 4.063rem !important;
    margin-left: 4.063rem !important;
  }
  .mx-lg-70 {
    margin-right: 4.375rem !important;
    margin-left: 4.375rem !important;
  }
  .mx-lg-75 {
    margin-right: 4.688rem !important;
    margin-left: 4.688rem !important;
  }
  .mx-lg-80 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }
  .mx-lg-85 {
    margin-right: 5.313rem !important;
    margin-left: 5.313rem !important;
  }
  .mx-lg-90 {
    margin-right: 5.625rem !important;
    margin-left: 5.625rem !important;
  }
  .mx-lg-95 {
    margin-right: 5.938rem !important;
    margin-left: 5.938rem !important;
  }
  .mx-lg-100 {
    margin-right: 6.25rem !important;
    margin-left: 6.25rem !important;
  }
  .mx-lg-105 {
    margin-right: 6.5625rem !important;
    margin-left: 6.5625rem !important;
  }
  .mx-lg-110 {
    margin-right: 6.875rem !important;
    margin-left: 6.875rem !important;
  }
  .mx-lg-115 {
    margin-right: 7.1875rem !important;
    margin-left: 7.1875rem !important;
  }
  .mx-lg-120 {
    margin-right: 7.5rem !important;
    margin-left: 7.5rem !important;
  }
  .mx-lg-125 {
    margin-right: 7.8125rem !important;
    margin-left: 7.8125rem !important;
  }
  .mx-lg-130 {
    margin-right: 8.125rem !important;
    margin-left: 8.125rem !important;
  }
  .mx-lg-135 {
    margin-right: 8.4375rem !important;
    margin-left: 8.4375rem !important;
  }
  .mx-lg-140 {
    margin-right: 8.75rem !important;
    margin-left: 8.75rem !important;
  }
  .mx-lg-145 {
    margin-right: 9.0625rem !important;
    margin-left: 9.0625rem !important;
  }
  .mx-lg-150 {
    margin-right: 9.375rem !important;
    margin-left: 9.375rem !important;
  }
  .mx-lg-155 {
    margin-right: 9.6875rem !important;
    margin-left: 9.6875rem !important;
  }
  .mx-lg-160 {
    margin-right: 10rem !important;
    margin-left: 10rem !important;
  }
  .mx-lg-165 {
    margin-right: 10.3125rem !important;
    margin-left: 10.3125rem !important;
  }
  .mx-lg-170 {
    margin-right: 10.625rem !important;
    margin-left: 10.625rem !important;
  }
  .mx-lg-175 {
    margin-right: 10.9375rem !important;
    margin-left: 10.9375rem !important;
  }
  .mx-lg-180 {
    margin-right: 11.25rem !important;
    margin-left: 11.25rem !important;
  }
  .mx-lg-185 {
    margin-right: 11.5625rem !important;
    margin-left: 11.5625rem !important;
  }
  .mx-lg-190 {
    margin-right: 11.875rem !important;
    margin-left: 11.875rem !important;
  }
  .mx-lg-195 {
    margin-right: 12.1875rem !important;
    margin-left: 12.1875rem !important;
  }
  .mx-lg-200 {
    margin-right: 12.5rem !important;
    margin-left: 12.5rem !important;
  }
  .mx-lg-205 {
    margin-right: 12.8125rem !important;
    margin-left: 12.8125rem !important;
  }
  .mx-lg-210 {
    margin-right: 13.125rem !important;
    margin-left: 13.125rem !important;
  }
  .mx-lg-215 {
    margin-right: 13.4375rem !important;
    margin-left: 13.4375rem !important;
  }
  .mx-lg-220 {
    margin-right: 13.75rem !important;
    margin-left: 13.75rem !important;
  }
  .mx-lg-225 {
    margin-right: 14.0625rem !important;
    margin-left: 14.0625rem !important;
  }
  .mx-lg-230 {
    margin-right: 14.375rem !important;
    margin-left: 14.375rem !important;
  }
  .mx-lg-235 {
    margin-right: 14.6875rem !important;
    margin-left: 14.6875rem !important;
  }
  .mx-lg-240 {
    margin-right: 15rem !important;
    margin-left: 15rem !important;
  }
  .mx-lg-245 {
    margin-right: 15.3125rem !important;
    margin-left: 15.3125rem !important;
  }
  .mx-lg-250 {
    margin-right: 15.625rem !important;
    margin-left: 15.625rem !important;
  }
  .mx-lg-255 {
    margin-right: 15.9375rem !important;
    margin-left: 15.9375rem !important;
  }
  .mx-lg-260 {
    margin-right: 16.25rem !important;
    margin-left: 16.25rem !important;
  }
  .mx-lg-265 {
    margin-right: 16.5625rem !important;
    margin-left: 16.5625rem !important;
  }
  .mx-lg-270 {
    margin-right: 16.875rem !important;
    margin-left: 16.875rem !important;
  }
  .mx-lg-275 {
    margin-right: 17.1875rem !important;
    margin-left: 17.1875rem !important;
  }
  .mx-lg-280 {
    margin-right: 17.5rem !important;
    margin-left: 17.5rem !important;
  }
  .mx-lg-285 {
    margin-right: 17.8125rem !important;
    margin-left: 17.8125rem !important;
  }
  .mx-lg-290 {
    margin-right: 18.125rem !important;
    margin-left: 18.125rem !important;
  }
  .mx-lg-295 {
    margin-right: 18.4375rem !important;
    margin-left: 18.4375rem !important;
  }
  .mx-lg-300 {
    margin-right: 18.75rem !important;
    margin-left: 18.75rem !important;
  }
  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .my-lg-5 {
    margin-top: 0.313rem !important;
    margin-bottom: 0.313rem !important;
  }
  .my-lg-10 {
    margin-top: 0.625rem !important;
    margin-bottom: 0.625rem !important;
  }
  .my-lg-15 {
    margin-top: 0.938rem !important;
    margin-bottom: 0.938rem !important;
  }
  .my-lg-20 {
    margin-top: 1.25rem !important;
    margin-bottom: 1.25rem !important;
  }
  .my-lg-25 {
    margin-top: 1.563rem !important;
    margin-bottom: 1.563rem !important;
  }
  .my-lg-30 {
    margin-top: 1.875rem !important;
    margin-bottom: 1.875rem !important;
  }
  .my-lg-35 {
    margin-top: 2.188rem !important;
    margin-bottom: 2.188rem !important;
  }
  .my-lg-40 {
    margin-top: 2.5rem !important;
    margin-bottom: 2.5rem !important;
  }
  .my-lg-45 {
    margin-top: 2.813rem !important;
    margin-bottom: 2.813rem !important;
  }
  .my-lg-50 {
    margin-top: 3.125rem !important;
    margin-bottom: 3.125rem !important;
  }
  .my-lg-55 {
    margin-top: 3.438rem !important;
    margin-bottom: 3.438rem !important;
  }
  .my-lg-60 {
    margin-top: 3.75rem !important;
    margin-bottom: 3.75rem !important;
  }
  .my-lg-65 {
    margin-top: 4.063rem !important;
    margin-bottom: 4.063rem !important;
  }
  .my-lg-70 {
    margin-top: 4.375rem !important;
    margin-bottom: 4.375rem !important;
  }
  .my-lg-75 {
    margin-top: 4.688rem !important;
    margin-bottom: 4.688rem !important;
  }
  .my-lg-80 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }
  .my-lg-85 {
    margin-top: 5.313rem !important;
    margin-bottom: 5.313rem !important;
  }
  .my-lg-90 {
    margin-top: 5.625rem !important;
    margin-bottom: 5.625rem !important;
  }
  .my-lg-95 {
    margin-top: 5.938rem !important;
    margin-bottom: 5.938rem !important;
  }
  .my-lg-100 {
    margin-top: 6.25rem !important;
    margin-bottom: 6.25rem !important;
  }
  .my-lg-105 {
    margin-top: 6.5625rem !important;
    margin-bottom: 6.5625rem !important;
  }
  .my-lg-110 {
    margin-top: 6.875rem !important;
    margin-bottom: 6.875rem !important;
  }
  .my-lg-115 {
    margin-top: 7.1875rem !important;
    margin-bottom: 7.1875rem !important;
  }
  .my-lg-120 {
    margin-top: 7.5rem !important;
    margin-bottom: 7.5rem !important;
  }
  .my-lg-125 {
    margin-top: 7.8125rem !important;
    margin-bottom: 7.8125rem !important;
  }
  .my-lg-130 {
    margin-top: 8.125rem !important;
    margin-bottom: 8.125rem !important;
  }
  .my-lg-135 {
    margin-top: 8.4375rem !important;
    margin-bottom: 8.4375rem !important;
  }
  .my-lg-140 {
    margin-top: 8.75rem !important;
    margin-bottom: 8.75rem !important;
  }
  .my-lg-145 {
    margin-top: 9.0625rem !important;
    margin-bottom: 9.0625rem !important;
  }
  .my-lg-150 {
    margin-top: 9.375rem !important;
    margin-bottom: 9.375rem !important;
  }
  .my-lg-155 {
    margin-top: 9.6875rem !important;
    margin-bottom: 9.6875rem !important;
  }
  .my-lg-160 {
    margin-top: 10rem !important;
    margin-bottom: 10rem !important;
  }
  .my-lg-165 {
    margin-top: 10.3125rem !important;
    margin-bottom: 10.3125rem !important;
  }
  .my-lg-170 {
    margin-top: 10.625rem !important;
    margin-bottom: 10.625rem !important;
  }
  .my-lg-175 {
    margin-top: 10.9375rem !important;
    margin-bottom: 10.9375rem !important;
  }
  .my-lg-180 {
    margin-top: 11.25rem !important;
    margin-bottom: 11.25rem !important;
  }
  .my-lg-185 {
    margin-top: 11.5625rem !important;
    margin-bottom: 11.5625rem !important;
  }
  .my-lg-190 {
    margin-top: 11.875rem !important;
    margin-bottom: 11.875rem !important;
  }
  .my-lg-195 {
    margin-top: 12.1875rem !important;
    margin-bottom: 12.1875rem !important;
  }
  .my-lg-200 {
    margin-top: 12.5rem !important;
    margin-bottom: 12.5rem !important;
  }
  .my-lg-205 {
    margin-top: 12.8125rem !important;
    margin-bottom: 12.8125rem !important;
  }
  .my-lg-210 {
    margin-top: 13.125rem !important;
    margin-bottom: 13.125rem !important;
  }
  .my-lg-215 {
    margin-top: 13.4375rem !important;
    margin-bottom: 13.4375rem !important;
  }
  .my-lg-220 {
    margin-top: 13.75rem !important;
    margin-bottom: 13.75rem !important;
  }
  .my-lg-225 {
    margin-top: 14.0625rem !important;
    margin-bottom: 14.0625rem !important;
  }
  .my-lg-230 {
    margin-top: 14.375rem !important;
    margin-bottom: 14.375rem !important;
  }
  .my-lg-235 {
    margin-top: 14.6875rem !important;
    margin-bottom: 14.6875rem !important;
  }
  .my-lg-240 {
    margin-top: 15rem !important;
    margin-bottom: 15rem !important;
  }
  .my-lg-245 {
    margin-top: 15.3125rem !important;
    margin-bottom: 15.3125rem !important;
  }
  .my-lg-250 {
    margin-top: 15.625rem !important;
    margin-bottom: 15.625rem !important;
  }
  .my-lg-255 {
    margin-top: 15.9375rem !important;
    margin-bottom: 15.9375rem !important;
  }
  .my-lg-260 {
    margin-top: 16.25rem !important;
    margin-bottom: 16.25rem !important;
  }
  .my-lg-265 {
    margin-top: 16.5625rem !important;
    margin-bottom: 16.5625rem !important;
  }
  .my-lg-270 {
    margin-top: 16.875rem !important;
    margin-bottom: 16.875rem !important;
  }
  .my-lg-275 {
    margin-top: 17.1875rem !important;
    margin-bottom: 17.1875rem !important;
  }
  .my-lg-280 {
    margin-top: 17.5rem !important;
    margin-bottom: 17.5rem !important;
  }
  .my-lg-285 {
    margin-top: 17.8125rem !important;
    margin-bottom: 17.8125rem !important;
  }
  .my-lg-290 {
    margin-top: 18.125rem !important;
    margin-bottom: 18.125rem !important;
  }
  .my-lg-295 {
    margin-top: 18.4375rem !important;
    margin-bottom: 18.4375rem !important;
  }
  .my-lg-300 {
    margin-top: 18.75rem !important;
    margin-bottom: 18.75rem !important;
  }
  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }
  .mt-lg-0 {
    margin-top: 0 !important;
  }
  .mt-lg-5 {
    margin-top: 0.313rem !important;
  }
  .mt-lg-10 {
    margin-top: 0.625rem !important;
  }
  .mt-lg-15 {
    margin-top: 0.938rem !important;
  }
  .mt-lg-20 {
    margin-top: 1.25rem !important;
  }
  .mt-lg-25 {
    margin-top: 1.563rem !important;
  }
  .mt-lg-30 {
    margin-top: 1.875rem !important;
  }
  .mt-lg-35 {
    margin-top: 2.188rem !important;
  }
  .mt-lg-40 {
    margin-top: 2.5rem !important;
  }
  .mt-lg-45 {
    margin-top: 2.813rem !important;
  }
  .mt-lg-50 {
    margin-top: 3.125rem !important;
  }
  .mt-lg-55 {
    margin-top: 3.438rem !important;
  }
  .mt-lg-60 {
    margin-top: 3.75rem !important;
  }
  .mt-lg-65 {
    margin-top: 4.063rem !important;
  }
  .mt-lg-70 {
    margin-top: 4.375rem !important;
  }
  .mt-lg-75 {
    margin-top: 4.688rem !important;
  }
  .mt-lg-80 {
    margin-top: 5rem !important;
  }
  .mt-lg-85 {
    margin-top: 5.313rem !important;
  }
  .mt-lg-90 {
    margin-top: 5.625rem !important;
  }
  .mt-lg-95 {
    margin-top: 5.938rem !important;
  }
  .mt-lg-100 {
    margin-top: 6.25rem !important;
  }
  .mt-lg-105 {
    margin-top: 6.5625rem !important;
  }
  .mt-lg-110 {
    margin-top: 6.875rem !important;
  }
  .mt-lg-115 {
    margin-top: 7.1875rem !important;
  }
  .mt-lg-120 {
    margin-top: 7.5rem !important;
  }
  .mt-lg-125 {
    margin-top: 7.8125rem !important;
  }
  .mt-lg-130 {
    margin-top: 8.125rem !important;
  }
  .mt-lg-135 {
    margin-top: 8.4375rem !important;
  }
  .mt-lg-140 {
    margin-top: 8.75rem !important;
  }
  .mt-lg-145 {
    margin-top: 9.0625rem !important;
  }
  .mt-lg-150 {
    margin-top: 9.375rem !important;
  }
  .mt-lg-155 {
    margin-top: 9.6875rem !important;
  }
  .mt-lg-160 {
    margin-top: 10rem !important;
  }
  .mt-lg-165 {
    margin-top: 10.3125rem !important;
  }
  .mt-lg-170 {
    margin-top: 10.625rem !important;
  }
  .mt-lg-175 {
    margin-top: 10.9375rem !important;
  }
  .mt-lg-180 {
    margin-top: 11.25rem !important;
  }
  .mt-lg-185 {
    margin-top: 11.5625rem !important;
  }
  .mt-lg-190 {
    margin-top: 11.875rem !important;
  }
  .mt-lg-195 {
    margin-top: 12.1875rem !important;
  }
  .mt-lg-200 {
    margin-top: 12.5rem !important;
  }
  .mt-lg-205 {
    margin-top: 12.8125rem !important;
  }
  .mt-lg-210 {
    margin-top: 13.125rem !important;
  }
  .mt-lg-215 {
    margin-top: 13.4375rem !important;
  }
  .mt-lg-220 {
    margin-top: 13.75rem !important;
  }
  .mt-lg-225 {
    margin-top: 14.0625rem !important;
  }
  .mt-lg-230 {
    margin-top: 14.375rem !important;
  }
  .mt-lg-235 {
    margin-top: 14.6875rem !important;
  }
  .mt-lg-240 {
    margin-top: 15rem !important;
  }
  .mt-lg-245 {
    margin-top: 15.3125rem !important;
  }
  .mt-lg-250 {
    margin-top: 15.625rem !important;
  }
  .mt-lg-255 {
    margin-top: 15.9375rem !important;
  }
  .mt-lg-260 {
    margin-top: 16.25rem !important;
  }
  .mt-lg-265 {
    margin-top: 16.5625rem !important;
  }
  .mt-lg-270 {
    margin-top: 16.875rem !important;
  }
  .mt-lg-275 {
    margin-top: 17.1875rem !important;
  }
  .mt-lg-280 {
    margin-top: 17.5rem !important;
  }
  .mt-lg-285 {
    margin-top: 17.8125rem !important;
  }
  .mt-lg-290 {
    margin-top: 18.125rem !important;
  }
  .mt-lg-295 {
    margin-top: 18.4375rem !important;
  }
  .mt-lg-300 {
    margin-top: 18.75rem !important;
  }
  .mt-lg-auto {
    margin-top: auto !important;
  }
  .me-lg-0 {
    margin-right: 0 !important;
  }
  .me-lg-5 {
    margin-right: 0.313rem !important;
  }
  .me-lg-10 {
    margin-right: 0.625rem !important;
  }
  .me-lg-15 {
    margin-right: 0.938rem !important;
  }
  .me-lg-20 {
    margin-right: 1.25rem !important;
  }
  .me-lg-25 {
    margin-right: 1.563rem !important;
  }
  .me-lg-30 {
    margin-right: 1.875rem !important;
  }
  .me-lg-35 {
    margin-right: 2.188rem !important;
  }
  .me-lg-40 {
    margin-right: 2.5rem !important;
  }
  .me-lg-45 {
    margin-right: 2.813rem !important;
  }
  .me-lg-50 {
    margin-right: 3.125rem !important;
  }
  .me-lg-55 {
    margin-right: 3.438rem !important;
  }
  .me-lg-60 {
    margin-right: 3.75rem !important;
  }
  .me-lg-65 {
    margin-right: 4.063rem !important;
  }
  .me-lg-70 {
    margin-right: 4.375rem !important;
  }
  .me-lg-75 {
    margin-right: 4.688rem !important;
  }
  .me-lg-80 {
    margin-right: 5rem !important;
  }
  .me-lg-85 {
    margin-right: 5.313rem !important;
  }
  .me-lg-90 {
    margin-right: 5.625rem !important;
  }
  .me-lg-95 {
    margin-right: 5.938rem !important;
  }
  .me-lg-100 {
    margin-right: 6.25rem !important;
  }
  .me-lg-105 {
    margin-right: 6.5625rem !important;
  }
  .me-lg-110 {
    margin-right: 6.875rem !important;
  }
  .me-lg-115 {
    margin-right: 7.1875rem !important;
  }
  .me-lg-120 {
    margin-right: 7.5rem !important;
  }
  .me-lg-125 {
    margin-right: 7.8125rem !important;
  }
  .me-lg-130 {
    margin-right: 8.125rem !important;
  }
  .me-lg-135 {
    margin-right: 8.4375rem !important;
  }
  .me-lg-140 {
    margin-right: 8.75rem !important;
  }
  .me-lg-145 {
    margin-right: 9.0625rem !important;
  }
  .me-lg-150 {
    margin-right: 9.375rem !important;
  }
  .me-lg-155 {
    margin-right: 9.6875rem !important;
  }
  .me-lg-160 {
    margin-right: 10rem !important;
  }
  .me-lg-165 {
    margin-right: 10.3125rem !important;
  }
  .me-lg-170 {
    margin-right: 10.625rem !important;
  }
  .me-lg-175 {
    margin-right: 10.9375rem !important;
  }
  .me-lg-180 {
    margin-right: 11.25rem !important;
  }
  .me-lg-185 {
    margin-right: 11.5625rem !important;
  }
  .me-lg-190 {
    margin-right: 11.875rem !important;
  }
  .me-lg-195 {
    margin-right: 12.1875rem !important;
  }
  .me-lg-200 {
    margin-right: 12.5rem !important;
  }
  .me-lg-205 {
    margin-right: 12.8125rem !important;
  }
  .me-lg-210 {
    margin-right: 13.125rem !important;
  }
  .me-lg-215 {
    margin-right: 13.4375rem !important;
  }
  .me-lg-220 {
    margin-right: 13.75rem !important;
  }
  .me-lg-225 {
    margin-right: 14.0625rem !important;
  }
  .me-lg-230 {
    margin-right: 14.375rem !important;
  }
  .me-lg-235 {
    margin-right: 14.6875rem !important;
  }
  .me-lg-240 {
    margin-right: 15rem !important;
  }
  .me-lg-245 {
    margin-right: 15.3125rem !important;
  }
  .me-lg-250 {
    margin-right: 15.625rem !important;
  }
  .me-lg-255 {
    margin-right: 15.9375rem !important;
  }
  .me-lg-260 {
    margin-right: 16.25rem !important;
  }
  .me-lg-265 {
    margin-right: 16.5625rem !important;
  }
  .me-lg-270 {
    margin-right: 16.875rem !important;
  }
  .me-lg-275 {
    margin-right: 17.1875rem !important;
  }
  .me-lg-280 {
    margin-right: 17.5rem !important;
  }
  .me-lg-285 {
    margin-right: 17.8125rem !important;
  }
  .me-lg-290 {
    margin-right: 18.125rem !important;
  }
  .me-lg-295 {
    margin-right: 18.4375rem !important;
  }
  .me-lg-300 {
    margin-right: 18.75rem !important;
  }
  .me-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-0 {
    margin-bottom: 0 !important;
  }
  .mb-lg-5 {
    margin-bottom: 0.313rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 0.625rem !important;
  }
  .mb-lg-15 {
    margin-bottom: 0.938rem !important;
  }
  .mb-lg-20 {
    margin-bottom: 1.25rem !important;
  }
  .mb-lg-25 {
    margin-bottom: 1.563rem !important;
  }
  .mb-lg-30 {
    margin-bottom: 1.875rem !important;
  }
  .mb-lg-35 {
    margin-bottom: 2.188rem !important;
  }
  .mb-lg-40 {
    margin-bottom: 2.5rem !important;
  }
  .mb-lg-45 {
    margin-bottom: 2.813rem !important;
  }
  .mb-lg-50 {
    margin-bottom: 3.125rem !important;
  }
  .mb-lg-55 {
    margin-bottom: 3.438rem !important;
  }
  .mb-lg-60 {
    margin-bottom: 3.75rem !important;
  }
  .mb-lg-65 {
    margin-bottom: 4.063rem !important;
  }
  .mb-lg-70 {
    margin-bottom: 4.375rem !important;
  }
  .mb-lg-75 {
    margin-bottom: 4.688rem !important;
  }
  .mb-lg-80 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-85 {
    margin-bottom: 5.313rem !important;
  }
  .mb-lg-90 {
    margin-bottom: 5.625rem !important;
  }
  .mb-lg-95 {
    margin-bottom: 5.938rem !important;
  }
  .mb-lg-100 {
    margin-bottom: 6.25rem !important;
  }
  .mb-lg-105 {
    margin-bottom: 6.5625rem !important;
  }
  .mb-lg-110 {
    margin-bottom: 6.875rem !important;
  }
  .mb-lg-115 {
    margin-bottom: 7.1875rem !important;
  }
  .mb-lg-120 {
    margin-bottom: 7.5rem !important;
  }
  .mb-lg-125 {
    margin-bottom: 7.8125rem !important;
  }
  .mb-lg-130 {
    margin-bottom: 8.125rem !important;
  }
  .mb-lg-135 {
    margin-bottom: 8.4375rem !important;
  }
  .mb-lg-140 {
    margin-bottom: 8.75rem !important;
  }
  .mb-lg-145 {
    margin-bottom: 9.0625rem !important;
  }
  .mb-lg-150 {
    margin-bottom: 9.375rem !important;
  }
  .mb-lg-155 {
    margin-bottom: 9.6875rem !important;
  }
  .mb-lg-160 {
    margin-bottom: 10rem !important;
  }
  .mb-lg-165 {
    margin-bottom: 10.3125rem !important;
  }
  .mb-lg-170 {
    margin-bottom: 10.625rem !important;
  }
  .mb-lg-175 {
    margin-bottom: 10.9375rem !important;
  }
  .mb-lg-180 {
    margin-bottom: 11.25rem !important;
  }
  .mb-lg-185 {
    margin-bottom: 11.5625rem !important;
  }
  .mb-lg-190 {
    margin-bottom: 11.875rem !important;
  }
  .mb-lg-195 {
    margin-bottom: 12.1875rem !important;
  }
  .mb-lg-200 {
    margin-bottom: 12.5rem !important;
  }
  .mb-lg-205 {
    margin-bottom: 12.8125rem !important;
  }
  .mb-lg-210 {
    margin-bottom: 13.125rem !important;
  }
  .mb-lg-215 {
    margin-bottom: 13.4375rem !important;
  }
  .mb-lg-220 {
    margin-bottom: 13.75rem !important;
  }
  .mb-lg-225 {
    margin-bottom: 14.0625rem !important;
  }
  .mb-lg-230 {
    margin-bottom: 14.375rem !important;
  }
  .mb-lg-235 {
    margin-bottom: 14.6875rem !important;
  }
  .mb-lg-240 {
    margin-bottom: 15rem !important;
  }
  .mb-lg-245 {
    margin-bottom: 15.3125rem !important;
  }
  .mb-lg-250 {
    margin-bottom: 15.625rem !important;
  }
  .mb-lg-255 {
    margin-bottom: 15.9375rem !important;
  }
  .mb-lg-260 {
    margin-bottom: 16.25rem !important;
  }
  .mb-lg-265 {
    margin-bottom: 16.5625rem !important;
  }
  .mb-lg-270 {
    margin-bottom: 16.875rem !important;
  }
  .mb-lg-275 {
    margin-bottom: 17.1875rem !important;
  }
  .mb-lg-280 {
    margin-bottom: 17.5rem !important;
  }
  .mb-lg-285 {
    margin-bottom: 17.8125rem !important;
  }
  .mb-lg-290 {
    margin-bottom: 18.125rem !important;
  }
  .mb-lg-295 {
    margin-bottom: 18.4375rem !important;
  }
  .mb-lg-300 {
    margin-bottom: 18.75rem !important;
  }
  .mb-lg-auto {
    margin-bottom: auto !important;
  }
  .ms-lg-0 {
    margin-left: 0 !important;
  }
  .ms-lg-5 {
    margin-left: 0.313rem !important;
  }
  .ms-lg-10 {
    margin-left: 0.625rem !important;
  }
  .ms-lg-15 {
    margin-left: 0.938rem !important;
  }
  .ms-lg-20 {
    margin-left: 1.25rem !important;
  }
  .ms-lg-25 {
    margin-left: 1.563rem !important;
  }
  .ms-lg-30 {
    margin-left: 1.875rem !important;
  }
  .ms-lg-35 {
    margin-left: 2.188rem !important;
  }
  .ms-lg-40 {
    margin-left: 2.5rem !important;
  }
  .ms-lg-45 {
    margin-left: 2.813rem !important;
  }
  .ms-lg-50 {
    margin-left: 3.125rem !important;
  }
  .ms-lg-55 {
    margin-left: 3.438rem !important;
  }
  .ms-lg-60 {
    margin-left: 3.75rem !important;
  }
  .ms-lg-65 {
    margin-left: 4.063rem !important;
  }
  .ms-lg-70 {
    margin-left: 4.375rem !important;
  }
  .ms-lg-75 {
    margin-left: 4.688rem !important;
  }
  .ms-lg-80 {
    margin-left: 5rem !important;
  }
  .ms-lg-85 {
    margin-left: 5.313rem !important;
  }
  .ms-lg-90 {
    margin-left: 5.625rem !important;
  }
  .ms-lg-95 {
    margin-left: 5.938rem !important;
  }
  .ms-lg-100 {
    margin-left: 6.25rem !important;
  }
  .ms-lg-105 {
    margin-left: 6.5625rem !important;
  }
  .ms-lg-110 {
    margin-left: 6.875rem !important;
  }
  .ms-lg-115 {
    margin-left: 7.1875rem !important;
  }
  .ms-lg-120 {
    margin-left: 7.5rem !important;
  }
  .ms-lg-125 {
    margin-left: 7.8125rem !important;
  }
  .ms-lg-130 {
    margin-left: 8.125rem !important;
  }
  .ms-lg-135 {
    margin-left: 8.4375rem !important;
  }
  .ms-lg-140 {
    margin-left: 8.75rem !important;
  }
  .ms-lg-145 {
    margin-left: 9.0625rem !important;
  }
  .ms-lg-150 {
    margin-left: 9.375rem !important;
  }
  .ms-lg-155 {
    margin-left: 9.6875rem !important;
  }
  .ms-lg-160 {
    margin-left: 10rem !important;
  }
  .ms-lg-165 {
    margin-left: 10.3125rem !important;
  }
  .ms-lg-170 {
    margin-left: 10.625rem !important;
  }
  .ms-lg-175 {
    margin-left: 10.9375rem !important;
  }
  .ms-lg-180 {
    margin-left: 11.25rem !important;
  }
  .ms-lg-185 {
    margin-left: 11.5625rem !important;
  }
  .ms-lg-190 {
    margin-left: 11.875rem !important;
  }
  .ms-lg-195 {
    margin-left: 12.1875rem !important;
  }
  .ms-lg-200 {
    margin-left: 12.5rem !important;
  }
  .ms-lg-205 {
    margin-left: 12.8125rem !important;
  }
  .ms-lg-210 {
    margin-left: 13.125rem !important;
  }
  .ms-lg-215 {
    margin-left: 13.4375rem !important;
  }
  .ms-lg-220 {
    margin-left: 13.75rem !important;
  }
  .ms-lg-225 {
    margin-left: 14.0625rem !important;
  }
  .ms-lg-230 {
    margin-left: 14.375rem !important;
  }
  .ms-lg-235 {
    margin-left: 14.6875rem !important;
  }
  .ms-lg-240 {
    margin-left: 15rem !important;
  }
  .ms-lg-245 {
    margin-left: 15.3125rem !important;
  }
  .ms-lg-250 {
    margin-left: 15.625rem !important;
  }
  .ms-lg-255 {
    margin-left: 15.9375rem !important;
  }
  .ms-lg-260 {
    margin-left: 16.25rem !important;
  }
  .ms-lg-265 {
    margin-left: 16.5625rem !important;
  }
  .ms-lg-270 {
    margin-left: 16.875rem !important;
  }
  .ms-lg-275 {
    margin-left: 17.1875rem !important;
  }
  .ms-lg-280 {
    margin-left: 17.5rem !important;
  }
  .ms-lg-285 {
    margin-left: 17.8125rem !important;
  }
  .ms-lg-290 {
    margin-left: 18.125rem !important;
  }
  .ms-lg-295 {
    margin-left: 18.4375rem !important;
  }
  .ms-lg-300 {
    margin-left: 18.75rem !important;
  }
  .ms-lg-auto {
    margin-left: auto !important;
  }
  .m-lg-n5 {
    margin: -0.313rem !important;
  }
  .m-lg-n10 {
    margin: -0.625rem !important;
  }
  .m-lg-n15 {
    margin: -0.938rem !important;
  }
  .m-lg-n20 {
    margin: -1.25rem !important;
  }
  .m-lg-n25 {
    margin: -1.563rem !important;
  }
  .m-lg-n30 {
    margin: -1.875rem !important;
  }
  .m-lg-n35 {
    margin: -2.188rem !important;
  }
  .m-lg-n40 {
    margin: -2.5rem !important;
  }
  .m-lg-n45 {
    margin: -2.813rem !important;
  }
  .m-lg-n50 {
    margin: -3.125rem !important;
  }
  .m-lg-n55 {
    margin: -3.438rem !important;
  }
  .m-lg-n60 {
    margin: -3.75rem !important;
  }
  .m-lg-n65 {
    margin: -4.063rem !important;
  }
  .m-lg-n70 {
    margin: -4.375rem !important;
  }
  .m-lg-n75 {
    margin: -4.688rem !important;
  }
  .m-lg-n80 {
    margin: -5rem !important;
  }
  .m-lg-n85 {
    margin: -5.313rem !important;
  }
  .m-lg-n90 {
    margin: -5.625rem !important;
  }
  .m-lg-n95 {
    margin: -5.938rem !important;
  }
  .m-lg-n100 {
    margin: -6.25rem !important;
  }
  .m-lg-n105 {
    margin: -6.5625rem !important;
  }
  .m-lg-n110 {
    margin: -6.875rem !important;
  }
  .m-lg-n115 {
    margin: -7.1875rem !important;
  }
  .m-lg-n120 {
    margin: -7.5rem !important;
  }
  .m-lg-n125 {
    margin: -7.8125rem !important;
  }
  .m-lg-n130 {
    margin: -8.125rem !important;
  }
  .m-lg-n135 {
    margin: -8.4375rem !important;
  }
  .m-lg-n140 {
    margin: -8.75rem !important;
  }
  .m-lg-n145 {
    margin: -9.0625rem !important;
  }
  .m-lg-n150 {
    margin: -9.375rem !important;
  }
  .m-lg-n155 {
    margin: -9.6875rem !important;
  }
  .m-lg-n160 {
    margin: -10rem !important;
  }
  .m-lg-n165 {
    margin: -10.3125rem !important;
  }
  .m-lg-n170 {
    margin: -10.625rem !important;
  }
  .m-lg-n175 {
    margin: -10.9375rem !important;
  }
  .m-lg-n180 {
    margin: -11.25rem !important;
  }
  .m-lg-n185 {
    margin: -11.5625rem !important;
  }
  .m-lg-n190 {
    margin: -11.875rem !important;
  }
  .m-lg-n195 {
    margin: -12.1875rem !important;
  }
  .m-lg-n200 {
    margin: -12.5rem !important;
  }
  .m-lg-n205 {
    margin: -12.8125rem !important;
  }
  .m-lg-n210 {
    margin: -13.125rem !important;
  }
  .m-lg-n215 {
    margin: -13.4375rem !important;
  }
  .m-lg-n220 {
    margin: -13.75rem !important;
  }
  .m-lg-n225 {
    margin: -14.0625rem !important;
  }
  .m-lg-n230 {
    margin: -14.375rem !important;
  }
  .m-lg-n235 {
    margin: -14.6875rem !important;
  }
  .m-lg-n240 {
    margin: -15rem !important;
  }
  .m-lg-n245 {
    margin: -15.3125rem !important;
  }
  .m-lg-n250 {
    margin: -15.625rem !important;
  }
  .m-lg-n255 {
    margin: -15.9375rem !important;
  }
  .m-lg-n260 {
    margin: -16.25rem !important;
  }
  .m-lg-n265 {
    margin: -16.5625rem !important;
  }
  .m-lg-n270 {
    margin: -16.875rem !important;
  }
  .m-lg-n275 {
    margin: -17.1875rem !important;
  }
  .m-lg-n280 {
    margin: -17.5rem !important;
  }
  .m-lg-n285 {
    margin: -17.8125rem !important;
  }
  .m-lg-n290 {
    margin: -18.125rem !important;
  }
  .m-lg-n295 {
    margin: -18.4375rem !important;
  }
  .m-lg-n300 {
    margin: -18.75rem !important;
  }
  .mx-lg-n5 {
    margin-right: -0.313rem !important;
    margin-left: -0.313rem !important;
  }
  .mx-lg-n10 {
    margin-right: -0.625rem !important;
    margin-left: -0.625rem !important;
  }
  .mx-lg-n15 {
    margin-right: -0.938rem !important;
    margin-left: -0.938rem !important;
  }
  .mx-lg-n20 {
    margin-right: -1.25rem !important;
    margin-left: -1.25rem !important;
  }
  .mx-lg-n25 {
    margin-right: -1.563rem !important;
    margin-left: -1.563rem !important;
  }
  .mx-lg-n30 {
    margin-right: -1.875rem !important;
    margin-left: -1.875rem !important;
  }
  .mx-lg-n35 {
    margin-right: -2.188rem !important;
    margin-left: -2.188rem !important;
  }
  .mx-lg-n40 {
    margin-right: -2.5rem !important;
    margin-left: -2.5rem !important;
  }
  .mx-lg-n45 {
    margin-right: -2.813rem !important;
    margin-left: -2.813rem !important;
  }
  .mx-lg-n50 {
    margin-right: -3.125rem !important;
    margin-left: -3.125rem !important;
  }
  .mx-lg-n55 {
    margin-right: -3.438rem !important;
    margin-left: -3.438rem !important;
  }
  .mx-lg-n60 {
    margin-right: -3.75rem !important;
    margin-left: -3.75rem !important;
  }
  .mx-lg-n65 {
    margin-right: -4.063rem !important;
    margin-left: -4.063rem !important;
  }
  .mx-lg-n70 {
    margin-right: -4.375rem !important;
    margin-left: -4.375rem !important;
  }
  .mx-lg-n75 {
    margin-right: -4.688rem !important;
    margin-left: -4.688rem !important;
  }
  .mx-lg-n80 {
    margin-right: -5rem !important;
    margin-left: -5rem !important;
  }
  .mx-lg-n85 {
    margin-right: -5.313rem !important;
    margin-left: -5.313rem !important;
  }
  .mx-lg-n90 {
    margin-right: -5.625rem !important;
    margin-left: -5.625rem !important;
  }
  .mx-lg-n95 {
    margin-right: -5.938rem !important;
    margin-left: -5.938rem !important;
  }
  .mx-lg-n100 {
    margin-right: -6.25rem !important;
    margin-left: -6.25rem !important;
  }
  .mx-lg-n105 {
    margin-right: -6.5625rem !important;
    margin-left: -6.5625rem !important;
  }
  .mx-lg-n110 {
    margin-right: -6.875rem !important;
    margin-left: -6.875rem !important;
  }
  .mx-lg-n115 {
    margin-right: -7.1875rem !important;
    margin-left: -7.1875rem !important;
  }
  .mx-lg-n120 {
    margin-right: -7.5rem !important;
    margin-left: -7.5rem !important;
  }
  .mx-lg-n125 {
    margin-right: -7.8125rem !important;
    margin-left: -7.8125rem !important;
  }
  .mx-lg-n130 {
    margin-right: -8.125rem !important;
    margin-left: -8.125rem !important;
  }
  .mx-lg-n135 {
    margin-right: -8.4375rem !important;
    margin-left: -8.4375rem !important;
  }
  .mx-lg-n140 {
    margin-right: -8.75rem !important;
    margin-left: -8.75rem !important;
  }
  .mx-lg-n145 {
    margin-right: -9.0625rem !important;
    margin-left: -9.0625rem !important;
  }
  .mx-lg-n150 {
    margin-right: -9.375rem !important;
    margin-left: -9.375rem !important;
  }
  .mx-lg-n155 {
    margin-right: -9.6875rem !important;
    margin-left: -9.6875rem !important;
  }
  .mx-lg-n160 {
    margin-right: -10rem !important;
    margin-left: -10rem !important;
  }
  .mx-lg-n165 {
    margin-right: -10.3125rem !important;
    margin-left: -10.3125rem !important;
  }
  .mx-lg-n170 {
    margin-right: -10.625rem !important;
    margin-left: -10.625rem !important;
  }
  .mx-lg-n175 {
    margin-right: -10.9375rem !important;
    margin-left: -10.9375rem !important;
  }
  .mx-lg-n180 {
    margin-right: -11.25rem !important;
    margin-left: -11.25rem !important;
  }
  .mx-lg-n185 {
    margin-right: -11.5625rem !important;
    margin-left: -11.5625rem !important;
  }
  .mx-lg-n190 {
    margin-right: -11.875rem !important;
    margin-left: -11.875rem !important;
  }
  .mx-lg-n195 {
    margin-right: -12.1875rem !important;
    margin-left: -12.1875rem !important;
  }
  .mx-lg-n200 {
    margin-right: -12.5rem !important;
    margin-left: -12.5rem !important;
  }
  .mx-lg-n205 {
    margin-right: -12.8125rem !important;
    margin-left: -12.8125rem !important;
  }
  .mx-lg-n210 {
    margin-right: -13.125rem !important;
    margin-left: -13.125rem !important;
  }
  .mx-lg-n215 {
    margin-right: -13.4375rem !important;
    margin-left: -13.4375rem !important;
  }
  .mx-lg-n220 {
    margin-right: -13.75rem !important;
    margin-left: -13.75rem !important;
  }
  .mx-lg-n225 {
    margin-right: -14.0625rem !important;
    margin-left: -14.0625rem !important;
  }
  .mx-lg-n230 {
    margin-right: -14.375rem !important;
    margin-left: -14.375rem !important;
  }
  .mx-lg-n235 {
    margin-right: -14.6875rem !important;
    margin-left: -14.6875rem !important;
  }
  .mx-lg-n240 {
    margin-right: -15rem !important;
    margin-left: -15rem !important;
  }
  .mx-lg-n245 {
    margin-right: -15.3125rem !important;
    margin-left: -15.3125rem !important;
  }
  .mx-lg-n250 {
    margin-right: -15.625rem !important;
    margin-left: -15.625rem !important;
  }
  .mx-lg-n255 {
    margin-right: -15.9375rem !important;
    margin-left: -15.9375rem !important;
  }
  .mx-lg-n260 {
    margin-right: -16.25rem !important;
    margin-left: -16.25rem !important;
  }
  .mx-lg-n265 {
    margin-right: -16.5625rem !important;
    margin-left: -16.5625rem !important;
  }
  .mx-lg-n270 {
    margin-right: -16.875rem !important;
    margin-left: -16.875rem !important;
  }
  .mx-lg-n275 {
    margin-right: -17.1875rem !important;
    margin-left: -17.1875rem !important;
  }
  .mx-lg-n280 {
    margin-right: -17.5rem !important;
    margin-left: -17.5rem !important;
  }
  .mx-lg-n285 {
    margin-right: -17.8125rem !important;
    margin-left: -17.8125rem !important;
  }
  .mx-lg-n290 {
    margin-right: -18.125rem !important;
    margin-left: -18.125rem !important;
  }
  .mx-lg-n295 {
    margin-right: -18.4375rem !important;
    margin-left: -18.4375rem !important;
  }
  .mx-lg-n300 {
    margin-right: -18.75rem !important;
    margin-left: -18.75rem !important;
  }
  .my-lg-n5 {
    margin-top: -0.313rem !important;
    margin-bottom: -0.313rem !important;
  }
  .my-lg-n10 {
    margin-top: -0.625rem !important;
    margin-bottom: -0.625rem !important;
  }
  .my-lg-n15 {
    margin-top: -0.938rem !important;
    margin-bottom: -0.938rem !important;
  }
  .my-lg-n20 {
    margin-top: -1.25rem !important;
    margin-bottom: -1.25rem !important;
  }
  .my-lg-n25 {
    margin-top: -1.563rem !important;
    margin-bottom: -1.563rem !important;
  }
  .my-lg-n30 {
    margin-top: -1.875rem !important;
    margin-bottom: -1.875rem !important;
  }
  .my-lg-n35 {
    margin-top: -2.188rem !important;
    margin-bottom: -2.188rem !important;
  }
  .my-lg-n40 {
    margin-top: -2.5rem !important;
    margin-bottom: -2.5rem !important;
  }
  .my-lg-n45 {
    margin-top: -2.813rem !important;
    margin-bottom: -2.813rem !important;
  }
  .my-lg-n50 {
    margin-top: -3.125rem !important;
    margin-bottom: -3.125rem !important;
  }
  .my-lg-n55 {
    margin-top: -3.438rem !important;
    margin-bottom: -3.438rem !important;
  }
  .my-lg-n60 {
    margin-top: -3.75rem !important;
    margin-bottom: -3.75rem !important;
  }
  .my-lg-n65 {
    margin-top: -4.063rem !important;
    margin-bottom: -4.063rem !important;
  }
  .my-lg-n70 {
    margin-top: -4.375rem !important;
    margin-bottom: -4.375rem !important;
  }
  .my-lg-n75 {
    margin-top: -4.688rem !important;
    margin-bottom: -4.688rem !important;
  }
  .my-lg-n80 {
    margin-top: -5rem !important;
    margin-bottom: -5rem !important;
  }
  .my-lg-n85 {
    margin-top: -5.313rem !important;
    margin-bottom: -5.313rem !important;
  }
  .my-lg-n90 {
    margin-top: -5.625rem !important;
    margin-bottom: -5.625rem !important;
  }
  .my-lg-n95 {
    margin-top: -5.938rem !important;
    margin-bottom: -5.938rem !important;
  }
  .my-lg-n100 {
    margin-top: -6.25rem !important;
    margin-bottom: -6.25rem !important;
  }
  .my-lg-n105 {
    margin-top: -6.5625rem !important;
    margin-bottom: -6.5625rem !important;
  }
  .my-lg-n110 {
    margin-top: -6.875rem !important;
    margin-bottom: -6.875rem !important;
  }
  .my-lg-n115 {
    margin-top: -7.1875rem !important;
    margin-bottom: -7.1875rem !important;
  }
  .my-lg-n120 {
    margin-top: -7.5rem !important;
    margin-bottom: -7.5rem !important;
  }
  .my-lg-n125 {
    margin-top: -7.8125rem !important;
    margin-bottom: -7.8125rem !important;
  }
  .my-lg-n130 {
    margin-top: -8.125rem !important;
    margin-bottom: -8.125rem !important;
  }
  .my-lg-n135 {
    margin-top: -8.4375rem !important;
    margin-bottom: -8.4375rem !important;
  }
  .my-lg-n140 {
    margin-top: -8.75rem !important;
    margin-bottom: -8.75rem !important;
  }
  .my-lg-n145 {
    margin-top: -9.0625rem !important;
    margin-bottom: -9.0625rem !important;
  }
  .my-lg-n150 {
    margin-top: -9.375rem !important;
    margin-bottom: -9.375rem !important;
  }
  .my-lg-n155 {
    margin-top: -9.6875rem !important;
    margin-bottom: -9.6875rem !important;
  }
  .my-lg-n160 {
    margin-top: -10rem !important;
    margin-bottom: -10rem !important;
  }
  .my-lg-n165 {
    margin-top: -10.3125rem !important;
    margin-bottom: -10.3125rem !important;
  }
  .my-lg-n170 {
    margin-top: -10.625rem !important;
    margin-bottom: -10.625rem !important;
  }
  .my-lg-n175 {
    margin-top: -10.9375rem !important;
    margin-bottom: -10.9375rem !important;
  }
  .my-lg-n180 {
    margin-top: -11.25rem !important;
    margin-bottom: -11.25rem !important;
  }
  .my-lg-n185 {
    margin-top: -11.5625rem !important;
    margin-bottom: -11.5625rem !important;
  }
  .my-lg-n190 {
    margin-top: -11.875rem !important;
    margin-bottom: -11.875rem !important;
  }
  .my-lg-n195 {
    margin-top: -12.1875rem !important;
    margin-bottom: -12.1875rem !important;
  }
  .my-lg-n200 {
    margin-top: -12.5rem !important;
    margin-bottom: -12.5rem !important;
  }
  .my-lg-n205 {
    margin-top: -12.8125rem !important;
    margin-bottom: -12.8125rem !important;
  }
  .my-lg-n210 {
    margin-top: -13.125rem !important;
    margin-bottom: -13.125rem !important;
  }
  .my-lg-n215 {
    margin-top: -13.4375rem !important;
    margin-bottom: -13.4375rem !important;
  }
  .my-lg-n220 {
    margin-top: -13.75rem !important;
    margin-bottom: -13.75rem !important;
  }
  .my-lg-n225 {
    margin-top: -14.0625rem !important;
    margin-bottom: -14.0625rem !important;
  }
  .my-lg-n230 {
    margin-top: -14.375rem !important;
    margin-bottom: -14.375rem !important;
  }
  .my-lg-n235 {
    margin-top: -14.6875rem !important;
    margin-bottom: -14.6875rem !important;
  }
  .my-lg-n240 {
    margin-top: -15rem !important;
    margin-bottom: -15rem !important;
  }
  .my-lg-n245 {
    margin-top: -15.3125rem !important;
    margin-bottom: -15.3125rem !important;
  }
  .my-lg-n250 {
    margin-top: -15.625rem !important;
    margin-bottom: -15.625rem !important;
  }
  .my-lg-n255 {
    margin-top: -15.9375rem !important;
    margin-bottom: -15.9375rem !important;
  }
  .my-lg-n260 {
    margin-top: -16.25rem !important;
    margin-bottom: -16.25rem !important;
  }
  .my-lg-n265 {
    margin-top: -16.5625rem !important;
    margin-bottom: -16.5625rem !important;
  }
  .my-lg-n270 {
    margin-top: -16.875rem !important;
    margin-bottom: -16.875rem !important;
  }
  .my-lg-n275 {
    margin-top: -17.1875rem !important;
    margin-bottom: -17.1875rem !important;
  }
  .my-lg-n280 {
    margin-top: -17.5rem !important;
    margin-bottom: -17.5rem !important;
  }
  .my-lg-n285 {
    margin-top: -17.8125rem !important;
    margin-bottom: -17.8125rem !important;
  }
  .my-lg-n290 {
    margin-top: -18.125rem !important;
    margin-bottom: -18.125rem !important;
  }
  .my-lg-n295 {
    margin-top: -18.4375rem !important;
    margin-bottom: -18.4375rem !important;
  }
  .my-lg-n300 {
    margin-top: -18.75rem !important;
    margin-bottom: -18.75rem !important;
  }
  .mt-lg-n5 {
    margin-top: -0.313rem !important;
  }
  .mt-lg-n10 {
    margin-top: -0.625rem !important;
  }
  .mt-lg-n15 {
    margin-top: -0.938rem !important;
  }
  .mt-lg-n20 {
    margin-top: -1.25rem !important;
  }
  .mt-lg-n25 {
    margin-top: -1.563rem !important;
  }
  .mt-lg-n30 {
    margin-top: -1.875rem !important;
  }
  .mt-lg-n35 {
    margin-top: -2.188rem !important;
  }
  .mt-lg-n40 {
    margin-top: -2.5rem !important;
  }
  .mt-lg-n45 {
    margin-top: -2.813rem !important;
  }
  .mt-lg-n50 {
    margin-top: -3.125rem !important;
  }
  .mt-lg-n55 {
    margin-top: -3.438rem !important;
  }
  .mt-lg-n60 {
    margin-top: -3.75rem !important;
  }
  .mt-lg-n65 {
    margin-top: -4.063rem !important;
  }
  .mt-lg-n70 {
    margin-top: -4.375rem !important;
  }
  .mt-lg-n75 {
    margin-top: -4.688rem !important;
  }
  .mt-lg-n80 {
    margin-top: -5rem !important;
  }
  .mt-lg-n85 {
    margin-top: -5.313rem !important;
  }
  .mt-lg-n90 {
    margin-top: -5.625rem !important;
  }
  .mt-lg-n95 {
    margin-top: -5.938rem !important;
  }
  .mt-lg-n100 {
    margin-top: -6.25rem !important;
  }
  .mt-lg-n105 {
    margin-top: -6.5625rem !important;
  }
  .mt-lg-n110 {
    margin-top: -6.875rem !important;
  }
  .mt-lg-n115 {
    margin-top: -7.1875rem !important;
  }
  .mt-lg-n120 {
    margin-top: -7.5rem !important;
  }
  .mt-lg-n125 {
    margin-top: -7.8125rem !important;
  }
  .mt-lg-n130 {
    margin-top: -8.125rem !important;
  }
  .mt-lg-n135 {
    margin-top: -8.4375rem !important;
  }
  .mt-lg-n140 {
    margin-top: -8.75rem !important;
  }
  .mt-lg-n145 {
    margin-top: -9.0625rem !important;
  }
  .mt-lg-n150 {
    margin-top: -9.375rem !important;
  }
  .mt-lg-n155 {
    margin-top: -9.6875rem !important;
  }
  .mt-lg-n160 {
    margin-top: -10rem !important;
  }
  .mt-lg-n165 {
    margin-top: -10.3125rem !important;
  }
  .mt-lg-n170 {
    margin-top: -10.625rem !important;
  }
  .mt-lg-n175 {
    margin-top: -10.9375rem !important;
  }
  .mt-lg-n180 {
    margin-top: -11.25rem !important;
  }
  .mt-lg-n185 {
    margin-top: -11.5625rem !important;
  }
  .mt-lg-n190 {
    margin-top: -11.875rem !important;
  }
  .mt-lg-n195 {
    margin-top: -12.1875rem !important;
  }
  .mt-lg-n200 {
    margin-top: -12.5rem !important;
  }
  .mt-lg-n205 {
    margin-top: -12.8125rem !important;
  }
  .mt-lg-n210 {
    margin-top: -13.125rem !important;
  }
  .mt-lg-n215 {
    margin-top: -13.4375rem !important;
  }
  .mt-lg-n220 {
    margin-top: -13.75rem !important;
  }
  .mt-lg-n225 {
    margin-top: -14.0625rem !important;
  }
  .mt-lg-n230 {
    margin-top: -14.375rem !important;
  }
  .mt-lg-n235 {
    margin-top: -14.6875rem !important;
  }
  .mt-lg-n240 {
    margin-top: -15rem !important;
  }
  .mt-lg-n245 {
    margin-top: -15.3125rem !important;
  }
  .mt-lg-n250 {
    margin-top: -15.625rem !important;
  }
  .mt-lg-n255 {
    margin-top: -15.9375rem !important;
  }
  .mt-lg-n260 {
    margin-top: -16.25rem !important;
  }
  .mt-lg-n265 {
    margin-top: -16.5625rem !important;
  }
  .mt-lg-n270 {
    margin-top: -16.875rem !important;
  }
  .mt-lg-n275 {
    margin-top: -17.1875rem !important;
  }
  .mt-lg-n280 {
    margin-top: -17.5rem !important;
  }
  .mt-lg-n285 {
    margin-top: -17.8125rem !important;
  }
  .mt-lg-n290 {
    margin-top: -18.125rem !important;
  }
  .mt-lg-n295 {
    margin-top: -18.4375rem !important;
  }
  .mt-lg-n300 {
    margin-top: -18.75rem !important;
  }
  .me-lg-n5 {
    margin-right: -0.313rem !important;
  }
  .me-lg-n10 {
    margin-right: -0.625rem !important;
  }
  .me-lg-n15 {
    margin-right: -0.938rem !important;
  }
  .me-lg-n20 {
    margin-right: -1.25rem !important;
  }
  .me-lg-n25 {
    margin-right: -1.563rem !important;
  }
  .me-lg-n30 {
    margin-right: -1.875rem !important;
  }
  .me-lg-n35 {
    margin-right: -2.188rem !important;
  }
  .me-lg-n40 {
    margin-right: -2.5rem !important;
  }
  .me-lg-n45 {
    margin-right: -2.813rem !important;
  }
  .me-lg-n50 {
    margin-right: -3.125rem !important;
  }
  .me-lg-n55 {
    margin-right: -3.438rem !important;
  }
  .me-lg-n60 {
    margin-right: -3.75rem !important;
  }
  .me-lg-n65 {
    margin-right: -4.063rem !important;
  }
  .me-lg-n70 {
    margin-right: -4.375rem !important;
  }
  .me-lg-n75 {
    margin-right: -4.688rem !important;
  }
  .me-lg-n80 {
    margin-right: -5rem !important;
  }
  .me-lg-n85 {
    margin-right: -5.313rem !important;
  }
  .me-lg-n90 {
    margin-right: -5.625rem !important;
  }
  .me-lg-n95 {
    margin-right: -5.938rem !important;
  }
  .me-lg-n100 {
    margin-right: -6.25rem !important;
  }
  .me-lg-n105 {
    margin-right: -6.5625rem !important;
  }
  .me-lg-n110 {
    margin-right: -6.875rem !important;
  }
  .me-lg-n115 {
    margin-right: -7.1875rem !important;
  }
  .me-lg-n120 {
    margin-right: -7.5rem !important;
  }
  .me-lg-n125 {
    margin-right: -7.8125rem !important;
  }
  .me-lg-n130 {
    margin-right: -8.125rem !important;
  }
  .me-lg-n135 {
    margin-right: -8.4375rem !important;
  }
  .me-lg-n140 {
    margin-right: -8.75rem !important;
  }
  .me-lg-n145 {
    margin-right: -9.0625rem !important;
  }
  .me-lg-n150 {
    margin-right: -9.375rem !important;
  }
  .me-lg-n155 {
    margin-right: -9.6875rem !important;
  }
  .me-lg-n160 {
    margin-right: -10rem !important;
  }
  .me-lg-n165 {
    margin-right: -10.3125rem !important;
  }
  .me-lg-n170 {
    margin-right: -10.625rem !important;
  }
  .me-lg-n175 {
    margin-right: -10.9375rem !important;
  }
  .me-lg-n180 {
    margin-right: -11.25rem !important;
  }
  .me-lg-n185 {
    margin-right: -11.5625rem !important;
  }
  .me-lg-n190 {
    margin-right: -11.875rem !important;
  }
  .me-lg-n195 {
    margin-right: -12.1875rem !important;
  }
  .me-lg-n200 {
    margin-right: -12.5rem !important;
  }
  .me-lg-n205 {
    margin-right: -12.8125rem !important;
  }
  .me-lg-n210 {
    margin-right: -13.125rem !important;
  }
  .me-lg-n215 {
    margin-right: -13.4375rem !important;
  }
  .me-lg-n220 {
    margin-right: -13.75rem !important;
  }
  .me-lg-n225 {
    margin-right: -14.0625rem !important;
  }
  .me-lg-n230 {
    margin-right: -14.375rem !important;
  }
  .me-lg-n235 {
    margin-right: -14.6875rem !important;
  }
  .me-lg-n240 {
    margin-right: -15rem !important;
  }
  .me-lg-n245 {
    margin-right: -15.3125rem !important;
  }
  .me-lg-n250 {
    margin-right: -15.625rem !important;
  }
  .me-lg-n255 {
    margin-right: -15.9375rem !important;
  }
  .me-lg-n260 {
    margin-right: -16.25rem !important;
  }
  .me-lg-n265 {
    margin-right: -16.5625rem !important;
  }
  .me-lg-n270 {
    margin-right: -16.875rem !important;
  }
  .me-lg-n275 {
    margin-right: -17.1875rem !important;
  }
  .me-lg-n280 {
    margin-right: -17.5rem !important;
  }
  .me-lg-n285 {
    margin-right: -17.8125rem !important;
  }
  .me-lg-n290 {
    margin-right: -18.125rem !important;
  }
  .me-lg-n295 {
    margin-right: -18.4375rem !important;
  }
  .me-lg-n300 {
    margin-right: -18.75rem !important;
  }
  .mb-lg-n5 {
    margin-bottom: -0.313rem !important;
  }
  .mb-lg-n10 {
    margin-bottom: -0.625rem !important;
  }
  .mb-lg-n15 {
    margin-bottom: -0.938rem !important;
  }
  .mb-lg-n20 {
    margin-bottom: -1.25rem !important;
  }
  .mb-lg-n25 {
    margin-bottom: -1.563rem !important;
  }
  .mb-lg-n30 {
    margin-bottom: -1.875rem !important;
  }
  .mb-lg-n35 {
    margin-bottom: -2.188rem !important;
  }
  .mb-lg-n40 {
    margin-bottom: -2.5rem !important;
  }
  .mb-lg-n45 {
    margin-bottom: -2.813rem !important;
  }
  .mb-lg-n50 {
    margin-bottom: -3.125rem !important;
  }
  .mb-lg-n55 {
    margin-bottom: -3.438rem !important;
  }
  .mb-lg-n60 {
    margin-bottom: -3.75rem !important;
  }
  .mb-lg-n65 {
    margin-bottom: -4.063rem !important;
  }
  .mb-lg-n70 {
    margin-bottom: -4.375rem !important;
  }
  .mb-lg-n75 {
    margin-bottom: -4.688rem !important;
  }
  .mb-lg-n80 {
    margin-bottom: -5rem !important;
  }
  .mb-lg-n85 {
    margin-bottom: -5.313rem !important;
  }
  .mb-lg-n90 {
    margin-bottom: -5.625rem !important;
  }
  .mb-lg-n95 {
    margin-bottom: -5.938rem !important;
  }
  .mb-lg-n100 {
    margin-bottom: -6.25rem !important;
  }
  .mb-lg-n105 {
    margin-bottom: -6.5625rem !important;
  }
  .mb-lg-n110 {
    margin-bottom: -6.875rem !important;
  }
  .mb-lg-n115 {
    margin-bottom: -7.1875rem !important;
  }
  .mb-lg-n120 {
    margin-bottom: -7.5rem !important;
  }
  .mb-lg-n125 {
    margin-bottom: -7.8125rem !important;
  }
  .mb-lg-n130 {
    margin-bottom: -8.125rem !important;
  }
  .mb-lg-n135 {
    margin-bottom: -8.4375rem !important;
  }
  .mb-lg-n140 {
    margin-bottom: -8.75rem !important;
  }
  .mb-lg-n145 {
    margin-bottom: -9.0625rem !important;
  }
  .mb-lg-n150 {
    margin-bottom: -9.375rem !important;
  }
  .mb-lg-n155 {
    margin-bottom: -9.6875rem !important;
  }
  .mb-lg-n160 {
    margin-bottom: -10rem !important;
  }
  .mb-lg-n165 {
    margin-bottom: -10.3125rem !important;
  }
  .mb-lg-n170 {
    margin-bottom: -10.625rem !important;
  }
  .mb-lg-n175 {
    margin-bottom: -10.9375rem !important;
  }
  .mb-lg-n180 {
    margin-bottom: -11.25rem !important;
  }
  .mb-lg-n185 {
    margin-bottom: -11.5625rem !important;
  }
  .mb-lg-n190 {
    margin-bottom: -11.875rem !important;
  }
  .mb-lg-n195 {
    margin-bottom: -12.1875rem !important;
  }
  .mb-lg-n200 {
    margin-bottom: -12.5rem !important;
  }
  .mb-lg-n205 {
    margin-bottom: -12.8125rem !important;
  }
  .mb-lg-n210 {
    margin-bottom: -13.125rem !important;
  }
  .mb-lg-n215 {
    margin-bottom: -13.4375rem !important;
  }
  .mb-lg-n220 {
    margin-bottom: -13.75rem !important;
  }
  .mb-lg-n225 {
    margin-bottom: -14.0625rem !important;
  }
  .mb-lg-n230 {
    margin-bottom: -14.375rem !important;
  }
  .mb-lg-n235 {
    margin-bottom: -14.6875rem !important;
  }
  .mb-lg-n240 {
    margin-bottom: -15rem !important;
  }
  .mb-lg-n245 {
    margin-bottom: -15.3125rem !important;
  }
  .mb-lg-n250 {
    margin-bottom: -15.625rem !important;
  }
  .mb-lg-n255 {
    margin-bottom: -15.9375rem !important;
  }
  .mb-lg-n260 {
    margin-bottom: -16.25rem !important;
  }
  .mb-lg-n265 {
    margin-bottom: -16.5625rem !important;
  }
  .mb-lg-n270 {
    margin-bottom: -16.875rem !important;
  }
  .mb-lg-n275 {
    margin-bottom: -17.1875rem !important;
  }
  .mb-lg-n280 {
    margin-bottom: -17.5rem !important;
  }
  .mb-lg-n285 {
    margin-bottom: -17.8125rem !important;
  }
  .mb-lg-n290 {
    margin-bottom: -18.125rem !important;
  }
  .mb-lg-n295 {
    margin-bottom: -18.4375rem !important;
  }
  .mb-lg-n300 {
    margin-bottom: -18.75rem !important;
  }
  .ms-lg-n5 {
    margin-left: -0.313rem !important;
  }
  .ms-lg-n10 {
    margin-left: -0.625rem !important;
  }
  .ms-lg-n15 {
    margin-left: -0.938rem !important;
  }
  .ms-lg-n20 {
    margin-left: -1.25rem !important;
  }
  .ms-lg-n25 {
    margin-left: -1.563rem !important;
  }
  .ms-lg-n30 {
    margin-left: -1.875rem !important;
  }
  .ms-lg-n35 {
    margin-left: -2.188rem !important;
  }
  .ms-lg-n40 {
    margin-left: -2.5rem !important;
  }
  .ms-lg-n45 {
    margin-left: -2.813rem !important;
  }
  .ms-lg-n50 {
    margin-left: -3.125rem !important;
  }
  .ms-lg-n55 {
    margin-left: -3.438rem !important;
  }
  .ms-lg-n60 {
    margin-left: -3.75rem !important;
  }
  .ms-lg-n65 {
    margin-left: -4.063rem !important;
  }
  .ms-lg-n70 {
    margin-left: -4.375rem !important;
  }
  .ms-lg-n75 {
    margin-left: -4.688rem !important;
  }
  .ms-lg-n80 {
    margin-left: -5rem !important;
  }
  .ms-lg-n85 {
    margin-left: -5.313rem !important;
  }
  .ms-lg-n90 {
    margin-left: -5.625rem !important;
  }
  .ms-lg-n95 {
    margin-left: -5.938rem !important;
  }
  .ms-lg-n100 {
    margin-left: -6.25rem !important;
  }
  .ms-lg-n105 {
    margin-left: -6.5625rem !important;
  }
  .ms-lg-n110 {
    margin-left: -6.875rem !important;
  }
  .ms-lg-n115 {
    margin-left: -7.1875rem !important;
  }
  .ms-lg-n120 {
    margin-left: -7.5rem !important;
  }
  .ms-lg-n125 {
    margin-left: -7.8125rem !important;
  }
  .ms-lg-n130 {
    margin-left: -8.125rem !important;
  }
  .ms-lg-n135 {
    margin-left: -8.4375rem !important;
  }
  .ms-lg-n140 {
    margin-left: -8.75rem !important;
  }
  .ms-lg-n145 {
    margin-left: -9.0625rem !important;
  }
  .ms-lg-n150 {
    margin-left: -9.375rem !important;
  }
  .ms-lg-n155 {
    margin-left: -9.6875rem !important;
  }
  .ms-lg-n160 {
    margin-left: -10rem !important;
  }
  .ms-lg-n165 {
    margin-left: -10.3125rem !important;
  }
  .ms-lg-n170 {
    margin-left: -10.625rem !important;
  }
  .ms-lg-n175 {
    margin-left: -10.9375rem !important;
  }
  .ms-lg-n180 {
    margin-left: -11.25rem !important;
  }
  .ms-lg-n185 {
    margin-left: -11.5625rem !important;
  }
  .ms-lg-n190 {
    margin-left: -11.875rem !important;
  }
  .ms-lg-n195 {
    margin-left: -12.1875rem !important;
  }
  .ms-lg-n200 {
    margin-left: -12.5rem !important;
  }
  .ms-lg-n205 {
    margin-left: -12.8125rem !important;
  }
  .ms-lg-n210 {
    margin-left: -13.125rem !important;
  }
  .ms-lg-n215 {
    margin-left: -13.4375rem !important;
  }
  .ms-lg-n220 {
    margin-left: -13.75rem !important;
  }
  .ms-lg-n225 {
    margin-left: -14.0625rem !important;
  }
  .ms-lg-n230 {
    margin-left: -14.375rem !important;
  }
  .ms-lg-n235 {
    margin-left: -14.6875rem !important;
  }
  .ms-lg-n240 {
    margin-left: -15rem !important;
  }
  .ms-lg-n245 {
    margin-left: -15.3125rem !important;
  }
  .ms-lg-n250 {
    margin-left: -15.625rem !important;
  }
  .ms-lg-n255 {
    margin-left: -15.9375rem !important;
  }
  .ms-lg-n260 {
    margin-left: -16.25rem !important;
  }
  .ms-lg-n265 {
    margin-left: -16.5625rem !important;
  }
  .ms-lg-n270 {
    margin-left: -16.875rem !important;
  }
  .ms-lg-n275 {
    margin-left: -17.1875rem !important;
  }
  .ms-lg-n280 {
    margin-left: -17.5rem !important;
  }
  .ms-lg-n285 {
    margin-left: -17.8125rem !important;
  }
  .ms-lg-n290 {
    margin-left: -18.125rem !important;
  }
  .ms-lg-n295 {
    margin-left: -18.4375rem !important;
  }
  .ms-lg-n300 {
    margin-left: -18.75rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .p-lg-5 {
    padding: 0.313rem !important;
  }
  .p-lg-10 {
    padding: 0.625rem !important;
  }
  .p-lg-15 {
    padding: 0.938rem !important;
  }
  .p-lg-20 {
    padding: 1.25rem !important;
  }
  .p-lg-25 {
    padding: 1.563rem !important;
  }
  .p-lg-30 {
    padding: 1.875rem !important;
  }
  .p-lg-35 {
    padding: 2.188rem !important;
  }
  .p-lg-40 {
    padding: 2.5rem !important;
  }
  .p-lg-45 {
    padding: 2.813rem !important;
  }
  .p-lg-50 {
    padding: 3.125rem !important;
  }
  .p-lg-55 {
    padding: 3.438rem !important;
  }
  .p-lg-60 {
    padding: 3.75rem !important;
  }
  .p-lg-65 {
    padding: 4.063rem !important;
  }
  .p-lg-70 {
    padding: 4.375rem !important;
  }
  .p-lg-75 {
    padding: 4.688rem !important;
  }
  .p-lg-80 {
    padding: 5rem !important;
  }
  .p-lg-85 {
    padding: 5.313rem !important;
  }
  .p-lg-90 {
    padding: 5.625rem !important;
  }
  .p-lg-95 {
    padding: 5.938rem !important;
  }
  .p-lg-100 {
    padding: 6.25rem !important;
  }
  .p-lg-105 {
    padding: 6.5625rem !important;
  }
  .p-lg-110 {
    padding: 6.875rem !important;
  }
  .p-lg-115 {
    padding: 7.1875rem !important;
  }
  .p-lg-120 {
    padding: 7.5rem !important;
  }
  .p-lg-125 {
    padding: 7.8125rem !important;
  }
  .p-lg-130 {
    padding: 8.125rem !important;
  }
  .p-lg-135 {
    padding: 8.4375rem !important;
  }
  .p-lg-140 {
    padding: 8.75rem !important;
  }
  .p-lg-145 {
    padding: 9.0625rem !important;
  }
  .p-lg-150 {
    padding: 9.375rem !important;
  }
  .p-lg-155 {
    padding: 9.6875rem !important;
  }
  .p-lg-160 {
    padding: 10rem !important;
  }
  .p-lg-165 {
    padding: 10.3125rem !important;
  }
  .p-lg-170 {
    padding: 10.625rem !important;
  }
  .p-lg-175 {
    padding: 10.9375rem !important;
  }
  .p-lg-180 {
    padding: 11.25rem !important;
  }
  .p-lg-185 {
    padding: 11.5625rem !important;
  }
  .p-lg-190 {
    padding: 11.875rem !important;
  }
  .p-lg-195 {
    padding: 12.1875rem !important;
  }
  .p-lg-200 {
    padding: 12.5rem !important;
  }
  .p-lg-205 {
    padding: 12.8125rem !important;
  }
  .p-lg-210 {
    padding: 13.125rem !important;
  }
  .p-lg-215 {
    padding: 13.4375rem !important;
  }
  .p-lg-220 {
    padding: 13.75rem !important;
  }
  .p-lg-225 {
    padding: 14.0625rem !important;
  }
  .p-lg-230 {
    padding: 14.375rem !important;
  }
  .p-lg-235 {
    padding: 14.6875rem !important;
  }
  .p-lg-240 {
    padding: 15rem !important;
  }
  .p-lg-245 {
    padding: 15.3125rem !important;
  }
  .p-lg-250 {
    padding: 15.625rem !important;
  }
  .p-lg-255 {
    padding: 15.9375rem !important;
  }
  .p-lg-260 {
    padding: 16.25rem !important;
  }
  .p-lg-265 {
    padding: 16.5625rem !important;
  }
  .p-lg-270 {
    padding: 16.875rem !important;
  }
  .p-lg-275 {
    padding: 17.1875rem !important;
  }
  .p-lg-280 {
    padding: 17.5rem !important;
  }
  .p-lg-285 {
    padding: 17.8125rem !important;
  }
  .p-lg-290 {
    padding: 18.125rem !important;
  }
  .p-lg-295 {
    padding: 18.4375rem !important;
  }
  .p-lg-300 {
    padding: 18.75rem !important;
  }
  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .px-lg-5 {
    padding-right: 0.313rem !important;
    padding-left: 0.313rem !important;
  }
  .px-lg-10 {
    padding-right: 0.625rem !important;
    padding-left: 0.625rem !important;
  }
  .px-lg-15 {
    padding-right: 0.938rem !important;
    padding-left: 0.938rem !important;
  }
  .px-lg-20 {
    padding-right: 1.25rem !important;
    padding-left: 1.25rem !important;
  }
  .px-lg-25 {
    padding-right: 1.563rem !important;
    padding-left: 1.563rem !important;
  }
  .px-lg-30 {
    padding-right: 1.875rem !important;
    padding-left: 1.875rem !important;
  }
  .px-lg-35 {
    padding-right: 2.188rem !important;
    padding-left: 2.188rem !important;
  }
  .px-lg-40 {
    padding-right: 2.5rem !important;
    padding-left: 2.5rem !important;
  }
  .px-lg-45 {
    padding-right: 2.813rem !important;
    padding-left: 2.813rem !important;
  }
  .px-lg-50 {
    padding-right: 3.125rem !important;
    padding-left: 3.125rem !important;
  }
  .px-lg-55 {
    padding-right: 3.438rem !important;
    padding-left: 3.438rem !important;
  }
  .px-lg-60 {
    padding-right: 3.75rem !important;
    padding-left: 3.75rem !important;
  }
  .px-lg-65 {
    padding-right: 4.063rem !important;
    padding-left: 4.063rem !important;
  }
  .px-lg-70 {
    padding-right: 4.375rem !important;
    padding-left: 4.375rem !important;
  }
  .px-lg-75 {
    padding-right: 4.688rem !important;
    padding-left: 4.688rem !important;
  }
  .px-lg-80 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }
  .px-lg-85 {
    padding-right: 5.313rem !important;
    padding-left: 5.313rem !important;
  }
  .px-lg-90 {
    padding-right: 5.625rem !important;
    padding-left: 5.625rem !important;
  }
  .px-lg-95 {
    padding-right: 5.938rem !important;
    padding-left: 5.938rem !important;
  }
  .px-lg-100 {
    padding-right: 6.25rem !important;
    padding-left: 6.25rem !important;
  }
  .px-lg-105 {
    padding-right: 6.5625rem !important;
    padding-left: 6.5625rem !important;
  }
  .px-lg-110 {
    padding-right: 6.875rem !important;
    padding-left: 6.875rem !important;
  }
  .px-lg-115 {
    padding-right: 7.1875rem !important;
    padding-left: 7.1875rem !important;
  }
  .px-lg-120 {
    padding-right: 7.5rem !important;
    padding-left: 7.5rem !important;
  }
  .px-lg-125 {
    padding-right: 7.8125rem !important;
    padding-left: 7.8125rem !important;
  }
  .px-lg-130 {
    padding-right: 8.125rem !important;
    padding-left: 8.125rem !important;
  }
  .px-lg-135 {
    padding-right: 8.4375rem !important;
    padding-left: 8.4375rem !important;
  }
  .px-lg-140 {
    padding-right: 8.75rem !important;
    padding-left: 8.75rem !important;
  }
  .px-lg-145 {
    padding-right: 9.0625rem !important;
    padding-left: 9.0625rem !important;
  }
  .px-lg-150 {
    padding-right: 9.375rem !important;
    padding-left: 9.375rem !important;
  }
  .px-lg-155 {
    padding-right: 9.6875rem !important;
    padding-left: 9.6875rem !important;
  }
  .px-lg-160 {
    padding-right: 10rem !important;
    padding-left: 10rem !important;
  }
  .px-lg-165 {
    padding-right: 10.3125rem !important;
    padding-left: 10.3125rem !important;
  }
  .px-lg-170 {
    padding-right: 10.625rem !important;
    padding-left: 10.625rem !important;
  }
  .px-lg-175 {
    padding-right: 10.9375rem !important;
    padding-left: 10.9375rem !important;
  }
  .px-lg-180 {
    padding-right: 11.25rem !important;
    padding-left: 11.25rem !important;
  }
  .px-lg-185 {
    padding-right: 11.5625rem !important;
    padding-left: 11.5625rem !important;
  }
  .px-lg-190 {
    padding-right: 11.875rem !important;
    padding-left: 11.875rem !important;
  }
  .px-lg-195 {
    padding-right: 12.1875rem !important;
    padding-left: 12.1875rem !important;
  }
  .px-lg-200 {
    padding-right: 12.5rem !important;
    padding-left: 12.5rem !important;
  }
  .px-lg-205 {
    padding-right: 12.8125rem !important;
    padding-left: 12.8125rem !important;
  }
  .px-lg-210 {
    padding-right: 13.125rem !important;
    padding-left: 13.125rem !important;
  }
  .px-lg-215 {
    padding-right: 13.4375rem !important;
    padding-left: 13.4375rem !important;
  }
  .px-lg-220 {
    padding-right: 13.75rem !important;
    padding-left: 13.75rem !important;
  }
  .px-lg-225 {
    padding-right: 14.0625rem !important;
    padding-left: 14.0625rem !important;
  }
  .px-lg-230 {
    padding-right: 14.375rem !important;
    padding-left: 14.375rem !important;
  }
  .px-lg-235 {
    padding-right: 14.6875rem !important;
    padding-left: 14.6875rem !important;
  }
  .px-lg-240 {
    padding-right: 15rem !important;
    padding-left: 15rem !important;
  }
  .px-lg-245 {
    padding-right: 15.3125rem !important;
    padding-left: 15.3125rem !important;
  }
  .px-lg-250 {
    padding-right: 15.625rem !important;
    padding-left: 15.625rem !important;
  }
  .px-lg-255 {
    padding-right: 15.9375rem !important;
    padding-left: 15.9375rem !important;
  }
  .px-lg-260 {
    padding-right: 16.25rem !important;
    padding-left: 16.25rem !important;
  }
  .px-lg-265 {
    padding-right: 16.5625rem !important;
    padding-left: 16.5625rem !important;
  }
  .px-lg-270 {
    padding-right: 16.875rem !important;
    padding-left: 16.875rem !important;
  }
  .px-lg-275 {
    padding-right: 17.1875rem !important;
    padding-left: 17.1875rem !important;
  }
  .px-lg-280 {
    padding-right: 17.5rem !important;
    padding-left: 17.5rem !important;
  }
  .px-lg-285 {
    padding-right: 17.8125rem !important;
    padding-left: 17.8125rem !important;
  }
  .px-lg-290 {
    padding-right: 18.125rem !important;
    padding-left: 18.125rem !important;
  }
  .px-lg-295 {
    padding-right: 18.4375rem !important;
    padding-left: 18.4375rem !important;
  }
  .px-lg-300 {
    padding-right: 18.75rem !important;
    padding-left: 18.75rem !important;
  }
  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
  .py-lg-5 {
    padding-top: 0.313rem !important;
    padding-bottom: 0.313rem !important;
  }
  .py-lg-10 {
    padding-top: 0.625rem !important;
    padding-bottom: 0.625rem !important;
  }
  .py-lg-15 {
    padding-top: 0.938rem !important;
    padding-bottom: 0.938rem !important;
  }
  .py-lg-20 {
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important;
  }
  .py-lg-25 {
    padding-top: 1.563rem !important;
    padding-bottom: 1.563rem !important;
  }
  .py-lg-30 {
    padding-top: 1.875rem !important;
    padding-bottom: 1.875rem !important;
  }
  .py-lg-35 {
    padding-top: 2.188rem !important;
    padding-bottom: 2.188rem !important;
  }
  .py-lg-40 {
    padding-top: 2.5rem !important;
    padding-bottom: 2.5rem !important;
  }
  .py-lg-45 {
    padding-top: 2.813rem !important;
    padding-bottom: 2.813rem !important;
  }
  .py-lg-50 {
    padding-top: 3.125rem !important;
    padding-bottom: 3.125rem !important;
  }
  .py-lg-55 {
    padding-top: 3.438rem !important;
    padding-bottom: 3.438rem !important;
  }
  .py-lg-60 {
    padding-top: 3.75rem !important;
    padding-bottom: 3.75rem !important;
  }
  .py-lg-65 {
    padding-top: 4.063rem !important;
    padding-bottom: 4.063rem !important;
  }
  .py-lg-70 {
    padding-top: 4.375rem !important;
    padding-bottom: 4.375rem !important;
  }
  .py-lg-75 {
    padding-top: 4.688rem !important;
    padding-bottom: 4.688rem !important;
  }
  .py-lg-80 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }
  .py-lg-85 {
    padding-top: 5.313rem !important;
    padding-bottom: 5.313rem !important;
  }
  .py-lg-90 {
    padding-top: 5.625rem !important;
    padding-bottom: 5.625rem !important;
  }
  .py-lg-95 {
    padding-top: 5.938rem !important;
    padding-bottom: 5.938rem !important;
  }
  .py-lg-100 {
    padding-top: 6.25rem !important;
    padding-bottom: 6.25rem !important;
  }
  .py-lg-105 {
    padding-top: 6.5625rem !important;
    padding-bottom: 6.5625rem !important;
  }
  .py-lg-110 {
    padding-top: 6.875rem !important;
    padding-bottom: 6.875rem !important;
  }
  .py-lg-115 {
    padding-top: 7.1875rem !important;
    padding-bottom: 7.1875rem !important;
  }
  .py-lg-120 {
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
  }
  .py-lg-125 {
    padding-top: 7.8125rem !important;
    padding-bottom: 7.8125rem !important;
  }
  .py-lg-130 {
    padding-top: 8.125rem !important;
    padding-bottom: 8.125rem !important;
  }
  .py-lg-135 {
    padding-top: 8.4375rem !important;
    padding-bottom: 8.4375rem !important;
  }
  .py-lg-140 {
    padding-top: 8.75rem !important;
    padding-bottom: 8.75rem !important;
  }
  .py-lg-145 {
    padding-top: 9.0625rem !important;
    padding-bottom: 9.0625rem !important;
  }
  .py-lg-150 {
    padding-top: 9.375rem !important;
    padding-bottom: 9.375rem !important;
  }
  .py-lg-155 {
    padding-top: 9.6875rem !important;
    padding-bottom: 9.6875rem !important;
  }
  .py-lg-160 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
  }
  .py-lg-165 {
    padding-top: 10.3125rem !important;
    padding-bottom: 10.3125rem !important;
  }
  .py-lg-170 {
    padding-top: 10.625rem !important;
    padding-bottom: 10.625rem !important;
  }
  .py-lg-175 {
    padding-top: 10.9375rem !important;
    padding-bottom: 10.9375rem !important;
  }
  .py-lg-180 {
    padding-top: 11.25rem !important;
    padding-bottom: 11.25rem !important;
  }
  .py-lg-185 {
    padding-top: 11.5625rem !important;
    padding-bottom: 11.5625rem !important;
  }
  .py-lg-190 {
    padding-top: 11.875rem !important;
    padding-bottom: 11.875rem !important;
  }
  .py-lg-195 {
    padding-top: 12.1875rem !important;
    padding-bottom: 12.1875rem !important;
  }
  .py-lg-200 {
    padding-top: 12.5rem !important;
    padding-bottom: 12.5rem !important;
  }
  .py-lg-205 {
    padding-top: 12.8125rem !important;
    padding-bottom: 12.8125rem !important;
  }
  .py-lg-210 {
    padding-top: 13.125rem !important;
    padding-bottom: 13.125rem !important;
  }
  .py-lg-215 {
    padding-top: 13.4375rem !important;
    padding-bottom: 13.4375rem !important;
  }
  .py-lg-220 {
    padding-top: 13.75rem !important;
    padding-bottom: 13.75rem !important;
  }
  .py-lg-225 {
    padding-top: 14.0625rem !important;
    padding-bottom: 14.0625rem !important;
  }
  .py-lg-230 {
    padding-top: 14.375rem !important;
    padding-bottom: 14.375rem !important;
  }
  .py-lg-235 {
    padding-top: 14.6875rem !important;
    padding-bottom: 14.6875rem !important;
  }
  .py-lg-240 {
    padding-top: 15rem !important;
    padding-bottom: 15rem !important;
  }
  .py-lg-245 {
    padding-top: 15.3125rem !important;
    padding-bottom: 15.3125rem !important;
  }
  .py-lg-250 {
    padding-top: 15.625rem !important;
    padding-bottom: 15.625rem !important;
  }
  .py-lg-255 {
    padding-top: 15.9375rem !important;
    padding-bottom: 15.9375rem !important;
  }
  .py-lg-260 {
    padding-top: 16.25rem !important;
    padding-bottom: 16.25rem !important;
  }
  .py-lg-265 {
    padding-top: 16.5625rem !important;
    padding-bottom: 16.5625rem !important;
  }
  .py-lg-270 {
    padding-top: 16.875rem !important;
    padding-bottom: 16.875rem !important;
  }
  .py-lg-275 {
    padding-top: 17.1875rem !important;
    padding-bottom: 17.1875rem !important;
  }
  .py-lg-280 {
    padding-top: 17.5rem !important;
    padding-bottom: 17.5rem !important;
  }
  .py-lg-285 {
    padding-top: 17.8125rem !important;
    padding-bottom: 17.8125rem !important;
  }
  .py-lg-290 {
    padding-top: 18.125rem !important;
    padding-bottom: 18.125rem !important;
  }
  .py-lg-295 {
    padding-top: 18.4375rem !important;
    padding-bottom: 18.4375rem !important;
  }
  .py-lg-300 {
    padding-top: 18.75rem !important;
    padding-bottom: 18.75rem !important;
  }
  .pt-lg-0 {
    padding-top: 0 !important;
  }
  .pt-lg-5 {
    padding-top: 0.313rem !important;
  }
  .pt-lg-10 {
    padding-top: 0.625rem !important;
  }
  .pt-lg-15 {
    padding-top: 0.938rem !important;
  }
  .pt-lg-20 {
    padding-top: 1.25rem !important;
  }
  .pt-lg-25 {
    padding-top: 1.563rem !important;
  }
  .pt-lg-30 {
    padding-top: 1.875rem !important;
  }
  .pt-lg-35 {
    padding-top: 2.188rem !important;
  }
  .pt-lg-40 {
    padding-top: 2.5rem !important;
  }
  .pt-lg-45 {
    padding-top: 2.813rem !important;
  }
  .pt-lg-50 {
    padding-top: 3.125rem !important;
  }
  .pt-lg-55 {
    padding-top: 3.438rem !important;
  }
  .pt-lg-60 {
    padding-top: 3.75rem !important;
  }
  .pt-lg-65 {
    padding-top: 4.063rem !important;
  }
  .pt-lg-70 {
    padding-top: 4.375rem !important;
  }
  .pt-lg-75 {
    padding-top: 4.688rem !important;
  }
  .pt-lg-80 {
    padding-top: 5rem !important;
  }
  .pt-lg-85 {
    padding-top: 5.313rem !important;
  }
  .pt-lg-90 {
    padding-top: 5.625rem !important;
  }
  .pt-lg-95 {
    padding-top: 5.938rem !important;
  }
  .pt-lg-100 {
    padding-top: 6.25rem !important;
  }
  .pt-lg-105 {
    padding-top: 6.5625rem !important;
  }
  .pt-lg-110 {
    padding-top: 6.875rem !important;
  }
  .pt-lg-115 {
    padding-top: 7.1875rem !important;
  }
  .pt-lg-120 {
    padding-top: 7.5rem !important;
  }
  .pt-lg-125 {
    padding-top: 7.8125rem !important;
  }
  .pt-lg-130 {
    padding-top: 8.125rem !important;
  }
  .pt-lg-135 {
    padding-top: 8.4375rem !important;
  }
  .pt-lg-140 {
    padding-top: 8.75rem !important;
  }
  .pt-lg-145 {
    padding-top: 9.0625rem !important;
  }
  .pt-lg-150 {
    padding-top: 9.375rem !important;
  }
  .pt-lg-155 {
    padding-top: 9.6875rem !important;
  }
  .pt-lg-160 {
    padding-top: 10rem !important;
  }
  .pt-lg-165 {
    padding-top: 10.3125rem !important;
  }
  .pt-lg-170 {
    padding-top: 10.625rem !important;
  }
  .pt-lg-175 {
    padding-top: 10.9375rem !important;
  }
  .pt-lg-180 {
    padding-top: 11.25rem !important;
  }
  .pt-lg-185 {
    padding-top: 11.5625rem !important;
  }
  .pt-lg-190 {
    padding-top: 11.875rem !important;
  }
  .pt-lg-195 {
    padding-top: 12.1875rem !important;
  }
  .pt-lg-200 {
    padding-top: 12.5rem !important;
  }
  .pt-lg-205 {
    padding-top: 12.8125rem !important;
  }
  .pt-lg-210 {
    padding-top: 13.125rem !important;
  }
  .pt-lg-215 {
    padding-top: 13.4375rem !important;
  }
  .pt-lg-220 {
    padding-top: 13.75rem !important;
  }
  .pt-lg-225 {
    padding-top: 14.0625rem !important;
  }
  .pt-lg-230 {
    padding-top: 14.375rem !important;
  }
  .pt-lg-235 {
    padding-top: 14.6875rem !important;
  }
  .pt-lg-240 {
    padding-top: 15rem !important;
  }
  .pt-lg-245 {
    padding-top: 15.3125rem !important;
  }
  .pt-lg-250 {
    padding-top: 15.625rem !important;
  }
  .pt-lg-255 {
    padding-top: 15.9375rem !important;
  }
  .pt-lg-260 {
    padding-top: 16.25rem !important;
  }
  .pt-lg-265 {
    padding-top: 16.5625rem !important;
  }
  .pt-lg-270 {
    padding-top: 16.875rem !important;
  }
  .pt-lg-275 {
    padding-top: 17.1875rem !important;
  }
  .pt-lg-280 {
    padding-top: 17.5rem !important;
  }
  .pt-lg-285 {
    padding-top: 17.8125rem !important;
  }
  .pt-lg-290 {
    padding-top: 18.125rem !important;
  }
  .pt-lg-295 {
    padding-top: 18.4375rem !important;
  }
  .pt-lg-300 {
    padding-top: 18.75rem !important;
  }
  .pe-lg-0 {
    padding-right: 0 !important;
  }
  .pe-lg-5 {
    padding-right: 0.313rem !important;
  }
  .pe-lg-10 {
    padding-right: 0.625rem !important;
  }
  .pe-lg-15 {
    padding-right: 0.938rem !important;
  }
  .pe-lg-20 {
    padding-right: 1.25rem !important;
  }
  .pe-lg-25 {
    padding-right: 1.563rem !important;
  }
  .pe-lg-30 {
    padding-right: 1.875rem !important;
  }
  .pe-lg-35 {
    padding-right: 2.188rem !important;
  }
  .pe-lg-40 {
    padding-right: 2.5rem !important;
  }
  .pe-lg-45 {
    padding-right: 2.813rem !important;
  }
  .pe-lg-50 {
    padding-right: 3.125rem !important;
  }
  .pe-lg-55 {
    padding-right: 3.438rem !important;
  }
  .pe-lg-60 {
    padding-right: 3.75rem !important;
  }
  .pe-lg-65 {
    padding-right: 4.063rem !important;
  }
  .pe-lg-70 {
    padding-right: 4.375rem !important;
  }
  .pe-lg-75 {
    padding-right: 4.688rem !important;
  }
  .pe-lg-80 {
    padding-right: 5rem !important;
  }
  .pe-lg-85 {
    padding-right: 5.313rem !important;
  }
  .pe-lg-90 {
    padding-right: 5.625rem !important;
  }
  .pe-lg-95 {
    padding-right: 5.938rem !important;
  }
  .pe-lg-100 {
    padding-right: 6.25rem !important;
  }
  .pe-lg-105 {
    padding-right: 6.5625rem !important;
  }
  .pe-lg-110 {
    padding-right: 6.875rem !important;
  }
  .pe-lg-115 {
    padding-right: 7.1875rem !important;
  }
  .pe-lg-120 {
    padding-right: 7.5rem !important;
  }
  .pe-lg-125 {
    padding-right: 7.8125rem !important;
  }
  .pe-lg-130 {
    padding-right: 8.125rem !important;
  }
  .pe-lg-135 {
    padding-right: 8.4375rem !important;
  }
  .pe-lg-140 {
    padding-right: 8.75rem !important;
  }
  .pe-lg-145 {
    padding-right: 9.0625rem !important;
  }
  .pe-lg-150 {
    padding-right: 9.375rem !important;
  }
  .pe-lg-155 {
    padding-right: 9.6875rem !important;
  }
  .pe-lg-160 {
    padding-right: 10rem !important;
  }
  .pe-lg-165 {
    padding-right: 10.3125rem !important;
  }
  .pe-lg-170 {
    padding-right: 10.625rem !important;
  }
  .pe-lg-175 {
    padding-right: 10.9375rem !important;
  }
  .pe-lg-180 {
    padding-right: 11.25rem !important;
  }
  .pe-lg-185 {
    padding-right: 11.5625rem !important;
  }
  .pe-lg-190 {
    padding-right: 11.875rem !important;
  }
  .pe-lg-195 {
    padding-right: 12.1875rem !important;
  }
  .pe-lg-200 {
    padding-right: 12.5rem !important;
  }
  .pe-lg-205 {
    padding-right: 12.8125rem !important;
  }
  .pe-lg-210 {
    padding-right: 13.125rem !important;
  }
  .pe-lg-215 {
    padding-right: 13.4375rem !important;
  }
  .pe-lg-220 {
    padding-right: 13.75rem !important;
  }
  .pe-lg-225 {
    padding-right: 14.0625rem !important;
  }
  .pe-lg-230 {
    padding-right: 14.375rem !important;
  }
  .pe-lg-235 {
    padding-right: 14.6875rem !important;
  }
  .pe-lg-240 {
    padding-right: 15rem !important;
  }
  .pe-lg-245 {
    padding-right: 15.3125rem !important;
  }
  .pe-lg-250 {
    padding-right: 15.625rem !important;
  }
  .pe-lg-255 {
    padding-right: 15.9375rem !important;
  }
  .pe-lg-260 {
    padding-right: 16.25rem !important;
  }
  .pe-lg-265 {
    padding-right: 16.5625rem !important;
  }
  .pe-lg-270 {
    padding-right: 16.875rem !important;
  }
  .pe-lg-275 {
    padding-right: 17.1875rem !important;
  }
  .pe-lg-280 {
    padding-right: 17.5rem !important;
  }
  .pe-lg-285 {
    padding-right: 17.8125rem !important;
  }
  .pe-lg-290 {
    padding-right: 18.125rem !important;
  }
  .pe-lg-295 {
    padding-right: 18.4375rem !important;
  }
  .pe-lg-300 {
    padding-right: 18.75rem !important;
  }
  .pb-lg-0 {
    padding-bottom: 0 !important;
  }
  .pb-lg-5 {
    padding-bottom: 0.313rem !important;
  }
  .pb-lg-10 {
    padding-bottom: 0.625rem !important;
  }
  .pb-lg-15 {
    padding-bottom: 0.938rem !important;
  }
  .pb-lg-20 {
    padding-bottom: 1.25rem !important;
  }
  .pb-lg-25 {
    padding-bottom: 1.563rem !important;
  }
  .pb-lg-30 {
    padding-bottom: 1.875rem !important;
  }
  .pb-lg-35 {
    padding-bottom: 2.188rem !important;
  }
  .pb-lg-40 {
    padding-bottom: 2.5rem !important;
  }
  .pb-lg-45 {
    padding-bottom: 2.813rem !important;
  }
  .pb-lg-50 {
    padding-bottom: 3.125rem !important;
  }
  .pb-lg-55 {
    padding-bottom: 3.438rem !important;
  }
  .pb-lg-60 {
    padding-bottom: 3.75rem !important;
  }
  .pb-lg-65 {
    padding-bottom: 4.063rem !important;
  }
  .pb-lg-70 {
    padding-bottom: 4.375rem !important;
  }
  .pb-lg-75 {
    padding-bottom: 4.688rem !important;
  }
  .pb-lg-80 {
    padding-bottom: 5rem !important;
  }
  .pb-lg-85 {
    padding-bottom: 5.313rem !important;
  }
  .pb-lg-90 {
    padding-bottom: 5.625rem !important;
  }
  .pb-lg-95 {
    padding-bottom: 5.938rem !important;
  }
  .pb-lg-100 {
    padding-bottom: 6.25rem !important;
  }
  .pb-lg-105 {
    padding-bottom: 6.5625rem !important;
  }
  .pb-lg-110 {
    padding-bottom: 6.875rem !important;
  }
  .pb-lg-115 {
    padding-bottom: 7.1875rem !important;
  }
  .pb-lg-120 {
    padding-bottom: 7.5rem !important;
  }
  .pb-lg-125 {
    padding-bottom: 7.8125rem !important;
  }
  .pb-lg-130 {
    padding-bottom: 8.125rem !important;
  }
  .pb-lg-135 {
    padding-bottom: 8.4375rem !important;
  }
  .pb-lg-140 {
    padding-bottom: 8.75rem !important;
  }
  .pb-lg-145 {
    padding-bottom: 9.0625rem !important;
  }
  .pb-lg-150 {
    padding-bottom: 9.375rem !important;
  }
  .pb-lg-155 {
    padding-bottom: 9.6875rem !important;
  }
  .pb-lg-160 {
    padding-bottom: 10rem !important;
  }
  .pb-lg-165 {
    padding-bottom: 10.3125rem !important;
  }
  .pb-lg-170 {
    padding-bottom: 10.625rem !important;
  }
  .pb-lg-175 {
    padding-bottom: 10.9375rem !important;
  }
  .pb-lg-180 {
    padding-bottom: 11.25rem !important;
  }
  .pb-lg-185 {
    padding-bottom: 11.5625rem !important;
  }
  .pb-lg-190 {
    padding-bottom: 11.875rem !important;
  }
  .pb-lg-195 {
    padding-bottom: 12.1875rem !important;
  }
  .pb-lg-200 {
    padding-bottom: 12.5rem !important;
  }
  .pb-lg-205 {
    padding-bottom: 12.8125rem !important;
  }
  .pb-lg-210 {
    padding-bottom: 13.125rem !important;
  }
  .pb-lg-215 {
    padding-bottom: 13.4375rem !important;
  }
  .pb-lg-220 {
    padding-bottom: 13.75rem !important;
  }
  .pb-lg-225 {
    padding-bottom: 14.0625rem !important;
  }
  .pb-lg-230 {
    padding-bottom: 14.375rem !important;
  }
  .pb-lg-235 {
    padding-bottom: 14.6875rem !important;
  }
  .pb-lg-240 {
    padding-bottom: 15rem !important;
  }
  .pb-lg-245 {
    padding-bottom: 15.3125rem !important;
  }
  .pb-lg-250 {
    padding-bottom: 15.625rem !important;
  }
  .pb-lg-255 {
    padding-bottom: 15.9375rem !important;
  }
  .pb-lg-260 {
    padding-bottom: 16.25rem !important;
  }
  .pb-lg-265 {
    padding-bottom: 16.5625rem !important;
  }
  .pb-lg-270 {
    padding-bottom: 16.875rem !important;
  }
  .pb-lg-275 {
    padding-bottom: 17.1875rem !important;
  }
  .pb-lg-280 {
    padding-bottom: 17.5rem !important;
  }
  .pb-lg-285 {
    padding-bottom: 17.8125rem !important;
  }
  .pb-lg-290 {
    padding-bottom: 18.125rem !important;
  }
  .pb-lg-295 {
    padding-bottom: 18.4375rem !important;
  }
  .pb-lg-300 {
    padding-bottom: 18.75rem !important;
  }
  .ps-lg-0 {
    padding-left: 0 !important;
  }
  .ps-lg-5 {
    padding-left: 0.313rem !important;
  }
  .ps-lg-10 {
    padding-left: 0.625rem !important;
  }
  .ps-lg-15 {
    padding-left: 0.938rem !important;
  }
  .ps-lg-20 {
    padding-left: 1.25rem !important;
  }
  .ps-lg-25 {
    padding-left: 1.563rem !important;
  }
  .ps-lg-30 {
    padding-left: 1.875rem !important;
  }
  .ps-lg-35 {
    padding-left: 2.188rem !important;
  }
  .ps-lg-40 {
    padding-left: 2.5rem !important;
  }
  .ps-lg-45 {
    padding-left: 2.813rem !important;
  }
  .ps-lg-50 {
    padding-left: 3.125rem !important;
  }
  .ps-lg-55 {
    padding-left: 3.438rem !important;
  }
  .ps-lg-60 {
    padding-left: 3.75rem !important;
  }
  .ps-lg-65 {
    padding-left: 4.063rem !important;
  }
  .ps-lg-70 {
    padding-left: 4.375rem !important;
  }
  .ps-lg-75 {
    padding-left: 4.688rem !important;
  }
  .ps-lg-80 {
    padding-left: 5rem !important;
  }
  .ps-lg-85 {
    padding-left: 5.313rem !important;
  }
  .ps-lg-90 {
    padding-left: 5.625rem !important;
  }
  .ps-lg-95 {
    padding-left: 5.938rem !important;
  }
  .ps-lg-100 {
    padding-left: 6.25rem !important;
  }
  .ps-lg-105 {
    padding-left: 6.5625rem !important;
  }
  .ps-lg-110 {
    padding-left: 6.875rem !important;
  }
  .ps-lg-115 {
    padding-left: 7.1875rem !important;
  }
  .ps-lg-120 {
    padding-left: 7.5rem !important;
  }
  .ps-lg-125 {
    padding-left: 7.8125rem !important;
  }
  .ps-lg-130 {
    padding-left: 8.125rem !important;
  }
  .ps-lg-135 {
    padding-left: 8.4375rem !important;
  }
  .ps-lg-140 {
    padding-left: 8.75rem !important;
  }
  .ps-lg-145 {
    padding-left: 9.0625rem !important;
  }
  .ps-lg-150 {
    padding-left: 9.375rem !important;
  }
  .ps-lg-155 {
    padding-left: 9.6875rem !important;
  }
  .ps-lg-160 {
    padding-left: 10rem !important;
  }
  .ps-lg-165 {
    padding-left: 10.3125rem !important;
  }
  .ps-lg-170 {
    padding-left: 10.625rem !important;
  }
  .ps-lg-175 {
    padding-left: 10.9375rem !important;
  }
  .ps-lg-180 {
    padding-left: 11.25rem !important;
  }
  .ps-lg-185 {
    padding-left: 11.5625rem !important;
  }
  .ps-lg-190 {
    padding-left: 11.875rem !important;
  }
  .ps-lg-195 {
    padding-left: 12.1875rem !important;
  }
  .ps-lg-200 {
    padding-left: 12.5rem !important;
  }
  .ps-lg-205 {
    padding-left: 12.8125rem !important;
  }
  .ps-lg-210 {
    padding-left: 13.125rem !important;
  }
  .ps-lg-215 {
    padding-left: 13.4375rem !important;
  }
  .ps-lg-220 {
    padding-left: 13.75rem !important;
  }
  .ps-lg-225 {
    padding-left: 14.0625rem !important;
  }
  .ps-lg-230 {
    padding-left: 14.375rem !important;
  }
  .ps-lg-235 {
    padding-left: 14.6875rem !important;
  }
  .ps-lg-240 {
    padding-left: 15rem !important;
  }
  .ps-lg-245 {
    padding-left: 15.3125rem !important;
  }
  .ps-lg-250 {
    padding-left: 15.625rem !important;
  }
  .ps-lg-255 {
    padding-left: 15.9375rem !important;
  }
  .ps-lg-260 {
    padding-left: 16.25rem !important;
  }
  .ps-lg-265 {
    padding-left: 16.5625rem !important;
  }
  .ps-lg-270 {
    padding-left: 16.875rem !important;
  }
  .ps-lg-275 {
    padding-left: 17.1875rem !important;
  }
  .ps-lg-280 {
    padding-left: 17.5rem !important;
  }
  .ps-lg-285 {
    padding-left: 17.8125rem !important;
  }
  .ps-lg-290 {
    padding-left: 18.125rem !important;
  }
  .ps-lg-295 {
    padding-left: 18.4375rem !important;
  }
  .ps-lg-300 {
    padding-left: 18.75rem !important;
  }
  .gap-lg-0 {
    gap: 0 !important;
  }
  .gap-lg-5 {
    gap: 0.313rem !important;
  }
  .gap-lg-10 {
    gap: 0.625rem !important;
  }
  .gap-lg-15 {
    gap: 0.938rem !important;
  }
  .gap-lg-20 {
    gap: 1.25rem !important;
  }
  .gap-lg-25 {
    gap: 1.563rem !important;
  }
  .gap-lg-30 {
    gap: 1.875rem !important;
  }
  .gap-lg-35 {
    gap: 2.188rem !important;
  }
  .gap-lg-40 {
    gap: 2.5rem !important;
  }
  .gap-lg-45 {
    gap: 2.813rem !important;
  }
  .gap-lg-50 {
    gap: 3.125rem !important;
  }
  .gap-lg-55 {
    gap: 3.438rem !important;
  }
  .gap-lg-60 {
    gap: 3.75rem !important;
  }
  .gap-lg-65 {
    gap: 4.063rem !important;
  }
  .gap-lg-70 {
    gap: 4.375rem !important;
  }
  .gap-lg-75 {
    gap: 4.688rem !important;
  }
  .gap-lg-80 {
    gap: 5rem !important;
  }
  .gap-lg-85 {
    gap: 5.313rem !important;
  }
  .gap-lg-90 {
    gap: 5.625rem !important;
  }
  .gap-lg-95 {
    gap: 5.938rem !important;
  }
  .gap-lg-100 {
    gap: 6.25rem !important;
  }
  .gap-lg-105 {
    gap: 6.5625rem !important;
  }
  .gap-lg-110 {
    gap: 6.875rem !important;
  }
  .gap-lg-115 {
    gap: 7.1875rem !important;
  }
  .gap-lg-120 {
    gap: 7.5rem !important;
  }
  .gap-lg-125 {
    gap: 7.8125rem !important;
  }
  .gap-lg-130 {
    gap: 8.125rem !important;
  }
  .gap-lg-135 {
    gap: 8.4375rem !important;
  }
  .gap-lg-140 {
    gap: 8.75rem !important;
  }
  .gap-lg-145 {
    gap: 9.0625rem !important;
  }
  .gap-lg-150 {
    gap: 9.375rem !important;
  }
  .gap-lg-155 {
    gap: 9.6875rem !important;
  }
  .gap-lg-160 {
    gap: 10rem !important;
  }
  .gap-lg-165 {
    gap: 10.3125rem !important;
  }
  .gap-lg-170 {
    gap: 10.625rem !important;
  }
  .gap-lg-175 {
    gap: 10.9375rem !important;
  }
  .gap-lg-180 {
    gap: 11.25rem !important;
  }
  .gap-lg-185 {
    gap: 11.5625rem !important;
  }
  .gap-lg-190 {
    gap: 11.875rem !important;
  }
  .gap-lg-195 {
    gap: 12.1875rem !important;
  }
  .gap-lg-200 {
    gap: 12.5rem !important;
  }
  .gap-lg-205 {
    gap: 12.8125rem !important;
  }
  .gap-lg-210 {
    gap: 13.125rem !important;
  }
  .gap-lg-215 {
    gap: 13.4375rem !important;
  }
  .gap-lg-220 {
    gap: 13.75rem !important;
  }
  .gap-lg-225 {
    gap: 14.0625rem !important;
  }
  .gap-lg-230 {
    gap: 14.375rem !important;
  }
  .gap-lg-235 {
    gap: 14.6875rem !important;
  }
  .gap-lg-240 {
    gap: 15rem !important;
  }
  .gap-lg-245 {
    gap: 15.3125rem !important;
  }
  .gap-lg-250 {
    gap: 15.625rem !important;
  }
  .gap-lg-255 {
    gap: 15.9375rem !important;
  }
  .gap-lg-260 {
    gap: 16.25rem !important;
  }
  .gap-lg-265 {
    gap: 16.5625rem !important;
  }
  .gap-lg-270 {
    gap: 16.875rem !important;
  }
  .gap-lg-275 {
    gap: 17.1875rem !important;
  }
  .gap-lg-280 {
    gap: 17.5rem !important;
  }
  .gap-lg-285 {
    gap: 17.8125rem !important;
  }
  .gap-lg-290 {
    gap: 18.125rem !important;
  }
  .gap-lg-295 {
    gap: 18.4375rem !important;
  }
  .gap-lg-300 {
    gap: 18.75rem !important;
  }
  .row-gap-lg-0 {
    row-gap: 0 !important;
  }
  .row-gap-lg-5 {
    row-gap: 0.313rem !important;
  }
  .row-gap-lg-10 {
    row-gap: 0.625rem !important;
  }
  .row-gap-lg-15 {
    row-gap: 0.938rem !important;
  }
  .row-gap-lg-20 {
    row-gap: 1.25rem !important;
  }
  .row-gap-lg-25 {
    row-gap: 1.563rem !important;
  }
  .row-gap-lg-30 {
    row-gap: 1.875rem !important;
  }
  .row-gap-lg-35 {
    row-gap: 2.188rem !important;
  }
  .row-gap-lg-40 {
    row-gap: 2.5rem !important;
  }
  .row-gap-lg-45 {
    row-gap: 2.813rem !important;
  }
  .row-gap-lg-50 {
    row-gap: 3.125rem !important;
  }
  .row-gap-lg-55 {
    row-gap: 3.438rem !important;
  }
  .row-gap-lg-60 {
    row-gap: 3.75rem !important;
  }
  .row-gap-lg-65 {
    row-gap: 4.063rem !important;
  }
  .row-gap-lg-70 {
    row-gap: 4.375rem !important;
  }
  .row-gap-lg-75 {
    row-gap: 4.688rem !important;
  }
  .row-gap-lg-80 {
    row-gap: 5rem !important;
  }
  .row-gap-lg-85 {
    row-gap: 5.313rem !important;
  }
  .row-gap-lg-90 {
    row-gap: 5.625rem !important;
  }
  .row-gap-lg-95 {
    row-gap: 5.938rem !important;
  }
  .row-gap-lg-100 {
    row-gap: 6.25rem !important;
  }
  .row-gap-lg-105 {
    row-gap: 6.5625rem !important;
  }
  .row-gap-lg-110 {
    row-gap: 6.875rem !important;
  }
  .row-gap-lg-115 {
    row-gap: 7.1875rem !important;
  }
  .row-gap-lg-120 {
    row-gap: 7.5rem !important;
  }
  .row-gap-lg-125 {
    row-gap: 7.8125rem !important;
  }
  .row-gap-lg-130 {
    row-gap: 8.125rem !important;
  }
  .row-gap-lg-135 {
    row-gap: 8.4375rem !important;
  }
  .row-gap-lg-140 {
    row-gap: 8.75rem !important;
  }
  .row-gap-lg-145 {
    row-gap: 9.0625rem !important;
  }
  .row-gap-lg-150 {
    row-gap: 9.375rem !important;
  }
  .row-gap-lg-155 {
    row-gap: 9.6875rem !important;
  }
  .row-gap-lg-160 {
    row-gap: 10rem !important;
  }
  .row-gap-lg-165 {
    row-gap: 10.3125rem !important;
  }
  .row-gap-lg-170 {
    row-gap: 10.625rem !important;
  }
  .row-gap-lg-175 {
    row-gap: 10.9375rem !important;
  }
  .row-gap-lg-180 {
    row-gap: 11.25rem !important;
  }
  .row-gap-lg-185 {
    row-gap: 11.5625rem !important;
  }
  .row-gap-lg-190 {
    row-gap: 11.875rem !important;
  }
  .row-gap-lg-195 {
    row-gap: 12.1875rem !important;
  }
  .row-gap-lg-200 {
    row-gap: 12.5rem !important;
  }
  .row-gap-lg-205 {
    row-gap: 12.8125rem !important;
  }
  .row-gap-lg-210 {
    row-gap: 13.125rem !important;
  }
  .row-gap-lg-215 {
    row-gap: 13.4375rem !important;
  }
  .row-gap-lg-220 {
    row-gap: 13.75rem !important;
  }
  .row-gap-lg-225 {
    row-gap: 14.0625rem !important;
  }
  .row-gap-lg-230 {
    row-gap: 14.375rem !important;
  }
  .row-gap-lg-235 {
    row-gap: 14.6875rem !important;
  }
  .row-gap-lg-240 {
    row-gap: 15rem !important;
  }
  .row-gap-lg-245 {
    row-gap: 15.3125rem !important;
  }
  .row-gap-lg-250 {
    row-gap: 15.625rem !important;
  }
  .row-gap-lg-255 {
    row-gap: 15.9375rem !important;
  }
  .row-gap-lg-260 {
    row-gap: 16.25rem !important;
  }
  .row-gap-lg-265 {
    row-gap: 16.5625rem !important;
  }
  .row-gap-lg-270 {
    row-gap: 16.875rem !important;
  }
  .row-gap-lg-275 {
    row-gap: 17.1875rem !important;
  }
  .row-gap-lg-280 {
    row-gap: 17.5rem !important;
  }
  .row-gap-lg-285 {
    row-gap: 17.8125rem !important;
  }
  .row-gap-lg-290 {
    row-gap: 18.125rem !important;
  }
  .row-gap-lg-295 {
    row-gap: 18.4375rem !important;
  }
  .row-gap-lg-300 {
    row-gap: 18.75rem !important;
  }
  .column-gap-lg-0 {
    column-gap: 0 !important;
  }
  .column-gap-lg-5 {
    column-gap: 0.313rem !important;
  }
  .column-gap-lg-10 {
    column-gap: 0.625rem !important;
  }
  .column-gap-lg-15 {
    column-gap: 0.938rem !important;
  }
  .column-gap-lg-20 {
    column-gap: 1.25rem !important;
  }
  .column-gap-lg-25 {
    column-gap: 1.563rem !important;
  }
  .column-gap-lg-30 {
    column-gap: 1.875rem !important;
  }
  .column-gap-lg-35 {
    column-gap: 2.188rem !important;
  }
  .column-gap-lg-40 {
    column-gap: 2.5rem !important;
  }
  .column-gap-lg-45 {
    column-gap: 2.813rem !important;
  }
  .column-gap-lg-50 {
    column-gap: 3.125rem !important;
  }
  .column-gap-lg-55 {
    column-gap: 3.438rem !important;
  }
  .column-gap-lg-60 {
    column-gap: 3.75rem !important;
  }
  .column-gap-lg-65 {
    column-gap: 4.063rem !important;
  }
  .column-gap-lg-70 {
    column-gap: 4.375rem !important;
  }
  .column-gap-lg-75 {
    column-gap: 4.688rem !important;
  }
  .column-gap-lg-80 {
    column-gap: 5rem !important;
  }
  .column-gap-lg-85 {
    column-gap: 5.313rem !important;
  }
  .column-gap-lg-90 {
    column-gap: 5.625rem !important;
  }
  .column-gap-lg-95 {
    column-gap: 5.938rem !important;
  }
  .column-gap-lg-100 {
    column-gap: 6.25rem !important;
  }
  .column-gap-lg-105 {
    column-gap: 6.5625rem !important;
  }
  .column-gap-lg-110 {
    column-gap: 6.875rem !important;
  }
  .column-gap-lg-115 {
    column-gap: 7.1875rem !important;
  }
  .column-gap-lg-120 {
    column-gap: 7.5rem !important;
  }
  .column-gap-lg-125 {
    column-gap: 7.8125rem !important;
  }
  .column-gap-lg-130 {
    column-gap: 8.125rem !important;
  }
  .column-gap-lg-135 {
    column-gap: 8.4375rem !important;
  }
  .column-gap-lg-140 {
    column-gap: 8.75rem !important;
  }
  .column-gap-lg-145 {
    column-gap: 9.0625rem !important;
  }
  .column-gap-lg-150 {
    column-gap: 9.375rem !important;
  }
  .column-gap-lg-155 {
    column-gap: 9.6875rem !important;
  }
  .column-gap-lg-160 {
    column-gap: 10rem !important;
  }
  .column-gap-lg-165 {
    column-gap: 10.3125rem !important;
  }
  .column-gap-lg-170 {
    column-gap: 10.625rem !important;
  }
  .column-gap-lg-175 {
    column-gap: 10.9375rem !important;
  }
  .column-gap-lg-180 {
    column-gap: 11.25rem !important;
  }
  .column-gap-lg-185 {
    column-gap: 11.5625rem !important;
  }
  .column-gap-lg-190 {
    column-gap: 11.875rem !important;
  }
  .column-gap-lg-195 {
    column-gap: 12.1875rem !important;
  }
  .column-gap-lg-200 {
    column-gap: 12.5rem !important;
  }
  .column-gap-lg-205 {
    column-gap: 12.8125rem !important;
  }
  .column-gap-lg-210 {
    column-gap: 13.125rem !important;
  }
  .column-gap-lg-215 {
    column-gap: 13.4375rem !important;
  }
  .column-gap-lg-220 {
    column-gap: 13.75rem !important;
  }
  .column-gap-lg-225 {
    column-gap: 14.0625rem !important;
  }
  .column-gap-lg-230 {
    column-gap: 14.375rem !important;
  }
  .column-gap-lg-235 {
    column-gap: 14.6875rem !important;
  }
  .column-gap-lg-240 {
    column-gap: 15rem !important;
  }
  .column-gap-lg-245 {
    column-gap: 15.3125rem !important;
  }
  .column-gap-lg-250 {
    column-gap: 15.625rem !important;
  }
  .column-gap-lg-255 {
    column-gap: 15.9375rem !important;
  }
  .column-gap-lg-260 {
    column-gap: 16.25rem !important;
  }
  .column-gap-lg-265 {
    column-gap: 16.5625rem !important;
  }
  .column-gap-lg-270 {
    column-gap: 16.875rem !important;
  }
  .column-gap-lg-275 {
    column-gap: 17.1875rem !important;
  }
  .column-gap-lg-280 {
    column-gap: 17.5rem !important;
  }
  .column-gap-lg-285 {
    column-gap: 17.8125rem !important;
  }
  .column-gap-lg-290 {
    column-gap: 18.125rem !important;
  }
  .column-gap-lg-295 {
    column-gap: 18.4375rem !important;
  }
  .column-gap-lg-300 {
    column-gap: 18.75rem !important;
  }
  .text-lg-start {
    text-align: left !important;
  }
  .text-lg-end {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 3.125rem !important;
  }
  .fs-2 {
    font-size: 2.5rem !important;
  }
  .fs-3 {
    font-size: 2rem !important;
  }
  .fs-4 {
    font-size: 1.5625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-grid {
    display: grid !important;
  }
  .d-print-inline-grid {
    display: inline-grid !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
  .d-print-none {
    display: none !important;
  }
}
@media (max-width: 575.98px) {
  #homeDetailCard,
  #homeStatusCard,
  #homeScheduleCard {
    height: auto !important;
  }
  #registerModal .title {
    margin-top: 10px;
    font-size: 13px;
  }
  #breadcrumbs {
    font-size: 13px;
  }
  .table-custom {
    font-size: 12px !important;
  }
}
@media (min-width: 576px) and (max-width: 767.98px) {
  #homeDetailCard,
  #homeStatusCard,
  #homeScheduleCard {
    height: auto !important;
  }
}