
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,900&family=Space+Grotesk:wght@300;500;700&display=swap');

// Typography
$font-family-base: Lato, sans-serif;
$font-family-sans-serif: Lato, sans-serif;
$font-family-monospace: Lato, sans-serif;

// Options
$enable-shadows: true;
$enable-rounded: true;
$enable-negative-margins: true;
$enable-grid-classes: true;
$enable-responsive-font-sizes: false;
$enable-deprecation-messages: false;
$enable-validation-icons: false;

// Grid Widths
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
$container-max-widths: (
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1440px
);
$grid-columns: 12;
$grid-gutter-width: 1.5rem;

// Grid
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 1400px,
);

// Color Schema
$body-bg: #ffffff;
$primary: #1e4d60;
$secondary: #6a8a9c;
$theme-colors: (
  "primary": #1e4d60,
  "secondary": #6a8a9c,
  "tertiary": #D3DCE1,
  "success": #b5be00,
  "dark": #3a3a3a,
  "light": #f6f6f6,
  "white": #ffffff,
  "danger": #080808,
  "red": #DA0000,
  "pink": #ec008c,
  "orange": #f2c900,
  "yellow": #f2c900,
  "green": #00c608,
  "blue": #0073b4,
  "cyan": #02c2dc,
  "grey": #BEB5B5,
);
$custom-colors: (
  "blue-alt": #3453c2,
  "blue-90": #1e4d60f6,
  "light-blue": #E6ECF0,
  "dark-blue": #183E4D,
  "light-secondary": #e5e5e5,
  "secondary-10": #D5DBDF,
  "secondary-20": #FBFDFF,
  "dark-grey": #8A8A8A,
  "dark-pink": #BD0071,
  "inactive": #D9D9D9,
  "accent": #b5be00,
);
$theme_colors: map-merge($theme-colors, $custom-colors);

//Navbar
$active-link-color: #B5BE00;

.nav-link {
  font-size: 17px;
  font-weight: 400;

  &.active {
    color: $active-link-color;
  }
}

.btn-white {
  color: #FFF !important;

  &:hover {
    color: #b5be00 !important;
  }
}

.btn-warranty {
  background: #FFF !important;

  &:hover {
    background-color: #E6ECF0 !important;
    border: 2px solid $secondary !important;
  }
}

//Sidebar
#sidebarMenu {
  a.nav-link {
    color: #FFF;
  }

  a.nav-link.active,
  a:hover.nav-link {
    color: #b5be00;
  }
}

//FIle Document Containers
#fileDocuments {
  a.nav-link.active {
    color: #b5be00 !important;
    border: 2px solid #1e4d60 !important;
  }
}

//Buttons
$btn-border-width: 0;
$btn-focus-width: 0;
$btn-box-shadow: 0;
$btn-focus-box-shadow: 0;

$btn-padding-x: 200px !important;
$btn-padding-y: 200px !important;

//Tooltip
$tooltip-arrow-color: #003366;
$tooltip-color: white;
$tooltip-bg: #006699;
$tooltip-opacity: 0.95;
$tooltip-padding-x: 10px;
$tooltip-padding-y: 10px;

//Badges
$badge-font-size: 13px;

//Off Canvas
$offcanvas-horizontal-width: 165px;

//Z Index
@for $i from 1 through 5 {
  .z-#{$i} {
    z-index: $i;
  }
}

//Font Size
@for $i from 1 through 60 {
  .fs-#{$i} {
    font-size: $i + px !important;
  }
}

//Special spacing
.mt-n2 {
  margin-top: -2px;
}

//Utilities
$spacer: 1rem;
$spacers: (
  0: 0,
  5: $spacer * 0.313,
  10: $spacer * 0.625,
  15: $spacer * 0.938,
  20: $spacer * 1.25,
  25: $spacer * 1.563,
  30: $spacer * 1.875,
  35: $spacer * 2.188,
  40: $spacer * 2.5,
  45: $spacer * 2.813,
  50: $spacer * 3.125,
  55: $spacer * 3.438,
  60: $spacer * 3.75,
  65: $spacer * 4.063,
  70: $spacer * 4.375,
  75: $spacer * 4.688,
  80: $spacer * 5,
  85: $spacer * 5.313,
  90: $spacer * 5.625,
  95: $spacer * 5.938,
  100: $spacer * 6.25,
  105: $spacer * 6.5625,
  110: $spacer * 6.875,
  115: $spacer * 7.1875,
  120: $spacer * 7.5,
  125: $spacer * 7.8125,
  130: $spacer * 8.125,
  135: $spacer * 8.4375,
  140: $spacer * 8.75,
  145: $spacer * 9.0625,
  150: $spacer * 9.375,
  155: $spacer * 9.6875,
  160: $spacer * 10,
  165: $spacer * 10.3125,
  170: $spacer * 10.625,
  175: $spacer * 10.9375,
  180: $spacer * 11.25,
  185: $spacer * 11.5625,
  190: $spacer * 11.875,
  195: $spacer * 12.1875,
  200: $spacer * 12.5,
  205: $spacer * 12.8125,
  210: $spacer * 13.125,
  215: $spacer * 13.4375,
  220: $spacer * 13.75,
  225: $spacer * 14.0625,
  230: $spacer * 14.375,
  235: $spacer * 14.6875,
  240: $spacer * 15,
  245: $spacer * 15.3125,
  250: $spacer * 15.625,
  255: $spacer * 15.9375,
  260: $spacer * 16.25,
  265: $spacer * 16.5625,
  270: $spacer * 16.875,
  275: $spacer * 17.1875,
  280: $spacer * 17.5,
  285: $spacer * 17.8125,
  290: $spacer * 18.125,
  295: $spacer * 18.4375,
  300: $spacer * 18.75,
);

$utilities: (
  "opacity": (property: opacity,
    values: (0: 0,
      10: 0.10,
      20: 0.20,
      25: 0.25,
      35: 0.35,
      40: 0.4,
      50: 0.5,
      75: 0.75,
      80: 0.8,
      90: 0.9,
      95: 0.95,
      100: 1,
    ),
  ),
  "bg-opacity": (css-var: true,
    class: bg-opacity,
    values: (0: 0,
      20: 0.20,
      25: 0.25,
      50: 0.5,
      55: 0.55,
      60: 0.60,
      65: 0.65,
      70: 0.70,
      75: 0.75,
      80: 0.8,
      85: 0.85,
      90: 0.9,
      95: 0.95,
      100: 1,
    ),
  ),
);

// Carousel
.carousel-control-prev {
  left: 80px !important;
}

.carousel-control-next {
  right: 80px !important;
}

$carousel-control-width: 4%;

$body-color: #3a3a3a !default;
$font-size-base: 1.1rem !default;
$line-height-base: 1.5 !default;
$btn-font-size: 16px !default;

// Headings
$headings-color: #1e4d60;
$h1-font-size: 50px !default;
$h2-font-size: 40px !default;
$h3-font-size: 32px !default;
$h4-font-size: 25px !default;
$h5-font-size: 20px !default;
$h6-font-size: 16px !default;
$headings-font-weight: bold !default;
$headings-line-height: 1.2 !default;

h1,
.h1 {
  line-height: 56px;
}

h2,
.h2 {
  line-height: 45px;
}

h3,
.h3 {
  line-height: 36px;
}

// Href
$link-color: #3a3a3a;
$link-hover-color: #CAD63E;
$link-decoration: none;
$link-hover-decoration: none;

// Font Weight
$font-weight-light: 300;
$font-weight-base: 400;
$font-weight-bold: 700;
$font-weight-bolder: 900;

.font-weight-semi-bold {
  font-weight: 600;
}

// Tooltips
$tooltip-max-width: 250px;
$tooltip-bg: white;
$tooltip-color: #1e4d60;
$tooltip-opacity: 1;
$tooltip-border-radius: 10px;
$tooltip-arrow-color: #e5e5e5;
$tooltip-padding-x: 0;
$tooltip-padding-y: 0;

.tooltip {
  top: -1px !important;
  z-index: 10;
  padding: 0;
}

// Buttons
$btn-font-size: 16px;
$btn-focus-width: 0;
$btn-white-space: normal !default;
$btn-font-weight: 700 !default;
$btn-border-radius: 30px !default;
$btn-border-radius-lg: 30px !default;
$btn-border-width: 0;
$btn-box-shadow: 0;
$btn-active-box-shadow: 0;
$btn-focus-box-shadow: 0;

$btn-padding-y-lg: 10px;
$btn-padding-x-lg: 16px;
$btn-font-size-lg: 19px;

$btn-padding-y-md: 10px;
$btn-padding-x-md: 16px;
$btn-font-size-md: 19px;

$btn-padding-y-sm: 6px;
$btn-padding-x-sm: 12px;
$btn-font-size-sm: 14px;

$btn-padding-y: 10px;
$btn-padding-x: 14px;
$btn-font-size: 17px;

$btn-border-radius-sm: 30px;

$input-placeholder-color: #c4c4c4;
$input-disabled-color: #FFFFFF;
$form-select-disabled-color: #FFFFFF;

// Toggle Switch
$custom-control-indicator-checked-bg: #ec008c;
$custom-control-indicator-active-bg: transparent;

// Range
$custom-range-thumb-bg: #b5be00;

// Links
$link-decoration: none;

// Modals
$modal-content-bg: transparent;
$modal-content-border-width: 0;
$modal-content-box-shadow-sm-up: 0;
$modal-content-box-shadow-xs: 0;

.modal-lg {
  max-width: 560px !important;
}

//Remove
.carousel-indicators {
  visibility: hidden;
}

//Helpers
.cursor-pointer {
  cursor: pointer;
}

html,
body,
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

.max-view-width {
  max-width: 1420px;
}

//Hide Down Carrot for button dropdown
#notifications::after,
#profile::after {
  content: none;
}

#homeDetailCard {
  height: 706px;
}

#homeStatusCard,
#homeScheduleCard {
  height: 706px;
}

#homeScheduleCard {
  max-width: 455px;
}

#breadcrumbs {
  margin-top: -1px;
}

// Hide validation icons
.form-control.is-valid,
.form-control.is-invalid,
.was-validated .form-control:valid,
.was-validated .form-control:invalid {
  background-image: none !important;
}

.was-validated .form-select:valid:not([multiple])[size="1"],
.was-validated .form-select:valid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple]):not([size]),
.was-validated .form-select:invalid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple])[size="1"],
.form-select.is-valid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple]):not([size]),
.form-select.is-invalid:not([multiple])[size="1"] {
  --bs-form-select-bg-icon: none !important;
  padding-right: 2.25rem;
}

@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/bootstrap";

@include media-breakpoint-only(xs) {

  #homeDetailCard,
  #homeStatusCard,
  #homeScheduleCard {
    height: auto !important;
  }

  #registerModal .title {
    margin-top: 10px;
    font-size: 13px;
  }

  #breadcrumbs {
    font-size: 13px;
  }

  .table-custom {
    font-size: 12px !important;
  }
}

@include media-breakpoint-only(sm) {

  #homeDetailCard,
  #homeStatusCard,
  #homeScheduleCard {
    height: auto !important;
  }
}